import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate } from "react-router-dom";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import LoaderScreen from "../../components/layout/LoaderScreen";

function WholeSalePageComponent(props:any){
    const navigate = useNavigate();
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const innerdata = [
        {
            name: t('Invoice reports'),
            desc: "Lorem ipsum dolor sit amet consectetur. Vitae.",
            link: "/wholesale/invoice-reports",
            Icon: ReceiptIcon
        },
        {
            name: t('Commissions'),
            desc: "Lorem ipsum dolor sit amet consectetur. Vitae.",
            link: "/wholesale/commissions",
            Icon: AttachMoneyIcon
        },
    ]
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    return (
        <Box sx={{
            padding: "40px",
            background: "#fff"
        }}>
            {isTransLoaded ? (
                    <Grid container spacing={2}>
                {
                    innerdata.map((item, index) => (
                        <Grid item md={6} lg={3} key={`admin-menu-${index}`}>
                            <Box sx={{
                                borderRadius: "15px",
                                border: " 1px solid #D8D8D8",
                                padding: "38px 25px 30px",
                                textAlign: "center",
                                "& :hover": {
                                    cursor: "pointer"
                                }
                            }} onClick={() => navigate(item.link)}>
                                <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center", margin: "auto", background: "#E8EBEE", height: "110px", width: "110px", borderRadius: "100%" }}>
                                    <item.Icon sx={{ fontSize: "50px" }} />
                                </Box>
                                <h2 style={{
                                    fontSize: "20px",
                                    fontWeight: 500,
                                    marginTop: "47px",
                                    marginBottom: "10px",
                                    color: "#313131"
                                }}>{item.name}</h2>
                                <p style={{
                                    fontSize: "14px",
                                    color: "rgba(49, 49, 49, 0.72)",
                                    margin: "0"
                                }}>{item.desc}</p>
                            </Box>
                        </Grid>
                    ))
                }

            </Grid>
            ) : <LoaderScreen />
            }
        </Box>
    )
}

function WholeSaleComponent({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(WholeSalePageComponent, "admin_dashboard", isAllow, {}, dependency);
    return <MainComponent />
}
export default WholeSaleComponent;