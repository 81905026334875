import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { RootState } from '../../redux/store';
import { useFormik } from 'formik';
import { FormControl, Grid, TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import colorConfigs from '../../configs/colorConfigs';
import { cleanDateObject } from '../../components/layout/Helper';
import SearchIcon from "@mui/icons-material/Search"
import AppPermissionHOC from '../../components/layout/AppPermissionHOC';
import { AppAPiUrl, appModelPermission, appModelType } from '../../configs/appData';
import { useSelector } from 'react-redux';
import PaginationMaterialTable from '../../components/layout/PaginationMaterialTable';
import TranslationGetHOC from '../../components/layout/TranslationGetHOC';
import LoaderScreen from '../../components/layout/LoaderScreen';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const CustomFormWrap = (props: any) => {
    const appFullOrg = useSelector((state: RootState) => state.appState.appOrg);
    const { setcdrsAction, setCDRsDependObject, tabStatus, t } = props;
    //const [firstTimeStatus, setFirstTimeStatus] = useState(true);
    var getTodayDate = new Date(), y = getTodayDate.getFullYear(), m = getTodayDate.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const cdrsForm = useFormik({
        initialValues: {
            startdate: firstDay,
            enddate: lastDay,
            phonenumber: "",
            goodsign_org_identifier: appFullOrg.goodsign_org_identifier
        },
        onSubmit: (val) => {
            //console.log(props);
            setcdrsAction(true);
        }
    })
    const dateFormt = (val: any) => {
        let newDate = new Date(val);
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        return `${year}-${month}-${day}`;
    }
    const resetForm = useCallback(() => {
        cdrsForm.resetForm();
        // eslint-disable-next-line
    }, [tabStatus])
    useEffect(() => {
        if (Object.keys(appFullOrg).length > 0) {
            let strdate = dateFormt(cdrsForm.values.startdate);
            let enddate = dateFormt(cdrsForm.values.enddate);
            let newData = {
                startdate: strdate,
                enddate: enddate,
                phonenumber: cdrsForm.values.phonenumber,
                goodsign_org_identifier: appFullOrg.goodsign_org_identifier
            }
            let dataset = cleanDateObject(newData);
            setCDRsDependObject((pred: any) => pred = { ...pred, ...dataset });
            // if (firstTimeStatus) {
            //     //setcdrsAction((prev: any) => prev = true);
            //     //setFirstTimeStatus((prev: any) => prev = false);
            // }
        }
        // eslint-disable-next-line
    }, [cdrsForm.values, appFullOrg, tabStatus]);
    useEffect(() => {
        resetForm();
    }, [resetForm])
    return (<form onSubmit={cdrsForm.handleSubmit}>
        <Grid container spacing={5} alignItems={"end"}>
            <Grid item md={3} sm={12} xs={12}>
                <FormControl sx={{ width: '100%' }} className="cm-datepicker-add">
                    <div className="form-lable-name">{t("StartDate")}</div>
                    <DatePicker
                        dateFormat="d MMMM yyyy"
                        className="cm-date-picker-field"
                        name="startdate"
                        selected={cdrsForm.values.startdate}
                        onChange={(date: any) => {
                            setcdrsAction(false);
                            cdrsForm.setFieldValue('startdate', date);
                        }} />
                </FormControl>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <FormControl sx={{ width: '100%' }} className="cm-datepicker-add">
                    <div className="form-lable-name">{t("EndDate")}</div>
                    <DatePicker
                        dateFormat="d MMMM yyyy"
                        selected={cdrsForm.values.enddate}
                        className="cm-date-picker-field"
                        name="enddate"
                        onChange={(date: any) => {
                            setcdrsAction(false);
                            cdrsForm.setFieldValue('enddate', date);
                        }} />
                </FormControl>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <Box sx={{ width: "100%" }}>
                    <div className="form-lable-name">
                        {t("Number")}
                    </div>
                    <FormControl sx={{ width: "100%" }}>
                        <TextField
                            type='text'
                            name="phonenumber"
                            variant="outlined"
                            value={cdrsForm.values.phonenumber}
                            onChange={cdrsForm.handleChange}
                            onKeyDown={() => {
                                setcdrsAction(false);
                            }}
                            onKeyUp={() => {
                                if (cdrsForm.values.phonenumber === '') {
                                    setcdrsAction(false);
                                }
                            }}
                        />
                    </FormControl>
                    {cdrsForm.errors.phonenumber && cdrsForm.touched.phonenumber && <p className="cm-form-error">{t(`${cdrsForm.errors.phonenumber}`)}</p>}
                </Box>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    className="cm-btn-style"
                    sx={{
                        color: colorConfigs.btnColor.hoverbgWhite,
                        background: colorConfigs.btnColor.bgColorGreen,
                        "&: hover": {
                            color: colorConfigs.btnColor.bgColorGreen,
                            background: colorConfigs.btnColor.hoverbgWhite,
                        },
                    }}
                ><SearchIcon />
                </Button>
            </Grid>
        </Grid>
    </form>)
}
type Props = {
    translateDataft: any;
    translationStatus: any;
};
const TrafficPage = (props: Props) => {
    const [cdrsDependObject, setCDRsDependObject] = useState({});
    const [cdrsAction, setcdrsAction] = useState(false);
    const appFullOrg = useSelector((state: RootState) => state.appState.appOrg);
    const [customTabValue, setCustomTabValue] = useState(0);
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const customTabHandleChange = (newValue: number) => {
        setcdrsAction((prev) => prev = false);
        setCustomTabValue(newValue);
    }
    var getTodayDate = new Date(), y = getTodayDate.getFullYear(), m = getTodayDate.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    const dateFormt = (val: any) => {
        let newDate = new Date(val);
        let year = newDate.getFullYear();
        let month = newDate.getMonth();
        let day = newDate.getDate();
        return `${year}-${month}-${day}`;
    }

    const SS_DataTableRow = [
        {
            header: t('ProductType'),
            accessorFn: (row: any) => row.ProductType,
        },
        {
            header: t('TypeOfPayment'),
            accessorFn: (row: any) => row.TypeOfPayment,
        },
        {
            header: t('ProductDescription'),
            accessorFn: (row: any) => row.ProductDescription,
        },
        {
            header: t('Pcs'),
            accessorFn: (row: any) => row.Pcs,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            header: t('Total'),
            accessorFn: (row: any) => row.Total,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },

    ];
    
    const Details_SSub_DataTableRow = [
        {
            header: t('Phone'),
            accessorFn: (row: any) => row.Phonenumber,
        },
        {
            header: t('UserName'),
            accessorFn: (row: any) => row.Username,
        },
        {
            header: t('TypeOfPayment'),
            accessorFn: (row: any) => row.TypeOfPayment,
        },
        {
            header: t('Product'),
            accessorFn: (row: any) => row.product,
        },
        {
            header: t('Pcs'),
            accessorFn: (row: any) => row.Pcs,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            header: t('Total'),
            accessorFn: (row: any) => row.Total,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },

    ];
    const SSub_DataTableRow = [
        {
            header: t('ProductType'),
            accessorFn: (row: any) => row.ProductType,
        },
        {
            header: t('PhoneNumber'),
            accessorFn: (row: any) => row.Phonenumber,
        },
        {
            header: t('Username'),
            accessorFn: (row: any) => row.Username,
        },
        {
            header: t('Monthly'),
            accessorFn: (row: any) => row.Monthly,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            header: t('Usage'),
            accessorFn: (row: any) => row.Usage,
        },
        {
            header: t('Total'),
            accessorFn: (row: any) => row.Total,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },

    ];
    const CDRs_DataTableRow = [
        {
            header: t('FirstName'),
            width: "200px",
            accessorFn: (row: any) => row.Firstname === '' ? "No Data" : row.Firstname,
        },
        {
            header: t('LastName'),
            width: "150px",
            accessorFn: (row: any) => row.Lastname,
        },
        {
            header: t('PrimaryNumber'),
            width: "200px",
            accessorFn: (row: any) => row.Phonenumber,
        },
        {
            header: t('Profile'),
            accessorFn: (row: any) => row.Profile,
        },
        {
            header: t('Time'),
            accessorFn: (row: any) => row.Time,
        },
        {
            header: t('Bnum'),
            accessorFn: (row: any) => row.Bnum,
        },
        {
            header: t('CallType'),
            accessorFn: (row: any) => row.Calltype,
        },
        {
            header: t('SubType'),
            accessorFn: (row: any) => row.Subtype,
        },
        {
            header: t('Duration'),
            accessorFn: (row: any) => row.Duration,
        },
        {
            header: t('Price'),
            accessorFn: (row: any) => row.Price,
        },
    ];
    const tableRow = [SS_DataTableRow, SSub_DataTableRow, Details_SSub_DataTableRow, CDRs_DataTableRow];
    const setSummaryData = (type: string) => {
        let strdate = dateFormt(firstDay);
        let enddate = dateFormt(lastDay);
        const newData = {
            startdate: strdate,
            enddate: enddate,
            summery_type: type,
            goodsign_org_identifier: appFullOrg.goodsign_org_identifier
        }
        setCDRsDependObject((prev) => prev = newData);
        //setcdrsAction((prev) => prev = true);
    }
    const setCDRsSummaryData = () => {
        let strdate = dateFormt(firstDay);
        let enddate = dateFormt(lastDay);
        const newData = {
            startdate: strdate,
            enddate: enddate,
            goodsign_org_identifier: appFullOrg.goodsign_org_identifier
        }
        setCDRsDependObject(newData);
        //setcdrsAction((prev) => prev = true);
    }
    const setCustomTabs = React.useCallback(() => {
        if (Object.keys(appFullOrg).length > 0) {
            if (customTabValue === 0) {
                setSummaryData("services");
            }
            if (customTabValue === 1) {
                setSummaryData("subscriptions");
            } 
            if (customTabValue === 2) {
                setSummaryData("detailsummery");
            }
            if (customTabValue === 3) {
                setCDRsSummaryData();
            }
        }
        // eslint-disable-next-line
    }, [customTabValue, appFullOrg])
    useEffect(() => {
        setCustomTabs();
    }, [setCustomTabs]);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
        return (
            <div className='cm-traffic-main' style={{ marginTop: "20px" }}>
                <div className='cm-global-tabs-wrapper'>
                    {isTransLoaded ? <Box sx={{ width: '100%' }}>
                        <Stack direction="row" spacing={1} className="cm-trafics-tab-btn-wrapper cm-global-tab-btn-wrapper">
                            <Button variant="outlined" {...a11yProps(0)} onClick={() => customTabHandleChange(0)} className={customTabValue === 0 ? "tab-active cm-global-tab-btn" : "cm-global-tab-btn"}>{t('SummarybyServices')}</Button>
                            <Button variant="outlined" {...a11yProps(1)} onClick={() => customTabHandleChange(1)} className={customTabValue === 1 ? "tab-active cm-global-tab-btn" : "cm-global-tab-btn"}>{t('SummarybySubscriptions')}</Button>
                            <Button variant="outlined" {...a11yProps(2)} onClick={() => customTabHandleChange(2)} className={customTabValue === 2 ? "tab-active cm-global-tab-btn" : "cm-global-tab-btn"}>{t('DetailedSummarybySubscriptions')}</Button>
                            <Button variant="outlined" {...a11yProps(3)} onClick={() => customTabHandleChange(3)} className={customTabValue === 3 ? "tab-active cm-global-tab-btn" : "cm-global-tab-btn"}>{t('CDRs')}</Button>
                        </Stack>
                        <Box className='cm-global-tabs-content' style={{ marginTop: "20px" }}>
                            <Box className='cm-global-tab-inner-content'>
                                <div style={{ marginBottom: "20px" }}>
                                    <CustomFormWrap t={t} status={cdrsAction} tabStatus={customTabValue} setCDRsDependObject={setCDRsDependObject} setcdrsAction={setcdrsAction} />
                                </div>
                                {customTabValue === 0 && (<>
                                    <PaginationMaterialTable
                                        isallData={true}
                                        apiLink={AppAPiUrl.AccountingDescription}
                                        tRow={tableRow[customTabValue]}
                                        dependRun={cdrsAction}
                                        dependValue={cdrsDependObject}
                                    /></>)}
                                {customTabValue === 1 && (<>
                                    <PaginationMaterialTable
                                        isallData={true}
                                        apiLink={AppAPiUrl.AccountingDescription}
                                        tRow={tableRow[customTabValue]}
                                        dependRun={cdrsAction}
                                        dependValue={cdrsDependObject}
                                    /></>)}
                                {customTabValue === 2 && (<>
                                    <PaginationMaterialTable
                                        isallData={true}
                                        apiLink={AppAPiUrl.AccountingDescription}
                                        tRow={tableRow[customTabValue]}
                                        dependRun={cdrsAction}
                                        dependValue={cdrsDependObject}
                                    /></>)}
                                {customTabValue === 3 && (<>
                                    <PaginationMaterialTable
                                        isallData={true}
                                        apiLink={customTabValue !== 3 ? AppAPiUrl.AccountingDescription : AppAPiUrl.AccountingCalldetails}
                                        tRow={tableRow[customTabValue]}
                                        dependRun={cdrsAction}
                                        dependValue={cdrsDependObject}
                                    /></>)}
                            </Box>

                        </Box>
                    </Box>
                        : <LoaderScreen />
                    }
                </div>
            </div>
        );
}
function AccountTrafficPageComponentReady({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(TrafficPage, "accounting_traffic", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AccountTrafficPageComponentReady, appModelPermission.ACCOUNTINGTRAFFIC, appModelType.READ);