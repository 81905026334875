import { Box, FormControl, Stack } from "@mui/material";
import LoaderScreen from "../../../components/layout/LoaderScreen";
import TranslationGetHOC from "../../../components/layout/TranslationGetHOC";
import { priceFormatWithCurrency } from "../../../components/layout/Helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import MaterialReactDataTable from "../../../components/layout/MaterialReactDataTable";
import { AppAPiUrl, appModelPermission, appModelType } from "../../../configs/appData";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import AppPermissionHOC from "../../../components/layout/AppPermissionHOC";
function InvoiceReportPageComponent(props:any){
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [tableSelectOptions, setTableSelectOptions] = useState<any>([]);
    const [tableDataShow, setTableDataShow] = useState([]);
    const [wsCommisionDate, setWSCommissionDate]= useState(null);
    const [paramObject, setParamObject]= useState({});
    const [tableTotalPrice, setTableTotalPrice] = useState(0);
    const [totalPriceCurrency, setTotalPriceCurrency] = useState([]);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const navigate = useNavigate();
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const commisionTableRow = [
        {
            size: 300,
            accessorFn: (row: any) => row.customer.name,
            header: t('Organization'),
        },
        {
            accessorFn: (row: any) => row.number,
            header: t("InvoiceNumber"),
        },
        {
            accessorKey: 'total',
            header: t('Total'),
            size: 230,
            accessorFn: (row: any) => "$ "+row.total,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
    ];
    const getUniqueArray = (arr: any, index: any) => {
        const unique = arr
            .map((e: any) => e[index])
            // store the keys of the unique objects
            .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e]).map((e: any) => arr[e]);
        return unique;
    }
    const dateFormt = (val: any) => {
        let newDate = new Date(val);
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        return `${year}-${month}`;
    }
    const getpopDate = (row: any, tableData: any) =>{
        navigate("/wholesale/commissions/"+row.id);
    };
    const cmSetTableData = (rows: any) => setTableDataShow(rows);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    useEffect(() => {
        if (tableDataShow.length > 0) {
            const newOptions = tableDataShow.map((item: any) => {
                let output: any = {};
                if (item.state === "open" || item.state === "Open") {
                    output["text"] = "Open";
                    output["value"] = "true";
                }
                else {
                    output["text"] = "Close";
                    output["value"] = "false";
                }
                return output;
            })
            const uniqueOptions = getUniqueArray(newOptions, "text");
            setTableSelectOptions(uniqueOptions)
        }
    }, [tableDataShow]);

    const handleTotalPrice = (data:any) =>{
        const total = data.reduce(
            (accumulator:any, currentValue:any) => accumulator + currentValue.total,
            0,
          );
        setTableTotalPrice(total)
    }
    return(
        <Box className='cm-global-tabs-wrapper' sx={{ overflow: "hidden", marginTop: "20px" }}>
            {isTransLoaded ?
                <div className='cm-form-inner-fields cm-pagination-billing-dt cm-table-remvove-style'>
                    <Box sx={{marginBottom:"20px"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl className="cm-datepicker-add">
                            <div className="form-lable-name">{t("SelectPeriod")}</div>
                            <DatePicker
                                views={['year', 'month']}
                                sx={{background:"#fff", p:"0px !important"}}
                                value={wsCommisionDate}
                                className="cm-date-picker-field"
                                onChange={(date: any) => {
                                    setWSCommissionDate(date);
                                    setParamObject({date:dateFormt(date)});
                                    console.log(paramObject);
                                }} />
                        </FormControl>
                        </LocalizationProvider>
                    </Box>
                      <MaterialReactDataTable
                            paramDependent={paramObject} 
                            apiLink={AppAPiUrl.WholeSaleCommission}
                            tRow={commisionTableRow}
                            triggerUpdate={true}
                            clickFunc={getpopDate}
                            clickFuncDataShow ={true}
                            setTableDataShow={cmSetTableData}
                            callbackFunction={handleTotalPrice}
                            /> 
                        
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent={"space-between"} className="sub-table-price">
                        <Box>
                            <Box className='cm-order-sale-total-tbl' sx={{ borderTop: 'none', marginTop: "20px" }}>
                                <Box className='order-sale-table-data tfoot'>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent={"between"}>
                                    <Box className='order-sl-th'>{t('Total')}:{tableTotalPrice}</Box>
                                    <Box className='order-sl-td'>{totalPriceCurrency.length > 0 &&
                                    priceFormatWithCurrency(totalPriceCurrency, tableTotalPrice)
                                    }</Box>
                                </Stack>
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </div>
                : <LoaderScreen/>}
        </Box>
    )
}

function WholesaleCommissionPage({ isAllow2}: any) {
    const isAllow = true;
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(InvoiceReportPageComponent, "accounting_billing", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}

export default AppPermissionHOC(WholesaleCommissionPage, appModelPermission.COMMISSIONS, appModelType.READ)