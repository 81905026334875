import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as Yup from 'yup';
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AxiosMain from "../../components/layout/AxiosMain";
import { Modal, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import LoaderScreen from "../../components/layout/LoaderScreen";
import { setAppNextRoute, setAppcurrentPageForm } from "../../redux/features/appStateSlice";
import PaginationDropDown from "../../components/layout/PaginationDropDown";
import DatePicker from "react-datepicker";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import PaginationEditDropDown from "../../components/layout/PaginationEditDropDown";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
type Props = {
    translateDataft: any;
    translationStatus: any;
    isAllow: any;
};
const AddMobileUser = (props: Props) => {
    const dispatch = useDispatch();
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const [isPermissionAllow, setPermissionAllow] = useState(false);
    const [isCatalougeSModalOpen, setCatalougeSModalOpen] = useState(false);
    const OrgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const isCurrenFormEidt = useSelector((state: RootState) => state.appState.appCurrentPageForm);
    const isNextRoute = useSelector((state: RootState) => state.appState.appNextRoute);
    const isPervRoute = useSelector((state: RootState) => state.appState.appPrevRoute);
    const [catalougeSData, setCatalougeSData] = useState([]);
    const [form_did_number_Value, setform_did_number_Value] = useState("");
    const [activationDate, setActivationDate] = useState(new Date());
    const barringsstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
    };
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const formSwalTranslation = {
        formSwalTitle: t("AreYouWanttoSave"),
        formSwalText: t("Youwontbeabletorevertthis"),
        fomrSwalCancel: t("Cancel"),
        formSwalSaved: t("YesSavedit"),
        formSwalDataSaved: t("YourDatahasbeensaved"),
        formSwalSubmited: t("Submited"),
        formSwalWrong: t("SomethingWentWrong"),
        emptyData: t('EmptyData'),
        formdontSaveChanges: t('Areyousureyoudontwanttosavethechanges?')
    };
    const AddUserSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        number: Yup.string().required('Required'),
        mobile_product: Yup.string().required('Required'),
        barring_voice: Yup.string(),
        barring_sms: Yup.string(),
        barring_mms: Yup.string(),
        barring_data: Yup.string(),
        barring_roaming: Yup.string(),
        barring_roamingdata: Yup.string(),
        carrier: Yup.string().required('Required'),
        sim: Yup.string().required('Required'),
        // catalog_first_name: Yup.string().required('Required'),
        // catalog_last_name: Yup.string().required('Required'),
        // catalog_street_address: Yup.string().required('Required'),
        // catalog_city: Yup.string().required('Required'),
        // catalog_postal_code: Yup.string().required('Required'),
        //voicemail_number: Yup.string().required('Required'),
        //form_user: Yup.string().required('Required')

    });
    const sendCatalogData = (data: any) => {
        const APiValues = { ...catalougeSFormik.values, "sub_id": data };
        AxiosMain.post(AppAPiUrl.MobileCatalog, APiValues).then((res) => {
            dispatch(setAppcurrentPageForm("process"));
            if (res.status === 201) {
                Swal.fire(
                    formSwalTranslation.formSwalSubmited,
                    formSwalTranslation.formSwalDataSaved,
                    "success"
                );
                dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
                dispatch(setAppcurrentPageForm("process"));
            }
        }).catch((e) => {
            console.log("Unable to Add Catalouge");
            Swal.fire({
                title: "Error",
                text: formSwalTranslation.formSwalWrong,
                icon: "error"
            });
        })
    }
    const formik = useFormik({
        initialValues: {
            name: "",
            number: form_did_number_Value,
            mobile_product: "",
            voicemail_number: "",
            carrier: "",
            sim: "",
            subscription_open_date: activationDate,
            // subscription_close_date:"",
            //catalog_status:"",
            barring_voice: 1,
            barring_sms: 1,
            barring_mms: 1,
            barring_data: 1,
            barring_roaming: 1,
            barring_roamingdata: 1,
            form_voice_package: "",
            form_data_package: "",
            //form_user: "",
            // catalog_first_name: "",
            // catalog_last_name: "",
            // catalog_street_address: "",
            // catalog_city: "",
            // catalog_postal_code: ""
        },
        validationSchema: AddUserSchema,
        onSubmit: (values) => {
            //setValueinForm();
            const APiValues = { ...values, "organization": OrgnisationData.id };
            //console.log("Add Mobile API Data", APiValues);

            AxiosMain.post(AppAPiUrl.MobileSubscription, APiValues).then((res) => {
                if (res.status === 201) {
                    sendCatalogData(res.data.id);
                }
            }).catch((e) => {
                console.log("error ", e);
                const { response } = e;
                console.log(response.data);
                let errormsg: any = [];
                if (response.status === 400) {
                    // eslint-disable-next-line
                    Object.entries(response.data).map(([key, value]: any) => {
                        console.log(key, value)
                        if (value.length > 0) {
                            // eslint-disable-next-line
                            value.map((val: any) => {
                                errormsg.push(val);
                            })
                        }

                    })
                    if (errormsg.length > 0) {
                        Swal.fire({
                            title: "Error",
                            text: errormsg[0],
                            icon: "error"
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: formSwalTranslation.formSwalWrong,
                            icon: "error"
                        });
                    }

                } else {
                    Swal.fire({
                        title: "Error",
                        text: formSwalTranslation.formSwalWrong,
                        icon: "error"
                    });
                }
            })
            // Swal.fire({
            //     title: formSwalTranslation.formSwalTitle,
            //     text: formSwalTranslation.formSwalText,
            //     icon: "warning",
            //     cancelButtonText: formSwalTranslation.fomrSwalCancel,
            //     showCancelButton: true,
            //     confirmButtonColor: "#103256",
            //     cancelButtonColor: "#d33",
            //     confirmButtonText: formSwalTranslation.formSwalSaved,
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         Swal.fire(
            //             formSwalTranslation.formSwalSubmited,
            //             formSwalTranslation.formSwalDataSaved,
            //             "success"
            //         );
            //     }
            // });
        },
    });

    const AddCatalougeSSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        street_address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        postal_code: Yup.string().required('Required')
    });

    const catalougeSFormik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            street_address: "",
            city: "",
            postal_code: ""
        },
        validationSchema: AddCatalougeSSchema,
        onSubmit: (values: any) => {
            console.log("Catalouge Values ", values);
            formik.setFieldValue("catalog_first_name", values.first_name);
            formik.setFieldValue("catalog_last_name", values.last_name);
            formik.setFieldValue("catalog_street_address", values.street_address);
            formik.setFieldValue("catalog_city", values.city);
            formik.setFieldValue("catalog_postal_code", values.postal_code);
            let data: any = [];
            data.push(values)   
            setCatalougeSData(data);
            setCatalougeSModalOpen(false);
        }
    })
    const cancelCatalogeForm = () => {
        catalougeSFormik.resetForm();
        setCatalougeSModalOpen(false)
    }
    //settings up Barrings Modal
    const setValueinForm = () => {
        const cmCarrie = formik.values.carrier.toString();
        if (cmCarrie !== '') {
            if (cmCarrie === "2") {
                const noValues = 1;
                formik.setFieldValue("barring_roamingdata", noValues);
                formik.setFieldValue("barring_roaming", noValues);
                formik.setFieldValue("barring_data", noValues);
                formik.setFieldValue("barring_mms", noValues);
                formik.setFieldValue("barring_sms", noValues);
                formik.setFieldValue("barring_voice", noValues);
            }
            else {
                formik.setFieldValue("barring_roamingdata", 8);
                formik.setFieldValue("barring_roaming", 3);
                formik.setFieldValue("barring_data", 3);
                formik.setFieldValue("barring_mms", 3);
                formik.setFieldValue("barring_sms", 6);
                formik.setFieldValue("barring_voice", 11);
            }

        }
    }

    useEffect(() => {
        setValueinForm();
        // eslint-disable-next-line
    }, [formik.values.carrier])
    const areYouSure = () => {
        Swal.fire({
            text: formSwalTranslation.formdontSaveChanges,
            icon: 'warning',
            cancelButtonText: formSwalTranslation.fomrSwalCancel,
            showCancelButton: true,
            confirmButtonColor: '#103256',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('Yes')}`,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setAppcurrentPageForm("process"));
            }
        })
    }

    useEffect(() => {
        if (formik.dirty && !isPermissionAllow) {
            dispatch(setAppcurrentPageForm("yes"));
        }
        // eslint-disable-next-line
    }, [formik.dirty, isPermissionAllow]);
    useEffect(() => {
        if (isCurrenFormEidt === "yes" && isNextRoute !== "" && isNextRoute !== isPervRoute) {
            areYouSure();
        }
        // eslint-disable-next-line
    }, [isNextRoute, isCurrenFormEidt, isPervRoute]);
    const discardChanges = () => {
        dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
        Swal.fire({
            text: formSwalTranslation.formdontSaveChanges,
            icon: 'warning',
            cancelButtonText: formSwalTranslation.fomrSwalCancel,
            showCancelButton: true,
            confirmButtonColor: '#103256',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('Yes')}`,
        }).then((result: any) => {
            if (result.isConfirmed) {
                dispatch(setAppcurrentPageForm("process"));
                //navigate("/mobiles/mobile-user-list")
            }
        })
    }
    useEffect(() => {
        const { translateDataft, isAllow } = props;
        if (!isPermissionAllow) {
            setPermissionAllow(isAllow);
        }
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
        // eslint-disable-next-line
    }, [props, appCountry]);
    return (
        <>
            <div className="cm-user-tabs-wrapper cm-global-tabs-wrapper cm-user-main">
                <div className="cm-user-name-wrap">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <div className="fw-600">{t("AddNewMobile")}</div>
                    </Stack>
                </div>
                <div>
                    {/* Catalog Services  */}
                    <Modal open={isCatalougeSModalOpen} onClose={() => setCatalougeSModalOpen(false)}>
                        <Box sx={barringsstyle}>
                            <form onSubmit={catalougeSFormik.handleSubmit}>
                                <Grid container spacing={5}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Box sx={{ width: "100%" }}>
                                            <div className="form-lable-name">
                                                {t("FirstName")}
                                            </div>
                                            <FormControl sx={{ width: "100%" }}>
                                                <TextField
                                                    name="first_name"
                                                    variant="outlined"
                                                    value={catalougeSFormik.values.first_name}
                                                    onChange={catalougeSFormik.handleChange}
                                                    onBlur={catalougeSFormik.handleBlur}
                                                />
                                            </FormControl>
                                            {catalougeSFormik.errors.first_name && catalougeSFormik.touched.first_name && <p className="cm-form-error">{t(`${catalougeSFormik.errors.first_name}`)}</p>}
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Box sx={{ width: "100%" }}>
                                            <div className="form-lable-name">
                                                {t("LastName")}
                                            </div>
                                            <FormControl sx={{ width: "100%" }}>
                                                <TextField
                                                    name="last_name"
                                                    variant="outlined"
                                                    value={catalougeSFormik.values.last_name}
                                                    onChange={catalougeSFormik.handleChange}
                                                    onBlur={catalougeSFormik.handleBlur}
                                                />
                                            </FormControl>
                                            {catalougeSFormik.errors.last_name && catalougeSFormik.touched.last_name && <p className="cm-form-error">{t(`${catalougeSFormik.errors.last_name}`)}</p>}
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Box sx={{ width: "100%" }}>
                                            <div className="form-lable-name">
                                                {t("PostalCode")}
                                            </div>
                                            <FormControl sx={{ width: "100%" }}>
                                                <TextField
                                                    name="postal_code"
                                                    variant="outlined"
                                                    value={catalougeSFormik.values.postal_code}
                                                    onChange={catalougeSFormik.handleChange}
                                                    onBlur={catalougeSFormik.handleBlur}
                                                />
                                            </FormControl>
                                            {catalougeSFormik.errors.postal_code && catalougeSFormik.touched.postal_code && <p className="cm-form-error">{t(`${catalougeSFormik.errors.postal_code}`)}</p>}
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Box sx={{ width: "100%" }}>
                                            <div className="form-lable-name">
                                                {t("City")}
                                            </div>
                                            <FormControl sx={{ width: "100%" }}>
                                                <TextField
                                                    name="city"
                                                    variant="outlined"
                                                    value={catalougeSFormik.values.city}
                                                    onChange={catalougeSFormik.handleChange}
                                                    onBlur={catalougeSFormik.handleBlur}
                                                />
                                            </FormControl>
                                            {catalougeSFormik.errors.city && catalougeSFormik.touched.city && <p className="cm-form-error">{t(`${catalougeSFormik.errors.city}`)}</p>}
                                        </Box>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box sx={{ width: "100%" }}>
                                            <div className="form-lable-name">
                                                {t("StreetAddress")}
                                            </div>
                                            <FormControl sx={{ width: "100%" }}>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    name="street_address"
                                                    value={catalougeSFormik.values.street_address}
                                                    onChange={catalougeSFormik.handleChange}
                                                    onBlur={catalougeSFormik.handleBlur}
                                                />
                                            </FormControl>
                                            {catalougeSFormik.errors.street_address && catalougeSFormik.touched.street_address && <p className="cm-form-error">{t(`${catalougeSFormik.errors.street_address}`)}</p>}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ marginTop: "40px" }}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent={"end"}
                                        className="form-submit-cancel-btn-wrapper"
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            endIcon={<CheckCircleIcon />}
                                            className="cm-btn-sm-style"
                                            sx={{
                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                background: colorConfigs.btnColor.bgColorGreen,
                                                "&: hover": {
                                                    color: colorConfigs.btnColor.bgColorGreen,
                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                },
                                            }}
                                        >
                                            {t("saveChanges")}
                                        </Button>
                                        <Button
                                            className="cm-btn-sm-style"
                                            variant="contained"
                                            endIcon={<CancelIcon />}
                                            onClick={() => cancelCatalogeForm()}
                                            sx={{
                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                background: colorConfigs.btnColor.bgColorRed,
                                                "&: hover": {
                                                    color: colorConfigs.btnColor.bgColorRed,
                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                },
                                            }}
                                        >Cancel</Button>
                                    </Stack>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
                </div>
                {isTransLoaded ? (
                    <div className="cm-user-form-wrap">
                    <Box sx={{ width: "100%" }}>
                        <Box className="cm-global-tabs-content">
                            <form onSubmit={formik.handleSubmit}>
                                <Box className="cm-global-tab-inner-content">
                                    <Grid container spacing={5}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Box className="cm-form-inner-fields">
                                                <div className="form-row-title">
                                                    {t("BasicDetails")}
                                                </div>
                                                <Box className="form-mb-30">
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={3}>
                                                            <Grid item md={6} sm={6} xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <div className="form-lable-name">
                                                                        {t("name")}
                                                                    </div>
                                                                    <FormControl sx={{ width: "100%" }}>
                                                                        <TextField
                                                                            id="form_name"
                                                                            name="name"
                                                                            variant="outlined"
                                                                            value={formik.values.name}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                        />
                                                                    </FormControl>
                                                                    {formik.errors.name && formik.touched.name && <p className="cm-form-error">{formik.errors.name}</p>}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={6} sm={6} xs={12}>
                                                                <Box className="cm-form-inner-fields">
                                                                    <Box className="">
                                                                        <div className="form-lable-name">
                                                                            {t("MobileNumber")}
                                                                        </div>
                                                                        <FormControl sx={{ width: "100%" }}>
                                                                            <TextField
                                                                                id="number"
                                                                                name="number"
                                                                                variant="outlined"
                                                                                value={form_did_number_Value}
                                                                                onChange={(e) => { formik.handleChange(e); formik.setFieldValue("number", e.target.value); setform_did_number_Value(e.target.value) }}
                                                                            />
                                                                        </FormControl>
                                                                        {formik.errors.number && formik.touched.number && <p className="cm-form-error">{formik.errors.number}</p>}
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <PaginationDropDown
                                                                        apiLink={AppAPiUrl.MobileCarrier}
                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                        optionLableName={`${t("Carrier")}`}
                                                                        saveValue={formik}
                                                                        formFiled="carrier"
                                                                    />
                                                                    {formik.errors.carrier && formik.touched.carrier && (
                                                                        <p className="cm-form-error">{`${formik.errors.carrier}`}</p>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <PaginationDropDown
                                                                        dependOn="apiParamValue"
                                                                        apiParamName="available"
                                                                        apiParamValue={true}
                                                                        defaultSort="no"
                                                                        apiLink={AppAPiUrl.MobileSim}
                                                                        apiDataMap={{ labelName: "icc", valueName: "id" }}
                                                                        optionLableName={`${t("SimCard")}`}
                                                                        saveValue={formik}
                                                                        formFiled="sim"
                                                                    />
                                                                    {formik.errors.sim && formik.touched.sim && (
                                                                        <p className="cm-form-error">{`${formik.errors.sim}`}</p>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <FormControl sx={{ width: '100%' }} className="cm-datepicker-add">
                                                                        <div className="form-lable-name">{t('ActivationDate')}</div>
                                                                        <DatePicker
                                                                            showTimeSelect
                                                                            dateFormat="d MMMM yyyy HH:mm"
                                                                            timeFormat="HH:mm"
                                                                            isClearable={true}
                                                                            minDate={new Date()}
                                                                            className="cm-date-picker-field" name="form_activation_date" selected={activationDate} onChange={(date: any) => {
                                                                                setActivationDate(date);
                                                                                formik.setFieldValue("subscription_open_date", date)
                                                                            }} />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box className="form-mb-30">
                                                    <div className="form-row-title">
                                                        {t("MobileProduct")}
                                                    </div>
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <PaginationDropDown
                                                                        dependOn="apiParamValue"
                                                                        apiParamName="carrier"
                                                                        apiParamValue={formik.values.carrier}
                                                                        apiLink={AppAPiUrl.Mobileproduct}
                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                        optionLableName={`${t("Product")}`}
                                                                        saveValue={formik}
                                                                        formFiled="mobile_product"
                                                                    />
                                                                    {formik.errors.mobile_product && formik.touched.mobile_product && <p className="cm-form-error">{formik.errors.mobile_product}</p>} 
                                                                </Box>
                                                            </Grid>
                                                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                                                <Box className="cm-form-inner-fields">
                                                                    <Box className="">
                                                                        <div className="form-lable-name">
                                                                            {t("VoicemailNumber")}
                                                                        </div>
                                                                        <FormControl sx={{ width: "100%" }}>
                                                                            <TextField
                                                                                id="voicemail_number"
                                                                                name="voicemail_number"
                                                                                variant="outlined"
                                                                                value={formik.values.voicemail_number}
                                                                                onChange={(e) => { formik.handleChange(e); formik.setFieldValue("voicemail_number", e.target.value); }}
                                                                            />
                                                                        </FormControl>
                                                                        {formik.errors.voicemail_number && formik.touched.voicemail_number && <p className="cm-form-error">{formik.errors.voicemail_number}</p>}
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box className="form-mb-30">
                                                    <div className="form-row-title">
                                                        {t("barrings")}
                                                    </div>
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={8} md={12} xs={12}>
                                                                <Box className='cm-list-stack-box cm-ac-dd-rm-border'>
                                                                    <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('Voice')}</strong></Box>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                            <PaginationEditDropDown
                                                                                dependOn="apiParamValue"
                                                                                apiParamName="carrier__id"
                                                                                apiParamValue={formik.values.carrier}
                                                                                apiLink={AppAPiUrl.MobileBarringvoice}
                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                optionLableName={``}
                                                                                useFor={`${t('barrings')} ${t('Voice')}`}
                                                                                saveValue={formik}
                                                                                formFiled="barring_voice"
                                                                                defaultValue={formik.values.barring_voice}
                                                                            />
                                                                        </Box>
                                                                    </Stack>
                                                                    <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('SMS')}</strong></Box>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                            <PaginationEditDropDown
                                                                                dependOn="apiParamValue"
                                                                                apiParamName="carrier__id"
                                                                                apiParamValue={formik.values.carrier}
                                                                                apiLink={AppAPiUrl.MobileBarringsms}
                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                optionLableName={``}
                                                                                useFor={`${t('barrings')} ${t('SMS')}`}
                                                                                saveValue={formik}
                                                                                formFiled="barring_sms"
                                                                                defaultValue={formik.values.barring_sms}
                                                                            />
                                                                        </Box>
                                                                    </Stack>
                                                                    <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('MMS')}</strong></Box>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                            <PaginationEditDropDown
                                                                                dependOn="apiParamValue"
                                                                                apiParamName="carrier__id"
                                                                                apiParamValue={formik.values.carrier}
                                                                                apiLink={AppAPiUrl.MobileBarringmms}
                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                optionLableName={``}
                                                                                saveValue={formik}
                                                                                useFor={`${t('barrings')} ${t('MMS')}`}
                                                                                formFiled="barring_mms"
                                                                                defaultValue={formik.values.barring_mms}
                                                                            />
                                                                        </Box>
                                                                    </Stack>
                                                                    <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('Data')}</strong></Box>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                            <PaginationEditDropDown
                                                                                dependOn="apiParamValue"
                                                                                apiParamName="carrier__id"
                                                                                apiParamValue={formik.values.carrier}
                                                                                apiLink={AppAPiUrl.MobileBarringdata}
                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                optionLableName={``}
                                                                                useFor={`${t('barrings')} ${t('Data')}`}
                                                                                saveValue={formik}
                                                                                formFiled="barring_data"
                                                                                defaultValue={formik.values.barring_data}
                                                                            />
                                                                        </Box>
                                                                    </Stack>
                                                                    <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('Roaming')}</strong></Box>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                            <PaginationEditDropDown
                                                                                dependOn="apiParamValue"
                                                                                apiParamName="carrier__id"
                                                                                apiParamValue={formik.values.carrier}
                                                                                apiLink={AppAPiUrl.MobileBarringroaming}
                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                optionLableName={``}
                                                                                useFor={`${t('barrings')} ${t('Roaming')}`}
                                                                                saveValue={formik}
                                                                                formFiled="barring_roaming"
                                                                                defaultValue={formik.values.barring_roaming}
                                                                            />
                                                                        </Box>
                                                                    </Stack>
                                                                    <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('RoamingData')}</strong></Box>
                                                                        <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                            <PaginationEditDropDown
                                                                                dependOn="apiParamValue"
                                                                                apiParamName="carrier__id"
                                                                                apiParamValue={formik.values.carrier}
                                                                                apiLink={AppAPiUrl.MobileBarringroamingdata}
                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                optionLableName={``}
                                                                                useFor={`${t('barrings')} ${t('RoamingData')}`}
                                                                                saveValue={formik}
                                                                                formFiled="barring_roamingdata"
                                                                                defaultValue={formik.values.barring_roamingdata}
                                                                            />
                                                                        </Box>
                                                                    </Stack>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        </Box>
                                                </Box>
                                                <Box className="form-mb-30">
                                                    <div className="form-row-title">
                                                        {t("CatalogServices")}
                                                    </div>
                                                    {/* {formik.errors.catalog_first_name && formik.touched.catalog_first_name && <p className="cm-form-error">{t("CatalogServices")} {formik.errors.catalog_first_name}</p>} */}
                                                </Box>
                                                {
                                                    catalougeSData.length > 0
                                                        ?
                                                        <>
                                                            <Box className="form-mb-30">
                                                                <TableContainer className="cm-barrring-cm-table-dt">
                                                                    <Table>
                                                                        <TableHead className="cm-barrring-cm-table-hd">
                                                                            <TableRow>
                                                                                <TableCell>{t("FirstName")}</TableCell>
                                                                                <TableCell>{t("LastName")}</TableCell>
                                                                                <TableCell>{t("PostalCode")}</TableCell>
                                                                                <TableCell>{t("StreetAddress")}</TableCell>
                                                                                <TableCell>{t("City")}</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                catalougeSData.length > 0 && catalougeSData.map((item: any, index: any) => (
                                                                                    <TableRow key={`catlouge-s-data-${index}`}>
                                                                                        <TableCell>{item.first_name}</TableCell>
                                                                                        <TableCell>{item.last_name}</TableCell>
                                                                                        <TableCell>{item.postal_code}</TableCell>
                                                                                        <TableCell sx={{
                                                                                            maxWidth: "300px"
                                                                                        }}>{item.street_address}</TableCell>
                                                                                        <TableCell>{item.city}</TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                            }
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Box>
                                                            <Box className="form-mb-30" textAlign={"right"}>
                                                                <Button
                                                                    variant="contained"
                                                                    endIcon={<AddIcon />}
                                                                    className="cm-btn-sm-style"
                                                                    sx={{
                                                                        color: colorConfigs.btnColor.hoverbgWhite,
                                                                        background: colorConfigs.btnColor.bgColorGreen,
                                                                        "&: hover": {
                                                                            color: colorConfigs.btnColor.bgColorGreen,
                                                                            background: colorConfigs.btnColor.hoverbgWhite,
                                                                        },
                                                                    }}
                                                                    onClick={() => setCatalougeSModalOpen(true)}
                                                                >
                                                                    {t("Update")}
                                                                </Button>
                                                            </Box>
                                                        </>
                                                        : <Box className="form-mb-30">
                                                            <Button
                                                                variant="contained"
                                                                endIcon={<AddIcon />}
                                                                className="cm-btn-md-style"
                                                                sx={{
                                                                    color: colorConfigs.btnColor.hoverbgWhite,
                                                                    background: colorConfigs.btnColor.bgColorGreen,
                                                                    "&: hover": {
                                                                        color: colorConfigs.btnColor.bgColorGreen,
                                                                        background: colorConfigs.btnColor.hoverbgWhite,
                                                                    },
                                                                }}
                                                                onClick={() => setCatalougeSModalOpen(true)}
                                                            >
                                                                {t("Add")}
                                                            </Button>
                                                        </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* Button save changes and Discard */}
                                <Box sx={{ marginTop: "40px" }}>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent={"start"}
                                        className="form-submit-cancel-btn-wrapper"
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            endIcon={<CheckCircleIcon />}
                                            className="cm-btn-style"
                                            sx={{
                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                background: colorConfigs.btnColor.bgColorGreen,
                                                "&: hover": {
                                                    color: colorConfigs.btnColor.bgColorGreen,
                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                },
                                            }}
                                        >
                                            {t("saveChanges")}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            endIcon={<CancelIcon />}
                                            className="cm-btn-style"
                                            sx={{
                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                background: colorConfigs.btnColor.bgColorRed,
                                                "&: hover": {
                                                    color: colorConfigs.btnColor.bgColorRed,
                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                },
                                            }}
                                            onClick={() => discardChanges()}
                                        >
                                            {t("discard")}
                                        </Button>
                                    </Stack>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                        </div>
                ) : <LoaderScreen />}
            </div>
        </>
    );
}
function MSAddComponentReady({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(AddMobileUser, "mobile_subscription", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(MSAddComponentReady, appModelPermission.SUBSCRIPTION, appModelType.CREATE);