import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import {  priceFormatWithCurrency } from '../../../components/layout/Helper';
import AppPermissionHOC from '../../../components/layout/AppPermissionHOC';
import { AppAPiUrl, appModelPermission, appModelType } from '../../../configs/appData';
import { Stack } from '@mui/material';
import TranslationGetHOC from '../../../components/layout/TranslationGetHOC';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import LoaderScreen from '../../../components/layout/LoaderScreen';
import MaterialReactDataTable from '../../../components/layout/MaterialReactDataTable';

type Props = {
  translateDataft: any;
  translationStatus: any;
};

const Subscription = (props: Props) => {
  const navigate = useNavigate();
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const [tableTotalPrice, setTableTotalPrice] = useState(0);
  const [totalPriceCurrency, setTotalPriceCurrency] = useState([]);
  const [tableDataShow, setTableDataShow] = useState([]);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }
  const subscRow = [
    {
      accessorFn: (row: any) => row.code,
      header: t("SubscriptionID"),
    },
    {
      accessorFn: (row: any) => (row.template_id.length > 0 ? row.template_id[1] : ""),
      header: t("SubscriptionTemplate"),
      filterVariant: 'text',
    },
    {
      accessorFn: (row: any) => (row.aggregate_invoice_group_id.length > 0 ? row.aggregate_invoice_group_id[1] : ""),
      header: t("AggregateInvoiceGroup"),
      filterVariant: 'text',
    },
    {
      accessorFn: (row: any) => (row.user_id.length > 0 ? row.user_id[1] : ""),
      header: t("SalesPerson"),
      filterVariant: 'text',
    },
    {
      size: 300,
      accessorFn: (row: any) => new Date(row.date_start),
      id: 'date_start',
      header: t('DueDate'),
      filterVariant: 'date-range',
      Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
    },
    {
      accessorFn: (row: any) => {
        setTotalPriceCurrency(row.currency_id);
        return priceFormatWithCurrency(row.currency_id, row.recurring_total)
      },
      header: t("RecurringPrice"),
      filterVariant: 'text',
    },
  ];
  const subscRowSorting = [
    {
      descSort: "-recurring_total",
      ascSort: "recurring_total",
      id: t("RecurringPrice"),
    },
    {
      descSort: "-user_id",
      ascSort: "user_id",
      id: t("SalesPerson"),
    },
    {
      descSort: "-aggregate_invoice_group_id",
      ascSort: "aggregate_invoice_group_id",
      id: t("AggregateInvoiceGroup"),
    },
    {
      descSort: "-template_id",
      ascSort: "template_id",
      id: t("SubscriptionTemplate"),
    },
    {
      descSort: "-code",
      ascSort: "code",
      id: t("SubscriptionID"),
    },
    {
      descSort: "-date_start",
      ascSort: "date_start",
      id: "date_start",
    },
  ];
  const subscRowSearching = [
    {
      match: "recurring_total",
      id: t("RecurringPrice"),
    },
    {
      match: "user_id",
      id: t("SalesPerson"),
    },
    {
      match: "aggregate_invoice_group_id",
      id: t("AggregateInvoiceGroup"),
    },
    {
      match: "template_id",
      id: t("SubscriptionTemplate"),
    },
    {
      match: "code",
      id: t("SubscriptionID"),
    },
    {
      match: "date_start",
      id: "date_start",
    },
  ];
  const getpopDate = (row: any) => navigate(`/accounting/subscription/edit/${row.id}`);
  const cmSetTableData = (rows: any) => setTableDataShow(rows);

  useEffect(() => {
    if (tableDataShow.length > 0) {
      setTableTotalPrice(0);
      tableDataShow.map((row: any) => setTableTotalPrice((prev) => prev = prev + row.recurring_total));
    }
  }, [tableDataShow]);
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <div className="cm-subscription-main-single">
      <div className='cm-subscription-Data-table'>
        {isTransLoaded ? (
          <>
            <MaterialReactDataTable
            apiLink={AppAPiUrl.AccountingSubscription}
            tRow={subscRow}
              setTableDataShow={cmSetTableData}
              clickFuncDataShow={true}
            clickFunc={getpopDate}
              sortingData={subscRowSorting}
              searchData={subscRowSearching}
            />
            {/* <PaginationMaterialTable
              apiLink={AppAPiUrl.AccountingSubscription}
              tRow={subscRow}
              clickFunc={getpopDate}
            /> */}
          </>) : <LoaderScreen />}
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={"end"} className="sub-table-price">
          <Box>
            <Box className='cm-order-sale-total-tbl' sx={{ borderTop: 'none', marginTop: "20px" }}>
              <Box className='order-sale-table-data tfoot'>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={"between"}>
                  <Box className='order-sl-th'>{t('Total')}:</Box>
                  <Box className='order-sl-td'>{totalPriceCurrency.length > 0 &&
                    priceFormatWithCurrency(totalPriceCurrency, tableTotalPrice)
                  }</Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Stack>
      </div>
    </div>
    );
};
function AccountSubscriptionComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(Subscription, "accounting_subscription", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AccountSubscriptionComponentReady, appModelPermission.ACCOUNTINGSUBSCRIPTION, appModelType.READ);

// Recurring Price = data[recurring_total];
// Total = data[];
