import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import assets from '../../assets';
import { Button, Modal } from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import Swal from 'sweetalert2'
import Stack from '@mui/material/Stack';
import AxiosMain from '../../components/layout/AxiosMain';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthState } from '../../redux/features/authStateSlice';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import jwt_decode from "jwt-decode";
import { AppAPiUrl } from '../../configs/appData';
import TranslationGetHOC from '../../components/layout/TranslationGetHOC';
import { RootState } from '../../redux/store';
import LoaderScreen from '../../components/layout/LoaderScreen';
import PrivacyPolicyPage from '../PrivacyPolicy/PrivacyPolicyPage';
type Props = {
  translateDataft: any;
  translationStatus: any;
};
const LoginPageComponent = (props: Props) => {
  const privacyPolicystyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "900px;",
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding:"20px",
    maxHeight: "calc(100% - 20px)",
    overflow: "auto",
    borderRadius: "21px",
    border:"20px solid #fff",
    outline:"none !important"
};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPrivacyModal, setPrivacyUserModal] = useState(false);
  const [isOTPScreen, setOTPScreen] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [otpToken, setOptToken] = useState("");
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  // const checkUser = useSelector(
  //   (state: RootState) => state.authState.authState
  // );
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }
  const formSwalTranslation = {
    formSwalTitle: t('SomethingWentWrong'),
    formSwalText: t('CheckYourLoginsDetailsInternet'),
  }
  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      //setOTPScreen(true);
      const { email, password } = values;
      const newVal = { email, password };
      AxiosMain.post(AppAPiUrl.Login, newVal)
        .then((val) => {
          const { data } = val;
          if (val.status === 200) {
            if (Object.keys(data).length > 0 && data.hasOwnProperty("token")) {
              setOptToken(data.token);
              setOTPScreen(true);
              const tokenExplore: any = jwt_decode(data.token);
              if (tokenExplore.hasOwnProperty("otp")) {
                console.log(tokenExplore.otp);
              }
            }
          }
        })
        .catch((e) => {
          const { response } = e;
          console.log("Token Response ", response);
          if (response) {
            const { data } = response;
            if (data.hasOwnProperty("message")) {
              Swal.fire({
                title: "Error",
                text: e.response.data.message,
                icon: 'warning',
              })
            } else {
              Swal.fire({
                title: formSwalTranslation.formSwalTitle,
                text: formSwalTranslation.formSwalText,
                icon: 'warning',
              })
            }
          } else {
            Swal.fire({
              title: formSwalTranslation.formSwalTitle,
              text: formSwalTranslation.formSwalText,
              icon: 'warning',
            })
          }


        });
    },
  });
  const oTPSchema = Yup.object().shape({
    otp: Yup.string().required("Required").min(4, 'Min 4 digits Allow')
      .max(4, 'Max 5 digits Allow'),
  });
  const otpFormik = useFormik({
    initialValues: {
      otp: "",
      token: otpToken
    },
    validationSchema: oTPSchema,
    onSubmit: (values) => {
      AxiosMain.post(AppAPiUrl.Otpverify, values)
        .then((val) => {
          //console.log("OTP", val)
          if (val.status === 200) {
            const useroutput = {
              access: val.data.access,
              refresh: val.data.refresh,
              user: jwt_decode(val.data.access)
            }
            setOptToken("");
            setOTPScreen(false);
            dispatch(setAuthState(useroutput));
          }
        })
        .catch((e) => {
          const { response } = e;
          if (response !== undefined) {
            console.log("OTP Response ", response);
            if (response.status === 401) {
              const { data } = response;
              setOptToken("");
              setOTPScreen(false);
              if (data.hasOwnProperty("non_field_errors")) {
                Swal.fire({
                  title: "Error",
                  text: data.non_field_errors[0],
                  icon: 'warning',
                })
              } else {
                Swal.fire({
                  title: "Error",
                  text: "Token has expired",
                  icon: 'warning',
                })
                setOptToken("");
                setOTPScreen(false);
              }

            } else {
              Swal.fire({
                title: formSwalTranslation.formSwalTitle,
                text: formSwalTranslation.formSwalText,
                icon: 'warning',
              })
            }
          } else {
            Swal.fire({
              title: formSwalTranslation.formSwalTitle,
              text: formSwalTranslation.formSwalText,
              icon: 'warning',
            })
          }


        });
    },
  });

  const onKeyDown = (keyEvent: any, formCompo:any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
    if (keyEvent.key === 'Enter') {
      formCompo.handleSubmit();
    }
  }
  // const sendOtpAPI = (mobile: string) => {
  //   if (mobile !== '') {
  //     // AxiosMain.post('/mobile-otp/', { mobile: mobile }).then((val) => {
  //     //   if (val.status === 200) {
  //     //     console.log("OTP ", val.data.otp);
  //     //     if (val.data.hasOwnProperty("otp")) {
  //     //       formik.setFieldValue("otp", val.data.otp);
  //     //     }
  //     //   }
  //     // }).catch((e) => {
  //     //   console.log("Error While getting OTP");
  //     //   if (e.response.status === 400) {
  //     //     Swal.fire({
  //     //       title: "Error",
  //     //       text: e.response.data.mobile[0],
  //     //       icon: 'warning',
  //     //     })
  //     //   } else {
  //     //     Swal.fire({
  //     //       title: formSwalTranslation.formSwalTitle,
  //     //       text: formSwalTranslation.formSwalText,
  //     //       icon: 'warning',
  //     //     })
  //     //   }
  //     // });
  //   }
  // }
  useEffect(() => {
    if (otpToken !== '') {
      otpFormik.setFieldValue("token", otpToken);
    }
    // eslint-disable-next-line
  }, [otpToken])
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <section className="login-page-wrapper">
       <Modal
          open={openPrivacyModal}
          onClose={() => setPrivacyUserModal(false)}
          aria-labelledby="Modal-User-Edit"
          aria-describedby="Modal-User-Edit"
        >
            <Box sx={privacyPolicystyle}>
                <Box sx={{textAlign: "right",position: "absolute",top: "12px",right: "25px",}}>
                  <CloseIcon onClick={() => setPrivacyUserModal(false)} sx={{
                    color: "#CCCCCC",
                    width: "28px",
                    height: "28px",
                    cursor: "pointer"
                }} /></Box>
                <Box>
                    <PrivacyPolicyPage/>
                </Box>
            </Box>
        </Modal>
      {
        isTransLoaded ? (
          <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className="cm-login-main-row"
          alignItems={"center"}
        >
          <Grid
            item
            lg={7}
            md={6}
            xs={12}
            style={{
              background: `linear-gradient(0deg, rgba(0, 24, 32, 0.81), rgba(0, 24, 32, 0.81)), url(${assets.images.loginBGImage})`,
            }}
            className="cm-col-login-bg cm-login-main-col"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              className="cm-login-row"
              alignItems="center"
            >
              <Grid item xs={12}>
                <div className="login-innter-content">
                  <h2>{t("login_leftfColTitle")}</h2>
                  <p>{t("login_leftColDescription")}</p>
                </div>
              </Grid>
            </Grid>
            <p className="login-copyright">
                  © 2021 - Setera | <span style={{cursor:"pointer"}} onClick={()=> setPrivacyUserModal(true)}>{t("Privacy")} {t("Policy")}</span>
            </p>
          </Grid>
          <Grid item lg={5} md={6} xs={12} className="cm-login-main-col">
            <div className="cm-login-form-wrap">
              {isOTPScreen ? (
                <>
                <h2>2 {t("StepVerification")}</h2>
                  <form onSubmit={otpFormik.handleSubmit} onKeyDown={(e)=> onKeyDown(e,otpFormik)}>
                    <Box sx={{ flexGrow: 1 }} className="form-mb-30">
                      <Grid container spacing={2}>
                        <Grid item xs={12} className="cm-form-grid-col">
                          <div className="form-lable-name">{t("Otp")}</div>
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              className="cm-login-filed"
                              variant="outlined"
                              name="otp"
                              value={otpFormik.values.otp}
                              onChange={otpFormik.handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <LockOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {otpFormik.errors.otp && otpFormik.touched.otp && (
                              <p className="cm-form-error">{otpFormik.errors.otp}</p>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="cm-form-grid-col"
                        sx={{ paddingTop: "18px !important" }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent={"end"}
                        >
                          <Box sx={{ width: "100%", textAlign: "right" }}>
                            <Button
                              variant="text"
                              className="cm-forgot-pass-btn"
                              onClick={() => {
                                setOTPScreen(false);
                                setOptToken("");
                              }}
                                >{t("Login")}
                            </Button>
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} className="cm-form-grid-col">
                        <Box sx={{ marginTop: "20px" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent={"start"}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              className="cm-login-submit-btn"
                            >
                                  {t("Send")}
                            </Button>
                          </Stack>
                        </Box>
                      </Grid>
                    </Box>

                  </form>
                </>
              ) : (
                <>
                  <h2>{t("login_FormTitle")}</h2>
                  <form onSubmit={formik.handleSubmit} onKeyDown={(e)=> onKeyDown(e,formik)}>
                    <Box sx={{ flexGrow: 1 }} className="form-mb-30">
                      <Grid container spacing={2}>
                    <Grid item xs={12} className="cm-form-grid-col">
                      <Box sx={{ width: "100%" }}>
                        <div className="form-lable-name">{t("email")}</div>
                        <FormControl sx={{ width: "100%" }}>
                          <TextField
                            className="cm-login-filed"
                            variant="outlined"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MailOutlineIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <p className="cm-form-error">{formik.errors.email}</p>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} className="cm-form-grid-col">
                      <Box sx={{ width: "100%" }}>
                        <div className="form-lable-name">{t("password")}</div>
                        <FormControl sx={{ width: "100%" }}>
                          <TextField
                            className="cm-login-filed"
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <LockOpenOutlinedIcon />
                                    ) : (
                                      <LockOutlinedIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {formik.errors.password && formik.touched.password && (
                            <p className="cm-form-error">{formik.errors.password}</p>
                          )}
                        </FormControl>
                      </Box>
                          </Grid>
                    <Grid
                      item
                      xs={12}
                      className="cm-form-grid-col"
                      sx={{ paddingTop: "18px !important" }}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent={"between"}
                      >
                        <Box sx={{ width: "100%" }}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormControlLabel
                              className="form-checkbox-text"
                              control={
                                <Checkbox
                                  name="remember"
                                  checked={formik.values.remember}
                                  onChange={formik.handleChange}
                                />
                              }
                              label={t("rememberMe")}
                            />
                          </FormControl>
                        </Box>
                        <Box sx={{ width: "100%", textAlign: "right" }}>
                          <Button
                            variant="text"
                            className="cm-forgot-pass-btn"
                            onClick={() => navigate("/forgotPass")}
                          >
                            {t("forgotPassword")}
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ marginTop: "40px" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent={"start"}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="cm-login-submit-btn"
                    >
                      {t("signIn")}
                    </Button>
                  </Stack>
                </Box>
              </form>
                </>
              )}

            </div>
          </Grid>
        </Grid>
      </Box>
        ) : <LoaderScreen msg='Loading Data' />
      }

    </section>
  );
};
function LoginPage() {
  const dependency = {};
  const MainComponent = TranslationGetHOC(LoginPageComponent, "unknown_User", true, {}, dependency);
  return <MainComponent />
}
export default LoginPage;