import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoaderScreen from "../../components/layout/LoaderScreen";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import AddIcon from '@mui/icons-material/Add';
import PaginationTableData from "../../components/layout/PaginationTableData";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import AddUser from "./AddUser";
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from "../../configs/colorConfigs";
type Props = {
  translateDataft: any;
  translationStatus: any;
};

const Users = (props: Props) => {
  const [openAddUserModal, setopenAddUserModal] = useState(false);
  const handleUserRoleOpen = () => setopenAddUserModal(true);
  const handleUserRoleClose = () => setopenAddUserModal(false);
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);

  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }

  const userModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "900px;",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: "calc(100% - 20px)",
    overflow: "auto",
    borderRadius: "21px",
    boxsize: "content-box"
  };
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <div className="cm-user-main">

      <Modal
        open={openAddUserModal}
        onClose={handleUserRoleClose}
        aria-labelledby="Modal-User-Add"
        aria-describedby="Modal-User-Add"
      >
        <Box sx={userModalstyle}>
          <Box sx={{
            textAlign: "right",
            position: "absolute",
            top: "12px",
            right: "25px",
          }}> <CloseIcon onClick={handleUserRoleClose} sx={{
            color: "#CCCCCC",
            width: "38px",
            height: "38px",
            cursor: "pointer"
          }} /></Box>
          <Box sx={{ maxWidth: "450px", margin: "0 auto" }}>
            <AddUser handleClose={handleUserRoleClose} />
          </Box>
        </Box>
      </Modal>
      {isTransLoaded ? (
          <div style={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}>
            <div className="text-right">
              <Button
                onClick={handleUserRoleOpen}
                variant="contained"
                endIcon={<AddIcon />}
                className="cm-btn-style"
                sx={{
                  color: colorConfigs.btnStyle.color,
                  background: colorConfigs.btnColor.bgColorGreen,
                  "&: hover": {
                    color: colorConfigs.btnColor.colorGreen,
                    background: colorConfigs.btnColor.hoverbgWhite,
                  },
                }}
              >
                {t("AddUser")}
              </Button>
            </div>
            <div className="cm-userData-table admin-cm-userData-table">
              <PaginationTableData
                useFor={"Users Data "}
                tableStyle="user"
                apiLink={AppAPiUrl.Users}
                searchfield={false}
              forcegetData={!openAddUserModal ? true : false}
                btnComponent={{
                  isBtnShow: false,
                }}
              />
            </div>
          </div>
      ) : <LoaderScreen />}
    </div>
  );
};
function UsersListComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(Users, "users", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(UsersListComponentReady, appModelPermission.SETERAUSER, appModelType.READ);
