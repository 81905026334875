import React, { useEffect, useState } from "react";
import { FormControl, Box, TextField, InputAdornment, Modal, Stack, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AxiosMain from "./AxiosMain";
import { AppAPiUrl } from "../../configs/appData";
import { MaterialReactTable } from "material-react-table";
import { useDispatch } from "react-redux";
import { setAppOrg, setAppcurrentPageForm } from "../../redux/features/appStateSlice";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
const dftData = [
    {
        name: "Mobile",
        count: 156,
        data: [
            {
                org: "Setera Oy",
                name: "Henri Leinonen",
                number: "+3584577509515"
            },
            {
                org: "Setera Oy",
                name: "Henri Leinonen",
                number: "+3584577509515"
            },
            {
                org: "Setera Oy",
                name: "Henri Leinonen",
                number: "+3584577509515"
            },
            {
                org: "Setera Oy",
                name: "Henri Leinonen",
                number: "+3584577509515"
            }
        ]
    },
    {
        name: "Organizations",
        count: 156,
        data: [
            { name: "Network Hill Net" },
            { name: "Solutions Alpha Studio" },
            { name: "Studio Industries" },
            { name: "Application Software Hardware" },
            { name: "Research Internet" }
        ]
    },
    {
        name: "Invoices",
        count: 156,
        data: [
            {
                date: "10/3/2023",
                number: "INVHEL/2023/3134",
                status: false
            },
            {
                date: "10/3/2023",
                number: "INVHEL/2023/3133",
                status: true
            },

        ]
    },
    {
        name: "Sales orders",
        count: 156,
        data: [
            {
                id: "SOHEL1985",
                date: "5/17/2023",
                total: "10,74 €"
            },
            {
                id: "SOHEL1985",
                date: "5/17/2023",
                total: "10,74 €"
            },
            {
                id: "SOHEL1985",
                date: "5/21/2023",
                total: "15,74 €"
            },
            {
                id: "SOHEL19995",
                date: "5/17/2023",
                total: "20,74 €"
            },
        ]
    },
    {
        name: "Subscriptions",
        count: 156,
        data: [
            {
                id: "SOHEL19995",
                customer: "5/17/2023",
                saleperson: "Marko Lahnala",
                price: "20,74 €"
            },
            {
                id: "SOHEL19996",
                customer: "5/17/2023",
                saleperson: "Kimmo Tanskanen",
                price: "0€"
            },
        ]
    }
];
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
const SearchTableData = ({ search, navigate, tRowHead, clickFunc }: any) => {
    const { data } = search;
    if (data.length > 0) {
        return (
            <>
                <MaterialReactTable
                    columns={tRowHead}
                    data={data}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enablePagination={true}
                    columnFilterDisplayMode='popover'
                    muiTableHeadProps={{
                        sx: {
                            "& > tr": {
                                background: "#fff"
                            },
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                                marginBottom: "10px"
                            },
                            ".MuiTableCell-head .Mui-TableHeadCell-Content-Wrapper": {
                                color: "#000",
                                fontSize:"13px"
                            },
                            ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper button": {
                                color: "rgb(255 255 255 / 26%)"
                            },
                            ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper input": {
                                color: "#000",
                                padding: "8px"
                            },
                            ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper .MuiInputBase-adornedEnd::before": {
                                borderColor: "rgb(255 255 255 / 26%)"
                            },
                            ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper  fieldset.MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgb(255 255 255 / 26%)"
                            },
                            "& .Mui-TableHeadCell-Content-Actions button": {
                                opacity: 1
                            },
                            "& .Mui-TableHeadCell-Content-Actions button svg": {
                                color: "#000",
                            }
                        }
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        onClick: (event) => {
                            clickFunc(row.original);
                        },
                        sx: {
                            cursor: 'pointer',
                        },
                    })}
                />
            </>)
    }
    return (<>No Data</>)
}


function TopBarSearchComponent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [searchData, setSearchData] = useState(dftData);
    const [searchModal, setSearchModal] = useState(false);
    const [customTabValue, setCustomTabValue] = useState(0);
    const { appCountry, appAllTranslate } = useSelector((state: RootState) => state.appState);
    const organizationChangeMsg = (val: any) => {
        enqueueSnackbar(`Organization Changed to ${val.name}`, { preventDuplicate: true, variant: "success", autoHideDuration: 2000 });
    }
    const t = (name: string) => {
        if (iComponetTranslate.length > 0) {
            const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
            if (ifExist.length > 0) {
                return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
            }
            return `No key found (${name})`;
        }
        return `....`;
    }
    const searchModalstyle = {
        position: 'absolute' as 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        width: "100%",
        maxWidth: "900px;",
        maxHeight: "950px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        overflow: "hidden",
        borderRadius: "11px",
        boxsize: "content-box",
        border: "1px solid #B9B9B9"
    };
    const atSubscriptionTHead = [
        { name: "Subscription ID" },
        { name: "Customer" },
        { name: "Sales Person" },
        { name: "Recurring Price" }
    ]
    const mobileTHead = [
        {
            header: 'Organization',
            width: "150px",
            accessorFn: (row: any) => Object.keys(row.organization).length > 0 ? row.organization.name : "not foud",
        },
        {
            header: 'Name',
            width: "200px",
            accessorFn: (row: any) => row.name,
        },
        {
            header: 'Mobile Subscription Number',
            accessorFn: (row: any) => row.number,
        },
    ];
    const orgTHead = [
        {
            header: 'Name',
            width: "150px",
            accessorFn: (row: any) => row.name,
        },
    ];
    const inovoiceTHead = [
        { name: "Inovoice Date" },
        { name: "Number" },
        { name: "Status" }
    ];
    const sOrderTHead = [
        { name: "Sales Order ID" },
        { name: "Order Date" },
        { name: "Status" }
    ]
    const handleChange = (e: any) => {
        setSearchField(e.target.value);
    }
    const getSearchData = (from: any) => {
        let newOutput: any = [];
        if (searchField !== "") {
            AxiosMain.get(`${AppAPiUrl.AppSearchGlobal}?q=${searchField}`).then((res) => {
                //console.log("Response ", res);
                if (res.status === 200) {
                    const { data } = res;
                    if (Object.keys(data).length > 0) {
                        for (const [key, value] of Object.entries(data)) {
                            const valueData: any = value;
                            if (valueData.length > 0) {
                                const tabname = key.replaceAll("_", " ");
                                if (data[key].length > 0) {
                                    let combinedata = {
                                        count: valueData.length,
                                        name: tabname,
                                        data: valueData
                                    }
                                    newOutput.push(combinedata);
                                }
                            }

                        }
                    }
                    if (newOutput.length > 0) {
                        setSearchData(newOutput);
                        setSearchModal(true);
                    } else {
                        enqueueSnackbar(`No Data Found of ${searchField}`, { preventDuplicate: true, variant: "info", autoHideDuration: 2000 });
                    }

                }
            }).catch((e) => {
                const { response } = e;
                console.log(e);
                console.log("Error of Search Data ", response);
                if(response){
                    if (response.status === 401) {
                        enqueueSnackbar(`${response.data.detail}`, { preventDuplicate: true, variant: "error", });
                    } else if (response.status === 400) {
                        for (const [key, value] of Object.entries(response.data)) {
                            enqueueSnackbar(`${key}: ${value}`, { preventDuplicate: true, variant: "error", });
                        }
                    }
                    else {
                        enqueueSnackbar(`Error while getting search data!`, { preventDuplicate: true, variant: "error", });
                    }
                }else{
                    enqueueSnackbar(`${e.message}`, { preventDuplicate: true, variant: "error", });
                }
            })
        }

    }
    const searchNavigate = (val: any) => {
        console.log("val", val);
    }
    const gotoMobile = (row: any) => {
        const { organization } = row;
        dispatch(setAppOrg(organization));
        organizationChangeMsg(organization);
        setSearchModal(false);
        setTimeout(() => {
            //console.log("Mobile ", row);
            navigate(`/mobiles/mobile-user-list/edit/${row.id}`)
        }, 100);
    };
    const gotoOrganization = (row: any) => {
        dispatch(setAppOrg(row));
        organizationChangeMsg(row);
        setSearchModal(false);
        dispatch(setAppcurrentPageForm("no"));
        navigate("/");
    };
    useEffect(() => {
        const newCode = appCountry.toLocaleLowerCase();
        const findifExist = appAllTranslate.filter((item: any) => item.name === "Sidebar" && item[`${newCode}`]);
        if (findifExist.length > 0) {
            setICTranslation(findifExist[0][`${newCode}`]);
        } else {
            setICTranslation([]);
        }
    }, [appAllTranslate, appCountry]);
    return (
        <Box>
            <Box sx={{ width: "100%" }}>
                <FormControl sx={{ width: "100%", padding: "0px", }}>
                    <TextField
                        sx={{
                            minWidth: "380px",
                            "& input":{
                                outline:"none"
                            },
                            "& fieldset": {
                                borderRadius: 0,
                                borderTop:"none",
                                borderRight:"none",
                                borderLeft:"none"
                            }
                        }}
                        size="small"
                        variant="outlined"
                        name="email"
                        value={searchField}
                        onChange={handleChange}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                getSearchData("Enter");
                                setSearchData([]);
                                setSearchModal(false);
                            } 
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Box>
            <Modal
                open={searchModal}
                onClose={() => setSearchModal(false)}
                aria-labelledby="Modal-Top-Search"
                aria-describedby="Modal-Top-Search"
                hideBackdrop={true}
                disableEnforceFocus={true}
                sx={{
                    top: 75
                }}
            >
                <Box sx={searchModalstyle}>
                    <Box sx={{
                        textAlign: "right", position: "absolute", top: "12px", right: "25px",
                    }}> <CloseIcon onClick={() => setSearchModal(false)} sx={{
                        color: "#CCCCCC",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer"
                    }} /></Box>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction="row" spacing={1} className="cm-global-tab-btn-wrapper">
                            {searchData.length > 0 && searchData.map((item: any, index: any) => (
                                <Button
                                    sx={{
                                        fontSize: 15,
                                        textTransform: "capitalize",
                                        position: "relative",
                                        color: `${customTabValue === index ? "#103256" : "#313131"}`,
                                        textDecoration: `${customTabValue === index ? "underline" : "none"}`,
                                        paddingRight: "50px",
                                        "&:hover": {
                                            background: "transparent",
                                            textDecoration: `underline`,
                                        }
                                    }}
                                    variant="text"
                                    key={`search-tab-btn-${index}`}
                                    {...a11yProps(index)} onClick={() => setCustomTabValue(index)}
                                    className={customTabValue === 0 ? "tab-active " : ""}>
                                    {item.name}
                                    <Box sx={{
                                        fontSize: "12px",
                                        position: "absolute",
                                        minWidth: "33px",
                                        right: 10,
                                        borderRadius: "4px",
                                        background: "rgba(217, 217, 217, 0.27)"
                                    }}>{item.count}</Box>

                                </Button>
                            ))}

                        </Stack>
                        <Box className='cm-global-tabs-content' style={{ marginTop: "20px", maxHeight: 850, overflow: "auto" }}>
                            <Box className='cm-global-tab-inner-content'>
                                {searchData.length > 0 && searchData.map((item: any, index: any) => (
                                    <TabPanel value={customTabValue} index={index} key={`top-bar-search-tabs-data-${index}`}>
                                        {(() => {
                                            switch (item.name) {
                                                case 'mobile subscription':
                                                    return (<SearchTableData clickFunc={gotoMobile} search={item} navigate={searchNavigate} tRowHead={mobileTHead} />)
                                                case 'organization':
                                                    return (<SearchTableData clickFunc={gotoOrganization} search={item} navigate={searchNavigate} tRowHead={orgTHead} />)
                                                case 'Invoices':
                                                    return (<SearchTableData search={item} navigate={searchNavigate} tRowHead={inovoiceTHead} />)
                                                case 'Sales orders':
                                                    return (<SearchTableData search={item} navigate={searchNavigate} tRowHead={sOrderTHead} />)
                                                case 'Subscriptions':
                                                    return (<SearchTableData search={item} navigate={searchNavigate} tRowHead={atSubscriptionTHead} />)
                                                default:
                                                    return (<>No Data Set of {item.name}</>);
                                            }
                                        })()}
                                    </TabPanel>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </Box>
    )
}
export default TopBarSearchComponent

