import React, { useState, useEffect } from "react";
import LoaderScreen from "../layout/LoaderScreen";
import { Grid, Stack, Box, Modal } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AxiosMain from "../layout/AxiosMain";
import { AppAPiUrl } from "../../configs/appData";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import EditUser from "../../pages/Users/EditUser";
const UserTableStyle = ({ tData, count, page, currentDataCount }: any) => {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const id: any = searchParams.get('id') ? searchParams.get('id') : 0;
    const navigate = useNavigate();
    const [isLoaded, setisLoaded] = useState(false);
    const [userRole, setUserRole] = useState([]);
    const [openEditUserModal, setopenEditUserModal] = useState(false);
    const [singleUserId, setSingleUserID] = useState(0);
    const userModalstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: "900px;",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: "calc(100% - 20px)",
        overflow: "auto",
        borderRadius: "21px",
        boxsize: "content-box"
    };
    // const getCurrentUserRole = (id: any) => {
    //     if (userRole.length > 0) {
    //         const roleDate: any = userRole.filter((item: any) => item.id === id);
    //         if (roleDate.length > 0) {
    //             return (`${roleDate[0].name}`);
    //         }
    //     }
    //     return (`No Role ${id}`);
    // }
    const getUserRole = () => {
        AxiosMain.get(AppAPiUrl.Addrole).then((res) => {
            if (res.status === 200 && res.data.count > 0) {
                if (res.data.results.length > 0) {
                    setUserRole(res.data.results);
                }
            }
        }).catch((e) => {
            console.log("Error ", e);
        })
    }
    const GetTotalofit = () => {
        if (currentDataCount === 10) {
            let newshow = page * currentDataCount;
            return (<>{newshow} of {count}</>)
        } else if (count > 10) {
            let newshow = count - currentDataCount;
            let newdat = newshow + currentDataCount;
            return (<>{newdat} of {count}</>)
        }
        else {
            return (<>{currentDataCount} of {count}</>)
        }

    }
    useEffect(() => {
        if (userRole.length === 0) {
            getUserRole();
        }
        // eslint-disable-next-line
    }, [userRole]);
    useEffect(() => {
        if (tData.length > 0) {
            if (tData.length > 10) {
                setTimeout(() => {
                    setisLoaded(false)
                }, 100);
            }
            else if (tData.length > 0 || tData.length < 10) {
                setTimeout(() => {
                    setisLoaded(false)
                }, 100);
            }
            else {
                setisLoaded((prev) => prev = true);
            }
        }
        else {
            setisLoaded(true);
            if (isLoaded) {
                setTimeout(() => {
                    setisLoaded(false);
                }, 100);
            }
        }
        // eslint-disable-next-line
    }, [tData]);
    useEffect(() => {
        if (singleUserId !== 0) {
            setopenEditUserModal(true);
            navigate(`?id=${singleUserId}`);
        }
        // eslint-disable-next-line
    }, [singleUserId])
    useEffect(() => {
        if (id !== null && id !== "") {
            setSingleUserID(id)
        }
    }, [id])
    return (
        <>
            {isLoaded ? (<><LoaderScreen /></>) : <>
                <Modal
                    open={openEditUserModal}
                    onClose={() => {
                        setSingleUserID(0);
                        setopenEditUserModal(false)
                        navigate("/users");
                    }}
                    aria-labelledby="Modal-User-Edit"
                    aria-describedby="Modal-User-Edit"
                >
                    <Box sx={userModalstyle}>
                        <Box sx={{
                            textAlign: "right",
                            position: "absolute",
                            top: "12px",
                            right: "25px",
                        }}> <CloseIcon onClick={() => {
                            setSingleUserID(0);
                            setopenEditUserModal(false)
                            navigate("/users");
                        }} sx={{
                            color: "#CCCCCC",
                            width: "38px",
                            height: "38px",
                            cursor: "pointer"
                        }} /></Box>
                        <Box sx={{ maxWidth: "450px", margin: "0 auto" }}>
                            <EditUser />
                        </Box>
                    </Box>
                </Modal>
                <div style={{ position: "relative", top: "-50px", maxWidth: "200px" }}><GetTotalofit /></div>
                {tData.length > 0 ? tData.map((item: any, index: any) => (
                    <Grid container spacing={2} key={`admin-user-list-${index}`} alignItems={"center"} sx={{
                        borderRadius: "18px",
                        background: "#FFF",
                        marginTop: index !== 0 ? "20px" : "0px"
                    }}>
                        <Grid item md={6} lg={3} sx={{
                            paddingTop: "20px", paddingBottom: "20px", paddingLeft: "30px",
                        }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <div>{item.hasOwnProperty("image") ? "image" : <PersonIcon sx={{
                                    fontSize: "40px",
                                    background: "#CCCCCC",
                                    borderRadius: "100%",
                                    color: "#fff",
                                    padding: "10px",
                                    boxSizing: "content-box"
                                }} />}</div>
                                <div>
                                    <h2 style={{ fontSize: "15px", margin: "0px" }}>{item.first_name}</h2>
                                    <div style={{ fontSize: "13px", color: "rgba(1, 36, 69, 0.51)" }}>
                                        {item.role.name}
                                    </div>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid item md={6} lg={4} sx={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            borderLeft: "2px solid rgba(0, 0, 0, 0.13)",
                            borderRight: "2px solid rgba(0, 0, 0, 0.13)"
                        }}>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                <EmailIcon sx={{ color: "#CCCCCC" }} />
                                <div style={{ color: "#012445", fontSize: "15px" }}>{item.email}</div>
                            </Stack>
                        </Grid>
                        <Grid item md={6} lg={5} sx={{ paddingTop: "20px", paddingBottom: "20px", paddingRight: "30px", paddingLeft: "30px" }}>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                    <PhoneIphoneIcon sx={{ color: "#CCCCCC", fontSize: "20px" }} />
                                    <div style={{ color: " #012445", fontSize: "15px" }}>{item.mobile}</div>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                    <EditIcon sx={{ color: "rgba(16, 50, 86, 1)" }}
                                        onClick={() => setSingleUserID(item.id)} />
                                    <KeyboardArrowRightIcon sx={{ color: "rgba(16, 50, 86, 1)", fontSize: "40px" }}
                                        onClick={() => setSingleUserID(item.id)} />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                )) : <Box sx={{
                    background: "#fff",
                    padding: "20px",
                    textAlign: "center"
                }}>Empty Data</Box>}</>
            }
        </>

    );
}
export default UserTableStyle