import { useEffect, useState } from "react";
import TranslationGetHOC from "../../../components/layout/TranslationGetHOC";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useParams } from 'react-router-dom';
import { Box, Stack } from "@mui/material";
import { AppAPiUrl } from "../../../configs/appData";
import MaterialReactDataTable from "../../../components/layout/MaterialReactDataTable";
import LoaderScreen from "../../../components/layout/LoaderScreen";
import { priceFormatWithCurrency } from "../../../components/layout/Helper";

function WholeSaleListPageComponent(props:any){
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [tableTotalPrice, setTableTotalPrice] = useState(0);
    const [paramObject, setParamObject]= useState({});
    const [totalPriceCurrency, setTotalPriceCurrency] = useState([]);
    const [tableDataShow, setTableDataShow] = useState([]);
    const param = useParams();
    const { id } = param;
    const { appCountry } = useSelector((state: RootState) => state.appState);
    // useEffect(()=>{
    //     if(id){
    //         setParamObject({organization:id});
    //     }
       
    // },[id])
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const whileSaleTableRow = [
        {
            accessorKey: 'product',
            header: t('Product'),
            accessorFn: (row: any) => row?.product?.description || row?.category?.category_name,
        },
        {
            accessorKey: 'quantity',
            header: t('Amount'),
            size: 230,
            accessorFn: (row: any) => row?.quantity,
            // enableSorting: false,
            // enableFilters: true,
            // enableColumnFilterModes: false,
            // enableColumnFilters: false,
            // enableColumnActions: false,
            // enableColumnFilter: false,
        },
        {
            accessorKey: 'price_unit',
            header: t('UnitPrice'),
            size: 230,
            accessorFn: (row: any) => "$"+ row?.price_unit,
            // enableSorting: false,
            // enableFilters: true,
            // enableColumnFilterModes: false,
            // enableColumnFilters: false,
            // enableColumnActions: false,
            // enableColumnFilter: false,
        },
        {
            accessorKey: 'price_total',
            header: t('Total'),
            size: 230,
            accessorFn: (row: any) => "$"+row?.price_total,
            // enableSorting: false,
            // enableFilters: true,
            // enableColumnFilterModes: false,
            // enableColumnFilters: false,
            // enableColumnActions: false,
            // enableColumnFilter: false,
        },
        {
            accessorKey: 'commission',
            header: t('Commission'),
            accessorFn: (row: any) => "$"+row?.commission,
        },
    ];
    const getpopDate = (row: any, tableData: any) => console.log(row);
    const cmSetTableData = (rows: any) => setTableDataShow(rows);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    const handleTotalPrice = (data:any) =>{
        const total = data.reduce(
            (accumulator:any, currentValue:any) => accumulator + currentValue.commission,
            0,
          );
        setTableTotalPrice(total)
    }
    return(
        <Box className='cm-global-tabs-wrapper' sx={{ overflow: "hidden",  marginTop: "20px" }}>
            {isTransLoaded  ? 
        <div className='cm-form-inner-fields cm-pagination-billing-dt cm-table-remvove-style'>
             <MaterialReactDataTable
                apiLink={`${AppAPiUrl.WholeSaleList}${id}/`}
                tRow={whileSaleTableRow}
                paramDependent={paramObject} 
                clickFunc={getpopDate}
                setTableDataShow={cmSetTableData}
                callbackFunction={handleTotalPrice}
                /> 
            <Stack direction="row" spacing={2} alignItems="center" justifyContent={"end"} className="sub-table-price">
                <Box>
                    <Box className='cm-order-sale-total-tbl' sx={{ borderTop: 'none', marginTop: "20px" }}>
                        <Box className='order-sale-table-data tfoot'>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent={"between"}>
                            <Box className='order-sl-th'>{t('Total')}: {"$"+tableTotalPrice}</Box>
                            <Box className='order-sl-td'>{totalPriceCurrency.length > 0 &&
                            priceFormatWithCurrency(totalPriceCurrency, tableTotalPrice)
                            }</Box>
                        </Stack>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </div>
        : <LoaderScreen/>}
</Box>
    )
}

function WholeSaleListPage({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(WholeSaleListPageComponent, "admin_dashboard", isAllow, {}, dependency);
    return <MainComponent />
}

export default WholeSaleListPage