
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import * as Yup from 'yup';
import Stack from "@mui/material/Stack";
import colorConfigs from "../../configs/colorConfigs";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import AxiosMain from "../../components/layout/AxiosMain";
import LoaderScreen from "../../components/layout/LoaderScreen";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const AddUserComponentReady = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const userDetails = useSelector((state: RootState) => state.authState.userState);
    //const dispatch = useDispatch();
    const [dropDownRole, setDropDownRole] = useState([]);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const getDropDownData = async ({ url, saveData }: any) => {
        AxiosMain.get(url).then((res) => {
            if (res.status === 200 && res.data.count > 0) {
                if(userDetails.id === 1){
                    saveData(res.data.results);
                }else{
                    const userrldata = res.data.results.filter((item: any) => item.name !== "Supervisor");
                    saveData(userrldata);
                }
            }
        }).catch((e) => {
            console.log("Something went wrong while fetching data");
        })
    }
    useEffect(() => {
        if (dropDownRole.length === 0) {
            getDropDownData({ url: "/add-role/", saveData: setDropDownRole });
        }
        // eslint-disable-next-line
    }, []);

    const formSwalTranslation = {
        formSwalTitle: t("AreYouWanttoSave"),
        formSwalText: t("Youwontbeabletorevertthis"),
        fomrSwalCancel: t("Cancel"),
        formSwalSaved: t("YesSavedit"),
        formSwalDataSaved: t("YourDatahasbeensaved"),
        formSwalSubmited: t("Submited"),
        formSwalWrong: t("SomethingWentWrong"),
    };
    const AddUserSchema = Yup.object().shape({
        email: Yup.string().email().required('Required'),
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        mobile: Yup.number().required('Required'),
        password: Yup.string()
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        role: Yup.string().required('Required')
    });
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const formik = useFormik({
        initialValues: {
            email: "",
            first_name: "",
            last_name: "",
            mobile: "",
            password: "",
            password2: "",
            role: "",
        },
        validationSchema: AddUserSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            //console.log("User Add ", values);
            AxiosMain.post(AppAPiUrl.Adduser, values).then((res) => {
                if (res.status === 201) {
                    Swal.fire({
                        title: formSwalTranslation.formSwalSubmited,
                        text: formSwalTranslation.formSwalDataSaved,
                        icon: "success"
                    });
                    setIsLoading(false);
                    formik.resetForm();
                    props.handleClose();
                }
            }).catch((e) => {
                setIsLoading(false);
                console.log("error ", e);
                const { response } = e;
                if (response.status === 400) {
                    const { email } = response.data;
                    if (email.length > 0) {
                        Swal.fire({
                            title: "Error",
                            text: email[0],
                            icon: "error"
                        });
                    }
                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: formSwalTranslation.formSwalWrong,
                        icon: "error"
                    });
                }
            })
        },
    });
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    return (
        <>
            <div className="cm-user-tabs-wrapper  cm-global-tabs-wrapper">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={"center"}>
                    <Box className="fw-600" sx={{ color: "#012445", fontSize: "40px;", lineHeight: "normal" }}>{t("AddUser")}</Box>
                </Stack>
                <div className="cm-user-form-wrap" style={{ marginTop: "10px" }}>
                    {isTransLoaded && isLoading ?
                        <LoaderScreen />
                        : (
                            <Box sx={{ width: "100%" }}>
                                <Box>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Box>
                                            <Grid container spacing={5} sx={{ paddingTop: "0" }}>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <Box className="cm-form-inner-fields">
                                                        <Box className="form-mb-30">
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("email")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <TextField
                                                                                    className="cm-login-filed"
                                                                                    name="email"
                                                                                    variant="outlined"
                                                                                    value={formik.values.email}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    placeholder={`${t("email")}`}
                                                                                />
                                                                            </FormControl>
                                                                            {formik.errors.email && formik.touched.email && <p className="cm-form-error">{formik.errors.email}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("FirstName")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <TextField
                                                                                    className="cm-login-filed"
                                                                                    name="first_name"
                                                                                    variant="outlined"
                                                                                    placeholder={`${t("FirstName")}`}
                                                                                    value={formik.values.first_name}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </FormControl>
                                                                            {formik.errors.first_name && formik.touched.first_name && <p className="cm-form-error">{formik.errors.first_name}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("LastName")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <TextField
                                                                                    className="cm-login-filed"
                                                                                    name="last_name"
                                                                                    variant="outlined"
                                                                                    placeholder={`${t("LastName")}`}
                                                                                    value={formik.values.last_name}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </FormControl>
                                                                            {formik.errors.last_name && formik.touched.last_name && <p className="cm-form-error">{formik.errors.last_name}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("MobileNumber")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <TextField
                                                                                    className="cm-login-filed"
                                                                                    name="mobile"
                                                                                    variant="outlined"
                                                                                    placeholder={`${t("MobileNumber")}`}
                                                                                    value={formik.values.mobile}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </FormControl>
                                                                            {formik.errors.mobile && formik.touched.mobile && <p className="cm-form-error">{formik.errors.mobile}</p>}
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={12} className="cm-form-grid-col">
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("password")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <TextField
                                                                                    className="cm-login-filed"
                                                                                    type={showPassword ? "text" : "password"}
                                                                                    variant="outlined"
                                                                                    name="password"
                                                                                    value={formik.values.password}
                                                                                    onChange={formik.handleChange}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton
                                                                                                    aria-label="toggle password visibility"
                                                                                                    onClick={handleClickShowPassword}
                                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                                    edge="end"
                                                                                                >
                                                                                                    {showPassword ? (
                                                                                                        <LockOpenOutlinedIcon />
                                                                                                    ) : (
                                                                                                        <LockOutlinedIcon />
                                                                                                    )}
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                            {formik.errors.password && <p className="cm-form-error">{formik.errors.password}</p>}
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={12} className="cm-form-grid-col">
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("Confirm password")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <TextField
                                                                                    className="cm-login-filed"
                                                                                    type={showConfirmPassword ? "text" : "password"}
                                                                                    variant="outlined"
                                                                                    name="password2"
                                                                                    value={formik.values.password2}
                                                                                    onChange={formik.handleChange}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                <IconButton
                                                                                                    aria-label="toggle password visibility"
                                                                                                    onClick={handleClickShowConfirmPassword}
                                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                                    edge="end"
                                                                                                >
                                                                                                    {showConfirmPassword ? (
                                                                                                        <LockOpenOutlinedIcon />
                                                                                                    ) : (
                                                                                                        <LockOutlinedIcon />
                                                                                                    )}
                                                                                                </IconButton>
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                            {formik.errors.password2 && <p className="cm-form-error">{formik.errors.password2}</p>}
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("Role")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <Select
                                                                                    className="form-select-pad"
                                                                                    id="role"
                                                                                    name="role"
                                                                                    label=""
                                                                                    value={
                                                                                        formik.values.role
                                                                                    }
                                                                                    onChange={formik.handleChange}
                                                                                >
                                                                                    <MenuItem value="">Choose Option</MenuItem>
                                                                                    {dropDownRole.length > 0 && (
                                                                                        dropDownRole.map((val: any) => (
                                                                                            <MenuItem key={`user-carrier-data--${val.id}`} value={val.id}>{val.name}</MenuItem>
                                                                                        ))
                                                                                    )}
                                                                                </Select>
                                                                            </FormControl>
                                                                            {formik.errors.role && formik.touched.role && <p className="cm-form-error">{formik.errors.role}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <Stack
                                                                                direction="row"
                                                                                spacing={2}
                                                                                alignItems="center"
                                                                                justifyContent={"start"}
                                                                                className="form-submit-cancel-btn-wrapper"
                                                                            >
                                                                                <Button
                                                                                    type="submit"
                                                                                    variant="contained"
                                                                                    className="cm-btn-style"
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        color: colorConfigs.btnColor.hoverbgWhite,
                                                                                        background: colorConfigs.btnColor.bgColorGreen,
                                                                                        "&: hover": {
                                                                                            color: colorConfigs.btnColor.bgColorGreen,
                                                                                            background: colorConfigs.btnColor.hoverbgWhite,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    {t("Submited")}
                                                                                </Button>
                                                                                {/* <Button
                                                                                    variant="contained"
                                                                                    endIcon={<CancelIcon />}
                                                                                    className="cm-btn-style"
                                                                                    sx={{
                                                                                        color: colorConfigs.btnColor.hoverbgWhite,
                                                                                        background: colorConfigs.btnColor.bgColorRed,
                                                                                        "&: hover": {
                                                                                            color: colorConfigs.btnColor.bgColorRed,
                                                                                            background: colorConfigs.btnColor.hoverbgWhite,
                                                                                        },
                                                                                    }}
                                                                                    onClick={() => setCustomTabs(0)}
                                                                                >
                                                                                    {t("discard")}
                                                                                </Button> */}
                                                                            </Stack>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/* Button save changes and Discard */}

                                    </form>
                        </Box>
                    </Box>
                        )}
                </div>
            </div>
        </>
    );
}
function AddUser(props: any) {
    const { isAllow } = props;
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(AddUserComponentReady, "users", isAllow, props, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AddUser, appModelPermission.SETERAUSER, appModelType.CREATE);