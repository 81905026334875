import { Box, Modal, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import assets from "../../assets";
import AxiosMain from "../../components/layout/AxiosMain";
import { priceFormatWithCurrency } from "../../components/layout/Helper";
import LoaderScreen from "../../components/layout/LoaderScreen";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import AttachmentIcon from '@mui/icons-material/Attachment';
import colorConfigs from "../../configs/colorConfigs";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { enqueueSnackbar } from "notistack";

const billingModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: "calc(100vh - 10%)",
    bgcolor: 'background.paper',
    border: '10px solid #E1E1E1',
    boxShadow: 24,
    padding: "30px 50px",
    overflow: "auto"
};
const attachmentModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    minHeight: "200px",
    bgcolor: 'background.paper',
    border: '10px solid #E1E1E1',
    boxShadow: 24,
    padding: "30px 50px",
    overflow: "auto"
};
const modalTopBarRight = {
    fontSize: "11px"
}
const modalAftertotalList = {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 500
}
type Props = {
    translateDataft: any;
    translationStatus: any;
};
function Billing(props: Props) {
    const [isStatusModalOpen, setStatusModalOpen] = useState(false);
    const [isAttachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
    const [isAttachmentsModalLoader, setAttachmentsModalLoader] = useState(true);
    const [attachmentsData, setAttachmentsData] = useState([]);;
    const [statusVal, setStatusVal] = useState<any>({});
    const [isModalLoading, setModalLoading] = useState(true);
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [tableSelectOptions, setTableSelectOptions] = useState<any>([]);
    const [tableDataShow, setTableDataShow] = useState([]);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const getSingleAccountBill = (row: any) => {
        setModalLoading(true);
        AxiosMain.get(`${AppAPiUrl.AccountingBilling}${row.id}/`).then((res) => {
            setModalLoading(false);
            if (res.status === 200) {
                if (Object.keys(res.data).length > 0) {
                    setStatusModalOpen(true);
                } else {
                    enqueueSnackbar(`No Data Found!`, { preventDuplicate: true, variant: "error", });
                }
                setStatusVal(res.data);
            }
        }).catch((e) => {
            const { response } = e
            console.log("error while geting single account Billing Data", response);
            setStatusModalOpen(false);
            setModalLoading(false);
            enqueueSnackbar(`Billing Data Error!`, { preventDuplicate: true, variant: "error", });
        })
    }
    const getOpenAttachment = (row: any) => {
        setAttachmentsModalLoader(true);
        setAttachmentsModalOpen(true);
        AxiosMain.get(`${AppAPiUrl.AccountingBillingAttachment}${row.id}/`).then((res) => {
            setAttachmentsModalLoader(false);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setAttachmentsData(res.data);
                } else {
                    enqueueSnackbar(`No Data Found!`, { preventDuplicate: true, variant: "error", });
                }
            }
        }).catch((e) => {
            const { response } = e
            console.log("error while geting single account Billing Attachment Data", response);
            setAttachmentsModalLoader(false);
            enqueueSnackbar(`Attachments Error!`, { preventDuplicate: true, variant: "error", });
        })
    }

    const billingRow = [
        {
            size: 300,
            accessorFn: (row: any) => new Date(row.date_invoice),
            id: 'date_invoice',
            header: t('InvoiceDate'),
            filterVariant: 'date-range',
            Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
        },
        {
            accessorFn: (row: any) => row.number,
            header: t('Number'),
            filterVariant: 'text',
            size: 230
        },

        {
            size: 300,
            accessorFn: (row: any) => new Date(row.date_due),
            id: 'date_due',
            header: t('DueDate'),
            filterVariant: 'date-range',
            Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
        },
        {
            accessorKey: 'amount_untaxed',
            header: t('TaxExcluded'),
            size: 230,
            accessorFn: (row: any) => priceFormatWithCurrency(row.currency_id, row.amount_untaxed),
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'amount_tax',
            header: t('Tax'),
            size: 230,
            accessorFn: (row: any) => priceFormatWithCurrency(row.currency_id, row.amount_tax),
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'amount_total',
            header: t('Total'),
            size: 230,
            accessorFn: (row: any) => priceFormatWithCurrency(row.currency_id, row.amount_total),
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            header: t('Status'),
            size: 230,
            accessorFn: (row: any) => <Box className="cm-table-td " sx={{ cursor: "pointer" }} onClick={() => {
                console.log(row);
                getSingleAccountBill(row);
            }}>{row.state !== '' ? row.state : "No state"}</Box>,
            // enableSorting: false,
            // enableFilters: true,
            // enableColumnFilterModes: false,
            // enableColumnFilters: false,
            // enableColumnActions: false,
            // enableColumnFilter: true,
            filterVariant: "select",
            filterSelectOptions: tableSelectOptions
        },
        {
            accessorFn: (row: any) => {
                return (
                    <Box onClick={() => getOpenAttachment(row)}
                            sx={{ alignItems: "center" }}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                            <Box><AttachmentIcon /></Box>
                            </Stack>
                    </Box>
                )
            },
            header: t('Attachments'),
            size: 230,
            enableSorting: false,
            enableFilters: false,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
    ];
    const billingSorting = [
        {
            descSort: false,
            ascSort: true,
            id: t("Status"),
        },
        {
            descSort: "-number",
            ascSort: "number",
            id: t('Number'),
        },
        {
            descSort: "-date_invoice",
            ascSort: "date_invoice",
            id: "date_invoice",
        },
        {
            descSort: "-date_due",
            ascSort: "date_due",
            id: "date_due",
        },
    ];
    const billingSearching = [
        {
            match: "date_invoice",
            id: "date_invoice",
        },
        {
            match: "date_due",
            id: "date_due",
        },
        {
            match: "state",
            id: t("Status"),
        },
        {
            match: "number",
            id: t('Number'),
        },
    ];
    const getBillCompany = (val: any, data: any, option: any) => {
        if (Object.keys(val).length > 0) {
            if (val.hasOwnProperty(data)) {
                if (val[`${data}`].length > 0) {
                    if (Array.isArray(val[`${data}`][0][`${option}`])) {
                        return val[`${data}`][0][`${option}`][1]
                    }

                    return val[`${data}`][0][`${option}`];
                } else {
                    return "No data";
                }
            } else {
                if (Array.isArray(val[`${option}`])) {
                    return val[`${option}`][1]
                }
                return val[`${option}`] ? val[`${option}`] : "Empty";
            }
        }
    }
    const cmSetTableData = (rows: any) => setTableDataShow(rows);
    const getpopDate = (row: any, tableData: any) => getSingleAccountBill(row);
    const getDataCurrency = (val: any, data: any, option: any) => {
        if (Object.keys(val).length > 0) {
            if (val.hasOwnProperty(data)) {
                if (val[`${data}`].length > 0) {
                    if (Array.isArray(val[`${data}`][0][`${option}`])) {
                        return val[`${data}`][0][`${option}`]
                    }
                } else {
                    return "No data";
                }
            }
        }
    }
    const base64ToBlob = (base64String: any, contentType = 'application/pdf') => {
        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], { type: contentType });
    };
    const getUniqueArray = (arr: any, index: any) => {
        const unique = arr
            .map((e: any) => e[index])
            // store the keys of the unique objects
            .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e]).map((e: any) => arr[e]);
        return unique;
    }
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);

    useEffect(() => {
        if (tableDataShow.length > 0) {
            const newOptions = tableDataShow.map((item: any) => {
                let output: any = {};
                if (item.state === "open" || item.state === "Open") {
                    output["text"] = "Open";
                    output["value"] = "true";
                }
                else {
                    output["text"] = "Close";
                    output["value"] = "false";
                }
                return output;
            })
            const uniqueOptions = getUniqueArray(newOptions, "text");
            setTableSelectOptions(uniqueOptions)
        }
    }, [tableDataShow]);
    return (
        <>
            <Modal open={isStatusModalOpen} onClose={() => setStatusModalOpen(false)}>
                <Box sx={billingModalStyle}>
                    {isModalLoading ? <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><LoaderScreen /></Box> : (
                        <Box>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent={"space-between"}>
                                <div>
                                    <img src={assets.images.colorLogo} width={170} height={72} alt="Setera Clould Communication" />
                                </div>
                                <div className="account-bill-modal-top-right-wrap">
                                    <div style={modalTopBarRight}>{getBillCompany(statusVal, "billCompany", "display_name")}</div>
                                    <div style={modalTopBarRight}>{getBillCompany(statusVal, "billCompany", "street")}</div>
                                    <div style={modalTopBarRight}>{`${getBillCompany(statusVal, "billCompany", "zip")} ${getBillCompany(statusVal, "billCompany", "city")} ${getBillCompany(statusVal, "billCompany", "country_id")}`}</div>
                                </div>
                            </Stack>
                            <Box sx={{ borderTop: "2px solid #F2F2F2", paddingTop: "30px", marginBottom: "30px" }}>
                                <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                                    <div>
                                        <div style={{ fontSize: "14px", fontWeight: "500" }}>{getBillCompany(statusVal, "billDeliveryCustomer", "display_name")}</div>
                                        <div style={{ fontSize: "14px" }}>{getBillCompany(statusVal, "billDeliveryCustomer", "street")}</div>
                                        <div style={{ fontSize: "14px" }}>{`${getBillCompany(statusVal, "billDeliveryCustomer", "zip")} ${getBillCompany(statusVal, "billDeliveryCustomer", "city")} ${getBillCompany(statusVal, "billDeliveryCustomer", "country_id")}`}</div>
                                    </div>
                                    <div>
                                        <div style={{ fontSize: "14px", fontWeight: "500" }}>{getBillCompany(statusVal, "billBuyerCustomer", "display_name")}</div>
                                        <div style={{ fontSize: "14px" }}>{getBillCompany(statusVal, "billBuyerCustomer", "street")}</div>
                                        <div style={{ fontSize: "14px" }}>{`${getBillCompany(statusVal, "billBuyerCustomer", "zip")} ${getBillCompany(statusVal, "billBuyerCustomer", "city")} ${getBillCompany(statusVal, "billBuyerCustomer", "country_id")}`}</div>
                                        <Box sx={{ marginTop: "15px" }}>
                                            <div style={{ fontSize: "14px" }}>{t("Vat")}:- {getBillCompany(statusVal, "billCompany", "vat")}</div>
                                            <div style={{ fontSize: "14px" }}>{t("BusinessID")}:- {getBillCompany(statusVal, "billCompany", "business_id")}</div>
                                        </Box>
                                    </div>
                                </Stack>
                            </Box>
                            <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
                                <h3 style={{ fontSize: "13px", color: "#4BABCC" }}>{t("VendorBill")} {getBillCompany(statusVal, "billBasicData", "number")}</h3>
                                <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                                    <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                                        <div>
                                            <div style={{ fontSize: "12px", color: "#4BABCC" }} >{t("Description")}:</div>
                                            <div style={{ fontSize: "12px", marginTop: "10px" }}>{getBillCompany(statusVal, "billBasicData", "name")}</div>
                                        </div>
                                        <div>
                                            <div style={{ fontSize: "12px", color: "#4BABCC" }}>{t('Date')}:</div>
                                            <div style={{ fontSize: "12px", marginTop: "10px" }}>{getBillCompany(statusVal, "billBasicData", "date_invoice")}</div>
                                        </div>
                                        <div>
                                            <div style={{ fontSize: "12px", color: "#4BABCC" }}>{t("DueDate")}:</div>
                                            <div style={{ fontSize: "12px", marginTop: "10px" }}>{getBillCompany(statusVal, "billBasicData", "date_due")}</div>
                                        </div>
                                    </Stack>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent={"space-between"}>
                                        <div>
                                            <div style={{ fontSize: "12px", color: "#4BABCC" }} >{t("Refrence")}:</div>
                                            <div style={{ fontSize: "12px", marginTop: "10px" }}>{getBillCompany(statusVal, "billBasicData", "reference")}</div>
                                        </div>
                                        <div>
                                            <div style={{ fontSize: "12px", color: "#4BABCC" }}>{t('Source')}:</div>
                                            <div style={{ fontSize: "12px", marginTop: "10px" }}>{getBillCompany(statusVal, "billBasicData", "origin")}</div>
                                        </div>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Box sx={{ marginBottom: "10px" }}>
                                <TableContainer sx={{ borderTop: "1px solid #F2F2F2" }}>
                                    <Table aria-label="Accounting Billing Modal" size="small" className="billing-modal-table-list">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" sx={{ paddingLeft: 0, color: "#4BABCC", width: "45%" }}>{t('Description')}</TableCell>
                                                <TableCell align="right" sx={{ color: "#4BABCC" }}>{t('Quantity')}</TableCell>
                                                <TableCell align="right" sx={{ color: "#4BABCC", width: "120px" }}>{t("UnitPrice")}</TableCell>
                                                <TableCell align="left" sx={{ color: "#4BABCC", width: "120px" }}>{t("Taxes")}</TableCell>
                                                <TableCell align="right" sx={{ paddingRight: 0, color: "#4BABCC", width: "140px" }}>{t("Amount")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(statusVal).length > 0 && statusVal.hasOwnProperty("billInvoiceLines") &&
                                                statusVal.billInvoiceLines.map((item: any, indexval: any) => {
                                                    let uom = "";
                                                    let line_section = "";
                                                    if (getBillCompany(item, "", "uom_id")) {
                                                        uom = getBillCompany(item, "", "uom_id");
                                                    }
                                                    if (getBillCompany(item, "", "display_type") === 'line_section') {
                                                        line_section = "Kuluttaja-asiakaspalvelu kausi 1.8.2023 - 31.8.2023";
                                                        return (
                                                            <TableRow key={`accounting-bill-modal-inovoice-details-${indexval}`}>
                                                                <TableCell sx={{ paddingLeft: 0, border: "none", color: "#4BABCC", }} colSpan={5}>{line_section}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    return (
                                                        <TableRow key={`accounting-bill-modal-inovoice-details-${indexval}`}>
                                                            <TableCell align="left" sx={{ paddingLeft: 0, fontSize: "13px" }}>{`${getBillCompany(item, "", "name")} ${line_section}`}</TableCell>
                                                            <TableCell align="right" sx={{ fontSize: "13px" }}>{`${getBillCompany(item, "", "quantity")} ${uom}`}</TableCell>
                                                            <TableCell align="right" sx={{ fontSize: "13px" }}>{`${getBillCompany(item, "", "price_unit")}`}</TableCell>
                                                            <TableCell align="left" sx={{ fontSize: "13px" }}>{`${t('Vat')} ${getBillCompany(item, "", "tax")}`}%</TableCell>
                                                            <TableCell align="right" sx={{ paddingRight: 0, fontSize: "13px" }}>{priceFormatWithCurrency(item[`currency_id`], getBillCompany(item, "", "price_subtotal"))}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ fontSize: "13px", background: "#F2F2F2", padding: "5px" }}>0800-133888 numeron puhelulaskutus erillisellä laskulla</Box>
                            </Box>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent={"end"}>
                                <Box sx={{ marginBottom: "30px", display: "inline-block", minWidth: "325px" }}>
                                    <TableContainer sx={{ borderTop: "1px solid #F2F2F2" }}>
                                        <Table aria-label="Accounting Billing Modal" size="small" className="billing-modal-table-list">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" sx={{ color: "#4BABCC" }}>{t("Subtotal")}</TableCell>
                                                    <TableCell align="right" sx={{ color: "#4BABCC", fontWeight: "400" }}>{priceFormatWithCurrency(getDataCurrency(statusVal, "billBasicData", "currency_id"), getBillCompany(statusVal, "billBasicData", "amount_untaxed"))}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left" sx={{ fontSize: "13px", fontWeight: "600" }}>{t("Taxes")}</TableCell>
                                                    <TableCell align="right" sx={{ fontSize: "13px" }}>{priceFormatWithCurrency(getDataCurrency(statusVal, "billBasicData", "currency_id"), getBillCompany(statusVal, "billBasicData", "amount_tax"))}</TableCell>
                                                </TableRow>
                                                <TableRow sx={{ background: "#F2F2F2" }}>
                                                    <TableCell align="left" sx={{ fontSize: "13px", color: "#4BABCC", fontWeight: "600" }}>{t('Total')}</TableCell>
                                                    <TableCell align="right" sx={{ fontSize: "13px", color: "#4BABCC" }}>{priceFormatWithCurrency(getDataCurrency(statusVal, "billBasicData", "currency_id"), getBillCompany(statusVal, "billBasicData", "amount_total"))}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Stack>
                            <Box sx={{ marginBottom: "10px", padding: "10px 0" }}>
                                <div style={{ fontSize: "14px", fontWeight: "500" }}>{getBillCompany(statusVal, "billBasicData", "payment_term_id")}</div>
                            </Box>
                            <Box sx={{ marginBottom: "10px", borderTop: "1px solid #F2F2F2", padding: "20px 0" }}>

                                <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                                    <div className="account-bill-modal-after-total-wrap">
                                        <div style={modalAftertotalList}>{getBillCompany(statusVal, "billCompany", "display_name")}</div>
                                        <div style={modalAftertotalList}>{getBillCompany(statusVal, "billCompany", "street")}</div>
                                        <div style={modalAftertotalList}>{`${getBillCompany(statusVal, "billCompany", "zip")} ${getBillCompany(statusVal, "billCompany", "city")} ${getBillCompany(statusVal, "billCompany", "country_id")}`}</div>
                                    </div>
                                    <div className="account-bill-modal-top-right-wrap">
                                        <div style={modalAftertotalList}>+358 201 500 800</div>
                                        <div style={modalAftertotalList}>invoice@setera.com</div>
                                        <div style={modalAftertotalList}>https://setera.com</div>
                                    </div>
                                    <div className="account-bill-modal-top-right-wrap">
                                        <div style={modalAftertotalList}>{t("Domicile")}: {getBillCompany(statusVal, "billCompany", "city")}</div>
                                        <div style={modalAftertotalList}>{t("Vat")}: {getBillCompany(statusVal, "billCompany", "vat")}</div>
                                    </div>
                                </Stack>
                            </Box>
                            <Box sx={{ borderTop: "1px dashed #F2F2F2", padding: "20px 0" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sx={{ borderBottom: "1px solid #DFE2E6" }}>
                                        <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                                            {t("RecipientsAccountNumber")}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sx={{ border: "1px solid #DFE2E6", borderTop: "none" }}>
                                        <div>{getBillCompany(statusVal, "billBasicData", "partner_bank_id")}</div>
                                    </Grid>
                                    <Grid item xs={4} sx={{ borderBottom: "1px solid #DFE2E6" }}>
                                        <div>IBI DABAFIHH</div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sx={{ borderBottom: "1px solid #DFE2E6" }}>
                                        <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                                            {t('Recipient')}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sx={{ border: "1px solid #DFE2E6", borderTop: "none" }}>
                                        <div style={modalAftertotalList}><strong>{getBillCompany(statusVal, "billCompany", "display_name")}</strong></div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={modalAftertotalList}><strong>{t('InvoiceNumber')}</strong></div>
                                        <div style={modalAftertotalList}><strong>{getBillCompany(statusVal, "billBasicData", "number")}</strong></div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sx={{ borderBottom: "1px solid #DFE2E6" }}>
                                        <Stack direction="column" spacing={2} justifyContent={"space-between"} sx={{
                                            height: "100%",
                                            py: "10px"
                                        }}>
                                            <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px" }}>
                                                {t("Debtor")}
                                            </div>
                                            <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px" }}>
                                                {t("Signature")}
                                            </div>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sx={{ border: "1px solid #DFE2E6", borderTop: "none" }}>
                                        <div style={{ fontSize: "14px", fontWeight: "500" }}>{getBillCompany(statusVal, "billBuyerCustomer", "display_name")}</div>
                                        <div style={{ fontSize: "14px" }}>{getBillCompany(statusVal, "billBuyerCustomer", "street")}</div>
                                        <div style={{ fontSize: "14px" }}>{`${getBillCompany(statusVal, "billBuyerCustomer", "zip")} ${getBillCompany(statusVal, "billBuyerCustomer", "city")} ${getBillCompany(statusVal, "billBuyerCustomer", "country_id")}`}</div>
                                        <Box sx={{ paddingTop: "30px", borderBottom: "1px solid #000", marginBottom: "10px" }}>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sx={{ borderBottom: "1px solid #DFE2E6" }}>
                                        <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
                                            {t('FromAccountNo')}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sx={{ border: "1px solid #DFE2E6", borderTop: "none" }}>

                                    </Grid>
                                    <Grid item xs={4} sx={{ borderBottom: "1px solid #DFE2E6", borderTop: "1px solid #DFE2E6", paddingTop: "0px !important" }}>
                                        <Grid container spacing={2} sx={{ marginTop: "0", paddingTop: "0" }}>
                                            <Grid item xs={4} sx={{ p: 0 }}>
                                                <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                                                    {t('PaymentReferenceNumber')}
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} sx={{ border: "1px solid #DFE2E6", borderTop: "none", borderBottom: "none", borderRight: "none" }}>
                                                <div>{getBillCompany(statusVal, "billBasicData", "payment_reference")}</div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ marginTop: "0" }}>
                                            <Grid item xs={4} sx={{ p: 0, borderTop: "1px solid #DFE2E6", }}>
                                                <div style={{ maxWidth: "100px", textAlign: "right", fontSize: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                                                    {t('DueDate')}
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} sx={{ border: "1px solid #DFE2E6", borderBottom: "none", borderRight: "none" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6} sx={{ borderRight: "1px solid #DFE2E6" }}>
                                                        <div style={{ fontSize: "10px" }}>{getBillCompany(statusVal, "billBasicData", "date_due")}</div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div style={{ fontSize: "10px", fontWeight: 700 }}>SUM {priceFormatWithCurrency(getDataCurrency(statusVal, "billBasicData", "currency_id"), getBillCompany(statusVal, "billBasicData", "amount_total"))}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{
                                textAlign: "center"
                            }}>
                                <img src={assets.images.defaultBarcode} width={464} height={58} alt="Setera Clould Communication" />
                                <p>{getBillCompany(statusVal, "billBasicData", "barcode_string")}</p>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Modal>
            <Modal open={isAttachmentsModalOpen} onClose={() => setAttachmentsModalOpen(false)}>
                <Box sx={attachmentModalStyle}>
                    {isAttachmentsModalLoader ? <LoaderScreen /> : (
                        <>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attachmentsData.map((item: any, index: any) => {
                                            const blob = base64ToBlob(item.datas);
                                            // Create a download link
                                            const url = URL.createObjectURL(blob);
                                            return (
                                                <TableRow key={`${item.name}-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button
                                                            target="_blank"
                                                            href={url}
                                                            startIcon={<OpenInNewIcon />}
                                                            variant="contained"
                                                            className="cm-btn-sm-style"
                                                            sx={{
                                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                                background: colorConfigs.btnColor.bgColorGreen,
                                                                "&: hover": {
                                                                    color: colorConfigs.btnColor.bgColorGreen,
                                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                                },
                                                            }}>{t("Preview")}</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </Box>
            </Modal>
            <Box className='cm-global-tabs-wrapper' sx={{ overflow: "hidden", borderRadius: "8px", marginTop: "20px" }}>
                <div className='cm-form-inner-fields cm-pagination-billing-dt cm-table-remvove-style'>
                    {isTransLoaded
                            ? <MaterialReactDataTable
                            isallData={true}
                                useFor={"Accounting Billing List"}
                                apiLink={AppAPiUrl.AccountingBilling}
                                tRow={billingRow}
                                clickFunc={getpopDate}
                            setTableDataShow={cmSetTableData}
                            clickFuncDataShow={true}
                            sortingData={billingSorting}
                            searchData={billingSearching}
                            />
                        : <LoaderScreen />}
                </div>
            </Box>
        </>)
}
function AccountBillingComponentReady({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(Billing, "accounting_billing", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AccountBillingComponentReady, appModelPermission.ACCOUNTINGBILLING, appModelType.READ);