import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import { RouteType } from "../../routes/config";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SidebarItemList from "./SidebarItemList";
import { useLocation, useNavigate } from "react-router-dom";
import { setAppNextRoute, setAppPrevRoute } from "../../redux/features/appStateSlice";


type Props = {
  item: RouteType;
  hideSidebar: Function;
  t: Function,
  translate: any
};

const SidebarItemCollapse = ({ item, hideSidebar, t, translate }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [parentMenu, setParentMenu] = useState(false);
  const [sideBarText, setSideBarText] = useState('');
  const { appState, appCountry, appCurrentPageForm } = useSelector((state: RootState) => state.appState);

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    }
    const displayText: any = item.sidebarProps?.displayText.replace(/ /g, '');
    const tempText = t(`sidebar_${displayText}`);
    setSideBarText(tempText);
    // eslint-disable-next-line
  }, [appState, item, appCountry, translate]);
  return (
    item.routeStatus && item.sidebarProps ? (
      <>
        <ListItemButton
          className={parentMenu ? "active-page" : "no-active"}
          onClick={(e) => {
            if (item.path === "/admin" || item.path === "/wholesale") {
              //navigate(item.path);
              dispatch(setAppPrevRoute(location.pathname));
              if (appCurrentPageForm === 'no') {
                navigate(item.path);
              } else {
                dispatch(setAppNextRoute(item.path));
              }
            }
            setOpen(!open);
          }}
          sx={{
            "&: hover": {
              backgroundColor: "transparent"
            },
            padding:"17px 37px 17px 24px",
            // paddingY: "17px",
            // paddingX: "24px"
          }}
        >

          <ListItemIcon className={`cm-menu-ics ${item.sidebarProps.icon ? "cm-icn-y" : "cm-icn-n"}`} sx={{
            color: colorConfigs.sidebar.color
          }}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={sideBarText}
          />
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List className={open ? "sub-pages" : "sub-pages-2"}>
            {item.child?.map((route, index) => (
              route.routeStatus && route.sidebarProps ? (
                route.child ? (
                  <SidebarItemCollapse item={route} key={index} hideSidebar={hideSidebar} t={t} translate={translate} />
                ) : route.isThisShow === undefined && (
                    <SidebarItemList t={t} item={route} key={index} hideSidebar={hideSidebar} setParentMenu={setParentMenu} parentMenu={parentMenu} translate={translate} />
                )
              ) : null
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SidebarItemCollapse;