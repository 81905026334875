import { Box, Button, FormControl, Grid, Modal, Stack, TextField, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AxiosMain from "../../components/layout/AxiosMain";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import PaginationDropDown from "../../components/layout/PaginationDropDown";
import LoaderScreen from "../../components/layout/LoaderScreen";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
function AdminCommissionCounting(props:any){
    const navigate = useNavigate();
    const [isAddCommission, setAddCommission]= useState(false);
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [isCommissionLoader, setCommissionLoader]= useState(false);
    const [dependendedVal, setDependedVal]= useState<any>([]);
    const [tableDataShow,setTableDataShow]=useState([]);
    const [triggerUpdate, setTriggerUpdateData] = useState(false);
    const [openAddCCModal, setopenAddCCModal] = useState(false);
    const appPermission = useSelector((state: RootState) => state.appState.appPermission)
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const handleAddCommission = ()=>{
        setAddCommission(!isAddCommission)
    }
    const orgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const commissionSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        company: Yup.string().required('Required'),
        currency: Yup.string().required('Required'),
    });
    const commissionForm = useFormik({
        initialValues:{
            name:"",
            company:"",
            currency:"",
        },
        validationSchema:commissionSchema,
        onSubmit:(values)=>{
            console.log("values", values);
            setCommissionLoader(true);
            const apiURL = AppAPiUrl.Commissions;
            addCommission(apiURL,values)
        }
    })

    const addCommission = (apiURL: any, data:any) => {
        let APiValues = { ...data};
        //APiValues["company"] = orgnisationData?.id;
        AxiosMain.post(apiURL, APiValues).then((res) => {
            commissionForm.resetForm();
            if(res.status === 201){
                setCommissionLoader(false);
                setTriggerUpdateData(true);
                setopenAddCCModal(false);
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            //const { response } = e;
            const { response } = e;
            if (response.status === 401) {
                Swal.fire({
                    text: response.data.detail,
                    icon: 'warning',
                  })
            } else if (response.status === 400) {
                for (const [key, value] of Object.entries(response.data)) {
                    const cmMsg:any = value;
                    Swal.fire({
                        text: cmMsg,
                        icon: 'warning',
                      })
                }
            }
            else {
                Swal.fire({
                    text: "Somethig Went Wrong!",
                    icon: 'warning',
                  })
            }
        })
    }
    
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const ccModalstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: "900px;",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: "calc(100% - 20px)",
        overflow: "auto",
        borderRadius: "21px",
        boxsize: "content-box"
      };
    const mobileSearching = [
        {
            match: "organization",
            id: t("Organization"),
        },
        {
            match: "total",
            id: t("Total"),
        },
    ];
    const mobileSorting = [
        {
            descSort: "-organization",
            ascSort: "organization",
            id: t('Organization'),
        },
        {
            descSort: "-total",
            ascSort: "total",
            id: t("Total"),
        },
    ]
    const mobileCertificateRow = [
        {
            accessorFn: (row: any) => row.name,
            header: t("CommissionName"),
        },
        {
            accessorFn: (row: any) => row.company.name,
            header: t("Company"),
        },
        {
            accessorFn: (row: any) => row.currency,
            header: t("Currency"),
        },
        
    ]
    const cmSetTableData = (rows: any) => setTableDataShow(rows);
    const getpopDate = (row: any) => navigate(`/admin/commission-counting/${row.id}`);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 1000);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    const handleModalClose = ()=>{
        commissionForm.resetForm()
        setopenAddCCModal(false)
    }
    const resetTrigger = ()=>{
        setTriggerUpdateData(false)
    }
    return(
        <Box sx={{my:"20px"}}>
            <Modal
                open={openAddCCModal}
                onClose={()=> handleModalClose()}
                aria-labelledby="Modal-commission-counting-Add"
                aria-describedby="Modal-commission-counting-Add"
            >
                <Box sx={ccModalstyle}>
                <Box sx={{
                    textAlign: "right",
                    position: "absolute",
                    top: "12px",
                    right: "25px",
                }}> <CloseIcon onClick={()=> handleModalClose()} sx={{
                    color: "#CCCCCC",
                    width: "38px",
                    height: "38px",
                    cursor: "pointer"
                }} /></Box>
                <Box sx={{ maxWidth: "450px", margin: "0 auto" }}>
                    <Box className="fw-600" sx={{ color: "#012445", fontSize: "40px;", lineHeight: "normal" }}>{t("AddCommission")}</Box>
                    <Box sx={{ width: "100%" }}>
                        <Box>
                            {isCommissionLoader ? <LoaderScreen/> :(
                                <form onSubmit={commissionForm.handleSubmit}>
                                <Box>
                                    <Grid container spacing={5} sx={{ paddingTop: "0" }}>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Box className="cm-form-inner-fields">
                                                <Box className="form-mb-30">
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <div className="form-lable-name">
                                                                        {t("Commission")}
                                                                    </div>
                                                                    <FormControl sx={{ width: "100%" }}>
                                                                        <TextField
                                                                            className="cm-login-filed"
                                                                            name="name"
                                                                            variant="outlined"
                                                                            placeholder={`${t("Commission")}`}
                                                                            value={commissionForm.values.name}
                                                                            onChange={commissionForm.handleChange}
                                                                            onBlur={commissionForm.handleBlur}
                                                                        />
                                                                    </FormControl>
                                                                    {commissionForm.errors.name && commissionForm.touched.name && <p className="cm-form-error">{commissionForm.errors.name}</p>}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                <PaginationDropDown
                                                                    apiLink={AppAPiUrl.CommissionOrgList}
                                                                    apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                    optionLableName={`${t("Company")}`}
                                                                    saveValue={commissionForm}
                                                                    formFiled="company"
                                                                />
                                                                {commissionForm.errors.company && <p className="cm-form-error">{commissionForm.errors.company}</p>}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box sx={{ width: "100%" }}>
                                                                    <div className="form-lable-name">
                                                                        {t("Currency")}
                                                                    </div>
                                                                    <FormControl sx={{ width: "100%" }}>
                                                                    <Select
                                                                            className='cm-login-filed'
                                                                            name="currency"
                                                                            label=""
                                                                            placeholder="Select"
                                                                            value={commissionForm.values.currency}
                                                                            onChange={commissionForm.handleChange}
                                                                            onBlur={commissionForm.handleBlur}
                                                                        >
                                                                            {/* <MenuItem value="">Select</MenuItem> */}
                                                                            <MenuItem value={"dollar"}>Dollar ($)</MenuItem>
                                                                            <MenuItem value={"euro"}>Euro (€)</MenuItem>
                                                                            <MenuItem value={"pound"}>Pound (£)</MenuItem>
                                                                        </Select>

                                                                    </FormControl>
                                                                    {commissionForm.errors.currency && commissionForm.touched.currency && <p className="cm-form-error">{commissionForm.errors.currency}</p>}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    <Stack
                                                                        direction="row"
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                        justifyContent={"start"}
                                                                        className="form-submit-cancel-btn-wrapper"
                                                                    >
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            className="cm-btn-style"
                                                                            sx={{
                                                                                width: "100%",
                                                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                                                background: colorConfigs.btnColor.bgColorGreen,
                                                                                "&: hover": {
                                                                                    color: colorConfigs.btnColor.bgColorGreen,
                                                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {t("Add")}
                                                                        </Button>
                                                                    </Stack>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* Button save changes and Discard */}

                            </form>
                            )}
                        </Box>
                    </Box>
                </Box>
                </Box>
            </Modal>
            <div className="text-right">
                <Button
                    onClick={()=> setopenAddCCModal(true)}
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="cm-btn-md-style"
                    sx={{
                    color: colorConfigs.btnStyle.color,
                    background: "#4BABCC",
                    "&: hover": {
                        color: "#4BABCC",
                        background: colorConfigs.btnColor.hoverbgWhite,
                    },
                    }}
                >Add Commission</Button>
            </div>
            {/* {
                !isAddCommission ? (
                    
                ): (
                    <Box>
                        <Card>
                            <CardContent>
                            <Box sx={{ marginBottom: "20px"}}>
                                <Button
                                    onClick={handleAddCommission}
                                    variant="contained"
                                    startIcon={<ArrowBackIosIcon />}
                                    className="cm-btn-sm-style"
                                    sx={{
                                        color: colorConfigs.btnColor.hoverbgWhite,
                                        background: colorConfigs.btnColor.bgColorRed,
                                        "&: hover": {
                                        color: colorConfigs.btnColor.bgColorRed,
                                        background: colorConfigs.btnColor.hoverbgWhite,
                                        }
                                    }}>
                                        Back
                                    </Button> 
                                </Box>

                            </CardContent>
                        </Card>
                    </Box>
                )
            } */}
             <div style={{marginTop:"20px", marginBottom:"20px"}}>
                <MaterialReactDataTable
                    parentDependValue={dependendedVal}
                    useFor={"Mobile Certification List"}
                    apiLink={AppAPiUrl.Commissions}
                    tRow={mobileCertificateRow}
                    searchData={mobileSearching}
                    sortingData={mobileSorting}
                    clickFunc={getpopDate}
                    setTableDataShow={cmSetTableData}
                    triggerUpdate={triggerUpdate}
                    resetTrigger={resetTrigger}
                    clickFuncDataShow={true}/>
            </div>
        </Box>
    )
}


function CommissionCounting({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(AdminCommissionCounting, "admin_commission", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}


export default AppPermissionHOC(CommissionCounting, appModelPermission.COMMISSIONS, appModelType.READ)
