import { Box, Button, Card, CardContent, IconButton, Stack, FormControl, Grid, Modal, Slider, Select, MenuItem, Divider, Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import colorConfigs from "../../configs/colorConfigs";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import PaymentsIcon from '@mui/icons-material/Payments';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import * as Yup from 'yup';
import AxiosMain from "../../components/layout/AxiosMain";
// import DatePicker from "react-datepicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Swal from "sweetalert2";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import { enqueueSnackbar } from "notistack";
import LoaderScreen from "../../components/layout/LoaderScreen";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import * as changeCase from "change-case";


function AdminCommissionCountingSingle(props: any) {
    const navigate = useNavigate();
    const param = useParams();
    const { id } = param;
    const [oldID, setId] = useState(id);
    const [singleCommissionData, setSingelCommissionData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditSimLoading, setEditSimLoading] = useState(false);
    const [isEndSubModal, setEndSubModal] = useState(false);
    const [triggerUpdate, setTriggerUpdateData] = useState(false);
    const [isAPIOn, setApiOn] = useState(false);
    const [isDataLoaded, setDataLoaded] = useState(false);
    // above portion added later
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [isAddProductLoader,setAddProductLoader]= useState(false);
    const [dependendedVal, setDependedVal] = useState<any>([]);
    const [productCategoryOptions, setProductCategoryOption] = useState<any>([]);
    const [allAgentOptions, setAllAgentOptions] = useState<any>([]);
    const [openAddProductModal, setopenAddProductModal] = useState(false);
    const [openAddAgentModal, setopenAddAgentModal] = useState(false);
    const [tableDataShow, setTableDataShow] = useState([]);
    const [agentsListData, setagentsListData] = useState<any>([]);
    const appPermission = useSelector((state: RootState) => state.appState.appPermission)
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const getProductCategory = (apiURL: any) => {
        AxiosMain.get(`${apiURL}${commissionForm.values.apply_on === "all" ? "?select=all" : "?select=productcategory"}`).then((res) => {
            if (res.status === 200 && res.data.length > 0) {
                const options = res.data?.map((item: any) => { return { "label": `${commissionForm.values.apply_on === "all"? item.description : item.category_name}`, "id": item.id } })
                setProductCategoryOption(options);
                setTriggerUpdateData(true);
            } else {
                setProductCategoryOption([]);
                // setTimeout(() => {
                //     setIsRefetching(false);
                // }, 800)
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            // const { response } = e;
            const { response } = e;
            if (response.status === 401) {
                Swal.fire({
                    text: response.data.detail,
                    icon: 'warning',
                  })
            } else if (response.status === 400) {
                for (const [key, value] of Object.entries(response.data)) {
                    const cmMsg:any = value;
                    Swal.fire({
                        text: cmMsg,
                        icon: 'warning',
                      })
                }
            }
            else {
                Swal.fire({
                    text: "Somethig Went Wrong!",
                    icon: 'warning',
                  })
            }
        })
    }

    const getAgentOptions = () => {
        AxiosMain.get(AppAPiUrl.CommissionAgentList).then((res) => {
            if (res.status === 200 && res.data.length > 0) {
                const options = res.data?.map((item: any) => { return { "label": item.name, "id": item.id } })
                setAllAgentOptions(options);
                setLoaded(true);
            } else {
                setAllAgentOptions([]);
                // setTimeout(() => {
                //     setIsRefetching(false);
                // }, 800)
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            const { response } = e;
            if (response.status === 401) {
                Swal.fire({
                    text: response.data.detail,
                    icon: 'warning',
                  })
            } else if (response.status === 400) {
                for (const [key, value] of Object.entries(response.data)) {
                    const cmMsg:any = value;
                    Swal.fire({
                        text: cmMsg,
                        icon: 'warning',
                      })
                }
            }
            else {
                Swal.fire({
                    text: "Somethig Went Wrong!",
                    icon: 'warning',
                  })
            }
        })
    }

    const deleteProduct = (apply_on: string, item_id: number) => {
        let apiURL = AppAPiUrl.CommissionproductDelete
        if (apply_on !== "all") {
            apiURL = AppAPiUrl.CommissioncategoryDelete
        }
        AxiosMain.delete(`${apiURL}${item_id}/`).then((res) => {
            if (res.status === 204) {
                setTriggerUpdateData(true)
                commissionForm.resetForm()
                setopenAddProductModal(false)

            }
        }).catch((e) => {
            console.log("While geting Error", e);
            const { response } = e;
            if (response.status === 401) {
                Swal.fire({
                    text: response.data.detail,
                    icon: 'warning',
                  })
            } else if (response.status === 400) {
                for (const [key, value] of Object.entries(response.data)) {
                    const cmMsg:any = value;
                    Swal.fire({
                        text: cmMsg,
                        icon: 'warning',
                      })
                }
            }
            else {
                Swal.fire({
                    text: "Somethig Went Wrong!",
                    icon: 'warning',
                  })
            }
        })
    }

    const deleteAgent = (agent_id: number) => {
        AxiosMain.delete(`${AppAPiUrl.CommissionAgent}${agent_id}/`).then((res) => {
            if (res.status === 204) {
                let updatedAgentList = agentsListData.filter((obj: any) => obj.id !== agent_id)
                setagentsListData(updatedAgentList)
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            const { response } = e;
            if (response.status === 401) {
                Swal.fire({
                    text: response.data.detail,
                    icon: 'warning',
                  })
            } else if (response.status === 400) {
                for (const [key, value] of Object.entries(response.data)) {
                    const cmMsg:any = value;
                    Swal.fire({
                        text: cmMsg,
                        icon: 'warning',
                      })
                }
            }
            else {
                Swal.fire({
                    text: "Somethig Went Wrong!",
                    icon: 'warning',
                  })
            }
        })
    }

    const getSingleCommissionData = () => {
        setApiOn(true);
        AxiosMain.get(`${AppAPiUrl.CommissionDetail}${id}`).then((res) => {
            if (res.status === 200) {
                setSingelCommissionData(res.data.commission_data);
                setagentsListData(res.data.agents)
            }
        }).catch((e) => {
            console.log("error", e);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        setIsLoading(true);
        setDataLoaded(false);
        getSingleCommissionData();
        setId(id);
        // eslint-disable-next-line
    }, [id])
    const resetTrigger = () => {
        // console.log("Hello iam calling");
        setTriggerUpdateData(false);
    }
    const ccModalstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: "600px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: "calc(100% - 20px)",
        overflow: "auto",
        borderRadius: "21px",
        boxsize: "content-box"
    };
    const mobileSearching = [
        {
            match: "organization",
            id: t("Organization"),
        },
        {
            match: "total",
            id: t("Total"),
        },
    ];
    const mobileSorting = [
        {
            descSort: "-organization",
            ascSort: "organization",
            id: t('Organization'),
        },
        {
            descSort: "-total",
            ascSort: "total",
            id: t("Total"),
        },
    ]
    const mobileCertificateRow = [
        {
            accessorFn: (row: any) => row?.product?.description || row?.category?.category_name || '-',
            header: t("Product"),
        },
        {
            accessorFn: (row: any) => changeCase.capitalCase(row.commission_type||''),
            header: t("CommissionType"),
        },
        {
            accessorFn: (row: any) => row.calculation,
            header: t("Calculation"),
        },
        {
            accessorFn: (row: any) => row.value,
            header: t("Value"),
        },
        {
            accessorFn: (row: any) => new Date(row.start_date).toLocaleDateString(),
            header: t("StartDate"),
        },
        {
            accessorFn: (row: any) => new Date(row.end_date).toLocaleDateString(),
            header: t("EndDate"),
        },
        {
            accessorFn: (row: any) => <DeleteIcon onClick={() => deleteProduct(`${row?.product? "all":"category"}`,row.id)} />,
            header: t("Action"),
        },

    ]

    const commissionSchema = Yup.object().shape({
        commission_type: Yup.string().required('Required'),
        apply_on: Yup.string().optional(),
        value: Yup.number().required('Required'),
        start_date: Yup.date().required('Required'),
        end_date: Yup.date().required('Required'),
        calculation: Yup.string().required('Required'),
        product: Yup.number().required('Required'),
    });

    const commissionForm = useFormik({
        initialValues: {
            commission_type: "",
            apply_on: "all",
            value: 0.0,
            start_date: "",
            end_date: "",
            calculation: "percent",
            product: "",
            category: "",
            active: true,
            commission: id,
            description: "-"
        },
        validationSchema: commissionSchema,
        onSubmit: (values) => {
            let apiURL = AppAPiUrl.CommissionProducts
            if (values.apply_on !== "all"){
                apiURL = AppAPiUrl.CommissionCategories
                values["category"] = values["product"]
            }
            // const apiURL = values.apply_on === "all" ? AppAPiUrl.CommissionProducts : AppAPiUrl.CommissionCategories
            setAddProductLoader(true);
            addProduct(apiURL, values)
        }
    })

    const agentSchema = Yup.object().shape({
        agent: Yup.number().required('Required'),
        commission: Yup.number().required('Required'),
        active: Yup.boolean().required('Required'),
    });

    const agentForm = useFormik({
        initialValues: {
            agent: "",
            active: true,
            commission: Number(id)
        },
        validationSchema: agentSchema,
        onSubmit: (values) => {
            console.log("agent values", values)
            //const apiURL = AppAPiUrl.products
            addAgent()
        }
    })

    const addAgent = () => {
        AxiosMain.post(AppAPiUrl.CommissionAgentAdd, agentForm.values).then((res) => {
            if (res.status === 201) {
                setTriggerUpdateData(true)
                agentForm.resetForm()
                getSingleCommissionData()
                setopenAddAgentModal(false)
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            // const { response } = e;
            // setIsError(true);
            // if (response) {
            //     if (response.status === 403 && response.statusText === "Forbidden") {
            //         enqueueSnackbar(`${useFor} Permission Denied`, { preventDuplicate: true, variant: "error", });
            //     }
            //     if (response.status === 401 && response.statusText === "Unauthorized") {
            //         enqueueSnackbar(`${useFor} Unauthorized`, { preventDuplicate: true, variant: "error", });
            //     }
            // }
        })
    }



    const addProduct = (apiURL: any, data: any) => {
        let APiValues = { ...commissionForm.values };
        // const { ['apply_on']: remove } = APiValues;
        APiValues["start_date"] = data.start_date.format('YYYY-MM-DD')
        APiValues["end_date"] = data.end_date.format('YYYY-MM-DD')

        // APiValues[""]=""
        AxiosMain.post(apiURL, APiValues).then((res) => {
            if (res.status === 201) {
                setAddProductLoader(false);
                setTriggerUpdateData(true)
                commissionForm.resetForm()
                setopenAddProductModal(false)
            }
        }).catch((e) => {
            console.log("While geting Error", e);
             const { response } = e;
            // setIsError(true);
            if(response){
                if (response.status === 401) {
                    enqueueSnackbar(`${response.data.detail}`, { preventDuplicate: true, variant: "error", });
                }      
                else if (response.status === 403 && response.statusText === "Forbidden") {
                    enqueueSnackbar(`Permission Denied`, { preventDuplicate: true, variant: "error", });
                }
                else if (response.status === 401 && response.statusText === "Unauthorized") {
                    enqueueSnackbar(`Unauthorized`, { preventDuplicate: true, variant: "error", });
                }
                else if (response.status === 400) {
                    for (const [key, value] of Object.entries(response.data)) {
                        enqueueSnackbar(`${key}: ${value}!`, { preventDuplicate: true, variant: "error", });
                    }
                }else {
                    enqueueSnackbar(`Somethig Went Wrong!`, { preventDuplicate: true, variant: "error", });
                }
            }
            else {
                enqueueSnackbar(`Somethig Went Wrong!`, { preventDuplicate: true, variant: "error", });
            }
        })
    }


    useEffect(() => {
        if (openAddProductModal && commissionForm.values.apply_on !== "") {
            const api_url = `${AppAPiUrl.ProductCategories}`
            getProductCategory(api_url)
        }
    }, [openAddProductModal, commissionForm.values.apply_on]);

    useEffect(() => {
        if (openAddAgentModal) {
            getAgentOptions()
        }
    }, [openAddAgentModal]);

    const handleCloseAgentForm = () =>{
        agentForm.resetForm();
        setopenAddAgentModal(false);
    }

    const cmSetTableData = (rows: any) => setTableDataShow(rows);
    // const getpopopenAddAgentModalDate = (row: any) => navigate(`/admin/commission-counting/${row.id}`);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 1000);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    return (
        <Box sx={{ my: "20px" }}>
            <Modal
                open={openAddAgentModal}
                onClose={() => handleCloseAgentForm()}
                aria-labelledby="Modal-commission-counting-Add"
                aria-describedby="Modal-commission-counting-Add"
            >
                <Box sx={ccModalstyle}>
                    <Box sx={{
                        textAlign: "right",
                        position: "absolute",
                        top: "12px",
                        right: "25px",
                    }}> <CloseIcon onClick={() => handleCloseAgentForm()} sx={{
                        color: "#CCCCCC",
                        width: "38px",
                        height: "38px",
                        cursor: "pointer"
                    }} /></Box>
                    <Box sx={{ maxWidth: "450px", margin: "0 auto" }}>
                        <Box className="fw-600" sx={{ color: "#012445", fontSize: "40px;", lineHeight: "normal" }}>{t("AddProduct")}</Box>
                        <Box sx={{ width: "100%" }}>
                            <Box>
                                <form onSubmit={agentForm.handleSubmit}>
                                    <Box>
                                        <Grid container spacing={5} sx={{ paddingTop: "0" }}>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Box className="cm-form-inner-fields">
                                                    <Box className="form-mb-30">
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        <div className="form-lable-name">
                                                                            {t("Agent")}
                                                                        </div>
                                                                        <FormControl sx={{ width: "100%" }}>
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                className='cm-login-filed'
                                                                                options={allAgentOptions}
                                                                                // sx={{ width: 300 }}                                                                                    
                                                                                value={allAgentOptions.find((option: any) => option.id === agentForm.values.agent)}
                                                                                onChange={(event, newValue: any) => agentForm.setFieldValue('agent', newValue.id)}
                                                                                onBlur={agentForm.handleBlur}
                                                                                renderInput={(params) => <TextField {...params} name="agent" />}
                                                                            />
                                                                            {/* <TextField
                                                                                className="cm-login-filed"
                                                                                name="apply_on"
                                                                                variant="outlined"
                                                                                placeholder={`${t("ApplyOn")}`}
                                                                                value={commissionForm.values.value}
                                                                                onChange={commissionForm.handleChange}
                                                                                onBlur={commissionForm.handleBlur}
                                                                            /> */}
                                                                        </FormControl>
                                                                        {agentForm.errors.agent && agentForm.touched.agent && <p className="cm-form-error">{agentForm.errors.agent}</p>}
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={2}
                                                                            alignItems="center"
                                                                            justifyContent={"start"}
                                                                            className="form-submit-cancel-btn-wrapper"
                                                                        >
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                className="cm-btn-style"
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    color: colorConfigs.btnColor.hoverbgWhite,
                                                                                    background: colorConfigs.btnColor.bgColorGreen,
                                                                                    "&: hover": {
                                                                                        color: colorConfigs.btnColor.bgColorGreen,
                                                                                        background: colorConfigs.btnColor.hoverbgWhite,
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {t("Add")}
                                                                            </Button>
                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/* Button save changes and Discard */}

                                </form>


                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openAddProductModal}
                onClose={() => setopenAddProductModal(false)}
                aria-labelledby="Modal-commission-counting-Add"
                aria-describedby="Modal-commission-counting-Add"
            >
                <Box sx={ccModalstyle}>
                    <Box sx={{
                        textAlign: "right",
                        position: "absolute",
                        top: "12px",
                        right: "25px",
                    }}> <CloseIcon onClick={() => setopenAddProductModal(false)} sx={{
                        color: "#CCCCCC",
                        width: "38px",
                        height: "38px",
                        cursor: "pointer"
                    }} /></Box>
                    <Box sx={{ maxWidth: "450px", margin: "0 auto" }}>
                        <Box className="fw-600" sx={{ color: "#012445", fontSize: "40px;", lineHeight: "normal" }}>{t("AddProduct")}</Box>
                        <Box sx={{ width: "100%" }}>
                            {isAddProductLoader ? (<LoaderScreen/>) : (
                                <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <form onSubmit={commissionForm.handleSubmit}>
                                        <Box>
                                            <Grid container spacing={5} sx={{ paddingTop: "0" }}>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <Box className="cm-form-inner-fields">
                                                        <Box className="form-mb-30">
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("CommissionType")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <Select
                                                                                    className='cm-login-filed'
                                                                                    name="commission_type"
                                                                                    label=""
                                                                                    placeholder="Select"
                                                                                    value={commissionForm.values.commission_type}
                                                                                    onChange={commissionForm.handleChange}
                                                                                >
                                                                                    {/* <MenuItem value="">Select</MenuItem> */}
                                                                                    <MenuItem value={"one_time"}>One Time</MenuItem>
                                                                                    <MenuItem value={"recurring"}>Recurring</MenuItem>
                                                                                </Select>
                                                                                {/* <TextField
                                                                                className="cm-login-filed"
                                                                                name="commission_type"
                                                                                variant="outlined"
                                                                                placeholder={`${t("CommissionType")}`}
                                                                                value={commissionForm.values.commission_type}
                                                                                onChange={commissionForm.handleChange}
                                                                                onBlur={commissionForm.handleBlur}
                                                                            /> */}
                                                                            </FormControl>
                                                                            {commissionForm.errors.commission_type && commissionForm.touched.commission_type && <p className="cm-form-error">{commissionForm.errors.commission_type}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("ApplyOn")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <Select
                                                                                    className='cm-login-filed'
                                                                                    name="apply_on"
                                                                                    label=""
                                                                                    placeholder="Select"
                                                                                    value={commissionForm.values.apply_on}
                                                                                    onChange={commissionForm.handleChange}
                                                                                >

                                                                                    <MenuItem value={"all"}>All Product</MenuItem>
                                                                                    <MenuItem value={"product_category"}>Product Category</MenuItem>

                                                                                </Select>
                                                                            </FormControl>
                                                                            {commissionForm.errors.apply_on && commissionForm.touched.apply_on && <p className="cm-form-error">{commissionForm.errors.apply_on}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("Product")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    id="combo-box-demo"
                                                                                    className='cm-login-filed'
                                                                                    options={productCategoryOptions}
                                                                                    value={productCategoryOptions.find((option: any) => option.id === commissionForm.values.product)}
                                                                                    onChange={(event, newValue: any) => commissionForm.setValues({ ...commissionForm.values, 'product': newValue?.id, 'description': newValue?.label })}
                                                                                    onBlur={commissionForm.handleBlur}
                                                                                    renderInput={(params) => <TextField {...params} name="product_category" />}
                                                                                />
                                                                            </FormControl>
                                                                            {commissionForm.errors.product && commissionForm.touched.product && <p className="cm-form-error">{commissionForm.errors.product}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("StartDate")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <DatePicker
                                                                                    // dateFormat="d MMMM yyyy"
                                                                                    // selected={commissionForm.values.start_date}
                                                                                    className="cm-date-picker-field"
                                                                                    //name="start_date"
                                                                                    onChange={(date: any) => {
                                                                                        // setcdrsAction(false);
                                                                                        commissionForm.setFieldValue('start_date', date);
                                                                                    }} />
                                                                                {/* <TextField
                                                                                className="cm-login-filed"
                                                                                name="start_date"
                                                                                variant="outlined"
                                                                                placeholder={`${t("StartDate")}`}
                                                                                value={commissionForm.values.start_date}
                                                                                onChange={commissionForm.handleChange}
                                                                                onBlur={commissionForm.handleBlur}
                                                                            /> */}
                                                                            </FormControl>
                                                                            {commissionForm.errors.start_date && commissionForm.touched.start_date && <p className="cm-form-error">{commissionForm.errors.start_date}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("EndDate")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <DatePicker
                                                                                    // dateFormat="d MMMM yyyy"
                                                                                    // selected={commissionForm.values.start_date}
                                                                                    className="cm-date-picker-field"
                                                                                    //name="end_date"
                                                                                    onChange={(date: any) => {
                                                                                        // setcdrsAction(false);
                                                                                        commissionForm.setFieldValue('end_date', date);
                                                                                    }} />
                                                                                {/* <TextField
                                                                                className="cm-login-filed"
                                                                                name="start_date"
                                                                                variant="outlined"
                                                                                placeholder={`${t("StartDate")}`}
                                                                                value={commissionForm.values.start_date}
                                                                                onChange={commissionForm.handleChange}
                                                                                onBlur={commissionForm.handleBlur}
                                                                            /> */}
                                                                            </FormControl>
                                                                            {commissionForm.errors.start_date && commissionForm.touched.start_date && <p className="cm-form-error">{commissionForm.errors.start_date}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    <Divider />
                                                                    <Grid item xs={12}><strong>Commission Calculation</strong></Grid>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ width: "100%" }}>
                                                                            <div className="form-lable-name">
                                                                                {t("ComputeCommission")}
                                                                            </div>
                                                                            <FormControl sx={{ width: "100%" }}>
                                                                                <Select
                                                                                    className='cm-login-filed'
                                                                                    name="calculation"
                                                                                    label=""
                                                                                    placeholder="Select"
                                                                                    value={commissionForm.values.calculation}
                                                                                    onChange={commissionForm.handleChange}
                                                                                >
                                                                                    {/* <MenuItem value="">Select</MenuItem> */}
                                                                                    <MenuItem value={"fixed"}>Fixed</MenuItem>
                                                                                    <MenuItem value={"percent"}>Percent</MenuItem>
                                                                                </Select>
                                                                                {/* <TextField
                                                                                className="cm-login-filed"
                                                                                name="apply_on"
                                                                                variant="outlined"
                                                                                placeholder={`${t("ApplyOn")}`}
                                                                                value={commissionForm.values.value}
                                                                                onChange={commissionForm.handleChange}
                                                                                onBlur={commissionForm.handleBlur}
                                                                            /> */}
                                                                            </FormControl>
                                                                            {commissionForm.errors.calculation && commissionForm.touched.calculation && <p className="cm-form-error">{commissionForm.errors.calculation}</p>}
                                                                        </Box>
                                                                    </Grid>
                                                                    {
                                                                        commissionForm.values.calculation && commissionForm.values.calculation == "percent" ?
                                                                            <Grid item xs={12}>
                                                                                <Box sx={{ width: "100%" }}>
                                                                                    <div className="form-lable-name">
                                                                                        {t("BasedOn")}
                                                                                    </div>
                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                        <Slider
                                                                                            size="small"
                                                                                            //defaultValue={commissionForm.values.value}
                                                                                            aria-label="value"
                                                                                            color="primary"
                                                                                            name="value"
                                                                                            valueLabelDisplay="auto"
                                                                                            step={0.01}
                                                                                            onChange={commissionForm.handleChange}
                                                                                            disabled={!!!commissionForm.values.calculation}
                                                                                        />
                                                                                    </FormControl>
                                                                                    {/* {commissionForm.errors.compute_commission && commissionForm.touched.compute_commission && <p className="cm-form-error">{commissionForm.errors.compute_commission}</p>} */}
                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                    <TextField
                                                                                        type="number"
                                                                                        className="cm-login-filed"
                                                                                        name="value"
                                                                                        variant="outlined"
                                                                                        placeholder={`${t("BasedOn")}`}
                                                                                        value={commissionForm.values.value}
                                                                                        onChange={(e)=> {
                                                                                            const  cmVal = parseFloat(e.target.value);
                                                                                            if(cmVal <= 100){
                                                                                                commissionForm.setFieldValue("value", cmVal)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                                    {/* <p>{commissionForm.values.value}%</p> */}
                                                                                </Box>
                                                                            </Grid> :
                                                                            <Grid item xs={12}>
                                                                                <Box sx={{ width: "100%" }}>
                                                                                    <div className="form-lable-name">
                                                                                        {t("BasedOn")}
                                                                                    </div>
                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                        <TextField
                                                                                            className="cm-login-filed"
                                                                                            name="value"
                                                                                            variant="outlined"
                                                                                            placeholder={`${t("Value")}`}
                                                                                            value={commissionForm.values.value}
                                                                                            onChange={commissionForm.handleChange}
                                                                                            onBlur={commissionForm.handleBlur}
                                                                                        />
                                                                                    </FormControl>
                                                                                    {commissionForm.errors.value && commissionForm.touched.value && <p className="cm-form-error">{commissionForm.errors.value}</p>}

                                                                                </Box>
                                                                            </Grid>
                                                                    }

                                                                    <Grid item xs={12}>
                                                                        <Box>
                                                                            <Stack
                                                                                direction="row"
                                                                                spacing={2}
                                                                                alignItems="center"
                                                                                justifyContent={"start"}
                                                                                className="form-submit-cancel-btn-wrapper"
                                                                            >
                                                                                <Button
                                                                                    type="submit"
                                                                                    variant="contained"
                                                                                    className="cm-btn-style"
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        color: colorConfigs.btnColor.hoverbgWhite,
                                                                                        background: colorConfigs.btnColor.bgColorGreen,
                                                                                        "&: hover": {
                                                                                            color: colorConfigs.btnColor.bgColorGreen,
                                                                                            background: colorConfigs.btnColor.hoverbgWhite,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    {t("Send")}
                                                                                </Button>
                                                                            </Stack>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {/* Button save changes and Discard */}

                                    </form>
                                </LocalizationProvider>
                            </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Box sx={{ marginBottom: "20px" }}>
                <Button
                    onClick={() => navigate("/admin/commission-counting")}
                    variant="contained"
                    startIcon={<ArrowBackIosIcon />}
                    className="cm-btn-sm-style"
                    sx={{
                        color: colorConfigs.btnColor.hoverbgWhite,
                        background: colorConfigs.btnColor.bgColorRed,
                        "&: hover": {
                            color: colorConfigs.btnColor.bgColorRed,
                            background: colorConfigs.btnColor.hoverbgWhite,
                        }
                    }}>
                    Back
                </Button>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
                <Card>
                    <CardContent>
                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                            <Box sx={{ fontSize: "20px", color: "#012445", fontWeight: 600 }}>{singleCommissionData?.name}</Box>
                            <Box>
                                <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                                    <Button sx={{ color: "#012445", textTransform: "capitalize" }} variant="text" startIcon={<PaymentsIcon sx={{ color: "#fff", height: "33px", width: "33px", p: "5px", background: "#0881AA" }} />} endIcon={<EditIcon />}>{singleCommissionData?.currency}</Button>
                                    <Button sx={{ color: "#012445", textTransform: "capitalize" }} variant="text" startIcon={<ApartmentIcon sx={{ color: "#fff", height: "33px", width: "33px", p: "5px", background: "#0881AA" }} />} endIcon={<EditIcon />}>{singleCommissionData?.company?.name}</Button>
                                </Stack>
                            </Box>
                        </Stack>
                        <Box sx={{ marginTop: "20px", paddingBottom: "15px", borderBottom: "1px solid #CCCCCC" }}>
                            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                <Box sx={{ fontSize: "16px", color: "#012445" }}>Agents</Box>
                                <Button
                                    sx={{
                                        color: "#012445",
                                        textTransform: "capitalize",
                                        textDecoration: "underline"
                                    }}
                                    onClick={()=>setopenAddAgentModal(true)}
                                    variant="text"
                                    startIcon={<AddIcon />}>
                                    {t("AddAgents")}
                                </Button>
                            </Stack>
                        </Box>
                        <Box sx={{ marginTop: "20px" }}>
                            {agentsListData.length > 0 && agentsListData.map((item: any, index: any) => {
                                return (
                                    <Box key={`commission-counting-agents-${index}`} sx={{ marginBottom: "20px" }}>
                                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                            <Box sx={{ fontSize: "16px", color: "#012445" }}>{item?.agent?.name}</Box>
                                            <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                                                <IconButton aria-label="Edit" size="small" sx={{ color: "#012445" }}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <IconButton aria-label="delete" size="small" sx={{ color: "#012445" }}>
                                                    <DeleteIcon onClick={() => deleteAgent(item.id)} fontSize="inherit" />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                )
                            })}
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <MaterialReactDataTable
                    parentDependValue={dependendedVal}
                    useFor={"Mobile Certification List"}
                    apiLink={`${AppAPiUrl.CommissionProductList}${id}/`}
                    tRow={mobileCertificateRow}
                    searchData={mobileSearching}
                    sortingData={mobileSorting}
                    // clickFunc={getpopDate}
                    triggerUpdate={triggerUpdate}
                    resetTrigger={resetTrigger}
                    setTableDataShow={cmSetTableData}
                    clickFuncDataShow={true} />
            </div>
            <div className="text-right">
                <Button
                    onClick={() => setopenAddProductModal(true)}
                    variant="contained"
                    startIcon={<AddIcon />}
                    className="cm-btn-md-style"
                    sx={{
                        color: colorConfigs.btnStyle.color,
                        background: "#4BABCC",
                        "&: hover": {
                            color: "#4BABCC",
                            background: colorConfigs.btnColor.hoverbgWhite,
                        },
                    }}
                >Add New Product</Button>
            </div>
        </Box>
    )
}


function CommissionCountingSingle({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(AdminCommissionCountingSingle, "admin_commission", isAllow, {}, dependency);
    return <MainComponent />
}

export default AppPermissionHOC(CommissionCountingSingle, appModelPermission.COMMISSIONS, appModelType.READ)