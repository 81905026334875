import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import WholeSaleComponent from "./WholeSaleComponent";
type Props = {};

const WholeSaleLayout = (props: Props) => {
    const [isMainPage, setMainPage] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/wholesale" || location.pathname === "/wholesale/") {
            setMainPage(true);
        } else {
            setMainPage(false);
        }
    }, [location]);
    return (
        <>{
            isMainPage ? <WholeSaleComponent /> : <><Outlet /></>}
        </>
    );
};

export default WholeSaleLayout;
