import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import PaginationTableData from "../../components/layout/PaginationTableData";
import colorConfigs from "../../configs/colorConfigs";
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoaderScreen from "../../components/layout/LoaderScreen";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
type Props = {
  translateDataft: any;
  translationStatus: any;
};
const SingleNotification = (props: any) => {
  const { data, type } = props;
  const navigate = useNavigate();
  const DataSupportRow = [
    {
      name: `${data ? data : type}`,
      cell: (row: any) => (
        <Box sx={{ width: "100%" }}>
          <Box className="cm-notify-content-old" sx={{ width: "100%" }}>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </Box>
        </Box>
      ),
    }
  ];
  return (
    <Box className="cm-single-notification-main">
      <Button variant="contained"
        className="cm-btn-style"
        sx={{
          color: colorConfigs.btnColor.hoverbgWhite,
          background: colorConfigs.btnColor.bgColorRed,
          "&: hover": {
            color: colorConfigs.btnColor.bgColorRed,
            background: colorConfigs.btnColor.hoverbgWhite,
          }
        }} onClick={() => navigate('/mobiles/mobile-prov-notifications/')}><ArrowBackIcon /></Button>
      <PaginationTableData
        useFor={"Notification Translation"}
        dependOn="apiParamValue"
        apiParamName="order_id"
        apiParamValue={data}
        apiLink={AppAPiUrl.DnaProvnotification}
        tRow={DataSupportRow}
        searchfield={false}
        btnComponent={{
          isBtnShow: false,
        }}
      />
    </Box>
  );
};
const Notifications = (props: Props) => {
  const navigate = useNavigate();
  const { id, subscription } = useParams();
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }

  const notificationSearching = [
    {
      match: "search",
      id: "Prov Notifivations",
    },
  ];
  const notificationSorting = [
    {
      descSort: "-order_id",
      ascSort: "order_id",
      id: "Prov Notifivations",
    },
  ]
  const notificationRow = [
    {
      accessorFn: (row: any) => (
        <Box
          sx={{ cursor: "pointer" }} className="cm-table-td">
          <Box>{row.order_id}</Box>
        </Box>
      ),
      header: t("ProvNotifivations"),
      size: 10,
    },
  ];
  const getpopDate = (row: any) => {
    navigate(`/mobiles/mobile-prov-notifications/${row.order_id}`)
  };
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <Box className="cm-notification-main">
      <div className="cm-mobile-user-Data-main">
        {isTransLoaded ? (
              <div className="cm-mobile-user-Data-table">
              {
                subscription !== '' && subscription !== undefined ? (<SingleNotification data={id} type="subscription" />) :
                  id !== ''
                    && id !== undefined
                    ? (
                      <SingleNotification data={id} type="order_id" />) : (
                      <>
                        <MaterialReactDataTable
                          useFor={"Notification Data"}
                          apiLink={AppAPiUrl.DnaProvnotification}
                          tRow={notificationRow}
                          sortingData={notificationSorting}
                          searchData={notificationSearching}
                          clickFunc={getpopDate}
                        />
                      </>)
              }
              </div>
        ) : <LoaderScreen />}
      </div>
    </Box>
  );
};
function MSNotificationsComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(Notifications, "mobile_prov_notification", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(MSNotificationsComponentReady, appModelPermission.PROVNOTIFICATION, appModelType.READ);
