import CurrencySymbol from "./CurrencySymbol";

export function priceFormatWithCurrency(currency: any, price: any) {
    if (currency !== undefined && currency.length > 0) {
        const currencyName: string = currency[1];
        let priceTotal = price.toFixed(2).replace('.', ',');
        if (currencyName === 'EUR') {
            return `${priceTotal} ${CurrencySymbol.EUR}`
        }
        if (currencyName === 'GBP') {
            return `${priceTotal} ${CurrencySymbol.GBP}`
        }
        return priceTotal
    }
    return false;
}
export const cleanDateObject = (obj: any) => {
    const newObj = JSON.parse(JSON.stringify(obj));
    for (var propName in newObj) {
        if (newObj[propName] === null || newObj[propName] === undefined || newObj[propName] === '') {
            delete newObj[propName];
        }
    }
    return newObj
}
export function isModalPermission(checkUser: any, appPermission: any, modelName: any, fieldName: any) {
    let isPermissionAllow: any = null;
    const { user_type } = checkUser.user;
    if (appPermission.length > 0 && user_type !== 'Supervisor') {
        // eslint-disable-next-line
        let checkIf: any = [];
        // eslint-disable-next-line
        appPermission.map((item: any) => {
            const { Model } = item;
            if (Object.keys(Model).length > 0) {
                const { name } = Model;
                const mmtype = modelName.toLowerCase();
                if (name === mmtype) {
                    checkIf.push(Model)
                    isPermissionAllow = Model[`${fieldName.toLowerCase()}`]
                }
            }
        })
        if (checkIf.length === 0) {
            setTimeout(() => {
                isPermissionAllow = false
            }, 100);
        }
    } else if (appPermission.length > 0 && user_type === 'Supervisor') {
        setTimeout(() => {
            isPermissionAllow = true;
        }, 100);
    }
    return isPermissionAllow;
}
export function isFieldPermission(checkUser: any, appPermission: any, modelName: any, fieldName: any, fieldType: any) {
    let isPermissionAllow: any = null;
    const { user_type } = checkUser.user;
    if (appPermission.length > 0 && user_type !== 'Supervisor') {
        // eslint-disable-next-line
        let checkIf: any = [];
        // eslint-disable-next-line
        appPermission.map((item: any) => {
            const { Field, Model } = item;
            if (Object.keys(Model).length > 0) {
                const { name } = Model;
                const matchingText = name.toUpperCase();
                const fieldTypeName = fieldType.toLowerCase();
                if (matchingText === modelName) {
                    const ifItExitst = Field.filter((mt: any) => mt.name === fieldName);
                    if (ifItExitst.length > 0) {
                        checkIf.push(...ifItExitst);
                        // eslint-disable-next-line
                        ifItExitst.map((val: any) => {
                            const newVal = val[`${fieldTypeName}`];
                            isPermissionAllow = newVal
                        })
                    }
                    //console.log("Found Match", ifItExitst, item, isPermissionAllow);
                }
            }
        })
        if (checkIf.length === 0) {
            isPermissionAllow = false
        }
    } else if (appPermission.length > 0 && user_type === 'Supervisor') {
        isPermissionAllow = true;
    }
    return isPermissionAllow;
}
const isRoutePermissioin = (item1: any, appPermission: any, user_type: any) => {
    if (item1.routePerm) {
        if (appPermission.length > 0 && user_type !== 'Supervisor') {
            // eslint-disable-next-line
            appPermission.map((item: any) => {
                const { Model } = item;
                if (Object.keys(Model).length > 0) {
                    const { name, read } = Model;
                    const matchingText = name.toUpperCase();
                    if (matchingText === item1.routePerm && read) {
                        item1 = { ...item1, routeStatus: true };
                    }
                    else if (matchingText === item1.routePerm && !read) {
                        item1 = { ...item1, routeStatus: false };
                    }
                }
            })
        } else if (appPermission.length > 0 && user_type === 'Supervisor') {
            item1 = { ...item1, routeStatus: true };
        }
    } else {
        item1 = { ...item1, routeStatus: true };
    }
    return item1;
}
export function checkMenuPermission(appRoutes: any, appPermission: any, user_type: any, setRoutes: any) {
    const newRoutes = appRoutes.map((item1: any) => {
        if (!item1.index) {
            if (item1.child) {
                let totalChild = item1.child.length;
                let matchChild = 0;
                let findChild: any = [];
                // eslint-disable-next-line
                item1.child.map((childItem: any) => {
                    const checkchild = isRoutePermissioin(childItem, appPermission, user_type);
                    findChild.push(checkchild);
                    if (!checkchild.routeStatus) {
                        matchChild = matchChild + 1;
                    }
                })
                if (matchChild === totalChild) {
                    item1 = { ...item1, routeStatus: false };
                } else {
                    item1 = { ...item1, routeStatus: true };
                }
                item1.child = findChild
            } else {
                item1 = isRoutePermissioin(item1, appPermission, user_type);
            }
        }
        return item1;
    })
    setRoutes(newRoutes);
}


