import React from "react";
import { CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
type Props = {
    msg?: string;
};
function LoaderScreen({ msg }: Props) {
    return (
        <Box>
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
            background: "#fff",
            borderRadius: "10px",
            marginTop: "20px"
            }}><Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}> <CircularProgress size={50} sx={{ color: "#103256" }} />
                    {msg && <Box sx={{
                        fontSize: "20px",
                        marginTop: "20px"
                    }}>{msg}</Box>}
                </Box>
            </Box>
        </Box>
    )
}
export default LoaderScreen;