import { RouteType } from "./config";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Users from "../pages/Users/Users";
import HomePage from "../pages/HomePage/HomePage";
import Subscription from "../pages/Accounts/Subscription/Subscription";
import SalesOrderList from "../pages/Accounts/SaleOrders/SalesOrderList";
import AccountsPageLayout from "../pages/Accounts/AccountsPageLayout";
import TrafficPage from "../pages/Accounts/Traffic";
import LicencePage from "../pages/Accounts/License";
import Notifications from "../pages/Notifications/Notifications";
import TasksPage from "../pages/Tasks/TasksPage";
import MobileUserList from "../pages/Mobiles/MobileUserList";
import SettingsIcon from "@mui/icons-material/Settings";
import Messages from "../pages/Tools/Messages";
import MobilePageLayout from "../pages/Mobiles/Mobiles";
import AddMobileUser from "../pages/Mobiles/AddMobileUser";
import EditMobileUser from "../pages/Mobiles/EditMobileUser";
import SalesOrderEdit from "../pages/Accounts/SaleOrders/SalesOrderEdit";
import SubscriptionEdit from "../pages/Accounts/Subscription/SubscriptionEdit";
import Mobilecertificate from "../pages/Mobiles/MobilecertificateList";
import TranslationUI from "../pages/TranslationUI/TranslationUI";
import Billing from "../pages/Accounts/Billing";
import PersonIcon from '@mui/icons-material/Person';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AdminPageLayout from "../pages/Admin/admin";
import EditUser from "../pages/Users/EditUser";
import UsersRolesList from "../pages/UsersRoles/UsersRolesList";
import DescriptionIcon from '@mui/icons-material/Description';
import { appModelPermission, appPermisionName, appModelType, AppRoutesPath } from "../configs/appData";
import CommissionCounting from "../pages/CommissionCounting/CommissionCounting";
import CommissionCountingSingle from "../pages/CommissionCounting/CommissionCountingSingle";
import WholeSaleLayout from "../pages/WholeSale/WholeSaleDashboard";
import WholeSaleInvoiceReportPage from "../pages/WholeSale/WholeSaleInvoiceReport/WholeSaleInvoiceReportPage";
import WholesaleCommissionPage from "../pages/WholeSale/WholesaleCommission/WholesaleCommissionPage";
import WholeSaleListPage from "../pages/WholeSale/WholeSaleList/WholeSaleListPage";
//import CreditCardIcon from '@mui/icons-material/CreditCard';
//import AddCircleIcon from '@mui/icons-material/AddCircle';
//import LockIcon from '@mui/icons-material/Lock';
//import UsersRolesList from "../pages/UsersRoles/UsersRolesList";
// import AddOns from "../pages/AddOns/AddOns";
// import Contact from "../pages/Contact/Contact";
// import DataConnection from "../pages/DataConnection/DataConnection";
// import SipTrunks from "../pages/SipTrunks/SipTrunks";
// import Support from "../pages/Support/Support";
// import CreateSupportTicket from "../pages/Support/CreateSupportTicket";
// import SupportPageLayout from "../pages/Support/SupportPageLayout";
// import OnecloudPageLayout from "../pages/Onecloud/OnecloudPageLayout";
// import UserCreation from "../pages/Onecloud/UserCreation";
// import ACDGroupCreation from "../pages/Onecloud/ACDGroupCreation";
// import OrderPageLayout from "../pages/Order/OrderPageLayout";
// import NewCustomer from "../pages/Order/NewCustomer";
//import SalesOrder from "../pages/Order/SalesOrder";
// import SimCard from "../pages/SimCard/SimCard";
// import UserInfo from "../pages/Restrictions/Restrictions";
// import Restrictions from "../pages/UserInfo/UserInfo";
const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
  },
  {
    path: AppRoutesPath.Notifications,
    element: <Notifications />,
    state: "notifications",
  },
  {
    path: AppRoutesPath.Users,
    element: <Users />,
    state: "users",
    routePermUseFor: appModelType.READ,
    routePerm: appModelPermission.SETERAUSER,
    routeStatus: null,
    sidebarProps: {
      displayText: "Users",
      icon: <PersonIcon />,
    },
  },
  {
    path: AppRoutesPath.UserEdit,
    element: <EditUser />,
    state: "users.edit",
    routePerm: appPermisionName.CanChangeSeteraUser,
    routeStatus: null,
  },
  {
    path: AppRoutesPath.Accounting,
    element: <AccountsPageLayout />,
    state: "Accounting",
    sidebarProps: {
      displayText: "Accounting",
      icon: <AssignmentIndIcon />,
    },
    isShownChild: true,
    routeStatus: null,
    child: [
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingBilling,
        element: <Billing />,
        state: "accounting.Billing",
        routePerm: appModelPermission.ACCOUNTINGBILLING,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        sidebarProps: {
          displayText: "Billing",
        },
      },
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingSubscription,
        element: <Subscription />,
        state: "accounting.subscription",
        routePerm: appModelPermission.ACCOUNTINGSUBSCRIPTION,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        sidebarProps: {
          displayText: "Subscription",
        },
      },
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingSubscriptionEdit,
        routePerm: appModelPermission.ACCOUNTINGSUBSCRIPTION,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        element: <SubscriptionEdit />,
        state: "accounting.subscription.edit",
        isThisShow: false,
        sidebarProps: {
          displayText: "Subscription Edit",
        },
      },
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingTraffic,
        element: <TrafficPage />,
        state: "accounting.traffic",
        routePerm: appModelPermission.ACCOUNTINGTRAFFIC,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        sidebarProps: {
          displayText: "Traffic",
        },
      },
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingLicence,
        element: <LicencePage />,
        state: "accounting.licence",
        routePerm: appModelPermission.ACCOUNTINGLICENCE,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        sidebarProps: {
          displayText: "Licence",
        },
      },
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingSalesOrder,
        element: <SalesOrderList />,
        state: "accounting.salesorder",
        routePerm: appModelPermission.ACCOUNTINGSALESORDER,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        sidebarProps: {
          displayText: "Sales Order",
        },
      },
      {
        parent: "accounting",
        isChild: true,
        path: AppRoutesPath.AccountingSalesOrderEdit,
        element: <SalesOrderEdit />,
        state: "accounting.saleorderedit",
        customtitle: "Sale order Edit",
        isThisShow: false,
        routePerm: appModelPermission.ACCOUNTINGSALESORDER,
        routePermUseFor: appModelType.READ,
        routeStatus: null,
        sidebarProps: {
          displayText: "Sale order Edit",
        },
      },
    ],
  },
  {
    path: AppRoutesPath.Admin,
    element: <AdminPageLayout />,
    state: "admin",
    sidebarProps: {
      displayText: "Admin",
      icon: <PersonIcon />,
    },
    isShownChild: true,
    routeStatus: null,
    child: [
      {
        parent: "admin",
        isChild: true,
        path: AppRoutesPath.AdminMessage,
        element: <Messages />,
        routePerm: appPermisionName.CanViewMessage,
        state: "admin.messages",
        sidebarProps: {
          displayText: "Messages",
        }
      },
      {
        parent: "admin",
        isChild: true,
        path: AppRoutesPath.AdminTask,
        element: <TasksPage />,
        routePerm: appModelPermission.TASK,
        routePermUseFor: appModelType.READ,
        state: "admin.tasks",
        sidebarProps: {
          displayText: "Tasks",
        },
      },
      {
        parent: "admin",
        isChild: true,
        path: AppRoutesPath.AdminTranslation,
        element: <TranslationUI />,
        routePerm: appModelPermission.TRANSLATION,
        routePermUseFor: appModelType.READ,
        state: "admin.translation",
        sidebarProps: {
          displayText: "Translation",
        },
      },
      {
        parent: "admin",
        isChild: true,
        path: AppRoutesPath.AdminUsersRoles,
        element: <UsersRolesList />,
        state: "admin.userroles",
        routePerm: appPermisionName.CanViewUserRoles,
        sidebarProps: {
          displayText: "Users Roles",
        },
      },
      {
        parent: "admin",
        isChild: true,
        isThisShow: false,
        path: AppRoutesPath.AdminUsersRolesEdit,
        element: <UsersRolesList />,
        state: "admin.userroles.edit",
        routePerm: appPermisionName.CanViewUserRoles,
        sidebarProps: {
          displayText: "Users Roles Edit",
        },
      },
      {
        parent: "admin",
        isChild: true,
        path: AppRoutesPath.AdminCommissionCounting,
        element: <CommissionCounting/>,
        state: "admin.commissioncounting",
        routePermUseFor: appModelType.READ,
        routePerm: appPermisionName.CanViewMessage,
        sidebarProps: {
          displayText: "Commission Counting",
        },
      },
      {
        parent: "admin",
        isChild: true,
        isThisShow: false,
        path: AppRoutesPath.AdminCommissionCountingSingle,
        element: <CommissionCountingSingle/>,
        state: "admin.commissioncounting.single",
        routePerm: appPermisionName.CanViewMessage,
        sidebarProps: {
          displayText: "Commission Counting Detail",
        },
      },
    ],
  },
  // {
  //   path: "/tools",
  //   element: <ToolsPageLayout />,
  //   state: "tools",
  //   sidebarProps: {
  //     displayText: "Tools",
  //     icon: <SettingsIcon />,
  //   },
  //   isShownChild: true,
  //   routeStatus: null,
  //   child: [
  //     {
  //       parent: "tools",
  //       isChild: true,
  //       path: "/tools/messages",
  //       element: <Messages />,
  //       routePerm: appPermisionName.CanViewMessage,
  //       state: "tools.messages",
  //       sidebarProps: {
  //         displayText: "Messages",
  //       },
  //     },
  //     {
  //       parent: "tools",
  //       isChild: true,
  //       isThisShow: false,
  //       path: "/tools/message/edit/:id",
  //       element: <MessageEdit />,
  //       routePerm: appPermisionName.CanViewMessage,
  //       state: "tools.messageedit",
  //       sidebarProps: {
  //         displayText: "Edit Message",
  //       },
  //     },
  //   ],
  // },
  {
    path: AppRoutesPath.Mobile,
    element: <MobilePageLayout />,
    state: "mobiles",
    sidebarProps: {
      displayText: "Mobiles",
      icon: <SettingsIcon />,
    },
    isShownChild: true,
    routeStatus: null,
    child: [
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileUserList,
        element: <MobileUserList />,
        state: "mobiles.mobileuserlist",
        customtitle: "Mobile Subscription List",
        routePermUseFor: appModelType.READ,
        routePerm: appModelPermission.SUBSCRIPTION,
        routeStatus: null,
        sidebarProps: {
          displayText: "MobileSubscription",
        },
      },
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileUserListAdd,
        element: <AddMobileUser />,
        state: "mobiles.mobileuseradd",
        customtitle: "Mobile Users Add",
        isThisShow: false,
        routePermUseFor: appModelType.CREATE,
        routePerm: appPermisionName.CanAddSubscription,
        routeStatus: null,
        sidebarProps: {
          displayText: "Users",
        },
      },
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileUserListEdit,
        routePerm: appPermisionName.CanChangeSubscription,
        routeStatus: null,
        element: <EditMobileUser />,
        state: "mobiles.mobileuseredit",
        customtitle: "Mobile Subscription Edit",
        isThisShow: false,
        sidebarProps: {
          displayText: "Edit Subscription List",
        },
      },
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileProvNotifications,
        element: <Notifications />,
        state: "mobiles.provnotifications",
        routePerm: appModelPermission.PROVNOTIFICATION,
        routeStatus: null,
        sidebarProps: {
          displayText: "Prov Notifications",
        },
      },
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileProvNotificationsEdit,
        element: <Notifications />,
        state: "mobiles.provnotifications",
        isThisShow: false,
        routePerm: appPermisionName.CanViewProvNotification,
        routeStatus: null,
        sidebarProps: {
          displayText: "Prov Notifications",
        },
      },
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileProvNotificationsSubscriptionEdit,
        element: <Notifications />,
        state: "mobiles.provnotifications",
        isThisShow: false,
        routePerm: appPermisionName.CanViewProvNotification,
        routeStatus: null,
        sidebarProps: {
          displayText: "Prov Notifications",
        },
      },
      {
        parent: "mobiles",
        isChild: true,
        path: AppRoutesPath.MobileCertificateList,
        element: <Mobilecertificate />,
        state: "mobiles.mobilecertificate",
        routePermUseFor: appModelType.READ,
        routePerm: appModelPermission.SUBSCRIPTIONCERTIFICATE,
        customtitle: "Mobile certificate",
        sidebarProps: {
          displayText: "Mobile certificate",
        },
      },
    ],
  },
  {
    path: AppRoutesPath.WholeSale,
    element: <WholeSaleLayout />,
    state: "wholesale",
    sidebarProps: {
      displayText: "wholesale",
      icon: <PersonIcon />,
    },
    isShownChild: true,
    routeStatus: null,
    child:[
      {
        parent: "wholesale",
        isChild: true,
        path: AppRoutesPath.WholeSaleInvoiceReport,
        element: <WholeSaleInvoiceReportPage/>,
        routePerm: appPermisionName.CanViewMessage,
        state: "admin.invoicereport",
        sidebarProps: {
          displayText: "Invoice Report",
        }
      },
      {
        parent: "wholesale",
        isChild: true,
        path: AppRoutesPath.WholeSaleCommission,
        element: <WholesaleCommissionPage/>,
        routePerm: appPermisionName.CanViewMessage,
        state: "admin.commissions",
        sidebarProps: {
          displayText: "Commissions",
        }
      },
    ]
  },
  {
    path: AppRoutesPath.WholeSaleList,
    element: <WholeSaleListPage/>,
    routePerm: appPermisionName.CanViewMessage,
    state: "null",
  }
  // {
  //   path: AppRoutesPath.WholeSale,
  //   element: <WholeSaleLayout/>,
  //   state: "wholesale",
  //   sidebarProps: {
  //     displayText: "Wholesale",
  //     icon: <DescriptionIcon />,
  //   },
  //   isShownChild: true,
  //   routeStatus: null,
  //   child:[
  //     {
  //       parent: "commissions",
  //       isChild: true,
  //       path: AppRoutesPath.CommissionsList,
  //       element: <CommissionsList/>,
  //       state: "wholesale.commissionsList",
  //       customtitle: "Commissions List",
  //       routePermUseFor: appModelType.READ,
  //       routePerm: appModelPermission.COMMISSIONS,
  //       routeStatus: null,
  //       sidebarProps: {
  //         displayText: "Commissions",
  //         icon: <HandshakeIcon/>,
  //       },
  //     },
  //     {
  //       parent: "commissions",
  //       isChild: true,
  //       path: AppRoutesPath.CommissionsSingle,
  //       element: <CommissionsSingle/>,
  //       state: "wholesale.commissionsSingle",
  //       customtitle: "Commissions List",
  //       routePermUseFor: appModelType.READ,
  //       routePerm: appModelPermission.COMMISSIONS,
  //       routeStatus: null,
  //       isThisShow: false,
  //       sidebarProps: {
  //         displayText: "Commissions",
  //         icon: <HandshakeIcon/>,
  //       },
  //     },
  //   ]
  // }
  // {
  //   path: "/data-connection",
  //   element: <DataConnection />,
  //   state: "dataConnection",
  //   sidebarProps: {
  //     displayText: "Data Connection",
  //     icon: <LockIcon />,
  //   },
  // },
  // {
  //   path: "/support",
  //   element: <SupportPageLayout />,
  //   state: "support",
  //   sidebarProps: {
  //     displayText: "Support",
  //     icon: <PersonIcon />,
  //   },
  //   isShownChild: true,
  //   child: [
  //     {
  //       parent: "support",
  //       isChild: true,
  //       path: "/support/all-tickets",
  //       element: <Support />,
  //       state: "support.alltickets",
  //       sidebarProps: {
  //         displayText: "All Tickets",
  //       },
  //     },
  //     {
  //       parent: "support",
  //       isChild: true,
  //       path: "/support/create-ticket",
  //       element: <CreateSupportTicket />,
  //       state: "support.create",
  //       sidebarProps: {
  //         displayText: "Create New Ticket",
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "/sip-truncks",
  //   element: <SipTrunks />,
  //   state: "Sip Trunks",
  //   sidebarProps: {
  //     displayText: "Sip Trunks",
  //     icon: <CreditCardIcon />,
  //   },
  // },
  // {
  //   path: "/onecloud",
  //   element: <OnecloudPageLayout />,
  //   state: "Onecloud",
  //   sidebarProps: {
  //     displayText: "Onecloud",
  //     icon: <AddCircleIcon />,
  //   },
  //   isShownChild: true,
  //   child: [
  //     {
  //       parent: "onecloud",
  //       isChild: true,
  //       path: "/onecloud/user-creation",
  //       element: <UserCreation />,
  //       state: "onecloud.usercreation",
  //       sidebarProps: {
  //         displayText: "User Creation",
  //       },
  //     },
  //     {
  //       parent: "onecloud",
  //       isChild: true,
  //       path: "/onecloud/acd-group-creation",
  //       element: <ACDGroupCreation />,
  //       state: "onecloud.acdgroupcreation",
  //       sidebarProps: {
  //         displayText: "ACD group creation",
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "/order",
  //   element: <OrderPageLayout />,
  //   state: "Order",
  //   sidebarProps: {
  //     displayText: "Order",
  //     icon: <AssignmentIndIcon />,
  //   },
  //   isShownChild: true,
  //   child: [
  //     {
  //       parent: "order",
  //       isChild: true,
  //       path: "/order/new-customer",
  //       element: <NewCustomer />,
  //       state: "order.newcustomer",
  //       sidebarProps: {
  //         displayText: "New customer",
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "/sim-card",
  //   element: <SimCard />,
  //   state: "simcard",
  //   sidebarProps: {
  //     displayText: "Sim Card",
  //     icon: <SimCardIcon />
  //   }
  // },

  // {
  //   path: "/user-info",
  //   element: <UserInfo />,
  //   state: "userinfo",
  //   sidebarProps: {
  //     displayText: "User Info",
  //     icon: <PersonIcon />
  //   }
  // },
  // {
  //   path: "/restriction",
  //   element: <Restrictions />,
  //   state: "restriction",
  //   sidebarProps: {
  //     displayText: "Restrictions",
  //     icon: <LockIcon />
  //   }
  // },
  // {
  //   path: "/add-ons",
  //   element: <AddOns />,
  //   state: "addons",
  //   sidebarProps: {
  //     displayText: "Add Ons",
  //     icon: <AddCircleIcon />
  //   }
  // },

  // {
  //   parent: "onecloud",
  //   isChild: true,
  //   path: "/onecloud/provision-notifications",
  //   element: <ProvisionNotificationsPage />,
  //   state: "onecloud.provisionnotifications",
  //   sidebarProps: {
  //     displayText: "Provision Notifications",
  //   },
  // },
  // {
  //   parent: "onecloud",
  //   isChild: true,
  //   path: "/onecloud/view-provision-notifications",
  //   element: <ViewProvisionNotificationsPage />,
  //   state: "onecloud.viewprovisionnotifications",
  //   sidebarProps: {
  //     displayText: "View Provision Notifications",
  //   },
  // },


];

export default appRoutes;