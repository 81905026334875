import React, { useState, useEffect } from "react";
import { Box, FormControl, OutlinedInput, InputAdornment, IconButton, List, ListItem, ClickAwayListener, Button, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AxiosMain from "./AxiosMain";
import { setOrgCount, setorganizationState, setSearchOrg, setSearchOrgCount } from "../../redux/features/organizationStateSlice";
import Swal from "sweetalert2";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setAppNextRoute, setAppOrg, setAppcurrentPageForm } from "../../redux/features/appStateSlice";
import { AppAPiUrl } from "../../configs/appData";
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';
const getUniqueArray = (arr: any, index: any) => {
    const unique = arr
        .map((e: any) => e[index])
        // store the keys of the unique objects
        .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter((e: any) => arr[e]).map((e: any) => arr[e]);
    return unique;
}

function OrganisationDropDown({cmClass}:any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAllOrg, setAllOrg] = useState(false);
    const [isAllSearchOrg, setAllSearchOrg] = useState(false);
    const [isadminShowOrg, setIsAdminShowOrg] = useState(false);
    const [isSearchOn, setSearchOn] = useState(false);
    const [isDropDownActive, setDropDownActive] = useState(false);
    const [organizationPage, setOrganizationPage] = useState(1);
    const [organizationSearch, setOrganizationSearch] = useState(1);
    const [lastAutocompleteVal, setLastAutoCompleteVal] = useState("");
    const [inputVal, setInputVal] = useState("");
    const checkUser = useSelector((state: RootState) => state.authState.authState);
    const orgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const allOrgData = useSelector((state: RootState) => state.organisation.organizationState);
    const totalOrg = useSelector((state: RootState) => state.organisation.orgCount);
    const searchOrgData = useSelector((state: RootState) => state.organisation.orgSearchState);
    const searchTotalOrg = useSelector((state: RootState) => state.organisation.orgSearchCount);
    const formSwalTranslation = {
        formSwalTitle: "SomethingWentWrong",
        formSwalText: "CheckYourLoginsDetailsInternet",
        formSwalErrorText: "Unabletogetorganization"
    };
    const getSearchOrganisation = (page: number) => {
        if (inputVal !== '') {
            AxiosMain.get(`${AppAPiUrl.OrganizationOrganization}?ordering=name&search=${inputVal}&page=${page}`).then((val) => {
                if (val.data.results.length > 0) {
                    dispatch(setSearchOrgCount(val.data.count));
                    if (page === 1) {
                        const orgData = val.data.results;
                        const sortingOrganization = [...orgData].sort((a: any, b: any) =>
                            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                        );
                        dispatch(setSearchOrg(sortingOrganization));
                    } else {
                        const orgData = [...val.data.results, ...searchOrgData];
                        const sortingArray = getUniqueArray(orgData, "id");
                        const sortingOrganization = [...sortingArray].sort((a: any, b: any) =>
                            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                        );
                        if (val.data.results.length === 10) {
                            setOrganizationSearch(page);
                        }
                        if (searchOrgData.length !== val.data.count) {
                            dispatch(setSearchOrg(sortingOrganization));

                        }
                    }
                    if (searchOrgData.length === val.data.count) {
                        setAllSearchOrg(true);
                    }
                } else {
                    Swal.fire({
                        title: "Warning",
                        text: "No Organization Found!",
                        icon: 'warning',
                    })
                }
            }).catch((e) => {
                Swal.fire({
                    title: formSwalTranslation.formSwalTitle,
                    text: formSwalTranslation.formSwalErrorText,
                    icon: 'warning',
                })
            });
        }

    }
    const getOrganisation = async (page: number) => {
        AxiosMain.get(`${AppAPiUrl.OrganizationOrganization}?ordering=name&page=${page}`).then((val) => {
            if (val.data.results.length > 0) {
                dispatch(setOrgCount(val.data.count));
                if (page === 1) {
                    const orgData = val.data.results;
                    const sortingOrganization = [...orgData].sort((a: any, b: any) =>
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                    );
                    dispatch(setorganizationState(sortingOrganization));
                } else {
                    const orgData = [...val.data.results, ...allOrgData];
                    const sortingArray = getUniqueArray(orgData, "id");
                    const sortingOrganization = [...sortingArray].sort((a: any, b: any) =>
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                    );
                    if (val.data.results.length === 10) {
                        setOrganizationPage(page);
                    }
                    if (allOrgData.length !== val.data.count) {
                        dispatch(setorganizationState(sortingOrganization));
                    }
                }
                if (allOrgData.length === val.data.count) {
                    setAllOrg(true);
                }
            }
            else {
                Swal.fire({
                    title: "Warning",
                    text: "No Organization Found!",
                    icon: 'warning',
                })
            }
        }).catch((e) => {
            const { response } = e;
            const { status, statusText } = response;
            if (status === 403 && statusText === "Forbidden") {
                Swal.fire({
                    title: "warning",
                    text: "Permission Denied in Organization",
                    icon: 'warning',
                })
            }
            else {
                Swal.fire({
                    title: formSwalTranslation.formSwalTitle,
                    text: formSwalTranslation.formSwalErrorText,
                    icon: 'warning',
                })
            }

        });
    }
    const organizationChangeMsg = (val: any) => {
        enqueueSnackbar(`Organization Changed to ${val.name}`, { preventDuplicate: true, variant: "success", autoHideDuration: 2000 });
        dispatch(setAppcurrentPageForm("no"));
        navigate("/");
    }
    //Setting Organisation in Store
    const setOrganisation = (value: any) => {
        if (value !== null) {
            dispatch(setAppOrg(value));
            organizationChangeMsg(value);
        } else {
            dispatch(setAppOrg(""));
        }
    }
    const setOrganisationDrop = (value: any) => {
        if (value !== null) {
            setOrganisation(value);
            organizationChangeMsg(value);
            setDropDownActive(false)
        } else {
            setOrganisation(value);
        }
    }
    const setOrganisationDropSearch = (value: any) => {
        if (value !== null) {
            dispatch(setAppOrg(value));
            organizationChangeMsg(value);
            setSearchOn(false);
            setInputVal("");
            setDropDownActive(false);
            setLastAutoCompleteVal("");
        }
    }
    // loadMore Organisation
    const loadMoreOrganizations = () => {
        if (!isAllOrg) {
            const nextPage = organizationPage + 1;
            getOrganisation(nextPage);
        }
    }
    const loadMoreSearchOrganizations = () => {
        if (!isAllSearchOrg) {
            const nextPage = organizationSearch + 1;
            getSearchOrganisation(nextPage);
        }
    }
    useEffect(() => {
        if (Object.keys(checkUser).length > 0) {
            if (allOrgData.length === 0 && Object.keys(checkUser).length > 0) {
                getOrganisation(organizationPage);
            }
            if (checkUser.user.user_type === 'CustomerAdmin') {
                if (allOrgData.length > 0 && Object.keys(orgnisationData).length === 0) {
                    setOrganisation(allOrgData[0]);
                }
            } else {
                if (allOrgData.length > 0 && Object.keys(orgnisationData).length > 0) {
                    setIsAdminShowOrg(true);
                }
                else if (allOrgData.length > 0 && Object.keys(orgnisationData).length === 0) {
                    setOrganisation(allOrgData[0]);
                }
            }
        }
        // eslint-disable-next-line
    }, [allOrgData, orgnisationData])

    const outerClickFun = () => {
        setDropDownActive(false);
        setLastAutoCompleteVal("");
        setSearchOn(false);
        setInputVal("");
    }
    useEffect(() => {
        if (organizationPage === 1 && allOrgData.length > 10 && !isSearchOn) {
            const previousPage = Math.ceil(allOrgData.length / 10);
            setOrganizationPage(previousPage);
        }
        // eslint-disable-next-line
    }, [totalOrg, allOrgData])
    return (
        <>
            <ClickAwayListener onClickAway={outerClickFun}>
                {isadminShowOrg ? (
                    <div style={{ position: "relative" }} className={`${cmClass && cmClass}`}>
                        {
                            Object.keys(orgnisationData).length > 0 && (
                                <div className="cm-organization-box-wrap" onClick={() => {
                                    setDropDownActive(!isDropDownActive);
                                    setSearchOn(!isSearchOn);
                                }}>
                                    <Tooltip title={orgnisationData.name}>
                                        <div className="cm-current-org-name"> {orgnisationData.name}</div>
                                    </Tooltip>
                                    <ExpandMoreOutlinedIcon />
                                </div>
                            )
                        }

                        {isDropDownActive && (
                            <>
                                <div className="cm-dropdown-listing-wrap cm-right-scrollbar">
                                    <Box sx={{ padding: "5px;" }}>
                                        <FormControl variant="outlined" sx={{ width: "100%", padding: "0" }}>
                                            <OutlinedInput
                                                autoFocus
                                                id="outlined-adornment-Organisatioin"
                                                placeholder="Search ..."
                                                value={isSearchOn ? inputVal : orgnisationData.name}
                                                onChange={(e) => setInputVal(e.target.value)}
                                                onFocus={() => setDropDownActive(true)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        getSearchOrganisation(1);
                                                        setDropDownActive(true);
                                                    }
                                                    else if (event.key !== 'Enter') {
                                                        setSearchOn(true);
                                                        setDropDownActive(true);
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {isSearchOn ? (
                                                            <IconButton
                                                                size="small"
                                                                aria-label="toggle Organisatioin visibility"
                                                                onClick={() => getSearchOrganisation(1)}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                    size="small"
                                                                aria-label="toggle Organisatioin visibility"
                                                                onClick={() => setSearchOn(true)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )}

                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Box>
                                    {isSearchOn && inputVal !== "" ? (
                                        <List>
                                            {searchOrgData && searchOrgData.length > 0 && searchOrgData.map((val: any, index: any) => {
                                                const existOrg = orgnisationData.name;
                                                setTimeout(() => {
                                                    const optionEl = document.querySelector(
                                                        `[data-name="${lastAutocompleteVal}"]`
                                                    );
                                                    optionEl?.scrollIntoView({ behavior: "smooth" });
                                                }, 100);
                                                if (existOrg === val.name) {
                                                    return (<ListItem sx={{ mb: 1, pointerEvents: "none", backgroundColor: "#C4C4C4", paddingBottom: "5px !important" }} data-name={val.name} className="cmmourse cm-org-list-item" key={`cm-org-list-${index}`}>{val.name}</ListItem>)
                                                }
                                                return (<ListItem sx={{ mb: 1 }} data-name={val.name} className="cmmourse cm-org-list-item" onClick={() => setOrganisationDropSearch(val)} key={`cm-org-list-${index}`}>{val.name}</ListItem>)
                                            })}
                                            {searchOrgData && searchOrgData.length < searchTotalOrg && (
                                                <ListItem className="cm-org-list-item"><Button sx={{ width: 'calc(100%)', display: "block" }} variant="outlined" size="small" onClick={() => {
                                                    loadMoreSearchOrganizations();
                                                    if (searchOrgData.length >= 20) {
                                                        setLastAutoCompleteVal(searchOrgData[searchOrgData.length - 1].name);
                                                    }
                                                }}>Load More</Button>
                                                </ListItem>)}
                                        </List>
                                    ) : (
                                        <List>
                                            {allOrgData.length > 0 && allOrgData.map((val: any, index: any) => {
                                                setTimeout(() => {
                                                    const optionEl = document.querySelector(
                                                        `[data-name="${lastAutocompleteVal}"]`
                                                    );
                                                    optionEl?.scrollIntoView({ behavior: "smooth" });
                                                }, 100);
                                                return (<ListItem sx={{ mb: 1 }} data-name={val.name} className="cmmourse cm-org-list-item" onClick={() => setOrganisationDrop(val)} key={`cm-org-list-${index}`}>{val.name}</ListItem>)
                                            })}
                                            {allOrgData.length < totalOrg && (
                                                <ListItem className="cm-org-list-item"><Button sx={{ width: 'calc(100%)', display: "block" }} variant="outlined" size="small" onClick={() => {
                                                    loadMoreOrganizations();
                                                    if (allOrgData.length >= 20) {
                                                        setLastAutoCompleteVal(allOrgData[allOrgData.length - 1].name);
                                                    }
                                                }}>Load More</Button>
                                                </ListItem>)}
                                        </List>
                                    )}

                                </div>
                            </>
                        )}
                    </div>
                ) : (<Box style={{paddingLeft:'15%'}}>{orgnisationData?.name}</Box>)}

            </ClickAwayListener>

        </>
    )
}
export default OrganisationDropDown;