import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { store } from "../../redux/store";
import { AppAPiUrl } from "../../configs/appData";
import { setAuthState } from "../../redux/features/authStateSlice";
let isRefreshing = false;
let failedQueue: any = [];
//let isRequestInProgress = false;
const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};
const AxiosMain = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

AxiosMain.interceptors.request.use(function (config) {
  // Do something before request is sent
  const superUerOrg = store.getState().appState.appOrg;
  const UserData = store.getState().authState.authState;
  // while (isRequestInProgress) {
  //   await new Promise(resolve => setTimeout(resolve, 100)); // Wait for a short duration
  // }
  if (config.url?.split("/").indexOf('confirm') === -1) {
    if (config.url !== AppAPiUrl.OrganizationOrganization && config.url !== AppAPiUrl.TranslateLanguages && config.url !== AppAPiUrl.Otpverify && config.url !== AppAPiUrl.MobileOtp && config.url !== AppAPiUrl.Login && config.url !== AppAPiUrl.Forgetpassword) {
      if (Object.keys(UserData).length > 0) {
        if (UserData.hasOwnProperty("access") && UserData.access !== '') {
          config.headers.Authorization = `Bearer ${UserData.access}`
          config.headers["Content-Type"] = "application/json"
        }
        if (Object.keys(superUerOrg).length > 0) {
          config.headers.organization = superUerOrg.id;
        } else {
          config.headers.organization = UserData.user.organization;
        }
      }
      else {
        config.headers.organization = null;
        throw new axios.Cancel('Organization Not Found!');
      }
  }
  }
  // Check if a request is already in progress
  //isRequestInProgress = true;
  return config
}, function (error) {
  // Do something with request error
  console.log("Request Error ", error)
  return Promise.reject(error);
});
AxiosMain.interceptors.response.use(function (response) {
  //console.log("Main API Route Response ", { url: response.config.url, status: response.status, data: response.data });
  //isRequestInProgress = false;
  return response;
}, function (error) {
  //isRequestInProgress = false;
  const originalRequest = error.config;
  //console.log("Main API Route Error", { url: error.config.url, message: error.message, alldata: error });
  if (error.message === 'Organization Not Found!') {
    showErrorData(error.message);
  }
  if (error.response.status === 401 &&  error.response.data === "token_not_valid"  && !originalRequest._retry) {
    //const { code, messages } = error.response.data;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({
          resolve,
          reject
        });
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      });
    }
    originalRequest._retry = true;
    isRefreshing = true;
    const cmToken = store.getState().authState.authState;
    const { refresh } = cmToken;
    return new Promise(function (resolve, reject) {
      axios.post(`${process.env.REACT_APP_BASE_URL}${AppAPiUrl.TokenRefresh}`, {
        refresh: refresh
      }).then((val) => {
        if (val.status === 200) {
          const useroutput = {
            access: val.data.access,
            refresh: val.data.refresh,
            user: jwt_decode(val.data.access)
          }
          store.dispatch(setAuthState(useroutput));
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + val.data.access;
          originalRequest.headers['Authorization'] = 'Bearer ' + val.data.access;
          processQueue(null, val.data.access);
          resolve(axios(originalRequest));
        }
      }).catch((error) => {
        processQueue(error, null);
        reject(error);
        onTokenExpireLogout("Token has expired");
        //console.log("Error while geting refresh token", error.response);
      }).then(() => {
        isRefreshing = false;
      });
    });
    // if (code === "token_not_valid") {
    //   if (messages.length > 0 && messages[0].token_class === "AccessToken") {
    //     //onTokenExpireLogout(messages[0].message);
    // Admin@#1234
    //   }
    // }
  }

  return Promise.reject(error);
});

const onTokenExpireLogout = (val: any) => {
  Swal.fire({
    title: "Error",
    text: val,
    icon: "error"
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.clear();
      setTimeout(() => {
        window.location.replace(window.location.origin)
      }, 100);
    }
  })
}

const showErrorData = (val: any) => {
  Swal.fire({
    title: "Error",
    text: val,
    icon: "error"
  })
}
export default AxiosMain;                      

