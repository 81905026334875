import React, { useCallback, useEffect, useState } from 'react';
import TranslationGetHOC from '../../components/layout/TranslationGetHOC';
import { Grid , Box, Stack, Button} from '@mui/material';
import AxiosMain from '../../components/layout/AxiosMain';
import { AppAPiUrl } from '../../configs/appData';
import { priceFormatWithCurrency } from '../../components/layout/Helper';
import { BarChart } from '@mui/x-charts';
import moment from 'moment';
import MaterialReactDataTable from '../../components/layout/MaterialReactDataTable';
import { useNavigate } from 'react-router-dom';
import LoaderScreen from '../../components/layout/LoaderScreen';
import PaginationMaterialTable from '../../components/layout/PaginationMaterialTable';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
type Props = {};

function HPCostUsageComponent({translateFun}:any){
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const [inovoiceData, setInovoiceData]= useState([]);
  const [lastTotalCost, setLastTotalCost]= useState(0);
  const [lastTotalDueDate, setLastTotalDueDate]= useState("");
  const [currencydata, setcurrencyData]= useState([]);
  const [totalcostChart, setTotalCostChart]= useState<any>({});
  const [isLoadingData, setLoadingData]= useState(false);
  const orgnisationData = useSelector((state: RootState) => state.appState.appOrg);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `(${name})`;
  }
  const getInovoicedata = useCallback(()=>{
    setLoadingData(true);
    AxiosMain.get(`${AppAPiUrl.AccountingBilling}`).then((res)=>{
      setLoadingData(false);
      if(res.status === 200){
        const {results}= res.data;
        const lastElement = results[results.length - 1];
        const {currency_id,amount_total,date_due}= lastElement;
        setLastTotalDueDate(date_due);
        setLastTotalCost(amount_total);
        setcurrencyData(currency_id);
        setInovoiceData(results.slice(0,6));
        const inovoiceDate = results.slice(0,6).map((item:any) => moment(new Date(item.date_invoice)).format("MMM"));
        const inovoiceAmount = results.slice(0,6).map((item:any) => item.amount_total);
        setTotalCostChart({date:inovoiceDate, amount:inovoiceAmount});
      }
    }).catch((e)=>{
      setLoadingData(false);
      console.log("error", e);
    })
  },[orgnisationData]);

  useEffect(()=>{
    getInovoicedata();
  },[getInovoicedata])
  return(
    <Box className={"hp-widget-wrapper"}>
      <h4 className='hp-widget-title'>{translateFun("CostandUsage")}</h4>
      {isLoadingData ? <LoaderScreen/> : (
        <Grid container spacing={2}>
          <Grid item md={4} lg={3} alignSelf={"center"}>
            <Box sx={{textAlign:"center"}}>
              <Box className="hpwidget-total-cost-price">{priceFormatWithCurrency(currencydata,lastTotalCost)}</Box>
              <Box>{translateFun("TotalCost")}</Box>
              <Box>{moment(new Date(lastTotalDueDate)).format("MMM D, dddd")}</Box>
              <Box>{translateFun("DueDate")}</Box>
            </Box>
          </Grid>
          <Grid item md={6} lg={5}>
            <Box className="hp-widget-total-cost-col">
              {inovoiceData.length > 0 ? inovoiceData.map((item:any, index:any)=>{
                const {number, state} = item
                return(
                  <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"} key={`hp-widget-total-costs-${index}`}>
                    <Box className="hp-widget-tc-name">{number}</Box>
                    <Box className="hp-widget-tc-price">{state}</Box>
                    {/* <Box className="hp-widget-tc-price">{priceFormatWithCurrency(currency_id,amount_total)}</Box> */}
                  </Stack>
                )
              }) : ""}
            </Box>
          </Grid>
          <Grid item md={6} lg={4}>
            <Box className="hp-widget-tc-chart-title">{translateFun("TotalCostPerMonth")}</Box>
            {
              Object.keys(totalcostChart).length > 0 && (
                <BarChart
                  series={[ { data: totalcostChart.amount },]}
                  height={200}
                  xAxis={[{ data: totalcostChart.date, scaleType: 'band' }]}
                  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                />
              )
            }
          </Grid> 
        </Grid>
      )}
    </Box>
  )
}
function HPRecentOrderDataComponent(props:any){
  const {translateFun} = props;
  const navigate = useNavigate();
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }
  const saleOrderRow = [
    {
      accessorFn: (row: any) => row.name,
      header: t("SalesOrderID"),
    },
    {
      accessorFn: (row: any) => row.original_contract_duration,
      header: t("ContractDuration"),
      filterVariant: 'text',
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row: any) => row.order_line.length,
      header: t("OrderLinesCount"),
      filterVariant: 'text',
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
    },
    {
      size: 300,
      accessorFn: (row: any) => new Date(row.create_date),
      id: 'create_date',
      header: t('OrderDate'),
      filterVariant: 'date-range',
      Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
    },
    {
      accessorFn: (row: any) => priceFormatWithCurrency(row.currency_id, row.amount_total),
      header: t("Total"),
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
    },
  ]
  const saleOrderSorting = [
    {
      descSort: "-name",
      ascSort: "name",
      id: t("SalesOrderID"),
    },
    {
      descSort: "-create_date",
      ascSort: "create_date",
      id: "create_date",
    },
  ];
  const saleOrderSearching = [
    {
      match: "name",
      id: t("SalesOrderID"),
    },
    {
      match: "create_date",
      id: "create_date",
    },
  ];
  const getpopDate = (row: any) => navigate(`/accounting/sales-order/edit/${row.id}`);
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return(
    <Box className={"hp-widget-wrapper"}>
      <h4 className='hp-widget-title'>{translateFun("RecentOrders")}</h4>
      <div className='cm-sale-order-main'>
        {isTransLoaded
            ? (<div className='cm-mobile-user-Data-table'>
              <PaginationMaterialTable
                useFor={"Accounting Sale Orders List"}
                apiLink={AppAPiUrl.AccountingSalesorder}
                tRow={saleOrderRow}
                clickFunc={getpopDate}
                sortingData={saleOrderSorting}
                searchData={saleOrderSearching}
                cmSizeShow={5}
                dependOn="apiParamValue"
                apiParamName={"ordering"}
                apiParamValue={"-create_date"}
              />
            </div>)
          : <LoaderScreen />
        }
      </div>
      </Box>
  )
}
function HPNewsComponent({translateFun}:any){
  const staticBlogs = [
    {
      image:"https://setera.com/wp-content/uploads/2023/05/China-768x512.jpg",
      date:"05/29/2023",
      title:"Setera started providing phone numbers, local calling & UCaaS services in China",
      link:"https://setera.com/news/setera-started-providing-phone-numbers-local-calling-ucaas-services-in-china/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2023/04/New-countries-768x512.jpg",
      date:"04/18/2023",
      title:"Setera has expanded its UCaaS and SIP trunking services to Portugal, Turkey and Greece ",
      link:"https://setera.com/news/setera-has-expanded-its-ucaas-service-to-portugal-turkey-greece/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2022/11/Singapore-1-768x513.jpg",
      date:"01/06/2023",
      title:"Setera enters into the Singapore UCaaS market",
      link:"https://setera.com/news/setera-enters-into-the-singapore-ucaas-market/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2022/10/Italia-hotellit-1-768x432.jpg",
      date:"10/19/2022",
      title:"Rome Hotel Association has chosen Setera to digitalise hotels in Rome",
      link:"https://setera.com/news/rome-hotel-association-has-chosen-setera-to-digitalise-hotels-in-rome/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2021/11/News-EMTEL-2-768x512.jpg",
      date:"11/26/2021",
      title:"Setera Spain and EMTEL del Noroeste form a strategic alliance to roll out UC Cloud services in Spain and internationally.",
      link:"https://setera.com/news/setera-spain-and-emtel-del-noroeste-form-a-strategic-alliance-to-roll-out-uc-cloud-services-in-spain-and-internationally/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2023/09/SMS-768x384.jpg",
      date:"09/06/2023",
      title:"SMS Gateways for Enhanced Customer Interactions: A Mobile Telephony Expert's Guide",
      link:"https://setera.com/blogs/leveraging-sms-gateways-for-enhanced-customer-interactions-a-mobile-telephony-experts-guide/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2023/09/AI-768x488.jpg",
      date:"09/06/2023",
      title:"The Future of Telephony: How AI Will Transform Communication",
      link:"https://setera.com/blogs/the-future-of-telephony-how-ai-will-transform-communication/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2023/05/MS-Teams-mobile-768x512.jpg",
      date:"05/25/2023",
      title:"How and why to connect MS-Teams to telephone network",
      link:"https://setera.com/blogs/how-and-why-to-connect-ms-teams-to-telephone-network/"
    },
    {
      image:"https://setera.com/wp-content/uploads/2023/05/UCaaS-768x512.jpg",
      date:"05/25/2023",
      title:"What is UCaaS",
      link:"https://setera.com/blogs/what-is-ucaas/"
    }
  ]
  const gotoLink= (url:any)=>{
    window.open(url);
  }
  return(
    <Box className={"hp-widget-wrapper"}>
      <h4 className='hp-widget-title'>{translateFun("News")}</h4>
      {staticBlogs.map((item:any, index:any)=>{
        return(
          <Stack className='hp-widget-news-wrapper' key={`hp-widget-news-wrap-${index}`} direction={"row"} spacing={2}>
            <Box><img onClick={()=>gotoLink(item.link)} className="hp-widget-blog-news-img" alt={item.title} src={item.image}/></Box>
            <Stack direction={"column"} spacing={1} justifyContent={"space-between"}>
              <Box className="hp-widget-blog-news-date">{moment(new Date(item.date)).format("D MMM Y")}</Box>
              <Box className="hp-widget-blog-news-title" onClick={()=>gotoLink(item.link)}>{item.title.substring(0,50)}...</Box>
              <Box><Button onClick={()=>gotoLink(item.link)}  className='hp-widget-blog-news-btn' variant="text" endIcon={<ArrowRightAltIcon fontSize={"inherit"}/>}>Read More</Button></Box>
            </Stack>
          </Stack>
        )
      })}
    </Box>
  )
}
function HPRecentOrderComponent(props:any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(HPRecentOrderDataComponent, "accounting_sales_order", true, props, dependency);
  return <MainComponent />;
}
const HomePageComponentReady = (props: any) => {
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <>
      <Box sx={{marginTop:"20px"}}>
        {
          isTransLoaded ?  (
            <Grid container spacing={2}>
              <Grid item md={8}>
                <HPCostUsageComponent translateFun={t}/>
                <HPRecentOrderComponent translateFun={t}/>
              </Grid>
              <Grid item md={4}>
                <HPNewsComponent translateFun={t}/>
              </Grid>
          </Grid>
          ) : <LoaderScreen/>
        }
      </Box>
    </>
  );
};
function HomePage() {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(HomePageComponentReady, "home_dashboard_widget", true, {}, dependency);
  return <MainComponent />
}
export default HomePage;