import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoaderScreen from "../../components/layout/LoaderScreen";
type Props = {
  translateDataft: any;
  translationStatus: any;
};
const MobileUserList = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);

  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }

  const mobileSearching = [
    {
      match: "name",
      id: t("Username"),
    },
    {
      match: "mobile_product__name",
      id: t("MobileProduct"),
    },
    {
      match: "number",
      id: t("Number"),
    },
    {
      match: "sim__icc",
      id: t("SimCard"),
    },
    {
      match: "carrier__name",
      id: t("Carrier"),
    },
    {
      match: "active",
      id: t("Status"),
    },
  ];

  const mobileSorting = [
    {
      descSort: "-name",
      ascSort: "name",
      id: t("Username"),
    },
    {
      descSort: "-mobile_product",
      ascSort: "mobile_product",
      id: t("MobileProduct"),
    },
    {
      descSort: "carrier",
      ascSort: "-carrier",
      id: t("Carrier"),
    },
    {
      descSort: "-number",
      ascSort: "number",
      id: t("number"),
    },
    {
      descSort: "-active",
      ascSort: "active",
      id: t("Status"),
    },
    {
      descSort: "-sim",
      ascSort: "sim",
      id: t("SimCard"),
    },
  ]

  const mobileSubColumn = [
    {
      accessorFn: (row: any) => row.name,
      header: t("Username"),
    },
    {
      accessorFn: (row: any) => (row.mobile_product ? row.mobile_product.name : "No Prooduct"),
      header: t("MobileProduct"),
      filterVariant: 'text',
    },
    {
      accessorFn: (row: any) => row.number,
      header: t("Number"),
    },
    {
      accessorFn: (row: any) => (row.sim?.id ? row.sim?.icc : "No Sim"),
      header: t("SimCard"),
    },
    {
      accessorFn: (row: any) => (row.carrier?.id ? row.carrier?.name : "No Carrier"),
      header: t("Carrier"),
    },
    {
      accessorFn: (row: any) => (row.active ? "Active" : "Deactive"),
      header: t("Status"),
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: true,
      enableColumnFilters: true,
      enableColumnActions: false,
      enableColumnFilter: true,
      filterVariant: "select",
      filterSelectOptions: [
        { text: 'Active', value: "true" },
        { text: 'Deactive', value: "false" },
      ]
    },
    // {
    //   accessorFn: (row: any) => {
    //     return (
    //       <Box className="cm-table-td">
    //         <Button
    //           sx={{
    //             cursor: "pointer",
    //             color: colorConfigs.btnStyle.color,
    //             background: colorConfigs.btnColor.bgColorGreen,
    //             "&: hover": {
    //               color: colorConfigs.btnColor.colorGreen,
    //               background: colorConfigs.btnColor.hoverbgWhite,
    //             },
    //           }}
    //           onClick={() => navigate(`/mobiles/mobile-prov-notifications/subscription/${row.id}`)}>
    //           View
    //         </Button>
    //       </Box>
    //     )
    //   },
    //   header: t("ProvNotification"),
    //   enableSorting: false,
    //   enableFilters: false,
    //   enableColumnFilterModes: false,
    //   enableColumnFilters: false,
    //   enableColumnActions: false,
    //   enableColumnFilter: false,
    // },
  ];
  const initialSortingData = [
    {
      desc: false,
      id: t("Username"),
    },
  ]
  const initialFilterSearch = [
    {
      id: t("Status"),
      value: "true"
    }
  ]
  // const initialFilterstatic = [
  //   {
  //     match: "carrier_id",
  //     value: 2,
  //   },
  //   {
  //     match: "active",
  //     value: true
  //   }
  // ]
  const getpopDate = (row: any) => navigate(`/mobiles/mobile-user-list/edit/${row.id}`)


  useEffect(() => {
    setLoaded(false);
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    } else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <div className="cm-mobile-user-Data-main">
      <div className="cm-mobile-user-Data-table">
        {isTransLoaded ? (
            <>
              <div style={{ textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent={"end"}>
                <Button
                  variant="contained"
                  endIcon={<AddIcon />}
                  className="cm-btn-style"
                  onClick={() => navigate(`${location.pathname}/add`)}
                  sx={{
                    color: colorConfigs.btnStyle.color,
                    background: colorConfigs.btnColor.bgColorGreen,
                    "&: hover": {
                      color: colorConfigs.btnColor.colorGreen,
                      background: colorConfigs.btnColor.hoverbgWhite,
                    },
                  }}>
                  {t("AddNewMobile")}
                </Button>
              </Stack>
              </div>
              <MaterialReactDataTable
                useFor={"Mobile Subscriptions List"}
                apiLink={AppAPiUrl.MobileSubscription}
                tRow={mobileSubColumn}
                searchData={mobileSearching}
                sortingData={mobileSorting}
                clickFunc={getpopDate}
                initialSortData={initialSortingData}
                initialFilterData={initialFilterSearch}
                initialStatus={true}
              />
            </>
        ) : <LoaderScreen />
        }
      </div>
    </div>
  );

};

function MSListComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(MobileUserList, "mobile_subscription", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}

export default AppPermissionHOC(MSListComponentReady, appModelPermission.SUBSCRIPTION, appModelType.READ)
//TranslationGetHOC(, "mobile_subscription");