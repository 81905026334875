import React, { useState, useEffect } from "react";
import AxiosMain from "./AxiosMain";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DataTableComponent from "./DataTableComponet";
import { Pagination } from "@mui/material";
import UserTableStyle from "../TableStyles/UserTableStyle";
import { enqueueSnackbar } from "notistack";

function PaginationTableData(props: any) {
    const { forcegetData, useFor, tableStyle, dependValue, apiLink, tRow, dependOn, apiParamName, apiParamValue } = props;
    const [totalPosts, setTotalPosts] = useState(0);
    const [currentPostsCount, setCurrentPostsCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableDataArray, setTableDataArray] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const orgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const getDataAPI = React.useCallback((data: any) => {
        setLoading((prev) => prev = true);
        setTableDataArray((prev) => prev = [])
        let apiURL = "";

        if (dependOn !== '') {
            apiURL = `${apiLink}?page=${data}&${apiParamName}=${apiParamValue}`
        }
        else if (Object.keys(dependValue).length > 0) {
            let str = Object.keys(dependValue).map(key => key + '=' + dependValue[key]).join('&');
            apiURL = `${apiLink}?page=${data}&${str}`
        }
        else {
            apiURL = `${apiLink}?page=${data}`
        }
        AxiosMain.get(`${apiURL}`).then((res) => {
            setLoading(false);
            if (res.status === 200 && res.data.count > 0) {
                const totalPage = res.data.count;
                setTotalPosts(totalPage);
                setTotalPages(Math.ceil(totalPage / 10));
                setTableDataArray(res.data.results);
                setCurrentPostsCount(res.data.results.length);
                setCurrentPage(data);
            } else {
                setTableDataArray([]);
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            const { response } = e;
            if (response) {
                if (response.status === 403 && response.statusText === "Forbidden") {
                    enqueueSnackbar(`${useFor} Permission Denied`, { preventDuplicate: true, variant: "error", });
                }
                if (response.status === 401 && response.statusText === "Unauthorized") {
                    enqueueSnackbar(`${useFor} Unauthorized`, { preventDuplicate: true, variant: "error", });
                }
            }
            setLoading(false);
        })
        // eslint-disable-next-line
    }, [orgnisationData, apiLink, apiParamValue, dependValue]);
    useEffect(() => {
        getDataAPI(currentPage);
        // eslint-disable-next-line
    }, [getDataAPI]);
    const paginationChange = (event: any, value: number) => {
        if (totalPages >= value) {
            getDataAPI(value);
        }
    }
    useEffect(() => {
        if (forcegetData) {
            getDataAPI(1);
        }
        // eslint-disable-next-line
    }, [forcegetData])
    return (
        <>
            <div className={`cm-paginate-data-table ${isLoading && "active-loader"}`}>
                {(() => {
                    switch (tableStyle) {
                        case 'default':
                            return <DataTableComponent isWithBG={true} isRounded={false} tRow={tRow} tData={tableDataArray} />;
                        case 'user':
                            return <UserTableStyle tData={tableDataArray} currentDataCount={currentPostsCount} count={totalPosts} page={currentPage} />;
                        default:
                            return "No Table Style Set";
                    }
                })()}
                <div style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    {!isLoading && tableDataArray.length > 0 && totalPages > 1 && (
                        <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={paginationChange}
                        />
                    )}

                </div>
            </div>
        </>
    )
}
PaginationTableData.defaultProps = {
    useFor: "Data",
    tableStyle: "default",
    dependOn: "",
    apiParamValue: "",
    defaultSort: "name",
    searchfieldName: "search",
    dependValue: {},
    forcegetData: false,
}
export default PaginationTableData