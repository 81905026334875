import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoaderScreen from "./LoaderScreen";
import axios from "axios";
import { setappAllTranslate } from "../../redux/features/appStateSlice";
import Swal from "sweetalert2";
import { enqueueSnackbar } from "notistack";

function TranslationGetHOC(OrignalComponent: any, name: any, isAllow: any, props?: any, dependency?: any) {
    function NewComponent() {
        const dispatch = useDispatch();
        const [translationStatus, setTranslationStatus] = useState<boolean | null>(null);
        const [currentTranslate, setCurrentTranslate] = useState({});
        // eslint-disable-next-line
        const [dependencyArray, setDependencyArray] = useState(dependency ? Object.keys(dependency).length > 0 ? dependency.require : [] : []);
        //const [urlDuplicate, setURLDuplicate] = useState<any>([]);
        const { appAllTranslate, appCountry } = useSelector((state: RootState) => state.appState);
        const getTranslate = (model_type: any) => {
            let countryCode = appCountry.toLowerCase();
            const apiURL = `${process.env.REACT_APP_BASE_URL}/translate/language/${countryCode}/feature/${model_type}/`;
            axios.get(apiURL)
                .then((res) => {
                    if (res.status === 200 && res.data.instances.length > 0) {
                        let dataOutput: any = {};
                        dataOutput.name = model_type;
                        dataOutput[`${countryCode}`] = res.data.instances
                        let output: any = [dataOutput];
                        if (appAllTranslate.length === 0) {
                            dispatch(setappAllTranslate(output));
                        }
                        else {
                            let otherOut: any = {};
                            otherOut[`${countryCode}`] = res.data.instances
                            const disptData = appAllTranslate.filter((item: any) => item.name === model_type);
                            if (disptData.length > 0) {
                                let mergedArray = appAllTranslate
                                const newoutput = mergedArray.map((item: any, index: any) => {
                                    if (item.name === model_type) {
                                        return { ...item, ...otherOut };
                                    }
                                    return item;
                                })
                                dispatch(setappAllTranslate(newoutput));
                            } else {
                                const newoutput: any = [...appAllTranslate, ...output];
                                dispatch(setappAllTranslate(newoutput));
                            }
                        }
                    } else {
                        setTranslationStatus(false);
                    }
                }).catch((e) => {
                    const { response } = e;
                    console.log("Error While getting Tranlation", e);
                    setTranslationStatus(false);
                    if(response){
                        if (response.status === 401) {
                            enqueueSnackbar(`${response.data.detail}`, { preventDuplicate: true, variant: "error", });
                        } else if (response.status === 400) {
                            for (const [key, value] of Object.entries(response.data)) {
                                enqueueSnackbar(`${value}`, { preventDuplicate: true, variant: "error", });
                            }
                        } else  if (response.status === 404 && response.statusText === "Not Found") {
                                    enqueueSnackbar(`Translation Not Found`, { preventDuplicate: true, variant: "error", });
                                }
                        else {
                            enqueueSnackbar(`Error while getting Tranlation data!`, { preventDuplicate: true, variant: "error", });
                        }
                    }else{
                        enqueueSnackbar(`${e.message}`, { preventDuplicate: true, variant: "error", });
                    }
                })
        }
        const getCountryChanged = useCallback(() => {
            let countryCode = appCountry.toLowerCase();
            if (appAllTranslate.length === 0) {
                getTranslate(name);
            } else {
                if (dependencyArray.length > 0) {
                    const findifExist = appAllTranslate.filter((item: any) => item.name === name && item[`${countryCode}`]);
                    const dependIfExist = appAllTranslate.filter((item: any) => dependencyArray.includes(item.name) && item[`${countryCode}`]);
                    if (dependIfExist.length > 0) {
                        if (dependIfExist.length > 0 && dependIfExist[0].hasOwnProperty(`${countryCode}`)) {
                            setTimeout(() => {
                                setTranslationStatus(true)
                            }, 100);
                            setCurrentTranslate(dependIfExist[0]);
                        }
                        if (findifExist.length === 0) {
                            setTranslationStatus(null);
                            getTranslate(name);
                        } else {
                            if (findifExist.length > 0 && findifExist[0].hasOwnProperty(`${countryCode}`)) {
                                setTimeout(() => {
                                    setTranslationStatus(true)
                                }, 100);
                                setCurrentTranslate(findifExist[0]);
                            }
                        }
                    }
                    if (dependIfExist.length === 0) {
                        getTranslate(dependencyArray[0]);
                    }
                } else {
                    const findifExist = appAllTranslate.filter((item: any) => item.name === name && item[`${countryCode}`]);
                    if (findifExist.length > 0 && findifExist[0].hasOwnProperty(`${countryCode}`)) {
                        setTimeout(() => {
                            setTranslationStatus(true)
                        }, 100);
                        setCurrentTranslate(findifExist[0]);
                    }
                    else {
                        setTranslationStatus(null);
                        getTranslate(name);
                    }
                }
            }
            // eslint-disable-next-line
        }, [appCountry, appAllTranslate]);
        useEffect(() => {
            getCountryChanged();
        }, [getCountryChanged]);
        if (translationStatus === null) {
            return (<LoaderScreen msg="Loading ..." />);
        }
        return (<OrignalComponent translateDataft={currentTranslate} isAllow={isAllow} translationStatus={translationStatus}  {...props} />)
    }
    return NewComponent;
}
export default TranslationGetHOC;