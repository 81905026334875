import React from "react"
import { Container} from "@mui/material"

export default function PrivacyPolicyPage(){
return(
    <div className="cm-privay-policy-wrapper">
        <Container>
            <h1>Privacy Policy</h1>
        <div className="block-area">
		<p><strong>14 June 2018</strong></p>
		<ol>
			<li>Introduction and acceptance</li>
			<li>Why we collect and use personal data</li>
			<li>How we collect personal data</li>
			<li>Submitting to us the personal data of other persons</li>
			<li>Children’s personal data</li>
			<li>Personal data collected by third parties</li>
			<li>Visiting linked websites</li>
			<li>What personal data we collect</li>
			<li>How we use personal data</li>
			<li>With whom we share personal data</li>
			<li>Countries to which we may transfer personal data</li>
			<li>How we protect your personal data</li>
			<li>Your data protection rights</li>
			<li>For how long time we store personal data</li>
			<li>Deleting your personal data</li>
			<li>How to contact us</li>
			<li>How we update this Privacy Policy</li>
		</ol>
		<h2 className="wp-block-heading">1. Introduction and acceptance</h2>
		<p>Setera Communications Oy and its affiliates (later: “we”) provide domestic and international network and communication services for enterprises. This Privacy Policy applies to all contracts, services and websites offered by us, which refer to this Privacy Policy.</p>
		<p>When you access our websites, enter into a contract with us, or use our services, you may be asked to accept this Privacy Policy, e.g. by ticking a box or clicking a button on the website or in a contract: doing so will be regarded as conclusive and written evidence of your consent to the collection, processing, use and transfer of your personal data as described in this Privacy Policy.</p>
		<p>The Privacy Policy was last updated on the date shown on top of page, and is effective as of that date.</p>
		<h2 className="wp-block-heading">2. Why we collect and use personal data</h2>
		<p>We collect and use your personal data to manage our relationship with you, e.g. to contact you when necessary, to provide or offer our products and services to you, and to manage the contractual, financial or legal obligations between you and us, to respond to requests by authorities, to respond to your enquiries or complaints, and for other legitimate business purposes.</p>
		<p>Submitting your personal data to us is voluntary. However, we may be unable to offer our services to you, if you fail to submit to us a sufficient amount of personal data, so that we can configure our services for you, invoice the services, and fulfill all contractual and legal obligations related to the services.</p>
		<p>While you have a contract with us, and we provide our services to you, we may have legal obligations to collect some personal data, regardless of your consent. An example of this is collecting logs of phone calls and Internet sessions, and storing them for the legally required time period.</p>
		<h2 className="wp-block-heading">3. How we collect personal data</h2>
		<p>We may request and collect your personal data via our websites, when you visit them to look at our products and services, or when you create an account on our website, or participate in a survey or a marketing campaign that we organize, or sign up for our newsletters. We may request and collect your personal data also via our telecommunication systems, signed contracts, technical survey forms and other written documents, or in discussions between you and us by telephone or in person.</p>
		<p>When you visit our websites or use our services, we may collect personal data also by automated means (subject to your prior consent where applicable by law), without you actively submitting such information, using technologies such as cookies, web server logs, or other similar technologies.</p>
		<p>Your telephone calls to us may be recorded for the purposes of staff training and service quality, or as evidence of a commercial transaction, subject to applicable laws. You will be informed about recording of the call, before your call is connected to a member of staff. Calls that are recorded for these purposes will be kept secure, will not be disclosed to third parties, and will be deleted in accordance with our policies and legal requirements.</p>
		<h2 className="wp-block-heading">4. Submitting to us the personal data of other persons</h2>
		<p>If you submit to us any personal data of other persons (for example: names of other employees in your company), you guarantee that you have informed these persons of doing so, and have complied with applicable data protection laws, and have obtained all necessary consents from these persons for processing their personal data by us as described in this Privacy Policy.</p>
		<h2 className="wp-block-heading">5. Children’s personal data</h2>
		<p>We do not knowingly collect the personal data of anyone who is under the age of 18 years, or knowingly allow such persons to register on our websites or services.</p>
		<h2 className="wp-block-heading">6. Personal data collected by third parties</h2>
		<p>We may receive information about persons or companies from third parties, e.g. who resell or market our services, or from whom we have purchased contact data for marketing purposes. We may combine this information with data that we already have about the same persons or companies. We will ensure that such operations comply with legal data protection requirements.</p>
		<h2 className="wp-block-heading">7. Visiting linked websites</h2>
		<p>If you access third-party websites by clicking links on our websites, the information collected by such third parties is not covered by this Privacy Policy, and is governed by the terms of your relationship with these third parties. We have no responsibility or liability regarding third-party websites.</p>
		<h2 className="wp-block-heading">8. What personal data we collect</h2>
		<p>In context of this Privacy Policy, “personal data” means any information that is related to an identifiable person.</p>
		<p>We may collect the following types of personal data, when necessary and legally acceptable:</p>
		<ul>
			<li>Contact details, such as the name of a person or company, address, phone number, fax number, email address, and other contact details.</li>
			<li>Professional title, and role in a company.</li>
			<li>Device models, operating systems, software versions and services used by a person.</li>
			<li>Account details such as username and password.</li>
			<li>Personal preferences, such as language and colour palette of user interface, etc.</li>
			<li>Any written communication between you and us, such as email messages, service request tickets, complaints, enquiries, your responses to our surveys, etc.</li>
			<li>Written notes for our internal use, which may summarize the main points of our discussions with you personally or by telephone, or describe aspects that our personnel needs to take into consideration, when we configure or manage your services, your invoicing, or otherwise manage our relationship with you.</li>
			<li>Drawings and maps that describe the physical layout of buildings, rooms, cable connections, desks, devices, employees etc. at a location where we have been requested to physically install telecommunication devices or services.</li>
			<li>Business profile information.</li>
			<li>Business ID, tax identification number, VAT code, or other such identifiers given by public authorities to a company or organization.</li>
			<li>In some cases we may ask a person’s social security number or scanned identification document, to confirm his identity.</li>
			<li>Financial and payment data, such as banking details, invoices and payment terms, and any personal data for legitimate processing of billing information.</li>
			<li>Visitor and activity log data of our websites and services, such as IP addresses, device names, referring addresses of web pages where the visitor clicked a link that leads to our website, timestamps of visiting and exiting an identified page, action taken on the page, device and software and operation system used by the visitor, etc.</li>
			<li>Log data of phone calls, text messages, Internet sessions etc. when you use our telecommunication or network services, which may include the phone number of caller, recipient and diverter, identification of the call, phone, other device, or user, duration of the call, price and category and recipient of a call to service numbers, date and duration and data volume of Internet session, location data related the log event, etc.</li>
		</ul>
		<h2 className="wp-block-heading">9. How we use personal data</h2>
		<p>We may use your personal data to conduct marketing, promotional and informational activities, and to perform business analytics, satisfaction surveys or market research.</p>
		<p>We shall only process your personal data for the purposes described in this Privacy Policy, or as otherwise permitted under applicable laws. The processing of your personal data by us for the purposes described in this Privacy Policy is based on your explicit consent, or on the necessity to process your personal data for the purposes of our legitimate interests, as provided by Article 9, 2., (a) and (f) of the General Data Protection Regulation (EU) 2016/679 of 27 April 2016 (“GDPR”).</p>
		<p>Personal information may be processed by us for purposes such as:</p>
		<ul>
			<li>to manage our relationship with you, and to respond to your inquiries or complaints</li>
			<li>to provide any goods, services or information that you have requested, and information about our products and services</li>
			<li>to configure, manage and improve our websites and services</li>
			<li>to manage your accounts, orders, invoices and payments</li>
			<li>to provide after-sale services</li>
			<li>to manage accounting and taxation</li>
			<li>to respond to requests from administrative or judicial authorities</li>
			<li>to comply with applicable laws and regulations</li>
			<li>to audit usage of the websites and service</li>
			<li>to prevent and detect fraud or other crimes, to recover debts, or to trace those who owe us money</li>
		</ul>
		<p>Providing personal data for these purposes is necessary, and refusal might make it impossible for us to fulfill our contractual and legal obligations related to you.</p>
		<p>Subject to your consent where required by law, we may also use your personal data for the following other purposes:</p>
		<ul>
			<li>to provide you access to our information and services</li>
			<li>to count how many persons visit our websites, where they come from, when they visit, how long they stay and what pages they look at</li>
			<li>to perform research and statistical analysis, such as statistically and anonymously monitoring how customers generally use our network, products and services</li>
			<li>to improve the way how our websites and services function</li>
			<li>to associate your activity on our website to you, to give us insight into the products and services that you might like, and to measure how our advertising performs</li>
			<li>to note the browser, operation system and device details that you use, to make sure that you get a smooth user experience, when you access our websites or services</li>
			<li>to market and improve the services that we offer</li>
			<li>to conduct promotion campaigns, surveys and other marketing activities</li>
			<li>to understand how you use our services, to develop new better versions</li>
		</ul>
		<h2 className="wp-block-heading">10. With whom we share personal data</h2>
		<p>By accepting this privacy policy, you approve that your personal data will be visible to other employees of your company that is in a customer relationship with us, to the extent that is legally acceptable and practically necessary for reasonable functioning of our services and their invoicing. You also approve that our services may show your name, phone number, email address or IP address to a third party with whom you engage in a phone call, email conversation, or other communication that is technically implemented by our services.</p>
		<p>We may share your personal data with other affiliates of the Setera International group of companies, or with third parties that process data on our behalf, when such sharing is purposeful and legally permissible, and protected by data privacy agreements.</p>
		<p>We do not give your personal data to third parties for promotional purposes. We will not give, sell or otherwise distribute personal data to third parties, except where this is necessary and in accordance with applicable laws. We may share personal data with:</p>
		<ul>
			<li>Subsidiaries and affiliates of Setera International group of companies.</li>
			<li>Third-party service providers, who act on our behalf to manage the services, perform data analytics, finance management, accounting, or other administrative services and information technology support. Such third party service providers will operate under applicable data protection laws. Website cookies may be administered by third parties, whose services we use for website traffic analytics, advertising, etc.</li>
			<li>Other data recipients: When necessary and permitted or required by law, we may need to make personal data available to third parties such as advisors, banks and financial institutions, credit reference institutions, professionals, independent consultants, potential or existing investors, regulatory or administrative authorities, courts, and police authorities.</li>
			<li>Other telecommunications operators: According to applicable laws, we may share some personal information about telecommunication service users with other telecommunications operators.</li>
			<li>Phone directory services: According to applicable laws, and depending on the privacy settings that each customer may set for their own data, we may share some personal information about telecommunication service users with national phone directory services.</li>
			<li>We may share with third parties some aggregated, non-personal information, such as how our customers statistically use of our services or websites. Such information would not identify any person individually. This statistical and anonymous information can be shared with our advertisers and other companies that offer their goods or services to us.</li>
			<li>A person who is registered as the user of a phone number or device, has the legal right to receive complete logs of his or her own phone calls. The management of a company that is our customer has the legal right to receive limited logs of phone calls of all their employees, where the phone numbers of third parties (typically: the recipients of calls) have been masked so that the three last digits of phone number are not shown. The management of a company may also have the legal right to see full unmasked logs of the phone calls of all their employees to premium-priced service phone numbers, and of payment transactions where the phone subscription is used as a payment method. The legislation may vary from country to country: we respect the local law in each country.</li>
		</ul>
		<h2 className="wp-block-heading">11. Countries to which we may transfer personal data</h2>
		<p>We store personal data only in countries within the European Economic Area, and do not transfer personal data outside of these countries, unless it is practically unavoidable for the technical implementation of an international phone call or other international communication services.</p>
		<p>Third parties may resell our services outside of the European Economic Area. In such case, third parties outside of the European Economic Area may collect personal data of customers (which can be from any country in the world), and store it in United States, and transfer it to us for practical implementation of the service. This transfer of personal data is one-directional, from United States to the European Economic Area.</p>
		<p>If we have customers who are outside of the European Economic Area, the invoicing or management of our services may involve transferring personal data between us in the European Economic Area and our customer in other countries.</p>
		<h2 className="wp-block-heading">12. How we protect your personal data</h2>
		<p>We have appropriate policies and technical and organizational measures in place to protect your personal data against unlawful or unauthorised access, accidental loss or destruction, damage, unlawful or unauthorised use, and disclosure.</p>
		<h2 className="wp-block-heading">13. Your data protection rights</h2>
		<p>In accordance with applicable data protection laws, you may have the right to:</p>
		<ul>
			<li>Withdraw your consent to our Privacy Policy. (This will not retrospectively affect the lawfulness of data processing based on your consent before it was withdrawn).</li>
			<li>Set restrictions on how we process your personal data.</li>
			<li>Set restrictions on the use of your personal data for marketing purposes.</li>
			<li>Set restrictions on the visibility of your contact details in public phone directories.</li>
			<li>Unsubscribe from any newsletter etc. that you may have subscribed to.</li>
			<li>Request a human-readable or machine-readable copy of the personal information that we have about you.</li>
			<li>Request any inaccurate information about you to be corrected.</li>
			<li>Request the personal data that we have about you to be transferred to another company.</li>
			<li>Request the personal data that we have about you to be deleted (see below: Deleting your personal data).</li>
			<li>File a complaint with a supervising data protection authority, if you believe that your rights to protection of personal data have been violated.</li>
		</ul>
		<h2 className="wp-block-heading">14. For how long time we store personal data</h2>
		<p>We store personal data only as long as it is necessary for fulfilling the purposes that are defined in our Data Privacy Statement, unless legislation requires otherwise. Anonymized data, which cannot be connected to any identifiable person, can be stored without time limitations.</p>
		<p>Customer data is typically stored for 3 – 6 years after the customer relationship has ended, to ensure our ability to fulfill all our legal obligations, which may include responding to reclamations and other inquiries, respecting the warranty periods of sold products, invoicing the customer for services that remain unpaid, storing complete accounting data for as long as the law requires, etc.</p>
		<p>Some data types have a shorter storage period defined in law. We store the logs of phone calls and Internet sessions for 15 months, due to legal requirements and practical aspects.</p>
		<h2 className="wp-block-heading">15. Deleting your personal data</h2>
		<p>In accordance with applicable data protection laws, you may have the right to request the personal data that we have about you to be deleted – if no contractual, invoicing, accounting, or other legal aspects remain in effect, which would void your right to get your personal data deleted.</p>
		<p>If you end your customer relationship or other relationship with us, and close all services and user accounts etc. that you have with us, we may retain some personal data about you for a limited time, to comply with legal obligations or other acceptable business purposes, such as legal retention obligations, collecting outstanding debts, or resolving legal disputes.</p>
		<h2 className="wp-block-heading">16. How to contact us</h2>
		<p>If you have any questions concerning this Privacy Policy, or if you wish to exercise your legal rights to manage your personal data, please contact us by one of the following means:</p>
		<p>Mail address: Setera International Oy, Metsänneidonkuja 12, 02130 Espoo, Finland</p>
		<p>Phone: <a href="tel:+358201500800">+358 20 1500 800</a></p>
		<p>Email: <a href="mailto:support@setera.com">support@setera.com</a></p>
		<h2 className="wp-block-heading">17. How we update this Privacy Policy</h2>
		<p>We reserve the right to review this Privacy Policy at any time. When necessary, we will notify you about the new Privacy Policy by publishing a notification on our services or websites, or by e-mail. Your continued use of our services or websites constitutes your agreement to the revised Privacy Policy. If you do not agree to the rewieved Privacy Policy, you must discontinue use of our services and websites.</p>
	</div>
    </Container>
    </div>
)
}
