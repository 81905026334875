import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import AxiosMain from "../../components/layout/AxiosMain";
import LoaderScreen from "../../components/layout/LoaderScreen";
import { useFormik } from "formik";
import * as Yup from 'yup';
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Stack, FormControl, Grid, Modal, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { setAppNextRoute, setAppcurrentPageForm } from "../../redux/features/appStateSlice";
import PaginationEditDropDown from "../../components/layout/PaginationEditDropDown";
import { AppAPiUrl, appFieldTypeName, appModelPermission, appModelType } from "../../configs/appData";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import FormikLayout from "../../components/layout/FormikLayout";
import { isFieldPermission } from "../../components/layout/Helper";
type Props = {
    translateDataft: any;
    translationStatus: any;
    isAllow: any,
    permissionData?: any,
};
const barringsstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};
const editSubsModalstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};
function EditMobileUser(props: Props) {
    const { permissionData } = props;
    const param = useParams();
    const { id } = param;
    //const navigate = useNavigate();
    const [oldID, setId] = useState(id);
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [singleuserdata, setSingeluserDaa] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditSimLoading, setEditSimLoading] = useState(false);
    const [isEndSubModal, setEndSubModal] = useState(false);
    const [isAPIOn, setApiOn] = useState(false);
    const dispatch = useDispatch();
    //const [isLoading, setIsLoading] = useState(false);
    const [isPermissionAllow, setPermissionAllow] = useState<boolean | null>(false);
    // eslint-disable-next-line
    //const [isPermissionToDelete, setPermissionToDelete] = useState(false);
    const OrgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const isCurrenFormEidt = useSelector((state: RootState) => state.appState.appCurrentPageForm);
    const isNextRoute = useSelector((state: RootState) => state.appState.appNextRoute);
    const isPervRoute = useSelector((state: RootState) => state.appState.appPrevRoute);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const checkUser = useSelector((state: RootState) => state.authState.authState);
    const appPermission = useSelector((state: RootState) => state.appState.appPermission)
    // eslint-disable-next-line
    const [editStartDate, setEditStartDate] = useState(new Date());
    //const [form_did_number_Value, setform_did_number_Value] = useState("");
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [catalougeSData, setCatalougeSData] = useState([]);
    const [isCatalougeSModalOpen, setCatalougeSModalOpen] = useState(false);
    //const [formDependData, setFormDependData] = useState("");
    const [otherDetailsData, setOtherDetailsData] = useState<any>({});
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const formSwalTranslation = {
        formSwalTitle: t("AreYouWanttoSave"),
        formSwalText: t("Youwontbeabletorevertthis"),
        fomrSwalCancel: t("Cancel"),
        formSwalSaved: t("YesSavedit"),
        formSwalDataSaved: t("YourDatahasbeensaved"),
        formEditDataupdate: t("UpdateSIMCard"),
        formSwalSubmited: t("Submited"),
        form_Delete: t('Delete'),
        formSwalWentWrong: t("SomethingWentWrong"),
        formSwalSureDele: t('Areyousureyouwanttodelete'),
        formdontSaveChanges: t('Areyousureyoudontwanttosavethechanges?'),
        emptyData: t('EmptyData'),
        formSwalWrong: t("SomethingWentWrong"),
    };
    const cancelCatalogeForm = () => {
        catalougeSFormik.resetForm();
        setCatalougeSModalOpen(false)
    }
    const AddCatalougeSSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        street_address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        postal_code: Yup.string().required('Required')
    });

    const discardChanges = () => {
        if (singleuserdata?.active) {
            dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
            Swal.fire({
                text: formSwalTranslation.formdontSaveChanges,
                icon: 'warning',
                cancelButtonText: formSwalTranslation.fomrSwalCancel,
                showCancelButton: true,
                confirmButtonColor: '#103256',
                cancelButtonColor: '#d33',
                confirmButtonText: `${t('Yes')}`,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    dispatch(setAppcurrentPageForm("process"));
                    //navigate("/mobiles/mobile-user-list")
                }
            })
        } else {
            dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
            dispatch(setAppcurrentPageForm("process"));
        }

    }

    // const candeleteMobileUser = (row: any) => {
    //     if (isPermissionToDelete && singleuserdata?.active) {
    //         deleteMobileUser(row);
    //     } else {
    //         Swal.fire({
    //             title: "Error",
    //             text: `${t("NotAllowed")}`,
    //             icon: "error"
    //         });
    //     }
    // }
    // const deleteMobileUser = (row: any) => {
    //     Swal.fire({
    //         text: `${formSwalTranslation.formSwalSureDele} ${row.name} ${t('user?')}`,
    //         icon: 'warning',
    //         cancelButtonText: formSwalTranslation.fomrSwalCancel,
    //         showCancelButton: true,
    //         confirmButtonColor: '#103256',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: formSwalTranslation.form_Delete,
    //     }).then((result: any) => {
    //         if (result.isConfirmed) {
    //             AxiosMain.delete(`${AppAPiUrl.MobileSubscription}${row.id}/`).then((res) => {
    //                 if (res.status === 204 && res.data === '') {
    //                     //setCustomTabs(0);
    //                     navigate("/mobiles/mobile-user-list/")
    //                     Swal.fire({ title: formSwalTranslation.form_Delete, icon: "success" });
    //                 }
    //             }).catch((e) => {
    //                 Swal.fire({
    //                     title: formSwalTranslation.formSwalWentWrong,
    //                     icon: "error"
    //                 });
    //             })
    //         }
    //     })
    // }

    const AddUserSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        number: Yup.number().required('Required'),
        mobile_product: Yup.string().required('Required'),
        // barring_voice: Yup.number().required('Required'),
        // barring_sms: Yup.number().required('Required'),
        // barring_mms: Yup.number().required('Required'),
        // barring_data: Yup.number().required('Required'),
        // barring_roaming: Yup.number().required('Required'),
        // barring_roamingdata: Yup.number().required('Required'),
        sim: Yup.number().required('Required'),
    });
    const formikDefaultValues = {
        name: "",
        number: "",
        mobile_product: "",
        voicemail_number: "",
        organization: "",
        barring_data: "",
        barring_mms: "",
        barring_roaming: "",
        barring_roamingdata: "",
        barring_sms: "",
        barring_voice: "",
        carrier: "",
        sim: "",
    }
    const formik = useFormik({
        initialValues: formikDefaultValues,
        validationSchema: AddUserSchema,
        onSubmit: (values: any) => {
            //console.log("Formik Value", APiValues);
            if (singleuserdata?.active) {
                const { form_change_sim_card } = formikEditSim.values;
                const APiValues = { ...values, "organization": OrgnisationData.id, sim: form_change_sim_card };
                const { Field } = permissionData;
                let apiPermisionValue: any = {};
                if (Field && Field.length > 0) {
                    // eslint-disable-next-line
                    Field.map((item: any) => {
                        if (APiValues[`${item.name}`] && item.update) {
                            apiPermisionValue[`${item.name}`] = APiValues[`${item.name}`]
                        }
                    })
                }
                if (Object.keys(apiPermisionValue).length > 0) {
                    AxiosMain.patch(`${AppAPiUrl.MobileSubscription}${singleuserdata.id}/`, apiPermisionValue).then((res) => {
                        if (res.status === 200) {
                            Swal.fire(
                                formSwalTranslation.formSwalSubmited,
                                formSwalTranslation.formSwalDataSaved,
                                "success"
                            );
                            dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
                            dispatch(setAppcurrentPageForm("process"));
                        }
                    }).catch((e) => {
                        console.log("Error While Update User", e);
                        Swal.fire({
                            title: "Error",
                            text: formSwalTranslation.formSwalWrong,
                            icon: "error"
                        });
                    })
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "No Data Allowed",
                        icon: "error"
                    });
                }

            } else {
                Swal.fire({
                    title: "Error",
                    text: "Not Allowed",
                    icon: "error"
                });
            }
        }
    })

    const catalougeSFormik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            street_address: "",
            city: "",
            postal_code: ""
        },
        validationSchema: AddCatalougeSSchema,
        onSubmit: (values: any) => {
            console.log("Catalouge Submiting", values);
            const { catelog }: any = singleuserdata;
            let APiValues = { ...values, "sub_id": id };

            if (catelog.hasOwnProperty("sub_id")) {
                //console.log("Data Found", catelog, catelog.id);
                AxiosMain.put(`${AppAPiUrl.MobileCatalog}${catelog.id}/`, APiValues).then((res) => {
                    console.log("Catalouge Updated", res);
                }).catch((e) => {
                    console.log("Unable to Add Catalouge");
                    Swal.fire({
                        title: "Error",
                        text: formSwalTranslation.formSwalWrong,
                        icon: "error"
                    });
                    setCatalougeSData([]);
                })
            } else {
                //console.log("Data Not Found", catelog, id);
                AxiosMain.post(AppAPiUrl.MobileCatalog, APiValues).then((res) => {
                    console.log("Catalouge Created", res);
                }).catch((e) => {
                    console.log("Unable to Add Catalouge");
                    setCatalougeSData([]);
                    Swal.fire({
                        title: "Error",
                        text: formSwalTranslation.formSwalWrong,
                        icon: "error"
                    });
                })
            }
            let data: any = [];
            data.push(values)
            setCatalougeSData(data);
            setCatalougeSModalOpen(false);
        }
    })

    const formikEditSubmit = (values: any) => {
        const APIValues = {
            // organization: OrgnisationData.id,
            // number: formik.values.number,
            sim: values.form_change_sim_card,
            // carrier: formik.values.carrier,
            // name: formik.values.name
        }
        AxiosMain.put(`${AppAPiUrl.MobileSimupdate}${id}/`, APIValues).then((res) => {
            setEditSimLoading(false);
            Swal.fire(
                formSwalTranslation.formSwalSubmited,
                formSwalTranslation.formSwalDataSaved,
                "success"
            );
            dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
            dispatch(setAppcurrentPageForm("process"));
        }).catch((e) => {
            console.log("error", e);
            setEditSimLoading(false);
            Swal.fire({
                title: "Error",
                text: formSwalTranslation.formSwalWrong,
                icon: "error"
            });
        })
    }

    const formikEditSim = useFormik({
        enableReinitialize: true,
        initialValues: {
            form_Subscription_Close_Open: false,
            form_temp_close_subscriptioin: false,
            form_reopen_temp_close_subscriptioin: false,
            form_end_sub_status: false,
            form_end_subscription: editStartDate,
            form_change_sim_card: "",
            customDataHandle: ""
        },
        onSubmit: (values: any) => {
            setEditSimLoading(true);
            formikEditSubmit(values);
        }
    })


    // Closing Mobile Subscription Modal
    const areyouSureCloseSubs = (val: boolean) => {
        let tempMessage;
        let isOpenOrClse = "";
        if (val) {
            tempMessage = "are you sure you want to temp close mobile subscription"
            isOpenOrClse = "subscription_close_date";
        } else {
            tempMessage = "are you sure you want to temp open mobile subscription"
            isOpenOrClse = "subscription_open_date";
        }
        const isAllowUpdate: any = isFieldPermission(checkUser, appPermission, appModelPermission.SUBSCRIPTION, isOpenOrClse, appModelType.UPDATE);
        if (isAllowUpdate) {
            Swal.fire({
                text: tempMessage,
                icon: 'warning',
                cancelButtonText: formSwalTranslation.fomrSwalCancel,
                showCancelButton: true,
                confirmButtonColor: '#103256',
                cancelButtonColor: '#d33',
                confirmButtonText: "Yes",
            }).then((result: any) => {
                if (result.isConfirmed) {
                    console.log("Suuces action");
                    tempCloseSubscription(val);
                }
            })
        } else {
            Swal.fire({
                title: "Error",
                text: `${t("NotAllowed")}`,
                icon: "error"
            });
        }

    }

    // Closing Mobile Subscription Function
    const tempCloseSubscription = (val: boolean) => {
        console.log("Calling Close And Open", val);
        // setEditSimLoading(true);
        let tempSubsURL = `${AppAPiUrl.MobileTempOpenclose}${id}/`;
        AxiosMain.patch(tempSubsURL, { temp_close: val }).then((res) => {
            console.log(res);
            if (res.status === 200) {
                formikEditSim.setFieldValue("form_Subscription_Close_Open", val);
                Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "success"
                });
                dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
                dispatch(setAppcurrentPageForm("process"));
            }
            setEditSimLoading(false);
        }).catch((e) => {
            console.log("Error While updateing Temporary close Subscription");
            setEditSimLoading(false);
            Swal.fire({
                title: "Error",
                text: formSwalTranslation.formSwalWrong,
                icon: "error"
            });
        })
    }

    const getSingleUserData = () => {
        setApiOn(true);
        AxiosMain.get(`${AppAPiUrl.MobileSubscription}${id}/`).then((res) => {
            if (res.status === 200) {
                setSingeluserDaa(res.data);
            }
        }).catch((e) => {
            console.log("error", e);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (Object.keys(singleuserdata).length === 0 && id !== '' && !isAPIOn) {
            getSingleUserData();
        }
        if (Object.keys(singleuserdata).length > 0) {
            const { catelog, temp_close, sim, carrier, mobile_product, name, number, voicemail_number, barring_data, barring_voice, barring_mms, barring_sms, barring_roaming, barring_roamingdata
            }: any = singleuserdata;
            const { first_name, last_name, postal_code, street_address, city } = catelog;
            formik.setFieldValue("name", name);
            formik.setFieldValue("voicemail_number", voicemail_number)
            //setform_did_number_Value(number);
            formik.setFieldValue("number", number);
            if (first_name !== '') {
                catalougeSFormik.setFieldValue('first_name', first_name);
            }
            if (last_name !== '') {
                catalougeSFormik.setFieldValue('last_name', last_name);
            }
            if (postal_code !== '') {
                catalougeSFormik.setFieldValue('postal_code', postal_code);
            }
            if (city !== '') {
                catalougeSFormik.setFieldValue('city', city);
            }
            if (street_address !== '') {
                catalougeSFormik.setFieldValue('street_address', street_address);
            }
            if (first_name !== '' && last_name !== '' && postal_code !== '' && street_address !== '' && city !== '') {
                let catlougeArray: any = [];
                catlougeArray.push(catelog)
                setCatalougeSData(catlougeArray);
            }

            if (carrier !== null && Object.keys(carrier).length > 0) {
                formik.setFieldValue("carrier", carrier.id);
            }
            if (sim !== null && sim?.id !== 1) {
                formik.setFieldValue("sim", sim.id);
                formikEditSim.setFieldValue("form_change_sim_card", sim.id);
                setOtherDetailsData(sim);
            }
            if (mobile_product !== null && mobile_product?.id !== 1) {
                formik.setFieldValue("mobile_product", mobile_product.id);
            }
            if (barring_voice !== null && barring_voice.id !== undefined) {
                formik.setFieldValue("barring_voice", barring_voice.id);
            }
            else {
                if (carrier !== null && Object.keys(carrier).length > 0) {
                    if (carrier.id === "2") {
                        formik.setFieldValue("barring_voice", 1);
                    } else {
                        formik.setFieldValue("barring_voice", 11);
                    }
                }
            }
            if (barring_sms !== null && barring_sms.id !== undefined) {
                formik.setFieldValue("barring_sms", barring_sms.id);
            } else {
                if (carrier !== null && Object.keys(carrier).length > 0) {
                    if (carrier.id === "2") {
                        formik.setFieldValue("barring_sms", 1);
                    } else {
                        formik.setFieldValue("barring_sms", 6);
                    }
                }
            }
            if (barring_data !== null && barring_data.id !== undefined) {
                formik.setFieldValue("barring_data", barring_data.id);
            } else {
                if (carrier !== null && Object.keys(carrier).length > 0) {
                    if (carrier.id === "2") {
                        formik.setFieldValue("barring_data", 1);
                    } else {
                        formik.setFieldValue("barring_data", 3);
                    }
                }
            }
            if (barring_mms !== null && barring_mms.id !== undefined) {
                formik.setFieldValue("barring_mms", barring_mms.id);
            } else {
                if (carrier !== null && Object.keys(carrier).length > 0) {
                    if (carrier.id === "2") {
                        formik.setFieldValue("barring_mms", 1);
                    } else {
                        formik.setFieldValue("barring_mms", 3);
                    }
                }
            }
            if (barring_roaming !== null && barring_roaming.id !== undefined) {
                formik.setFieldValue("barring_roaming", barring_roaming.id);
            } else {
                if (carrier !== null && Object.keys(carrier).length > 0) {
                    if (carrier.id === "2") {
                        formik.setFieldValue("barring_roaming", 1);
                    } else {
                        formik.setFieldValue("barring_roaming", 3);
                    }
                }
            }
            if (barring_roamingdata !== null && barring_roamingdata.id !== undefined) {
                formik.setFieldValue("barring_roamingdata", barring_roamingdata.id);
            } else {
                if (carrier !== null && Object.keys(carrier).length > 0) {
                    if (carrier.id === "2") {
                        formik.setFieldValue("barring_roamingdata", 1);
                    } else {
                        formik.setFieldValue("barring_roamingdata", 8);
                    }
                }
            }
            if (temp_close !== undefined) {
                formikEditSim.setFieldValue('form_Subscription_Close_Open', temp_close);
            }
            // if (active !== undefined) {
            //     formikEditSim.setFieldValue("form_end_sub_status", active);
            // }

            setTimeout(() => {
                setIsLoading(false);
                setDataLoaded(true);
            }, 100)

        }
        // eslint-disable-next-line
    }, [singleuserdata, id])

    useEffect(() => {
        if (oldID !== id) {
            setIsLoading(true);
            setDataLoaded(false);
            getSingleUserData();
            setId(id);
        }
        // eslint-disable-next-line
    }, [oldID, id])
    //Update Mobile Product on Select

    const apiQueryHandle = () => {
        //mobileProductUpdate(formik.values.mobile_product);
        formik.submitForm();
    }
    // const mobileProductUpdate = (productval: any) => {
    //     if (Object.keys(singleuserdata).length > 0) {
    //         const { mobile_product }: any = singleuserdata;
    //         if (productval !== '' && mobile_product?.id !== productval) {
    //             AxiosMain.patch(`${AppAPiUrl.Mobileproductupdate}${id}/`, {
    //                 mobile_product: productval
    //             }).then((res) => {
    //                 console.log("mobile-product-update update", res.status);
    //                 if (res.status === 200) {
    //                     formik.submitForm();
    //                 }
    //             }).catch((e) => {
    //                 console.log("error", e);
    //                 setIsLoading(false);
    //                 Swal.fire({
    //                     title: "Error",
    //                     text: "Mobile Product Update!",
    //                     icon: "error"
    //                 });
    //             })
    //         } else {
    //             formik.submitForm();
    //         }
    //     }
    // }

    const updateEndSubscriptionDate = () => {
        const isAllowUpdate: any = isFieldPermission(checkUser, appPermission, appModelPermission.SUBSCRIPTION, appFieldTypeName.ACTIVE, appModelType.UPDATE);
        if (isAllowUpdate) {
            const val = formikEditSim.values.form_end_subscription
            AxiosMain.patch(`${AppAPiUrl.MobileSubscriptionClosedate}${id}/`, {
                subscription_close_date: val
            }).then((res) => {
                singleuserdata.subscription_close_date = val;
                setSingeluserDaa({ ...singleuserdata });
                if (res.status === 200) {
                    setEndSubModal(false);
                    Swal.fire(
                        formSwalTranslation.formSwalSubmited,
                        "success"
                    );
                    dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
                    dispatch(setAppcurrentPageForm("process"));
                }
            }).catch((e) => {
                console.log("Error While update Mobile Subscription Close Date");
                const { response } = e;
                if (response) {
                    console.log(response);
                    Swal.fire({
                        title: "Error",
                        text: "Update Mobile Subscription Close Date!",
                        icon: "error"
                    });
                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: formSwalTranslation.formSwalWrong,
                        icon: "error"
                    });
                }
            })
        }
        else {
            Swal.fire({
                title: "Error",
                text: `${t("NotAllowed")}`,
                icon: "error"
            });
        }

    }
    const areYouSure = () => {
        Swal.fire({
            text: formSwalTranslation.formdontSaveChanges,
            icon: 'warning',
            cancelButtonText: formSwalTranslation.fomrSwalCancel,
            showCancelButton: true,
            confirmButtonColor: '#103256',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('Yes')}`,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setAppcurrentPageForm("process"));
            }
        })
    }
    useEffect(() => {
        const { translateDataft, isAllow } = props;
        if (!isPermissionAllow) {
            setPermissionAllow(isAllow);
        }
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
        // eslint-disable-next-line
    }, [props, appCountry]);
    useEffect(() => {
        if (formik.dirty && isPermissionAllow && singleuserdata?.active) {
            dispatch(setAppcurrentPageForm("yes"));
        }
        // eslint-disable-next-line
    }, [formik.dirty, isPermissionAllow]);
    useEffect(() => {
        if (isCurrenFormEidt === "yes" && isNextRoute !== "" && isNextRoute !== isPervRoute) {
            areYouSure();
        }
        // eslint-disable-next-line
    }, [isNextRoute, isCurrenFormEidt, isPervRoute]);
    return (
        <>
            {/* End Subscription Modal */}
            <Modal open={isEndSubModal}>
                <Box sx={editSubsModalstyle}>
                    <div className="form-lable-name">
                        {t("selectEndDate")}
                    </div>
                    <FormControl sx={{ width: '100%', marginTop: "20px" }} className="cm-datepicker-wrappwer">
                        <DatePicker
                            dateFormat="d MMMM yyyy HH:mm"
                            timeFormat="HH:mm"
                            isClearable={true}
                            showTimeSelect
                            minDate={editStartDate}
                            className="cm-date-picker-field"
                            name="form_end_subscription"
                            selected={formikEditSim.values.form_end_subscription}
                            onChange={(date: any) => {
                                formikEditSim.setFieldValue("form_end_subscription", date);
                                //setEditStartDate(date);
                            }} />
                    </FormControl>
                    <Box sx={{ marginTop: "40px" }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent={"end"}
                            className="form-submit-cancel-btn-wrapper"
                        >
                            <Button
                                onClick={updateEndSubscriptionDate}
                                variant="contained"
                                endIcon={<CheckCircleIcon />}
                                className="cm-btn-sm-style"
                                sx={{
                                    color: colorConfigs.btnColor.hoverbgWhite,
                                    background: colorConfigs.btnColor.bgColorGreen,
                                    "&: hover": {
                                        color: colorConfigs.btnColor.bgColorGreen,
                                        background: colorConfigs.btnColor.hoverbgWhite,
                                    },
                                }}
                            >
                                {t("saveChanges")}
                            </Button>
                            <Button
                                className="cm-btn-sm-style"
                                variant="contained"
                                endIcon={<CancelIcon />}
                                onClick={() => {
                                    setEndSubModal(false);
                                    formikEditSim.setFieldValue("form_end_subscription", null);
                                }}
                                sx={{
                                    color: colorConfigs.btnColor.hoverbgWhite,
                                    background: colorConfigs.btnColor.bgColorRed,
                                    "&: hover": {
                                        color: colorConfigs.btnColor.bgColorRed,
                                        background: colorConfigs.btnColor.hoverbgWhite,
                                    },
                                }}
                            >Cancel</Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal >
            {
                isLoading ? <LoaderScreen msg="Loading ..." /> : (
                    <>
                        {/* Catalog Services  */}
                        <Modal open={isCatalougeSModalOpen} onClose={() => setCatalougeSModalOpen(false)}>
                            <Box sx={barringsstyle}>
                                <form onSubmit={catalougeSFormik.handleSubmit}>
                                    <Grid container spacing={5}>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <FormikLayout
                                                    user={checkUser}
                                                    singleuserdata={singleuserdata}
                                                    name="first_name"
                                                    labelName={t("FirstName")}
                                                    type="text"
                                                    permisisonFieldName={"catalogservices"}
                                                    permisionData={permissionData}
                                                    form={formik}
                                                />
                                                {/* <div className="form-lable-name">
                                                    {t("FirstName")}
                                                </div>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <TextField
                                                        disabled={singleuserdata?.active ? false : true}
                                                        name="first_name"
                                                        variant="outlined"
                                                        value={catalougeSFormik.values.first_name}
                                                        onChange={catalougeSFormik.handleChange}
                                                        onBlur={catalougeSFormik.handleBlur}
                                                    />
                                                </FormControl>
                                                {catalougeSFormik.errors.first_name && catalougeSFormik.touched.first_name && <p className="cm-form-error">{t(`${catalougeSFormik.errors.first_name}`)}</p>} */}
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <FormikLayout
                                                    user={checkUser}
                                                    singleuserdata={singleuserdata}
                                                    name="last_name"
                                                    labelName={t("LastName")}
                                                    type="text"
                                                    permisisonFieldName={"catalogservices"}
                                                    permisionData={permissionData}
                                                    form={formik}
                                                />
                                                {/* <div className="form-lable-name">
                                                    {t("LastName")}
                                                </div>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <TextField
                                                        disabled={singleuserdata?.active ? false : true}
                                                        name="last_name"
                                                        variant="outlined"
                                                        value={catalougeSFormik.values.last_name}
                                                        onChange={catalougeSFormik.handleChange}
                                                        onBlur={catalougeSFormik.handleBlur}
                                                    />
                                                </FormControl>
                                                {catalougeSFormik.errors.last_name && catalougeSFormik.touched.last_name && <p className="cm-form-error">{t(`${catalougeSFormik.errors.last_name}`)}</p>} */}
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <FormikLayout
                                                    user={checkUser}
                                                    singleuserdata={singleuserdata}
                                                    name="postal_code"
                                                    labelName={t("PostalCode")}
                                                    type="text"
                                                    permisisonFieldName={"catalogservices"}
                                                    permisionData={permissionData}
                                                    form={formik}
                                                />
                                                {/* <div className="form-lable-name">
                                                    {t("PostalCode")}
                                                </div>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <TextField
                                                        disabled={singleuserdata?.active ? false : true}
                                                        name="postal_code"
                                                        variant="outlined"
                                                        value={catalougeSFormik.values.postal_code}
                                                        onChange={catalougeSFormik.handleChange}
                                                        onBlur={catalougeSFormik.handleBlur}
                                                    />
                                                </FormControl>
                                                {catalougeSFormik.errors.postal_code && catalougeSFormik.touched.postal_code && <p className="cm-form-error">{t(`${catalougeSFormik.errors.postal_code}`)}</p>} */}
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <FormikLayout
                                                    user={checkUser}
                                                    singleuserdata={singleuserdata}
                                                    name="city"
                                                    labelName={t("City")}
                                                    type="text"
                                                    permisisonFieldName={"catalogservices"}
                                                    permisionData={permissionData}
                                                    form={formik}
                                                />
                                                {/* <div className="form-lable-name">
                                                    {t("City")}
                                                </div>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <TextField
                                                        disabled={singleuserdata?.active ? false : true}
                                                        name="city"
                                                        variant="outlined"
                                                        value={catalougeSFormik.values.city}
                                                        onChange={catalougeSFormik.handleChange}
                                                        onBlur={catalougeSFormik.handleBlur}
                                                    />
                                                </FormControl>
                                                {catalougeSFormik.errors.city && catalougeSFormik.touched.city && <p className="cm-form-error">{t(`${catalougeSFormik.errors.city}`)}</p>} */}
                                            </Box>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <FormikLayout
                                                    user={checkUser}
                                                    singleuserdata={singleuserdata}
                                                    name="street_address"
                                                    labelName={t("StreetAddress")}
                                                    type="multiline"
                                                    permisisonFieldName={"catalogservices"}
                                                    permisionData={permissionData}
                                                    form={formik}
                                                />
                                                {/* <div className="form-lable-name">
                                                    {t("StreetAddress")}
                                                </div> */}
                                                {/* <FormControl sx={{ width: "100%" }}>
                                                    <TextField
                                                        multiline
                                                        rows={4}
                                                        name="street_address"
                                                        value={catalougeSFormik.values.street_address}
                                                        onChange={catalougeSFormik.handleChange}
                                                        onBlur={catalougeSFormik.handleBlur}
                                                        disabled={singleuserdata?.active ? false : true}
                                                    />
                                                </FormControl>
                                                {catalougeSFormik.errors.street_address && catalougeSFormik.touched.street_address && <p className="cm-form-error">{t(`${catalougeSFormik.errors.street_address}`)}</p>} */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ marginTop: "40px" }}>
                                        {singleuserdata?.active && (
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent={"end"}
                                            className="form-submit-cancel-btn-wrapper"
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                endIcon={<CheckCircleIcon />}
                                                className="cm-btn-sm-style"
                                                sx={{
                                                    color: colorConfigs.btnColor.hoverbgWhite,
                                                    background: colorConfigs.btnColor.bgColorGreen,
                                                    "&: hover": {
                                                        color: colorConfigs.btnColor.bgColorGreen,
                                                        background: colorConfigs.btnColor.hoverbgWhite,
                                                    },
                                                }}
                                            >
                                                {t("saveChanges")}
                                            </Button>
                                            <Button
                                                className="cm-btn-sm-style"
                                                variant="contained"
                                                endIcon={<CancelIcon />}
                                                onClick={() => cancelCatalogeForm()}
                                                sx={{
                                                    color: colorConfigs.btnColor.hoverbgWhite,
                                                    background: colorConfigs.btnColor.bgColorRed,
                                                    "&: hover": {
                                                        color: colorConfigs.btnColor.bgColorRed,
                                                        background: colorConfigs.btnColor.hoverbgWhite,
                                                    },
                                                }}
                                            >Cancel</Button>
                                        </Stack>
                                        )}

                                    </Box>
                                </form>
                            </Box>
                        </Modal>
                        {isTransLoaded ? (
                                <div className="cm-user-tabs-wrapper  cm-global-tabs-wrapper cm-user-main">
                                    <div className="cm-user-name-wrap">
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <div className="fw-600">{t("Subscription")}</div>
                                            <CircleIcon sx={{ fontSize: "10px" }} />
                                            <div>{Object.keys(singleuserdata).length > 0 && singleuserdata?.number}</div>
                                        </Stack>
                                    </div>
                                    <div className="cm-user-form-wrap" style={{ position: "relative" }}>
                                        {!singleuserdata?.active && (<div className='disabled-form'></div>)}
                                        {isDataLoaded ? (
                                            <Box sx={{ width: "100%" }}>
                                                <>
                                                    <Box className="cm-global-tab-inner-content">
                                                        <Grid container spacing={5}>
                                                            <Grid item md={7} sm={12} xs={12}>
                                                                <form onSubmit={formik.handleSubmit}>
                                                                    <Box className="cm-form-inner-fields">
                                                                        <Box className="form-mb-30">
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item md={6} sm={6} xs={12}>
                                                                                        <Box sx={{ width: "100%" }}>
                                                                                            <FormikLayout
                                                                                                user={checkUser}
                                                                                                singleuserdata={singleuserdata}
                                                                                                name="name"
                                                                                                labelName={t("name")}
                                                                                                type="text"
                                                                                                permisisonFieldName={"name"}
                                                                                                permisionData={permissionData}
                                                                                                form={formik}
                                                                                            />
                                                                                            {/* <div className="form-lable-name">
                                                                                        {t("name")}
                                                                                    </div>
                                                                                    <FormControl sx={{ width: "100%" }}>
                                                                                        <TextField
                                                                                                    disabled={singleuserdata?.active ? false : true}
                                                                                            id="form_name"
                                                                                            name="name"
                                                                                            variant="outlined"
                                                                                            value={formik.values.name}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                        />
                                                                                    </FormControl>
                                                                                            {formik.errors.name && formik.touched.name && <p className="cm-form-error">{t(`${formik.errors.name}`)}</p>} */}
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    {/* <Grid item md={6} sm={6} xs={12}>
                                                                                <Box className="cm-form-inner-fields">
                                                                                    <Box className="">
                                                                                        <div className="form-lable-name">
                                                                                            {t("MobileNumber")}
                                                                                        </div>
                                                                                        <FormControl sx={{ width: "100%" }}>
                                                                                            <TextField
                                                                                                name="number"
                                                                                                variant="outlined"
                                                                                                value={form_did_number_Value}
                                                                                                onChange={(e) => { formik.handleChange(e); formik.setFieldValue("number", e.target.value); setform_did_number_Value(e.target.value) }}
                                                                                            />
                                                                                        </FormControl>
                                                                                            {formik.errors.number && formik.touched.number && <p className="cm-form-error">{t(`${formik.errors.number}`)}</p>}
                                                                                    </Box>
                                                                                </Box>
                                                                                </Grid> */}
                                                                                    <Grid item md={6} sm={6} xs={12}>
                                                                                        <Box sx={{ width: "100%" }}>
                                                                                            <FormikLayout
                                                                                                user={checkUser}
                                                                                                singleuserdata={singleuserdata}
                                                                                                name="mobile_product"
                                                                                                labelName={t("MobileProduct")}
                                                                                                type="dropdown"
                                                                                                permisisonFieldName={"mobile_product"}
                                                                                                permisionData={permissionData}
                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                dropComponent={
                                                                                                    <PaginationEditDropDown
                                                                                                        isDisabled={singleuserdata?.active ? false : true}
                                                                                                        dependOn="apiParamValue"
                                                                                                        apiParamName="carrier"
                                                                                                        apiParamValue={formik.values.carrier}
                                                                                                        apiLink={AppAPiUrl.Mobileproduct}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        optionLableName={`${t("MobileProduct")}`}
                                                                                                        useFor={`${t("MobileProduct")}`}
                                                                                                        saveValue={formik}
                                                                                                        formFiled="mobile_product"
                                                                                                        defaultValue={formik.values.mobile_product}
                                                                                                    />
                                                                                                }
                                                                                                form={formik}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                                        <Box className="cm-form-inner-fields">
                                                                                            <Box className="">
                                                                                                <FormikLayout
                                                                                                    user={checkUser}
                                                                                                    singleuserdata={singleuserdata}
                                                                                                    name="voicemail_number"
                                                                                                    labelName={t("VoicemailNumber")}
                                                                                                    type="text"
                                                                                                    permisisonFieldName={"voicemail_number"}
                                                                                                    permisionData={permissionData}
                                                                                                    form={formik}
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                                        <Box className="cm-form-inner-fields">
                                                                                            {
                                                                                                Object.keys(singleuserdata).length > 0 && singleuserdata?.sim !== null && (
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}
                                                                                                        name="form_change_sim_card"
                                                                                                        labelName={t("ChangeSIMCard")}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"sim"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="available"
                                                                                                                apiParamValue={true}
                                                                                                                apiLink={AppAPiUrl.MobileSim}
                                                                                                                apiDataMap={{ labelName: "icc", valueName: "id" }}
                                                                                                                optionLableName={`${t("ChangeSIMCard")}`}
                                                                                                                useFor={`${t("SIM")}`}
                                                                                                                saveValue={formikEditSim}
                                                                                                                formFiled="form_change_sim_card"
                                                                                                                defaultValue={singleuserdata?.sim.id}
                                                                                                                replaceDefault={`${t("SelectSimCard")}`}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />

                                                                                                )
                                                                                            }

                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className="form-mb-30">
                                                                            <div className="form-row-title">
                                                                                {t("barrings")}
                                                                            </div>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item lg={12} md={12} xs={12}>
                                                                                        <Box className='cm-list-stack-box cm-ac-dd-rm-border'>
                                                                                            <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('Voice')}</strong></Box>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}

                                                                                                        name="barring_voice"
                                                                                                        labelName={`${t('barrings')} ${t('Voice')}`}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"barring_voice"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="carrier__id"
                                                                                                                apiParamValue={formik.values.carrier}
                                                                                                                apiLink={AppAPiUrl.MobileBarringvoice}
                                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                                optionLableName={``}
                                                                                                                useFor={`${t('barrings')} ${t('Voice')}`}
                                                                                                                saveValue={formik}
                                                                                                                formFiled="barring_voice"
                                                                                                                defaultValue={formik.values.barring_voice}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />

                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('SMS')}</strong></Box>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}
                                                                                                        name="barring_sms"
                                                                                                        labelName={`${t('barrings')} ${t('SMS')}`}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"barring_sms"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="carrier__id"
                                                                                                                apiParamValue={formik.values.carrier}
                                                                                                                apiLink={AppAPiUrl.MobileBarringsms}
                                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                                optionLableName={``}
                                                                                                                useFor={`${t('barrings')} ${t('SMS')}`}
                                                                                                                saveValue={formik}
                                                                                                                formFiled="barring_sms"
                                                                                                                defaultValue={formik.values.barring_sms}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />

                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('MMS')}</strong></Box>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}
                                                                                                        name="barring_mms"
                                                                                                        labelName={`${t('barrings')} ${t('MMS')}`}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"barring_mms"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="carrier__id"
                                                                                                                apiParamValue={formik.values.carrier}
                                                                                                                apiLink={AppAPiUrl.MobileBarringmms}
                                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                                optionLableName={``}
                                                                                                                saveValue={formik}
                                                                                                                useFor={`${t('barrings')} ${t('MMS')}`}
                                                                                                                formFiled="barring_mms"
                                                                                                                defaultValue={formik.values.barring_mms}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />

                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('Data')}</strong></Box>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}
                                                                                                        name="barring_data"
                                                                                                        labelName={`${t('barrings')} ${t('Data')}`}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"barring_data"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="carrier__id"
                                                                                                                apiParamValue={formik.values.carrier}
                                                                                                                apiLink={AppAPiUrl.MobileBarringdata}
                                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                                optionLableName={``}
                                                                                                                useFor={`${t('barrings')} ${t('Data')}`}
                                                                                                                saveValue={formik}
                                                                                                                formFiled="barring_data"
                                                                                                                defaultValue={formik.values.barring_data}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('Roaming')}</strong></Box>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}
                                                                                                        name="barring_roaming"
                                                                                                        labelName={`${t('barrings')} ${t('Roaming')}`}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"barring_roaming"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="carrier__id"
                                                                                                                apiParamValue={formik.values.carrier}
                                                                                                                apiLink={AppAPiUrl.MobileBarringroaming}
                                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                                optionLableName={``}
                                                                                                                useFor={`${t('barrings')} ${t('Roaming')}`}
                                                                                                                saveValue={formik}
                                                                                                                formFiled="barring_roaming"
                                                                                                                defaultValue={formik.values.barring_roaming}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />
                                                                                                </Box>
                                                                                            </Stack>
                                                                                            <Stack direction="row" spacing={3} className="col-box-inner-row" alignItems={"center"}>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-th"><strong>{t('RoamingData')}</strong></Box>
                                                                                                <Box sx={{ width: "50%" }} className="cm-col-box-td">
                                                                                                    <FormikLayout
                                                                                                        user={checkUser}
                                                                                                        singleuserdata={singleuserdata}
                                                                                                        name="barring_roamingdata"
                                                                                                        labelName={`${t('barrings')} ${t('RoamingData')}`}
                                                                                                        type="dropdown"
                                                                                                        permisisonFieldName={"barring_roamingdata"}
                                                                                                        permisionData={permissionData}
                                                                                                        apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                        dropComponent={
                                                                                                            <PaginationEditDropDown
                                                                                                                isDisabled={singleuserdata?.active ? false : true}
                                                                                                                dependOn="apiParamValue"
                                                                                                                apiParamName="carrier__id"
                                                                                                                apiParamValue={formik.values.carrier}
                                                                                                                apiLink={AppAPiUrl.MobileBarringroamingdata}
                                                                                                                apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                                                                optionLableName={``}
                                                                                                                useFor={`${t('barrings')} ${t('RoamingData')}`}
                                                                                                                saveValue={formik}
                                                                                                                formFiled="barring_roamingdata"
                                                                                                                defaultValue={formik.values.barring_roamingdata}
                                                                                                            />
                                                                                                        }
                                                                                                        form={formik}
                                                                                                    />

                                                                                                </Box>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </form>
                                                                <Box className="form-mb-30">
                                                                    <div className="form-row-title">
                                                                        {t("CatalogServices")}
                                                                    </div>
                                                                </Box>

                                                                {
                                                                    catalougeSData.length > 0 ?
                                                                        (
                                                                            <>
                                                                                <Box className="form-mb-30">
                                                                                    <TableContainer className="cm-barrring-cm-table-dt">
                                                                                        <Table>
                                                                                            <TableHead className="cm-barrring-cm-table-hd">
                                                                                                <TableRow>
                                                                                                    <TableCell>{t("FirstName")}</TableCell>
                                                                                                    <TableCell>{t("LastName")}</TableCell>
                                                                                                    <TableCell>{t("PostalCode")}</TableCell>
                                                                                                    <TableCell>{t("StreetAddress")}</TableCell>
                                                                                                    <TableCell>{t("City")}</TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {
                                                                                                    catalougeSData.length > 0 && catalougeSData.map((item: any, index: any) => (
                                                                                                        <TableRow key={`catlouge-s-data-${index}`}>
                                                                                                            <TableCell>{item.first_name}</TableCell>
                                                                                                            <TableCell>{item.last_name}</TableCell>
                                                                                                            <TableCell>{item.postal_code}</TableCell>
                                                                                                            <TableCell sx={{
                                                                                                                maxWidth: "300px"
                                                                                                            }}>{item.street_address}</TableCell>
                                                                                                            <TableCell>{item.city}</TableCell>
                                                                                                        </TableRow>
                                                                                                    ))
                                                                                                }
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Box>
                                                                                <Box className="form-mb-30" textAlign={"right"}>
                                                                                    <Button
                                                                                        disabled={singleuserdata?.active ? false : true}
                                                                                        variant="contained"
                                                                                        endIcon={<AddIcon />}
                                                                                        className="cm-btn-sm-style"
                                                                                        sx={{
                                                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                                                            background: colorConfigs.btnColor.bgColorGreen,
                                                                                            "&: hover": {
                                                                                                color: colorConfigs.btnColor.bgColorGreen,
                                                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                                                            },
                                                                                        }}
                                                                                        onClick={() => setCatalougeSModalOpen(true)}
                                                                                    >
                                                                                        {t("Update")}
                                                                                    </Button>
                                                                                </Box>
                                                                            </>)
                                                                        : (
                                                                            <Box className="form-mb-30">
                                                                                <Button
                                                                                    disabled={singleuserdata?.active ? false : true}
                                                                                    variant="contained"
                                                                                    endIcon={<AddIcon />}
                                                                                    className="cm-btn-md-style"
                                                                                    sx={{
                                                                                        color: colorConfigs.btnColor.hoverbgWhite,
                                                                                        background: colorConfigs.btnColor.bgColorGreen,
                                                                                        "&: hover": {
                                                                                            color: colorConfigs.btnColor.bgColorGreen,
                                                                                            background: colorConfigs.btnColor.hoverbgWhite,
                                                                                        },
                                                                                    }}
                                                                                    onClick={() => setCatalougeSModalOpen(true)}
                                                                                >
                                                                                    {t("Add")}
                                                                                </Button>
                                                                            </Box>
                                                                        )
                                                                }
                                                            </Grid>
                                                            <Grid item md={5} sm={12} xs={12}>
                                                                <Box className="cm-right-user-wrapper">
                                                                    <div className="form-row-title">
                                                                        {t("EditSimCard")}
                                                                    </div>
                                                                    {
                                                                        Object.keys(singleuserdata).length > 0 && (
                                                                            <Box>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ marginBottom: "20px" }}>
                                                                                    <Box className="order-sl-th">
                                                                                        {t("MobileStatus")}
                                                                                    </Box>
                                                                                    <Box className="order-sl-td">
                                                                                    {formikEditSim.values.form_Subscription_Close_Open ? t("TemporarilyClosed") : t("Active")}
                                                                                    </Box>
                                                                            </Stack>
                                                                            </Box>
                                                                        )
                                                                    }

                                                                    {isEditSimLoading ? <LoaderScreen /> : (
                                                                        <form onSubmit={formikEditSim.handleSubmit}>
                                                                            <Grid item sm={12} xs={12}>
                                                                            <Box sx={{ width: "100%", marginBottom: "15px" }}>
                                                                                    <Button
                                                                                        disabled={singleuserdata?.active ? false : true}
                                                                                        variant="contained"
                                                                                        className="cm-btn-sm-style"
                                                                                        sx={{
                                                                                            width: "100%",
                                                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                                                            background: colorConfigs.btnColor.bgColorGreen,
                                                                                            "&: hover": {
                                                                                                color: colorConfigs.btnColor.bgColorGreen,
                                                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                                                            },
                                                                                        }}
                                                                                        onClick={() => areyouSureCloseSubs(!formikEditSim.values.form_Subscription_Close_Open)}
                                                                                    >
                                                                                        {formikEditSim.values.form_Subscription_Close_Open ? t("ReopeningTemporarilyCloseSubscription") : t("TemporarilyCloseSubscription")}
                                                                                </Button>
                                                                            </Box>

                                                                        </Grid>


                                                                            <Box sx={{ marginTop: "20px" }}>
                                                                                <Stack
                                                                                    direction="row"
                                                                                    spacing={2}
                                                                                    alignItems="center"
                                                                                    justifyContent={"end"}
                                                                                    className="form-submit-cancel-btn-wrapper"
                                                                                >
                                                                                    {/* <Button
                                                                            disabled={formikEditSim.values.form_end_sub_status ? false : true}
                                                                            variant="contained"
                                                                                    className="cm-btn-sm-style"
                                                                            sx={{
                                                                                width: "100%",
                                                                                color: colorConfigs.btnColor.hoverbgWhite,
                                                                                background: colorConfigs.btnColor.bgColorGreen,
                                                                                "&: hover": {
                                                                                    color: colorConfigs.btnColor.bgColorGreen,
                                                                                    background: colorConfigs.btnColor.hoverbgWhite,
                                                                                },
                                                                            }}
                                                                            type="submit"
                                                                        >
                                                                            {t("UpdateSIMCard")}
                                                                        </Button> */}
                                                                                    <Button
                                                                                        disabled={singleuserdata?.active ? false : true}
                                                                                        variant="contained"
                                                                                        className="cm-btn-sm-style"
                                                                                        onClick={() => {
                                                                                            setEndSubModal(true);
                                                                                        }}
                                                                                        sx={{
                                                                                            width: "100%",
                                                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                                                            background: colorConfigs.btnColor.bgColorGreen,

                                                                                            "&: hover": {
                                                                                                color: colorConfigs.btnColor.bgColorGreen,
                                                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {t("closeSubscription")}
                                                                                    </Button>
                                                                                </Stack>
                                                                            </Box>


                                                                        </form>
                                                                    )}

                                                                </Box>

                                                                <Box className="cm-right-user-wrapper" style={{ marginTop: "40px" }}>
                                                                    <div className="form-row-title">
                                                                        {t("OtherDetails")}
                                                                    </div>
                                                                    <Grid item sm={12} xs={12}>
                                                                        {Object.keys(singleuserdata).length > 0 && (
                                                                            <>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <Box className="order-sl-th" >
                                                                                        {t("MobileNumber")}
                                                                                    </Box>
                                                                                    <Box className="order-sl-td">
                                                                                        {singleuserdata?.number}
                                                                                    </Box>
                                                                                </Stack>
                                                                                <hr />
                                                                            </>
                                                                        )}
                                                                        {
                                                                            Object.keys(otherDetailsData).length > 0 && (
                                                                                <>
                                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                        <Box className="order-sl-th" >
                                                                                            {t("Sim")}
                                                                                        </Box>
                                                                                        <Box className="order-sl-td">
                                                                                            {otherDetailsData.icc}
                                                                                        </Box>
                                                                                    </Stack>
                                                                                    <hr />

                                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                        <Box className="order-sl-th" sx={{ width: "70%" }}>
                                                                                            puk 1
                                                                                        </Box>
                                                                                        <Box className="order-sl-td" sx={{ width: "30%" }}>
                                                                                            {otherDetailsData.puk1}
                                                                                        </Box>
                                                                                    </Stack>
                                                                                    <hr />
                                                                                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                        <Box className="order-sl-th" sx={{ width: "70%" }}>
                                                                                            puk 2
                                                                                        </Box>
                                                                                        <Box className="order-sl-td" sx={{ width: "30%" }}>
                                                                                            {otherDetailsData.puk2}
                                                                                        </Box>
                                                                                    </Stack>
                                                                                    <hr />
                                                                                    {/* <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <Box
                                                                                        className="order-sl-th"
                                                                                        sx={{ width: "60%" }}
                                                                                    >
                                                                                        {t("SubscripionOpenDate")}
                                                                                    </Box>
                                                                                    <Box
                                                                                        className="order-sl-td"
                                                                                        sx={{ width: "40%" }}
                                                                                    >
                                                                                        {
                                                                                            otherDetailsData.created !== null ? (<>
                                                                                                <div>{new Date(otherDetailsData.created).toDateString()}</div>
                                                                                        <div>{new Date(otherDetailsData.created).toLocaleTimeString()}</div>
                                                                                            </>) : (<>-</>)
                                                                                        }
                                                                                    </Box>
                                                                                </Stack> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                        {Object.keys(singleuserdata).length > 0 && (
                                                                            <>
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <Box
                                                                                        className="order-sl-th"
                                                                                        sx={{ width: "60%" }}
                                                                                    >
                                                                                        {t("SubscripionOpenDate")}
                                                                                    </Box>
                                                                                    <Box
                                                                                        className="order-sl-td"
                                                                                        sx={{ width: "40%" }}
                                                                                    >
                                                                                        {
                                                                                            singleuserdata.subscription_open_date !== null ? (<>
                                                                                                <div>{new Date(singleuserdata.subscription_open_date).toDateString()}</div>
                                                                                                <div>{new Date(singleuserdata.subscription_open_date).toLocaleTimeString()}</div>
                                                                                            </>) : (<>-</>)
                                                                                        }
                                                                                    </Box>
                                                                                </Stack>
                                                                                <hr />
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ marginBottom: "20px" }}>
                                                                                    <Box className="order-sl-th">
                                                                                        {t("EndSubscription")}
                                                                                    </Box>
                                                                                    <Box className="order-sl-td">
                                                                                        {singleuserdata.subscription_close_date !== null ? (
                                                                                            <>
                                                                                                <div>{new Date(singleuserdata.subscription_close_date).toDateString()}</div>
                                                                                                <div>{new Date(singleuserdata.subscription_close_date).toLocaleTimeString()}</div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div>-</div>
                                                                                            </>
                                                                                        )}
                                                                                    </Box>
                                                                                </Stack>
                                                                                <hr />
                                                                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                                                    <Box className="order-sl-th" >
                                                                                        {t("Mobilecertificate")}
                                                                                    </Box>
                                                                                    <Box className="order-sl-td">
                                                                                        {singleuserdata?.active ? "Active" : "Deactive"}
                                                                                    </Box>
                                                                                </Stack>
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    {/* Button save changes and Discard */}
                                                    <Box sx={{ marginTop: "40px" }}>
                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                            alignItems="center"
                                                            justifyContent={"space-between"}
                                                            className="form-submit-cancel-btn-wrapper"
                                                        >
                                                            <Box>
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={2}
                                                                    alignItems="center"
                                                                    justifyContent={"start"}
                                                                    className="form-submit-cancel-btn-wrapper"
                                                                >
                                                                    <Button
                                                                        disabled={singleuserdata?.active ? false : true}
                                                                        onClick={apiQueryHandle}
                                                                        variant="contained"
                                                                        endIcon={<CheckCircleIcon />}
                                                                        className="cm-btn-style"
                                                                        sx={{
                                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                                            background: colorConfigs.btnColor.bgColorGreen,
                                                                            "&: hover": {
                                                                                color: colorConfigs.btnColor.bgColorGreen,
                                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                                            },
                                                                        }}
                                                                    >
                                                                        {t("saveChanges")}
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        endIcon={<CancelIcon />}
                                                                        className="cm-btn-style"
                                                                        sx={{
                                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                                            background: colorConfigs.btnColor.bgColorRed,
                                                                            "&: hover": {
                                                                                color: colorConfigs.btnColor.bgColorRed,
                                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                                            },
                                                                        }}
                                                                        onClick={() => discardChanges()}
                                                                    >
                                                                        {t("discard")}
                                                                    </Button>
                                                                </Stack>
                                                            </Box>

                                                            {/* <Button
                                                            disabled={singleuserdata?.active ? false : true}
                                                        variant="contained"
                                                        endIcon={<CancelIcon />}
                                                        className="cm-btn-style justify-self-end"
                                                        sx={{
                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                            background: colorConfigs.btnColor.bgColorRed,
                                                            "&: hover": {
                                                                color: colorConfigs.btnColor.bgColorRed,
                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                            },
                                                        }}
                                                            onClick={() => candeleteMobileUser(singleuserdata)}
                                                    >
                                                        {t("Delete")}
                                                    </Button> */}
                                                        </Stack>
                                                    </Box>
                                                </>
                                            </Box>
                                        ) : <LoaderScreen />}

                                    </div>
                                </div>
                        ) : <LoaderScreen />
                        }
                    </>
                )
            }
        </>
    )
}
function MSEditComponentReady(props: any) {
    const { isAllow } = props;
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(EditMobileUser, "mobile_subscription", isAllow, props, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(MSEditComponentReady, appModelPermission.SUBSCRIPTION, appModelType.UPDATE);