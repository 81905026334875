import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import colorConfigs from '../../configs/colorConfigs';
import DataTableComponent from '../../components/layout/DataTableComponet';
import AppPermissionHOC from '../../components/layout/AppPermissionHOC';
import { AppAPiUrl, appModelPermission, appModelType } from '../../configs/appData';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MaterialReactDataTable from '../../components/layout/MaterialReactDataTable';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { RootState } from '../../redux/store';
import TranslationGetHOC from '../../components/layout/TranslationGetHOC';
import { Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import LoaderScreen from '../../components/layout/LoaderScreen';
type Props = {
  translateDataft: any;
  translationStatus: any;
};

const TasksPage = (props: Props) => {
  const navigate = useNavigate();
  const [isPaylodModal, setPayloadModal] = useState(false);
  const [payLoadDataShow, setPayloadData] = useState([]);
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }
  const stylePayloadModal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  const CheckStatus = ({ row }: any) => {
    switch (row) {
      case 1:
        return <Button sx={{
          color: colorConfigs.btnColor.hoverbgWhite,
          background: colorConfigs.btnColor.bgColorLightBlue,
          "&: hover": {
            color: colorConfigs.btnColor.bgColorLightBlue,
            background: colorConfigs.btnColor.hoverbgWhite,
          }
        }}>{t('Started')}</Button>
      case 0:
        return <Button sx={{
          color: colorConfigs.btnColor.hoverbgWhite,
          background: colorConfigs.btnColor.bgColorGreen,
          "&: hover": {
            color: colorConfigs.btnColor.bgColorGreen,
            background: colorConfigs.btnColor.hoverbgWhite,
          }
        }}>{t('Start')}</Button>
      case 3:
        return <Button sx={{
          color: colorConfigs.btnColor.hoverbgWhite,
          background: colorConfigs.btnColor.bgColorGreen,
          "&: hover": {
            color: colorConfigs.btnColor.bgColorGreen,
            background: colorConfigs.btnColor.hoverbgWhite,
          }
        }}>{t('MarkDone')}</Button>
      case 2:
        return <Button sx={{
          color: colorConfigs.btnColor.hoverbgWhite,
          background: colorConfigs.btnColor.bgColorRed,
          "&: hover": {
            color: colorConfigs.btnColor.bgColorRed,
            background: colorConfigs.btnColor.hoverbgWhite,
          }
        }} startIcon={<CheckCircleIcon />}>{t('Failed')}</Button>
      default:
        return null
    }
  }
  const payLoadDataRow = [
    {
      name: t('Name'),
      cell: (row: any) => <Box className="cm-table-td cm-table-ord-wd">{row.name}</Box>,
    },
    {
      name: t('Before'),
      cell: (row: any) => <Box className="cm-table-td cm-table-ord-wd">{row.before}</Box>,
    },
    {
      name: t('After'),
      cell: (row: any) => <Box className="cm-table-td cm-table-ord-wd">{row.after}</Box>,
    },
  ]
  const SingleTasksRow2 = [
    {
      accessorKey: 'order_id',
      header: t('OrderID'),
    },
    {
      accessorKey: 'author_id',
      header: t('Author'),
      enableSorting: false,
      enableFilters: false,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      accessorFn: (row: any) => <Button
        onClick={() => {
          if (row.payload && row.payload.length > 0) {
            setPayloadData(row.payload);
            setPayloadModal(!isPaylodModal);
          }
          // if (isString(row.payload)) {
          //   setPayloadData(JSON.parse(row.payload));
          //   setPayloadModal(!isPaylodModal);
          // }
        }}
        sx={{
          color: colorConfigs.btnColor.hoverbgWhite,
          background: colorConfigs.btnColor.bgColorLightBlue,
          "&: hover": {
            color: colorConfigs.btnColor.bgColorLightBlue,
            background: colorConfigs.btnColor.hoverbgWhite,
          }
        }}>View</Button>,
      header: t('DataPayload'),
      enableSorting: false,
      enableFilters: false,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      accessorKey: 'order_product',
      header: t('Product'),
    },
    {
      accessorKey: 'order_type',
      header: t('Type'),
      enableSorting: false,
      enableFilters: false,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      accessorKey: 'order_target',
      header: t('Target'),
    },
    {
      accessorFn: (row: any) => <CheckStatus row={row.operator_provisioning_status} />,
      header: t('OperatorStatus'),
      enableSorting: false,
      enableFilters: false,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      accessorFn: (row: any) => <CheckStatus row={row.goodsign_provisioning_status} />,
      header: t('GoodsignStatus'),
      enableSorting: false,
      enableFilters: false,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      accessorFn: (row: any) => <CheckStatus row={row.mexmanager_provisioning_status} />,
      header: t('MexmanagerStatus'),
      enableSorting: false,
      enableFilters: false,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      size: 300,
      accessorFn: (row: any) => new Date(row.created),
      id: 'created',
      header: t('CreatedDate'),
      filterFn: 'lessThanOrEqualTo',
      sortingFn: 'datetime',
      Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
      Filter: ({ column }: any) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{
              textField: {
                sx: {
                  minWidth: '120px',
                  "p.MuiFormHelperText-root": {
                    color: "#fff"
                  }
                },
                helperText: 'Filter Mode: Less Than',
              },
            }}
            onChange={(newValue) => {
              console.log(newValue.$d.toDateString())
              column.setFilterValue(newValue.$d);
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),

    },
    {
      accessorFn: (row: any) => row.completed ? "Complete" : "Not done",
      header: t('Ready'),
      filterVariant: "select",
      filterSelectOptions: [
        { text: 'Complete', value: "true" },
        { text: 'Not done', value: "false" },
      ],
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: true,
      enableColumnActions: false,
      enableColumnFilter: true
    },
  ]
  const mobileSearching = [
    {
      match: "order_id",
      id: 'order_id',
    },
    {
      match: 'order_product',
      id: "order_product",
    },
    {
      match: 'order_type',
      id: "order_type",
    }, {
      match: "order_target",
      id: "order_target"
    }, {
      match: 'created',
      id: t('created'),
    }, {
      match: "completed",
      id: t("Ready")
    }
  ];
  const taskSorting = [
    {
      descSort: "-id",
      ascSort: "id",
      id: 'order_id',
    },
    {
      descSort: "-order_product",
      ascSort: "order_product",
      id: "order_product",
    },
    {
      descSort: "-order_target",
      ascSort: "order_target",
      id: "order_target"
    },
    {
      descSort: "-created",
      ascSort: "created",
      id: "created"
    },
  ]
  const initialSortingData = [
    {
      desc: false,
      id: t("OrderID"),
    },
  ]
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <>
      <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={"start"}>
          <Button variant="contained" startIcon={<ArrowBackIosIcon />}
            className="cm-btn-sm-style"
            sx={{
              color: colorConfigs.btnColor.hoverbgWhite,
              background: colorConfigs.btnColor.bgColorRed,
              "&: hover": {
                color: colorConfigs.btnColor.bgColorRed,
                background: colorConfigs.btnColor.hoverbgWhite,
              }
            }} onClick={() => navigate("/admin")}>{t('Back')}</Button>
        </Stack>
      </Box>
    <div className='cm-task-page-main' style={{ marginTop: "20px" }}>
      {isPaylodModal && (
        <>
          <Modal open={isPaylodModal} onClose={() => {
            setPayloadData([]);
            setPayloadModal(!isPaylodModal)
          }}>
            <Box sx={stylePayloadModal}>
              {payLoadDataShow.length > 0 && (
                <DataTableComponent
                  isWithBG={false}
                  isRounded={false}
                  tRow={payLoadDataRow}
                  tData={payLoadDataShow}
                />
              )}
            </Box>
          </Modal>
        </>
      )}
      <div className='cm-tasks-Data-table'>
          {isTransLoaded
              ? (<MaterialReactDataTable
                useFor={"Tasks List"}
                apiLink={AppAPiUrl.TasksTasks}
                tRow={SingleTasksRow2}
                searchData={mobileSearching}
                sortingData={taskSorting}
                initialSortData={initialSortingData}
                initialStatus={true}
              />)
            : <LoaderScreen />}
      </div>
    </div>
    </>
  );
};
function AdminTasksPageComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(TasksPage, "admin_tasks", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AdminTasksPageComponentReady, appModelPermission.TASK, appModelType.READ);
