import React, { useState, useEffect } from "react";
import { store } from "../../redux/store";
import LoaderScreen from "./LoaderScreen";
import PermisionDenied from "./PermisionDenied";
function AppPermissionHOC(OrignalComponent: any, pmName: any, modelType: any = "No") {
    function NewComponent(props: any) {
        const [currentModalandFiled, setModalfieldData] = useState<any>({});
        const [isPermissionAllow, setPermissionAllow] = useState<boolean | null>(null);
        const checkUser = store.getState().authState.authState;
        const appPermission = store.getState().appState.appPermission;
        useEffect(() => {
            const { user_type } = checkUser.user;
            if (appPermission.length > 0 && user_type !== 'Supervisor') {
                let checkIf: any = [];
                // eslint-disable-next-line
                appPermission.map((item: any) => {
                    const { Model } = item;
                    if (Object.keys(Model).length > 0) {
                        const { name } = Model;
                        const matchingText = name.toUpperCase();
                        const mmtype = modelType.toLowerCase();
                        const checkStatus = Model[`${mmtype}`];
                        if (matchingText === pmName) {
                            setModalfieldData(item);
                        }
                        if (matchingText === pmName && checkStatus) {
                            checkIf.push(pmName);
                            setTimeout(() => {
                                setPermissionAllow(true)
                            }, 100);
                        }
                        else if (matchingText === pmName && !checkStatus) {
                            setTimeout(() => {
                                setPermissionAllow(false)
                            }, 100);
                        }
                    }
                })
                if (checkIf.length === 0) {
                    setTimeout(() => {
                        setPermissionAllow(false)
                    }, 1000);
                }
            } else if (appPermission.length > 0 && user_type === 'Supervisor') {
                // eslint-disable-next-line
                appPermission.map((item: any) => {
                    const { Model } = item;
                    if (Object.keys(Model).length > 0) {
                        const { name } = Model;
                        const matchingText = name.toUpperCase();
                        if (matchingText === pmName) {
                            setModalfieldData(item);
                        }
                    }
                })
                setTimeout(() => {
                    setPermissionAllow(true)
                }, 100);
            }
        }, [appPermission, checkUser]);
        if (isPermissionAllow === null) {
            return (<LoaderScreen msg="Loading ..." />);
        }
        else if (!isPermissionAllow) {
            return (<PermisionDenied />);
        }
        else {
            return (<OrignalComponent isAllow={isPermissionAllow} permissionData={currentModalandFiled} {...props} />)
        }
    }
    return NewComponent;
}
export default AppPermissionHOC;