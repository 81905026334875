import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField } from '@mui/material';


const UpdateableField = ({ value }: any) => {
    return (<Box sx={{
        padding: "18px 12px ",
        fontSize: "16px ",
        lineHeight: "19px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        minHeight: "52px"
    }}>{value}</Box>)
}

const UpdateableDropField = ({ value }: any) => {
    return (<Box sx={{
        padding: "18px 12px ",
        fontSize: "16px ",
        lineHeight: "19px",
        borderRadius: "4px",
        minHeight: "52px"
    }}>{value}</Box>)
}

function FormikLayout(props: any) {
    const { user, labelName, type, name, placeholder, form, passwordComponent, dropComponent, permisisonFieldName, permisionData, apiDataMap, singleuserdata } = props;
    const [isFiledDisabled, setFielddisbaled] = useState(false);
    const [isAllowUpdate, setAllowUpdate] = useState<any>(null);
    const [isAllowRead, setAllowRead] = useState<any>(null);
    useEffect(() => {
        if (singleuserdata) {
            if (singleuserdata.hasOwnProperty("active")) {
                if (singleuserdata?.active) {
                    setFielddisbaled(false);
                } else {
                    setFielddisbaled(true);
                }
            }
        }
    }, [singleuserdata])
    useEffect(() => {
        const { permisionData } = props;
        const { user_type } = user.user;
        if (user_type !== 'Supervisor') {
            if (Object.keys(permisionData).length > 0) {
                const { Model, Field } = permisionData;
                if (Object.keys(Model).length > 0) {
                    const ifItExitst = Field.filter((mt: any) => mt.name === permisisonFieldName);
                    // eslint-disable-next-line
                    ifItExitst.map((val: any) => {
                        const { read, update } = val;
                        if (!isAllowUpdate) {
                            setAllowUpdate(update);
                        }
                        if (!isAllowRead) {
                            setAllowRead(read);
                        }
                    })
                }
            }
        } else {
            setAllowUpdate(true);
            setAllowRead(true);
        }
        // eslint-disable-next-line
    }, [permisionData])
    switch (type) {
        case "password":
            return (
                <>
                    {passwordComponent}
                    {form.errors[`${name}`]?.length > 0 && form.touched[`${name}`] && <p className="cm-form-error">{form.errors[`${name}`]}</p>}
                </>)
        case "text":
            return (
                <>
                    <div className="form-lable-name">
                        {labelName} 
                    </div>
                    {
                        isAllowRead ? isAllowUpdate ? (
                            <>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        disabled={isFiledDisabled}
                                        name={name}
                                        placeholder={placeholder}
                                        value={form.values[`${name}`]}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />
                                </FormControl>
                                {form.errors[`${name}`]?.length > 0 && form.touched[`${name}`] && <p className="cm-form-error">{form.errors[`${name}`]}</p>}
                            </>
                        ) : (<UpdateableField value={form.values[`${name}`]} />) : <UpdateableField value={"Hidden"} />
                    }

                </>
            );
        case "multiline":
            return (
                <>
                    <div className="form-lable-name">
                        {labelName}
                    </div>
                    {
                        isAllowRead ? isAllowUpdate ? (
                            <>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        multiline
                                        rows={4}
                                        name={name}
                                        placeholder={placeholder}
                                        value={form.values[`${name}`]}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        disabled={isFiledDisabled}
                                    />
                                </FormControl>
                                {form.errors[`${name}`]?.length > 0 && form.touched[`${name}`] && <p className="cm-form-error">{form.errors[`${name}`]}</p>}
                            </>
                        ) : (<UpdateableField value={form.values[`${name}`]} />) : <UpdateableField value={"----"} />
                    }

                </>
            );
        case "dropdown":
            let defautVal = "";
            if (singleuserdata && singleuserdata[`${name}`] && singleuserdata[`${name}`][`${apiDataMap.labelName}`] !== "") {
                defautVal = singleuserdata[`${name}`][`${apiDataMap.labelName}`];
            }
            return (
                <>
                    {/* <div className="form-lable-name">
                        {labelName}
                    </div> */}
                    {
                        isAllowRead ? isAllowUpdate ? (
                            <>
                                {dropComponent}
                            </>
                        ) : (<><UpdateableDropField value={defautVal} /></>) : <><UpdateableDropField value={"----"} /></>
                    }
                </>
            );
        case "smalldropdown":
            let defautValsmall = "";
            if (singleuserdata && singleuserdata[`${name}`] && singleuserdata[`${name}`][`${apiDataMap.labelName}`] !== "") {
                defautValsmall = singleuserdata[`${name}`][`${apiDataMap.labelName}`];
            }
            return (
                <>
                    <div className="form-lable-name">
                        {labelName}
                    </div>
                    {
                        isAllowRead ? isAllowUpdate ? (
                            <>
                                {dropComponent}
                            </>
                        ) : (<><UpdateableField value={defautValsmall} /></>) : <><UpdateableField value={"----"} /></>
                    }
                </>
            );
        default:
            return null
    }
}

export default FormikLayout;