import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useState } from "react";

const drawerWidth = 345;
interface Props {
  window?: () => Window;
}

const MainLayout = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Topbar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} className="sidebar-wrap">
          <Sidebar container={container} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />
        </Box>
        <Box className="body-wrap" component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                width: `calc(100% - ${drawerWidth}px)`,
                minHeight: "100vh",
                backgroundColor: colorConfigs.mainBg
              }}>
            <Toolbar />
            <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;