import { Avatar, Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Tooltip } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import CancelIcon from '@mui/icons-material/Cancel';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { removeAuthState } from "../../redux/features/authStateSlice";
import { resetOrderSale } from "../../redux/features/orderSaleStateSlice";
import { resetUserState } from "../../redux/features/usersStateSlice";
import { resetTrafficState } from "../../redux/features/trafficStateSlice";
import { resetTaskState } from "../../redux/features/tasksStateSlice";
import { resetSupportState } from "../../redux/features/supportStateSlice";
import { resetSubsriptionState } from "../../redux/features/subscriptionStateSlice";
import { resetSipTrucksState } from "../../redux/features/sipTunksStateSlice";
import { removeorganizationState } from "../../redux/features/organizationStateSlice";
import { resetMobileUserState } from "../../redux/features/mobileUserStateSlice";
import { removeMessageState } from "../../redux/features/messagesStateSlice";
import { resetLicenceState } from "../../redux/features/licenceStateSlice";
import { resetDataConnectionState } from "../../redux/features/dataConnectionStateSlice";
import { resetAppState, setAppCountry, setAppNextRoute, setAppcurrentPageForm } from "../../redux/features/appStateSlice";
import { useEffect, useState } from "react";
import { checkMenuPermission } from "../layout/Helper";
import { RootState } from "../../redux/store";
import { formMessage } from "../../configs/appData";
import Swal from "sweetalert2";
import ReactFlagsSelect from "react-flags-select";
import { Logout } from "@mui/icons-material";
import OrganisationDropDown from "../layout/OrganisationDropDown";


const Sidebar = (props: any) => {
  const { container, mobileOpen, handleDrawerToggle, drawerWidth } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countrySelected, setCountrySelected] = useState("GB");
  const countriesLable = Object(
    {
      GB: { primary: "English" },
      ES: { primary: "Spanish" },
      IT: { primary: "Italian" },
      FI: { primary: "Finnish" },
      SE: { primary: "Swedish" },
    }
  );
  const [userName, setUserName] = useState("");
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorElProfile);
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [cmAppRoutes, setCmAppRoutes] = useState([]);
  const checkUser = useSelector((state: RootState) => state.authState.authState);
  const userDetails = useSelector((state: RootState) => state.authState.userState);
  const appPermission = useSelector((state: RootState) => state.appState.appPermission);
  const { appCountry, appAllTranslate } = useSelector((state: RootState) => state.appState);
  const appCountryList = useSelector((state: RootState) => state.appState.appCountryList);
  const [appCountryState, setAppCountryState] = useState(appCountryList);
  const [appCountryLabels, setAppCountryLabels] = useState(countriesLable);
  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };
  const t = (name: string) => {
    if (iComponetTranslate.length > 0) {
      const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
      if (ifExist.length > 0) {
        return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
      }
      return `No key found (${name})`;
    }
    return `....`;
  }
  const msgSureWantLogout = t(formMessage.Sidebar.SureWantLogout);
  const msgLogout = t(formMessage.Sidebar.Logout);
  const msgCancel = t(formMessage.Global.Cancel);
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };
  const userLogout = () => {
    handleDrawerToggle();
    Swal.fire({
      text: msgSureWantLogout,
      icon: 'warning',
      cancelButtonText: msgCancel,
      showCancelButton: true,
      confirmButtonColor: '#103256',
      cancelButtonColor: '#d33',
      confirmButtonText: msgLogout,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(resetAppState());
        dispatch(resetDataConnectionState());
        dispatch(resetLicenceState());
        dispatch(removeMessageState());
        dispatch(resetMobileUserState());
        dispatch(removeorganizationState());
        dispatch(resetSipTrucksState());
        dispatch(resetSubsriptionState());
        dispatch(resetSupportState());
        dispatch(resetTaskState());
        dispatch(resetTrafficState());
        dispatch(resetUserState());
        dispatch(resetOrderSale());
        dispatch(removeAuthState());
        localStorage.clear();
        navigate("/");
      }
    })
  }
  useEffect(() => {
    if (Object.keys(checkUser).length > 0) {
      const { user_type } = checkUser.user;
      checkMenuPermission(appRoutes, appPermission, user_type, setCmAppRoutes);
    }
    // eslint-disable-next-line
  }, [appPermission, checkUser]);
  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      setUserName(userDetails.first_name?.slice(0, 2));
    }
  // eslint-disable-next-line
  }, [userDetails, checkUser]);
  useEffect(() => {
    if (Object.keys(checkUser).length > 0) {
      if (appCountryList.length > 1) {
        if (appCountryList.length > 1) {
          let countryCode: any = [];
          let countrycodeLable: any = {};
          // eslint-disable-next-line
          appCountryList.map((item: any) => {
            countryCode.push(item.language_code.toUpperCase());
            countrycodeLable[`${item.language_code.toUpperCase()}`] = { primary: item.language_name }
          });

          setAppCountryState(countryCode);
          setAppCountryLabels({ ...countrycodeLable, ...appCountryLabels });
        }
      } 
    }
    // eslint-disable-next-line
  }, [appCountryList, checkUser])
  useEffect(() => {
    const newCode: any = appCountry;
    if (appCountry !== countrySelected) {
      setCountrySelected(newCode);
    }
    // eslint-disable-next-line
  }, [appCountry]);
  useEffect(() => {
    const newCode = appCountry.toLocaleLowerCase();
    const findifExist = appAllTranslate.filter((item: any) => item.name === "Sidebar" && item[`${newCode}`]);
    if (findifExist.length > 0) {
      setICTranslation(findifExist[0][`${newCode}`]);
    } else {
      setICTranslation([]);
    }
  }, [appAllTranslate, appCountry]);
  return (
    <>
      {mobileOpen ? <CancelIcon className="cm-mobile-close" onClick={handleDrawerToggle} /> : ""}
      <Drawer
        className="cm-mobile-sidebar-menu cm-left-scroll-drawer"
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
            height:`calc(100% - 218px)`,
          }
        }}
      >
        <List sx={{
          position:"fixed",
          top:0,
          left:0,
          right:0,
          maxWidth: "345px",
          background: "#012445",
          zIndex:1000
        }}>
        <Toolbar sx={{ marginBottom: "20px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <Box sx={{cursor:"pointer"}} onClick={()=> navigate("/")}><img src={assets.images.logo} width={170} height={39} alt="Setera Clould Communication" /></Box>
            </Stack>
          </Toolbar>
          <Box>
            <OrganisationDropDown cmClass="cm-sidebar-orgdrop-down"/>
          </Box>
        </List>
        <List disablePadding sx={{marginTop:"160px",paddingBottom:"30px"}}>
          {/* <Toolbar sx={{ marginBottom: "20px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <Box sx={{cursor:"pointer"}} onClick={()=> navigate("/")}><img src={assets.images.logo} width={170} height={39} alt="Setera Clould Communication" /></Box>
            </Stack>
          </Toolbar>
          <Box>
            <OrganisationDropDown cmClass="cm-sidebar-orgdrop-down"/>
          </Box> */}
          <Box className="cm-left-scroll">
          {cmAppRoutes.length > 0 && cmAppRoutes.map((route: any, index) => (
            route.sidebarProps ? (
              route.child ? (
                route.isShownChild ? (
                  <SidebarItemCollapse item={route} key={index} hideSidebar={handleDrawerToggle} t={t} translate={iComponetTranslate} />
                ) : route.routeStatus && (
                    <SidebarItem item={route} key={index} hideSidebar={handleDrawerToggle} t={t} translate={iComponetTranslate} />
                )
              ) : route.routeStatus && (
                  <SidebarItem item={route} key={index} hideSidebar={handleDrawerToggle} t={t} translate={iComponetTranslate} />
              )
            ) : null
          ))}
          </Box>
          <Stack direction={"column"} className="cm-left-sidebar-bottom-btns" sx={{background:colorConfigs.sidebar.bg}}>
            <Box sx={{borderTop:"1px solid rgb(255 255 255 / 25%)", maxWidth:`calc(100% - 98px)`, marginLeft:"61px"}}></Box>
            <Box sx={{ padding:"17px 37px 17px 0", marginLeft:"50px", cursor:"pointer"}}>
              <Tooltip title="">
                <Stack direction={"row"}  onClick={() => {
                    handleDrawerToggle();
                    if (Object.keys(userDetails).length > 0) {
                      dispatch(setAppNextRoute(`/users?id=${userDetails.id}`));
                      dispatch(setAppcurrentPageForm("process"));
                    } else {
                      dispatch(setAppNextRoute(`/users`));
                      dispatch(setAppcurrentPageForm("process"));
                    }
                  }} alignItems={"center"} justifyContent={"space-between"}>
                  <IconButton
                    aria-controls={openProfile ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openProfile ? "true" : undefined}
                    sx={{ p: 0, margin: { xs: "0 10px !important" } }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <Avatar sx={{ bgcolor: "#103256" }}>{userName.length > 0 ? userName : ""}</Avatar>
                      <Box sx={{color:"#fff", paddingLeft:"18px", fontSize:"16px"}}>Profile</Box>
                    </Stack>
                  </IconButton>
                  {/* <ArrowDropDownIcon sx={{marginRight:"0px"}}/> */}
                </Stack>
                </Tooltip>
                <Menu
                  anchorEl={anchorElProfile}
                  id="account-menu"
                  open={openProfile}
                  onClose={handleCloseProfile}
                  onClick={handleCloseProfile}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: "200px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem sx={{ marginBottom: "10px" }} onClick={() => {
                    if (Object.keys(userDetails).length > 0) {
                      dispatch(setAppNextRoute(`/users?id=${userDetails.id}`));
                      dispatch(setAppcurrentPageForm("process"));
                    } else {
                      dispatch(setAppNextRoute(`/users`));
                      dispatch(setAppcurrentPageForm("process"));
                    }
                  }}>
                    <Avatar /> {t("Profile")}
                  </MenuItem>
                  {/* <MenuItem sx={{ marginBottom: "10px" }} onClick={() => userLogout()}>
                    <Logout />
                    <div style={{ paddingLeft: "10px" }}>{t("logout")}</div>
                  </MenuItem> */}
                </Menu>
              </Box>
              <Box
                  sx={{
                    padding:"17px 44px 17px 0",
                    marginLeft: "60px",
                    display: { xs: "flex", sm: "flex" },
                  }}
                >
                  <ReactFlagsSelect
                    className="cm-logged-in-fleg-select-left-bar"
                    selected={countrySelected}
                    showSelectedLabel={true}
                    countries={appCountryState}
                    customLabels={appCountryLabels}
                    onSelect={(code) => dispatch(setAppCountry(code))}
                  />
            </Box>
            <ListItemButton
                onClick={() => userLogout()}
                sx={{
                  "&: hover": {
                    backgroundColor: "transparent"
                  },
                  color: colorConfigs.sidebar.colorWT,
                  padding:"17px 37px 17px 21px",
                  fontfamily: "inherite"
                }}
              >
              <ListItemIcon className={`cm-menu-ics cm-icn-y`} sx={{color: colorConfigs.sidebar.color}}>
                  <LogoutIcon sx={{marginLeft:"0px"}}/>
                </ListItemIcon>
                {t('logout')}
              </ListItemButton>
          </Stack>
        </List>
      </Drawer>
      <Drawer
        variant="permanent"
        className="cm-left-scroll-drawer"
        sx={{
          display: { xs: 'none', md: 'block' },
          flexShrink: 0,
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
            height:`calc(100% - 218px)`,
          }
        }}
      >
        <List sx={{
          position:"fixed",
          top:0,
          left:0,
          right:0,
          maxWidth: "345px",
          background: "#012445",
          zIndex:1000
        }}>
        <Toolbar sx={{ marginBottom: "20px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <Box sx={{cursor:"pointer"}} onClick={()=> navigate("/")}><img src={assets.images.logo} width={170} height={39} alt="Setera Clould Communication" /></Box>
            </Stack>
          </Toolbar>
          <Box>
            <OrganisationDropDown cmClass="cm-sidebar-orgdrop-down"/>
          </Box>
        </List>
        <List disablePadding sx={{marginTop:"160px", paddingBottom:"30px"}}>
          <Box className="cm-left-scroll"> 
          {cmAppRoutes.length > 0 && cmAppRoutes.map((route: any, index) => (
            route.sidebarProps ? (
              route.child ? (
                route.isShownChild ? (
                  <SidebarItemCollapse item={route} key={index} hideSidebar={handleDrawerToggle} t={t} translate={iComponetTranslate} />
                ) : route.routeStatus && (
                    <SidebarItem item={route} key={index} hideSidebar={handleDrawerToggle} t={t} translate={iComponetTranslate} />
                )
              ) : route.routeStatus && (
                  <SidebarItem item={route} key={index} hideSidebar={handleDrawerToggle} t={t} translate={iComponetTranslate} />
              )
            ) : null
          ))}
          </Box>
          <Stack direction={"column"} className="cm-left-sidebar-bottom-btns" sx={{background:colorConfigs.sidebar.bg}}>
            <Box sx={{borderTop:"1px solid rgb(255 255 255 / 25%)", maxWidth:`calc(100% - 98px)`, height:'500px',marginLeft:"61px"}}></Box>
             <Box sx={{ padding:"17px 37px 17px 0", marginLeft:"50px" ,cursor:"pointer"}}>
              <Tooltip title="">
                <Stack direction={"row"} onClick={() => {
                    if (Object.keys(userDetails).length > 0) {
                      dispatch(setAppNextRoute(`/users?id=${userDetails.id}`));
                      dispatch(setAppcurrentPageForm("process"));
                    } else {
                      dispatch(setAppNextRoute(`/users`));
                      dispatch(setAppcurrentPageForm("process"));
                    }
                  }} alignItems={"center"} justifyContent={"space-between"}>
                  <IconButton
                    aria-controls={openProfile ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openProfile ? "true" : undefined}
                    sx={{ p: 0, margin: { xs: "0 10px !important" } }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <Avatar variant="square" sx={{ bgcolor: "#103256" ,borderRadius:'10px' }}>{userName.length > 0 ? userName : ""}</Avatar>
                      <Box sx={{color:"#fff", paddingLeft:"26px", fontSize:"16px"}}>Profile</Box>
                    </Stack>
                  </IconButton>
                  {/* <ArrowDropDownIcon sx={{marginRight:"0px"}}/> */}
                </Stack>
                </Tooltip>
                {/* <Menu
                  anchorEl={anchorElProfile}
                  id="account-menu"
                  open={openProfile}
                  onClose={handleCloseProfile}
                  onClick={handleCloseProfile}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: "200px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem sx={{ marginBottom: "10px" }} onClick={() => {
                    if (Object.keys(userDetails).length > 0) {
                      dispatch(setAppNextRoute(`/users?id=${userDetails.id}`));
                      dispatch(setAppcurrentPageForm("process"));
                    } else {
                      dispatch(setAppNextRoute(`/users`));
                      dispatch(setAppcurrentPageForm("process"));
                    }
                  }}>
                    <Avatar /> {t("Profile")}
                  </MenuItem>
                </Menu> */}
              </Box>
              <Box
                  sx={{
                    padding:"17px 44px 17px 0",
                    marginLeft: "60px",
                    display: { xs: "flex", sm: "flex" },
                  }}
                >
                  <ReactFlagsSelect
                    className="cm-logged-in-fleg-select-left-bar"
                    selected={countrySelected}
                    showSelectedLabel={true}
                    countries={appCountryState}
                    customLabels={appCountryLabels}
                    onSelect={(code) => dispatch(setAppCountry(code))}
                  />
            </Box>
            <ListItemButton
              onClick={() => userLogout()}
              sx={{
                "&: hover": {
                  backgroundColor: "transparent"
                },
                color: colorConfigs.sidebar.colorWT,
                padding:"17px 37px 17px 22px",
                fontfamily: "inherite"
              }}
            >
            <ListItemIcon className={`cm-menu-ics cm-icn-y`} sx={{color: colorConfigs.sidebar.color}}>
                <LogoutIcon sx={{marginLeft:"0px"}}/>
              </ListItemIcon>
              {t('logout')}
            </ListItemButton>
          </Stack>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;