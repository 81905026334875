import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import AxiosMain from "../../components/layout/AxiosMain";
import { AppAPiUrl, appModelPermission, appModelType } from "../../configs/appData";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Stack, Box, Grid, FormControl, Select, MenuItem, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import colorConfigs from "../../configs/colorConfigs";
import LoaderScreen from "../../components/layout/LoaderScreen";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import FormikLayout from "../../components/layout/FormikLayout";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
//import { isModalPermission } from "../../components/layout/Helper";
type Props = {
    translateDataft: any;
    permissionData?: any,
    translationStatus: any;
};
function EditUser(props: Props) {
    const { permissionData } = props;
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [isDatafound, setDataFound] = useState(false);
    const [singleUSer, setSingleUser] = useState({});
    const [userRole, setUserRole] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const checkUser = useSelector((state: RootState) => state.authState.authState);
    //const appPermission = useSelector((state: RootState) => state.appState.appPermission);
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const { appCountry } = useSelector((state: RootState) => state.appState);

    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const formSwalTranslation = {
        formSwalTitle: t("AreYouWanttoSave"),
        formSwalText: t("Youwontbeabletorevertthis"),
        fomrSwalCancel: t("Cancel"),
        formSwalSaved: t("YesSavedit"),
        formSwalDataSaved: t("YourDatahasbeensaved"),
        formSwalSubmited: t("Submited"),
        formSwalWrong: t("SomethingWentWrong"),
        form_Delete: t('Delete'),
        form_NoFound: t('NotFound'),
        formSwalWentWrong: t("SomethingWentWrong"),
        formSwalSureDele: t('Areyousureyouwanttodelete'),
    };
    const getUserRole = () => {
        AxiosMain.get(AppAPiUrl.Addrole).then((res) => {
            if (res.status === 200 && res.data.count > 0) {
                if (res.data.results.length > 0) {
                    const userrldata = res.data.results.filter((item: any) => item.name !== "Supervisor");
                    setUserRole(userrldata);
                }
            }
        }).catch((e) => {
            console.log("Error ", e);
        })
    }
    const handleUserDetail = () => {
        if (id) {
            AxiosMain.get(`${AppAPiUrl.Users}${id}/`).then((res) => {
                setTimeout(() => setLoading(false), 100);
                setSingleUser(res.data);
                setDataFound(true);
            }).catch((e) => {
                setTimeout(() => setLoading(false), 100);
                setSingleUser({});
                setDataFound(true);
                if (e.response.status === 404 && e.response.statusText === "Not Found") {
                    console.log("error while getting single user Details", e);
                    Swal.fire({
                        title: formSwalTranslation.form_NoFound,
                        icon: 'warning',
                    })
                } else {
                    Swal.fire({
                        title: formSwalTranslation.formSwalWrong,
                        icon: 'warning',
                    })
                }

            })
        }

    };
    const AddUserSchema = Yup.object().shape({
        email: Yup.string().email().required('Required'),
        first_name: Yup.string().required('Required'),
        role: Yup.string().required('Required'),
        password: Yup.string()
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            mobile: "",
            email: "",
            role: "",
            password: "",
            password2: ""
        },
        validationSchema: AddUserSchema,
        onSubmit: (values: any) => {
            const { Field } = permissionData;
            let apiPermisionValue: any = {};
            if (Field && Field.length > 0) {
                // eslint-disable-next-line
                Field.map((item: any) => {
                    if (values[`${item.name}`] && item.update) {
                        apiPermisionValue[`${item.name}`] = values[`${item.name}`]
                    }
                })
            }
            if (Object.keys(apiPermisionValue).length > 0) {
                AxiosMain.patch(`${AppAPiUrl.Users}${id}/`, apiPermisionValue).then((res) => {
                    //console.log("User Updated ", res);
                    if (res.status === 200) {
                        Swal.fire({
                            title: formSwalTranslation.formSwalSubmited,
                            text: formSwalTranslation.formSwalDataSaved,
                            icon: "success"
                        });

                        //formik.resetForm();
                    }
                }).catch((e) => {
                    const { response } = e;
                    console.log("response ", response);
                    if (response.status === 500) {
                        Swal.fire({
                            title: "Error",
                            text: formSwalTranslation.formSwalWrong,
                            icon: "error"
                        });
                    }
                    else if (response.status === 400) {
                        const { email } = response.data;
                        if (email.length > 0) {
                            Swal.fire({
                                title: "Error",
                                text: email[0],
                                icon: "error"
                            });
                        }
                    }
                })
            } else {
                Swal.fire({
                    title: "Error",
                    text: "No Data Allowed",
                    icon: "error"
                });
            }

        },
    });
    // const deleteUser = (row: any) => {
    //     const isAllowUpdate: any = isModalPermission(checkUser, appPermission, appModelPermission.SETERAUSER, appModelType.DESTROY);
    //     if (isAllowUpdate) {
    //         if (Object.keys(checkUser).length > 0 && Object.keys(row).length > 0) {
    //             if (checkUser.user.user_id === row.id) {
    //                 Swal.fire({
    //                     title: "Warning",
    //                     text: "Unable to delet Current User!",
    //                     icon: "warning"
    //                 });
    //             } else {
    //                 Swal.fire({
    //                     text: `${formSwalTranslation.formSwalSureDele} ${row.name} ${t('user?')}`,
    //                     icon: 'warning',
    //                     cancelButtonText: formSwalTranslation.fomrSwalCancel,
    //                     showCancelButton: true,
    //                     confirmButtonColor: '#103256',
    //                     cancelButtonColor: '#d33',
    //                     confirmButtonText: formSwalTranslation.form_Delete,
    //                 }).then((result: any) => {
    //                     if (result.isConfirmed) {
    //                         AxiosMain.delete(`${AppAPiUrl.Adduser}${row.id}/`).then((res) => {
    //                             console.log("User Delete Response ", res);
    //                             if (res.status === 204 && res.data === '') {
    //                                 Swal.fire({ title: formSwalTranslation.form_Delete, icon: "success" });
    //                             }
    //                         }).catch((e) => {
    //                             Swal.fire({
    //                                 title: formSwalTranslation.formSwalWentWrong,
    //                                 icon: "error"
    //                             });
    //                         })
    //                     }
    //                 })
    //             }
    //         } else {
    //             Swal.fire({
    //                 title: formSwalTranslation.formSwalWentWrong,
    //                 icon: "error"
    //             });
    //         }
    //     } else {
    //         Swal.fire({
    //             title: "Error",
    //             text: "Not Allowed",
    //             icon: "error"
    //         });
    //     }


    // }
    useEffect(() => {
        if (Object.keys(singleUSer).length > 0 && userRole.length > 0) {
            const { first_name, email, role, last_name, mobile }: any = singleUSer;
            if (first_name !== '') {
                formik.setFieldValue("first_name", first_name)
            }
            if (mobile !== '') {
                formik.setFieldValue("mobile", mobile)
            }
            if (last_name !== '') {
                formik.setFieldValue("last_name", last_name)
            }
            if (email !== '') {
                formik.setFieldValue("email", email);
            }
            if (role !== '') {
                formik.setFieldValue("role", role);
            }

        } else if (userRole.length === 0 && Object.keys(singleUSer).length > 0 && iComponetTranslate.length > 0) {
            getUserRole();
        }
        else {
            if (!isDatafound && iComponetTranslate.length > 0) {
                handleUserDetail();
            }
        }
        // eslint-disable-next-line
    }, [singleUSer, userRole, iComponetTranslate])
    // useEffect(() => {
    //     if (userRole.length === 0) {
    //         getUserRole();
    //     }
    //     // eslint-disable-next-line
    // }, [userRole]);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    return (
        <>
            <div className="cm-user-tabs-wrapper  cm-global-tabs-wrapper">
                <div className="cm-user-name-wrap">
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent={"center"}>
                        <Box className="fw-600" sx={{ color: "#012445", fontSize: "40px;", lineHeight: "normal" }}>{t("EditUser")}</Box>
                    </Stack>
                </div>
                {
                    isTransLoaded ? (
                        <div className="cm-user-form-wrap">
                    {
                                isLoading ? <LoaderScreen /> : (
                                    <Box sx={{ width: "100%" }}>
                            <Box className="cm-global-tabs-content">
                                <form onSubmit={formik.handleSubmit}>
                                    <Box className="cm-global-tab-inner-content">
                                        <Grid container spacing={5}>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Box className="cm-form-inner-fields">
                                                    <Box className="form-mb-30">
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        <FormikLayout
                                                                            user={checkUser}
                                                                            singleuserdata={singleUSer}
                                                                            name="first_name"
                                                                            labelName={t("FirstName")}
                                                                            type="text"
                                                                            permisisonFieldName={"first_name"}
                                                                            permisionData={permissionData}
                                                                            form={formik}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        <FormikLayout
                                                                            user={checkUser}
                                                                            singleuserdata={singleUSer}
                                                                            name="last_name"
                                                                            labelName={t("LastName")}
                                                                            type="text"
                                                                            permisisonFieldName={"last_name"}
                                                                            permisionData={permissionData}
                                                                            form={formik}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        <FormikLayout
                                                                            user={checkUser}
                                                                            singleuserdata={singleUSer}
                                                                            name="email"
                                                                            labelName={t("email")}
                                                                            type="text"
                                                                            permisisonFieldName={"email"}
                                                                            permisionData={permissionData}
                                                                            form={formik}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        <FormikLayout
                                                                            user={checkUser}
                                                                            singleuserdata={singleUSer}
                                                                            name="mobile"
                                                                            labelName={t("MobileNumber")}
                                                                            type="text"
                                                                            permisisonFieldName={"mobile"}
                                                                            permisionData={permissionData}
                                                                            form={formik}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        <FormikLayout
                                                                            user={checkUser}
                                                                            singleuserdata={singleUSer}
                                                                                        name="password"
                                                                                        labelName={t("Password")}
                                                                                        type="password"
                                                                                        permisisonFieldName={"password"}
                                                                                        permisionData={permissionData}
                                                                                        form={formik}
                                                                                        passwordComponent={
                                                                                            <Box sx={{ width: "100%" }}>
                                                                                                <div className="form-lable-name">
                                                                                                    {t("password")}
                                                                                                </div>
                                                                                                <FormControl sx={{ width: "100%" }}>
                                                                                                    <TextField
                                                                                                        className="cm-login-filed"
                                                                                                        type={showPassword ? "text" : "password"}
                                                                                                        variant="outlined"
                                                                                                        name="password"
                                                                                                        value={formik.values.password}
                                                                                                        onChange={formik.handleChange}
                                                                                                        InputProps={{
                                                                                                            endAdornment: (
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        aria-label="toggle password visibility"
                                                                                                                        onClick={handleClickShowPassword}
                                                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                                                        edge="end"
                                                                                                                    >
                                                                                                                        {showPassword ? (
                                                                                                                            <LockOpenOutlinedIcon />
                                                                                                                        ) : (
                                                                                                                            <LockOutlinedIcon />
                                                                                                                        )}
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            ),
                                                                                                        }}
                                                                                                    />
                                                                                                </FormControl>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Box sx={{ width: "100%" }}>
                                                                                    <FormikLayout
                                                                                        user={checkUser}
                                                                                        singleuserdata={singleUSer}
                                                                                        name="password2"
                                                                                        labelName={t("ConfirmPassword")}
                                                                                        type="password"
                                                                                        permisisonFieldName={"password"}
                                                                                        permisionData={permissionData}
                                                                                        form={formik}
                                                                                        passwordComponent={
                                                                                            <Box sx={{ width: "100%" }}>
                                                                                                <div className="form-lable-name">
                                                                                                    {t("Confirm password")}
                                                                                                </div>
                                                                                                <FormControl sx={{ width: "100%" }}>
                                                                                                    <TextField
                                                                                                        className="cm-login-filed"
                                                                                                        type={showConfirmPassword ? "text" : "password"}
                                                                                                        variant="outlined"
                                                                                                        name="password2"
                                                                                                        value={formik.values.password2}
                                                                                                        onChange={formik.handleChange}
                                                                                                        InputProps={{
                                                                                                            endAdornment: (
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        aria-label="toggle password visibility"
                                                                                                                        onClick={handleClickShowConfirmPassword}
                                                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                                                        edge="end"
                                                                                                                    >
                                                                                                                        {showConfirmPassword ? (
                                                                                                                            <LockOpenOutlinedIcon />
                                                                                                                        ) : (
                                                                                                                            <LockOutlinedIcon />
                                                                                                                        )}
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            ),
                                                                                                        }}
                                                                                                    />
                                                                                                </FormControl>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Box sx={{ width: "100%" }}>
                                                                                    <FormikLayout
                                                                                        user={checkUser}
                                                                                        singleuserdata={singleUSer}
                                                                            name="role"
                                                                            labelName={t("Role")}
                                                                            type="smalldropdown"
                                                                            permisisonFieldName={"role"}
                                                                            permisionData={permissionData}
                                                                            apiDataMap={{ labelName: "name", valueName: "id" }}
                                                                            form={formik}
                                                                            dropComponent={
                                                                                <>
                                                                                    <FormControl sx={{ width: "100%" }} disabled>
                                                                                        <Select
                                                                                            className="form-select-pad"
                                                                                            id="role"
                                                                                            name="role"
                                                                                            label=""
                                                                                            value={formik.values.role}
                                                                                            onChange={formik.handleChange}>
                                                                                            <MenuItem value="">Choose Option</MenuItem>
                                                                                            {userRole.length > 0 && (
                                                                                                userRole.map((val: any) => (<MenuItem key={`user-carrier-data--${val.id}`} value={val.id}>{val.name}</MenuItem>))
                                                                                            )}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                    {formik.errors.role && formik.touched.role && <p className="cm-form-error">{`${formik.errors.role}`}</p>}
                                                                                </>
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ width: "100%" }}>
                                                                        {/* Button save changes and Discard */}
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={2}
                                                                            alignItems="center"
                                                                            justifyContent={"space-between"}
                                                                            className="form-submit-cancel-btn-wrapper">
                                                                            <Box sx={{ width: "100%" }}>
                                                                                <Stack
                                                                                    direction="row"
                                                                                    spacing={2}
                                                                                    alignItems="center"
                                                                                    justifyContent={"start"}
                                                                                    className="form-submit-cancel-btn-wrapper">
                                                                                    <Button
                                                                                        type="submit"
                                                                                        variant="contained"
                                                                                        endIcon={<CheckCircleIcon />}
                                                                                        className="cm-btn-style"
                                                                                        sx={{
                                                                                            width: "100%",
                                                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                                                            background: colorConfigs.btnColor.bgColorGreen,
                                                                                            "&: hover": {
                                                                                                color: colorConfigs.btnColor.bgColorGreen,
                                                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {t("saveChanges")}
                                                                                    </Button>
                                                                                    {/* <Button
                                                        variant="contained"
                                                        endIcon={<CancelIcon />}
                                                        className="cm-btn-style"
                                                        sx={{
                                                            color: colorConfigs.btnColor.hoverbgWhite,
                                                            background: colorConfigs.btnColor.bgColorRed,
                                                            "&: hover": {
                                                                color: colorConfigs.btnColor.bgColorRed,
                                                                background: colorConfigs.btnColor.hoverbgWhite,
                                                            },
                                                        }}
                                                        onClick={() => navigate("/users")}
                                                    >
                                                        {t("discard")}
                                                    </Button> */}
                                                </Stack>
                                            </Box>
                                                                            {/* <Button
                                                variant="contained"
                                                endIcon={<CancelIcon />}
                                                className="cm-btn-style justify-self-end"
                                                sx={{
                                                    color: colorConfigs.btnColor.hoverbgWhite,
                                                    background: colorConfigs.btnColor.bgColorRed,
                                                    "&: hover": {
                                                        color: colorConfigs.btnColor.bgColorRed,
                                                        background: colorConfigs.btnColor.hoverbgWhite,
                                                    },
                                                }}
                                                onClick={() => deleteUser(singleUSer)}
                                            >
                                                {t("Delete")}
                                            </Button> */}
                                        </Stack>
                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </form>
                            </Box>
                        </Box>)
                    }

                </div>
                    ) : (<LoaderScreen />)
                }

            </div>
        </>
    );
}
function EditUserComponentReady(props: any) {
    const { isAllow } = props;
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(EditUser, "users", isAllow, props, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(EditUserComponentReady, appModelPermission.SETERAUSER, appModelType.UPDATE);