import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Chip, Popover, Dialog, DialogContent, DialogContentText } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Swal from "sweetalert2";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import { AppAPiUrl, appFieldTypeName, appModelPermission, appModelType } from "../../configs/appData";
import AxiosMain from "../../components/layout/AxiosMain";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import { isFieldPermission } from "../../components/layout/Helper";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import LoaderScreen from "../../components/layout/LoaderScreen";
function TranslationUI(props: any) {
    const navigate = useNavigate();
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [currentCountryId, setcountryID] = useState(5);
    const [updatedTranslated, setUpdateTranslate] = useState([]);
    const [appCountryFilter, setAppCountryFilter] = useState([]);
    const [appTranslationFeatures, setAppTranslateFeatures] = useState([]);
    const [appTFeaturesStatus, setApptFeaturesStatus] = useState<any>(null);
    const { appCountryList, appCountry } = useSelector((state: RootState) => state.appState);
    const checkUser = useSelector((state: RootState) => state.authState.authState);
    const appPermission = useSelector((state: RootState) => state.appState.appPermission);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const checkIfUpdated = (row: any) => {
        if (updatedTranslated.length > 0) {
            const ifExist = updatedTranslated.filter((item: any) => item.id === row.id);
            console.log("ifExist", ifExist)
        }
        return (row.value);
    }
    const translateRowData = [
        {
            accessorFn: (row: any) => row.label,
            header: t("name"),
        },
        {
            accessorFn: (row: any) => checkIfUpdated(row),
            header: t("Translations"),
            enableSorting: false,
        },
        {
            accessorFn: (row: any) => {
                const langId = row.language;
                const currentLang = appCountryList.filter((item: any) => item.id === langId);
                if (currentLang.length > 0) {
                    return (<>{currentLang[0].language_name}</>)
                }
                return (<>..</>)
            },
            filterVariant: "select",
            filterSelectOptions: appCountryFilter,
            header: t("language"),
            enableSorting: false,
        },
        {
            accessorFn: (row: any) => {
                const { feature } = row;
                return (
                    <Box>
                        <Stack spacing={2}>
                            {
                                feature.map((item: any, index: number) => {
                                    return (<div key={`translate-st-features-${index}`}>{index + 1} :- {item.feature_name}</div>);
                                })
                            }
                        </Stack>
                        {/* <Button variant="contained" onClick={(e) => {
                            let htmldd = "";
                            // eslint-disable-next-line array-callback-return
                            feature.map((item: any) => {
                                htmldd += `<div style="">${item.feature_name}</div>`;
                            })
                            Swal.fire({
                                title: "<strong>Features</strong>",
                                icon: "info",
                                html: htmldd,
                            });

                        }}>
                            Show Features {feature.length}
                        </Button> */}
                    </Box>
                );
            },
            filterVariant: "select",
            filterSelectOptions: appTranslationFeatures,
            enableSorting: false,
            header: t("Feature"),
        },
        {
            accessorFn: (row: any) => {
                //const isAllowUpdate: any = isFieldPermission(checkUser, appPermission, appModelPermission.TRANSLATION, appFieldTypeName.VALUE, appModelType.UPDATE);
                return (
                    <Button variant="contained" onClick={() => updateFiledDat(row)}>{t("Change")}</Button >
                )
            },
            header: t("Action"),
            filterVariant: 'text',
            enableSorting: false,
            enableFilters: false,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false
        },
    ]
    const translateSorting = [
        {
            descSort: "-label",
            ascSort: "label",
            id: t("name"),
        },
        {
            descSort: "-value",
            ascSort: "value",
            id: t("Translations"),
        },
        {
            descSort: "-language",
            ascSort: "language",
            id: t("language"),
        }
    ]
    const initialSortingData = [
        {
            desc: false,
            id: t("name"),
        },
    ]
    const translateSearching = [
        {
            match: "label",
            id: t("name"),
        },
        {
            match: "value",
            id: t("Translations"),
        },
        {
            match: "language",
            id: t("language"),
        },
        {
            match: "feature",
            id: t("Feature"),
        },

    ]
    // const initialFilterData = [
    //     {
    //         id: t("language"),
    //         value: currentCountryId
    //     }
    // ]
    const updateEditJSonDate = (data: any) => {
        //console.log(data, AppAPiUrl.TranslationUpdate);

        AxiosMain.patch(`${AppAPiUrl.TranslationUpdate}${data.id}/`, {
            "value": data.value
        }).then((res) => {
            //console.log("RES", res);
            if (res.status === 204) {
                let combine: any = [...updatedTranslated, data]
                setUpdateTranslate(combine)
                Swal.fire({
                    title: `Updated`,
                    icon: "success"
                })
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Error", response)
            Swal.fire({
                text: `Error!`,
                icon: "error"
            })
        })
    }
    const updateFiledDat = async (row: any) => {
        const isAllowRead: any = isFieldPermission(checkUser, appPermission, appModelPermission.TRANSLATION, appFieldTypeName.VALUE, appModelType.READ);
        const isAllowUpdate: any = isFieldPermission(checkUser, appPermission, appModelPermission.TRANSLATION, appFieldTypeName.VALUE, appModelType.UPDATE);
        let formInner = "";
        console.log("isAllowRead", isAllowRead, isAllowUpdate);
        const HTMLUIUpdate = '<div><label>Key</label><input id="first-input3" type="hidden" name="id" class="swal2-input" value="' + row.id + '" disabled/><input style="border:none;box-shadow=none" id="first-input1" name="key" class="swal2-input" value="' + row.label + '" disabled/></div>' +
            '<div><label>Value</label><input id="second-input1" name="value" class="swal2-input" value="' + row.value + '"/></div>';
        const HTMLUIURead = '<div><div><label>Key</label><span> ' + row.label + '<span></div><div><label>Value</label><span> ' + row.value + ' <span></div><div>';
        if (isAllowRead && isAllowUpdate) {
            formInner = HTMLUIUpdate;
        } else if (isAllowRead && !isAllowUpdate) {
            formInner = HTMLUIURead;
        }
        if (!isAllowRead && !isAllowUpdate) {
            Swal.fire({
                title: "Error",
                text: "Not Allowed",
                icon: "error"
            });
        } else {
            const { value: formValues } = await Swal.fire({
                title: 'Update',
                html: formInner,
                focusConfirm: false,
                preConfirm: () => {
                    const first = document.getElementById('first-input1');
                    const second = document.getElementById('second-input1');
                    const third = document.getElementById('first-input3');
                    return [first, second, third]
                }
            })
            if (formValues && formValues.length > 0) {
                let outObj: any = {};
                // eslint-disable-next-line
                formValues.map((item: any) => {
                    if (item.name === "key") {
                        outObj["key"] = item.value
                    } else if (item.name === "id") {
                        outObj["id"] = item.value
                    } else {
                        outObj["value"] = item.value
                    }
                })
                updateEditJSonDate(outObj);
            }
        }

    }
    useEffect(() => {
        if (appCountryList.length > 1 && appCountry !== '') {
            const checkif = appCountryList.filter((item: any) => item.language_code === appCountry.toLowerCase());
            if (checkif.length > 0) {
                if (currentCountryId !== checkif[0].id) {
                    setcountryID(checkif[0].id)
                }
            }
        }
        // eslint-disable-next-line
    }, [appCountryList, appCountry]);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 1000);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    useEffect(() => {
        if (appCountryFilter.length === 0) {
            const newCountry = appCountryList.map((item: any) => {
                const out = { "text": item.language_name, "value": item.id };
                return out
            });
            setAppCountryFilter(newCountry);
        }
    }, [appCountryFilter, appCountryList])
    useEffect(() => {
        if (appTFeaturesStatus === null) {
            AxiosMain.get("/translate/feature/?page_size=100").then((res) => {
                if (res.status === 200 && res.data.count > 0) {
                    const newFeatures = res.data.results.map((item: any) => {
                        const out = { "text": item.feature_name, "value": item.id };
                        return out
                    });
                    setAppTranslateFeatures(newFeatures);
                    setApptFeaturesStatus(true);
                }
                else {
                    setApptFeaturesStatus(false);
                    enqueueSnackbar(`Not Found`, { preventDuplicate: true, variant: "error", });
                }
            }).catch((e) => {
                console.log("Error While getting Features", e);
                setApptFeaturesStatus(false);
                enqueueSnackbar(`Translation Featured Data Error`, { preventDuplicate: true, variant: "error", });
            })
        }
    }, [appTFeaturesStatus]);
    return (
        <>
            <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={"start"}>
                    <Button variant="contained" startIcon={<ArrowBackIosIcon />}
                        className="cm-btn-sm-style"
                        sx={{
                            color: colorConfigs.btnColor.hoverbgWhite,
                            background: colorConfigs.btnColor.bgColorRed,
                            "&: hover": {
                                color: colorConfigs.btnColor.bgColorRed,
                                background: colorConfigs.btnColor.hoverbgWhite,
                            }
                        }} onClick={() => navigate("/admin")}>{t('Back')}</Button>
                </Stack>
            </Box>
        <div className="cm-mobile-user-Data-main" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <div className="cm-mobile-user-Data-table">
                    {isTransLoaded
                            ? (<><MaterialReactDataTable
                                useFor={"Translation List"}
                                tRow={translateRowData}
                                apiLink={AppAPiUrl.TranslateAll}
                                initialSortData={initialSortingData}
                                searchData={translateSearching}
                                sortingData={translateSorting}
                            //initialFilterData={initialFilterData}
                                initialStatus={true}
                            /></>)
                        : <LoaderScreen />
                    }
            </div>
        </div>
        </>
    )
}
function TranslationUIComponentReady({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(TranslationUI, "admin_translation", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(TranslationUIComponentReady, appModelPermission.TRANSLATION, appModelType.READ);

function enqueueSnackbar(arg0: string, arg1: { preventDuplicate: boolean; variant: string; }) {
    throw new Error("Function not implemented.");
}
