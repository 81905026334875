import React, { useState, useEffect } from "react";
import {
    Box, Button, FormControlLabel, Checkbox, TableContainer,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Stack
} from "@mui/material";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AxiosMain from "../../components/layout/AxiosMain";
import Swal from "sweetalert2";
import colorConfigs from "../../configs/colorConfigs";
import { AppAPiUrl, appPermisionName } from "../../configs/appData";
import { useNavigate } from "react-router-dom";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import LoaderScreen from "../../components/layout/LoaderScreen";
import * as changeCase from "change-case";

type Props = {
    translateDataft: any;
    translationStatus: any;
};
function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function CustomTableRow({ row, handleChangeModal, handleChangeField, data }: any) {
    const [openTableCollapse, setOpenTableCollapse] = useState(false);
    const { Model, Field } = row;
    const { md, fl } = data;
    const PermissionField = ({ row, fieldName, isLast }: any) => {
        let checkBoxstatus = false;
        const newPermision = fl.filter((item: any) => item.id === row.id);
        if (newPermision.length > 0) {
            // eslint-disable-next-line
            newPermision.map((val: any) => {
                checkBoxstatus = val[`${fieldName}`];
            })
        }
        return (
            <TableCell key={`table-user-role-cell-fields-${fieldName}-${row.id}`} width={`14.5%`} sx={isLast ? { borderBottom: "0" } : {}}>
                <div className="d-flex text-right">
                    <FormControlLabel sx={{ margin: "0" }} control={<Checkbox sx={{ paddingRight: "0px" }} checked={checkBoxstatus} name={row.name} onChange={(e) => handleChangeField(e, row, fieldName)} />} label="" />
                </div>
            </TableCell>
        )
    }
    const PermissionModal = ({ row, fieldName }: any) => {
        let checkBoxstatus = false;
        const newPermision = md.filter((item: any) => item.id === row.id);
        if (newPermision.length > 0) {
            // eslint-disable-next-line
            newPermision.map((val: any) => {
                checkBoxstatus = val[`${fieldName}`];
            })
        }
        return (
            <TableCell key={`table-user-role-cell-${fieldName}-${row.id}`} width={`15%`} >
                <div className="d-flex text-right">
                    <FormControlLabel sx={{ margin: "0" }} control={<Checkbox sx={{ padding: "0px" }} checked={checkBoxstatus} name={row.name} onChange={(e) => handleChangeModal(e, row, fieldName)} />} label="" />
                </div>
            </TableCell>
        )
    }
    return (
        <React.Fragment>
            <TableRow>
                {
                    Field.length > 0 ? (
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpenTableCollapse(!openTableCollapse)}>
                                {openTableCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    ) : (<TableCell></TableCell>)
                }
                <TableCell component="th" scope="row" >
                    {changeCase.capitalCase(Model.name)}
                </TableCell>
                <PermissionModal row={Model} fieldName="read" />
                <PermissionModal row={Model} fieldName="update" />
                <PermissionModal row={Model} fieldName="create" />
                <PermissionModal row={Model} fieldName="destroy" />
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: "0" }} colSpan={6} sx={!openTableCollapse ? { display: "none" } : { display: "table-cell" }}>
                    <Collapse in={openTableCollapse} timeout="auto" unmountOnExit>
                        <Box sx={{ marginY: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {Field.length > 0 && Field.map((item: any, key2: any) => {
                                        const isLast = Field.length - 1 === key2 ? true : false;
                                        return (
                                            <TableRow key={`inner-user-tabl-${key2}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                <TableCell width={`66px`} sx={isLast ? { borderBottom: "0" } : {}}></TableCell>
                                                <TableCell width={`35%`} sx={isLast ? { borderBottom: "0" } : {}}>{changeCase.capitalCase(item.name)}</TableCell>
                                                <PermissionField row={item} fieldName="read" isLast={isLast} />
                                                <PermissionField row={item} fieldName="update" isLast={isLast} />
                                                <TableCell sx={isLast ? { borderBottom: "0" } : {}}></TableCell>
                                                <TableCell sx={isLast ? { borderBottom: "0" } : {}}></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >)
}
function UsersRolesListComponent(props: Props) {
    const params = useParams();
    const navigate = useNavigate();
    const [isEditRole, setEditrole] = useState(false);
    const [modalPermissions, setModalPermission] = useState([])
    const [fieldsPermissionm, setFieldPermission] = useState([]);
    const [userPermissionsData, setUserPermission] = useState([]);
    const [roleGrouping, setRoleGrouping] = useState<any>([]);
    const [valueTab, setValueTab] = React.useState("");
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const handleTabChange = (newValue: any) => {
        setValueTab(newValue);
    };
    const addinExisting = (fieldName: any, status: any, row: any, arrayofData: any, arrayFunc: any) => {
        if (arrayofData.length > 0) {
            let objData = arrayofData.map((item: any) => {
                if (item.id === row.id) {
                    let output = { ...item };
                    output[`${fieldName}`] = status;
                    return output
                }
                return item;
            })
            arrayFunc(objData);
        }
        else {
            let newAdd: any = [row];
            arrayFunc(newAdd);
            console.log("First Adding");
        }
    }
    const handleChangeModal = (event: React.ChangeEvent<HTMLInputElement>, val: any, fieldName: any) => {
        let checkboxstatus = event.target.checked
        let outout: any = {};
        outout[`${fieldName}`] = checkboxstatus
        AxiosMain.patch(`${AppAPiUrl.PermissionsUpdateModel}${val.id}/`, outout).then((res) => {
            if (res.status === 204) {
                addinExisting(fieldName, checkboxstatus, val, modalPermissions, setModalPermission);
                Swal.fire({
                    title: "Updated",
                    text: "",
                    icon: "success"
                });
            }
        }).catch((e) => console.log(e))
    };
    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>, val: any, fieldName: any) => {
        let checkboxstatus = event.target.checked
        let outout: any = {};
        outout[`${fieldName}`] = checkboxstatus
        AxiosMain.patch(`${AppAPiUrl.PermissionsUpdateField}${val.id}/`, outout).then((res) => {
            if (res.status === 204) {
                addinExisting(fieldName, checkboxstatus, val, fieldsPermissionm, setFieldPermission);
                Swal.fire({
                    title: "Updated",
                    text: "",
                    icon: "success"
                });
            }
        }).catch((e) => console.log(e))
    };
    const getUserPermissions = (id: any) => {
        AxiosMain.get(`${AppAPiUrl.PermissionsRole}${id}/`).then((res) => {
            console.log("Useer Permission loaded");
            //console.log(res);
            setUserPermission(res.data);
            console.log(res.data,"data")
        }).catch((e) => {
            console.log("Error while user permission", e);
        })
    }
    useEffect(() => {
        if (Object.keys(params).length > 0) {
            if (params.hasOwnProperty('role')) {
                setEditrole(true);
                console.log("Current suer role", params.role);
                getUserPermissions(params.role);
            } else {
                setEditrole(false);
            }
        } else {
            setEditrole(false);
        }
    }, [params])
    const userRoleRow = [
        {
            enableSorting: false,
            enableFilters: false,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            accessorKey: 'name',
            header: t('name'),
            accessorFn: (row: any) => row.name
        },
        {
            enableSorting: false,
            enableFilters: false,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
            header: t('Action'),
            muiTableHeadCellProps: {
                align: 'right',
                sx: { paddingRight: "100px" }
            },
            muiTableBodyCellProps: {
                align: 'right',
                sx: { paddingRight: "100px" }
            },

            accessorFn: (row: any) => <Button onClick={() => navigate(`/admin/users-roles/edit/${row.id}`)}><EditIcon /></Button>
        },
    ]
    useEffect(() => {
        if (userPermissionsData.length > 0) {
            let modelData: any = [];
            let fieldData: any = [];
            let gouping: any = [];
            // eslint-disable-next-line
            userPermissionsData.map((item: any) => {
                const { Field, Model } = item;
                const groupName = Model.group;
                if (gouping.indexOf(groupName) === -1 && groupName !== "other") {
                    gouping.push(Model.group);
                } 
                modelData.push(Model);
                if (Field.length > 0) {
                    fieldData.push(...Field);
                }
            })
            if (roleGrouping.length === 0) {
                gouping.sort();
                setValueTab(gouping[0])
                setRoleGrouping(gouping);
            }
            setModalPermission(modelData);
            setFieldPermission(fieldData);
        }
        // eslint-disable-next-line
    }, [userPermissionsData]);

    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    return (
        <>
            {isTransLoaded ? (
                <div className="cm-mobile-user-Data-main" style={{ marginTop: "20px", marginBottom: "20px" }} >
            <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={"space-between"}>
                    <Button variant="contained" startIcon={<ArrowBackIosIcon />}
                        className="cm-btn-sm-style"
                        sx={{
                            color: colorConfigs.btnColor.hoverbgWhite,
                            background: colorConfigs.btnColor.bgColorRed,
                            "&: hover": {
                                color: colorConfigs.btnColor.bgColorRed,
                                background: colorConfigs.btnColor.hoverbgWhite,
                            }
                        }} onClick={() => !isEditRole ? navigate("/admin") : navigate("/admin/users-roles")}>{t('Back')}</Button>
                    {
                        !isEditRole && (
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                className="cm-btn-style"
                                onClick={() => console.log("Add new role")}
                                sx={{
                                    color: colorConfigs.btnColor.hoverbgWhite,
                                    background: colorConfigs.btnColor.bgColorGreen,
                                    "&: hover": {
                                        color: colorConfigs.btnColor.bgColorGreen,
                                        background: colorConfigs.btnColor.hoverbgWhite,
                                    },
                                }}>Add New Role</Button>
                        )
                    }
                </Stack>
            </Box>
                    <div className="cm-mobile-user-Data-table">
                {
                    !isEditRole
                        ? (
                            <MaterialReactDataTable
                                useFor={"User Role List"}
                                apiLink={AppAPiUrl.Usergroup}
                                tRow={userRoleRow} />
                        ) : (
                            <Box sx={{
                                display: "flex",
                                background: "#fff",
                                borderRadius: "10px",
                                marginTop: "20px",
                                padding: "35px"
                            }}>

                                        <Box sx={{ width: "100%" }}>
                                    <Stack direction="row" spacing={2} className="cm-userrole-tabs-btn-wrap" sx={{ marginBottom: "15px", marginTop: "15px" }}>
                                                {roleGrouping.length > 0 && roleGrouping.map((item: any, index: any) => {
                                                return (<Button key={`role-groping-btn-${item}-${index}`} variant="outlined" {...a11yProps(`${item}-${index}`)} onClick={() => handleTabChange(item)} className={`user-role-btn-outline ${valueTab === item && "active-btn"}`}>{item ==="commissions"? "Wholesale":item}</Button>)
                                                })}
                                    </Stack>
                                    <Box sx={{ width: "100%" }}>
                                        <TableContainer>
                                            <Table aria-label="collapsible table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={`5%`}></TableCell>
                                                                <TableCell width={`35%`}>{t("name")}</TableCell>
                                                                <TableCell align="right" width={`15%`}>{t("View")}</TableCell>
                                                                <TableCell align="right" width={`15%`}>{t("Edit")}</TableCell>
                                                                <TableCell align="right" width={`15%`}>{t("Add")}</TableCell>
                                                                <TableCell align="right" width={`15%`}>{t("Remove")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {userPermissionsData.length > 0 && userPermissionsData.map((item: any, key): any => {
                                                        const { Model } = item;
                                                        if (Model.group === valueTab) {
                                                            return (
                                                                <CustomTableRow key={`userrole-main-wrap-${key}`} row={item} data={{ md: modalPermissions, fl: fieldsPermissionm }} handleChangeModal={handleChangeModal} handleChangeField={handleChangeField} />
                                                            )
                                                        }
                                                        return (<React.Fragment key={`userrole-main-wrap-${key}`}></React.Fragment>)
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                            </Box>
                                </Box>
                            </Box>)
                        }
                    </div>
                </div >
            ) : (<LoaderScreen />)
            }

        </>
    );
}
function UsersRolesList({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(UsersRolesListComponent, "admin_userrole", isAllow, {}, dependency);
    return <MainComponent />
}
export default AppPermissionHOC(UsersRolesList, appPermisionName.CanViewUserRoles);