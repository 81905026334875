import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AxiosMain from "./AxiosMain";
import { Pagination } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch } from "react-redux";
import {
    MaterialReactTable,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
} from 'material-react-table';
import UserTableStyle from "../TableStyles/UserTableStyle";
import { enqueueSnackbar } from "notistack";
import { setAppNextRoute } from "../../redux/features/appStateSlice";
function MaterialReactDataTable(props: any) {
    const { cmPageSize, paramDependent,parentDependValue, setTableDataShow, isallData, clickFuncDataShow, useFor, initialFilterData, initialSortData, clickFunc, sortingData, tableStyle, apiLink, tRow, searchData ,triggerUpdate, resetTrigger,callbackFunction} = props;
    const [paramCount, setParamcount] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0);
    const [currentPostsCount, setCurrentPostsCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableDataArray, setTableDataArray] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(true);
    const [dependValue, setDependValue] = useState<any>([]);
    const [isAllParam, setAllParam] = useState<any>(null);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: cmPageSize ? cmPageSize : 10,
    });
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [isFilterData, setFilteringdata] = useState<any>([]);
    const [isFilterDataold, setFilteringdataold] = useState<any>([]);
    const orgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const dispatch = useDispatch();
    
    const callAPI = (apiURL: any, countpls: any) => {
        AxiosMain.get(`${apiURL}`).then((res) => {
            if (res.status === 200 && res.data.count > 0) {
                const totalPage = res.data.count;
                setTotalPosts(totalPage);
                setTotalPages(Math.ceil(totalPage / 10));
                setTableDataArray(res.data.results);
                if(callbackFunction){
                    callbackFunction(res.data.results)
                }
                setCurrentPostsCount(res.data.results.length);
                setCurrentPage(pagination.pageIndex + countpls);
                setIsRefetching(false);
            } else {
                setTableDataArray([]);
                setTimeout(() => {
                    setIsRefetching(false);
                }, 100)
            }
            resetTrigger();
        }).catch((e) => {
            console.log("While geting Error", e);
            const { response } = e;
            setIsError(true);
            setIsRefetching(false);
            if (response) {
                if (response.status === 403 && response.statusText === "Forbidden") {
                    enqueueSnackbar(`${useFor} Permission Denied`, { preventDuplicate: true, variant: "error", });
                }
                if (response.status === 401 && response.statusText === "Unauthorized") {
                    enqueueSnackbar(`${useFor} Unauthorized`, { preventDuplicate: true, variant: "error", });
                }
            }
        })
    }

    const getDataAPI = React.useCallback(() => {
        
        let apiURL = "";
        let countpls = 1;
        setIsRefetching(true);
        setTableDataArray([]);
        if (dependValue.length > 0) {
            let newParams: any = dependValue.join("&");
            apiURL = `${apiLink}?${newParams}`; 
            if (Object.keys(paramDependent).length > 0) {
                let str = Object.keys(paramDependent).map(key => key + '=' + paramDependent[key]).join('&');
                apiURL = apiLink+"?"+str
            }
            callAPI(apiURL, countpls);
        }
        
        // eslint-disable-next-line                                                                                 
    }, [apiLink, dependValue, paramCount, isAllParam,parentDependValue,paramDependent,triggerUpdate]);
    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: cmPageSize ? cmPageSize : 10,
        })

    }, [orgnisationData])
    const getCustomSorting = useCallback(() => {
        setParamcount((prev: any) => prev = 0);
        setDependValue((prev: any) => prev = []);
        setAllParam((prev: any) => prev = null);
        let countpls = 1;
        let staticparam = `page_size=${pagination.pageSize}&page=${pagination.pageIndex + countpls}`;
        let filterSort: any = [];
        let isfound: any = [];
        let isnotfound: any = [];
        filterSort.push(staticparam);
        if (columnFilters.length > 0 && searchData.length > 0) {
            // eslint-disable-next-line
            columnFilters.map((item: any) => {
                // eslint-disable-next-line
                searchData.map((innerItem: any, index: any) => {
                    if (innerItem.id === item.id) {
                        isfound.push(innerItem);
                        if (typeof (item.value) === 'object') {
                            if (item.value[0] && !item.value[1]) {
                                filterSort.push(`${innerItem.match}_min=${item.value}`);
                            }
                            if (item.value[1] && !item.value[0]) {
                                filterSort.push(`${innerItem.match}_max=${item.value}`);
                            }
                            if (item.value[0] && item.value[1]) {
                                filterSort.push(`${innerItem.match}_min_max=${item.value}`);
                            }
                        } else {
                            filterSort.push(`${innerItem.match}=${item.value}`);
                        }

                    } else {
                        isnotfound.push(innerItem)
                    }
                });
            })
        }
        if (sorting.length > 0 && sortingData.length > 0) {
            // eslint-disable-next-line
            sorting.map((item: any) => {
                // eslint-disable-next-line
                sortingData.map((innerItem: any, index: any) => {
                    if (innerItem.id === item.id) {
                        isfound.push(innerItem);
                        filterSort.push(item.desc ? `ordering=${innerItem.descSort}` : `ordering=${innerItem.ascSort}`);
                    }
                    else {
                        isnotfound.push(innerItem)
                    }
                });
            })
        }
        setFilteringdata(filterSort);
        // eslint-disable-next-line
    }, [columnFilters, sorting, pagination]);

    useEffect(() => {
        getCustomSorting();
    }, [getCustomSorting]);

    useEffect(() => {
        getDataAPI();
    }, [getDataAPI]);

    useEffect(() => {
        if (initialSortData.length > 0) {
            setSorting(initialSortData);
        }
    }, [initialSortData]);
    useEffect(() => {
        if (initialFilterData.length > 0) {
            setColumnFilters(initialFilterData);
        }
    }, [initialFilterData])
    useEffect(() => {
        if (isFilterData.length > 0) {
            setFilteringdataold(isFilterData);
        }
    }, [isFilterData]);
    useEffect(() => {
        if ((isFilterData.length > 0 && isFilterDataold.length > 0) && isFilterData.length === isFilterDataold.length) {
            setDependValue(isFilterData);
            setParamcount(isFilterData.length);
            setAllParam(true);
        }
    }, [isFilterData, isFilterDataold]);
    useEffect(() => {
        if (clickFuncDataShow) {
            setTableDataShow(tableDataArray);
        }
    }, [clickFuncDataShow, tableDataArray])
    return (
        <div>
            {(() => {
                switch (tableStyle) {
                    case 'default':
                        return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {
                                    isallData ? (
                                        <MaterialReactTable
                                            columns={tRow}
                                            data={tableDataArray}
                                            initialState={{ showColumnFilters: true }}
                                            enableTopToolbar={false}
                                            enablePagination={isallData}
                                            onColumnFiltersChange={setColumnFilters}
                                            state={{
                                                columnFilters,
                                            }}
                                            muiTableHeadProps={{
                                                sx: {
                                                    "& > tr": {
                                                        background: "#00ADD0"
                                                    },
                                                    "& .Mui-TableHeadCell-Content": {
                                                        justifyContent: "space-between",
                                                        marginBottom: "10px"
                                                    },
                                                    ".MuiTableCell-head .Mui-TableHeadCell-Content-Wrapper": {
                                                        color: "#fff",
                                                        fontSize:"13px"
                                                    },
                                                    ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper button": {
                                                        color: "rgb(255 255 255 / 26%)"
                                                    },
                                                    ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper input": {
                                                        color: "#fff",
                                                        padding: "8px"
                                                    },
                                                    ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper .MuiInputBase-adornedEnd::before": {
                                                        borderColor: "rgb(255 255 255 / 26%)"
                                                    },
                                                    ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper  fieldset.MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgb(255 255 255 / 26%)"
                                                    },
                                                    "& .Mui-TableHeadCell-Content-Actions button": {
                                                        opacity: 1
                                                    },
                                                    "& .Mui-TableHeadCell-Content-Actions button svg": {
                                                        color: "#fff",
                                                    }
                                                }
                                            }}
                                            columnFilterDisplayMode='popover'
                                            muiTableBodyRowProps={({ row }) => ({
                                                onDoubleClick: (event) => {
                                                    if (clickFuncDataShow) {
                                                        clickFunc(row.original, tableDataArray);
                                                    } else {
                                                        clickFunc(row.original);
                                                    }
                                                },
                                                sx: {
                                                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                                },
                                            })}
                                        />
                                    ) : (
                                            <MaterialReactTable
                                                columns={tRow}
                                                data={tableDataArray}
                                                initialState={{ showColumnFilters: true, }}
                                                enableTopToolbar={false}
                                                enablePagination={true}
                                                rowCount={totalPosts}
                                                manualFiltering
                                                manualPagination
                                                manualSorting
                                                onColumnFiltersChange={setColumnFilters}
                                                onPaginationChange={setPagination}
                                                onSortingChange={setSorting}
                                                muiToolbarAlertBannerProps={
                                                    isError
                                                        ? {
                                                            color: 'error',
                                                            children: 'Error loading data',
                                                        }
                                                        : undefined
                                                }
                                                state={
                                                    {
                                                        columnFilters,
                                                        sorting,
                                                        pagination,
                                                        showAlertBanner: isError,
                                                        showProgressBars: isRefetching,
                                                    }}
                                                columnFilterDisplayMode='popover'
                                                muiTableBodyRowProps={({ row, }) => ({
                                                    onDoubleClick: (event) => {
                                                        if (clickFuncDataShow) {
                                                            clickFunc(row.original, tableDataArray);
                                                        } else {
                                                            clickFunc(row.original);
                                                        }
                                                    },
                                                    onClick :(event)=>{
                                                      if(clickFuncDataShow){
                                                        clickFunc(row.original);
                                                      }
                                                    },
                                                
                                                    sx: {
                                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                                    },
                                                })}
                                                muiTableHeadProps={{
                                                    sx: {
                                                        "& > tr": {
                                                            background: "#00ADD0"
                                                        },
                                                        "&": {
                                                            zIndex: 200,
                                                        },
                                                        "& .Mui-TableHeadCell-Content": {
                                                            justifyContent: "space-between",
                                                            marginBottom: "10px"
                                                        },
                                                        ".MuiTableCell-head .Mui-TableHeadCell-Content-Wrapper": {
                                                            color: "#fff",
                                                            fontSize:"13px"
                                                        },
                                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper button": {
                                                            color: "rgb(255 255 255 / 26%)"
                                                        },
                                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper input": {
                                                            color: "#fff",
                                                            padding: "8px"
                                                        },
                                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper .MuiInputBase-adornedEnd::before": {
                                                            borderColor: "rgb(255 255 255 / 26%)"
                                                        },
                                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper  fieldset.MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "rgb(255 255 255 / 26%)"
                                                        },
                                                        "& .Mui-TableHeadCell-Content-Actions button": {
                                                            opacity: 1
                                                        },
                                                        "& .Mui-TableHeadCell-Content-Actions button svg": {
                                                            color: "#fff",
                                                        }
                                                    }
                                                }}
                                />
                                    )
                                }

                            </LocalizationProvider>);
                    case 'user':
                        return <UserTableStyle tData={tableDataArray} currentDataCount={currentPostsCount} count={totalPosts} page={currentPage} />;
                    default:
                        return "No Table Style Set";
                }
            })()}

            <div style={{ marginTop: "30px", display: "none", justifyContent: "center" }}>
                {tableDataArray.length > 0 && totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                    />
                )}
            </div>
        </div>
    )
}
MaterialReactDataTable.defaultProps = {
    isallData: false,
    useFor: "Data",
    tableStyle: "default",
    dependOn: "",
    apiParamValue: "",
    defaultSort: "name",
    paramDependent:{},
    searchfieldName: "search",
    searchData: {},
    sortingData: {},
    initialSortData: [],
    initialFilterData: [],
    parentDependValue:[],
    initialFilter: [],
    initialStatus: false,
    clickFuncDataShow: false,
    triggerUpdate: false,
    resetTrigger: () => {},
    clickFunc: () => { },
    setTableDataShow: () => { }
}
export default MaterialReactDataTable;
