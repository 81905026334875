import React, { useEffect, useState } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import AxiosMain from "./AxiosMain";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from 'notistack'
import Swal from "sweetalert2";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setAppNextRoute, setAppcurrentPageForm } from "../../redux/features/appStateSlice";
function PaginationEditDropDown(props: any) {
    const { isDisabled, useFor, className, replaceDefault, apiLink, defaultValue, optionLableName, saveValue, formFiled, apiDataMap, dependOn, apiParamValue, apiParamName, defaultSort } = props;
    const { labelName, valueName } = apiDataMap;
    const dispatch = useDispatch();
    const [selectPage, setSelectPage] = useState(1);
    const [arrayOptions, setArrayOptions] = useState<any[]>([]);
    const [isDefault, setDefault] = useState<any>({});
    const [defaultText, setDefaultText] = useState<any>({});
    const [isAPiOn, setAPIOn] = useState(false);
    const [isShow, setIsshow] = useState(false);
    const [isAllPost, setAllPost] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const getUniqueArray = (arr: any, index: any) => {

        const unique = arr
            .map((e: any) => e[index])

            // store the keys of the unique objects
            .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e]).map((e: any) => arr[e]);

        return unique;
    }
    const getDataAPi = (page: number) => {
        setAPIOn(true);
        let apiURL = "";
        if (defaultSort !== 'no') {
            apiURL = `${apiLink}?ordering=${defaultSort}&page=${page}`;
        } else {
            apiURL = `${apiLink}?page=${page}`;
        }
        if (dependOn !== '') {
            if (defaultSort !== 'no') {
                apiURL = `${apiLink}?ordering=${defaultSort}&page=${page}&${apiParamName}=${apiParamValue}`;
            } else {
                apiURL = `${apiLink}?page=${page}&${apiParamName}=${apiParamValue}`;
            }
        }
        AxiosMain.get(apiURL).then((res) => {
            if (totalCount !== res.data.count) {
                setTotalCount(res.data.count);
            }
            if (page === 1) {
                if (res.data.results.length > 0) {
                    setArrayOptions(res.data.results);
                }
                setSelectPage(page);
            } else {
                let orgData;
                if (dependOn !== '' && page === 1) {
                    orgData = [...res.data.results];
                } else {
                    orgData = [...res.data.results, ...arrayOptions];
                }
                const sortingArray = getUniqueArray(orgData, "id");
                const sortingData = [...sortingArray].sort((a: any, b: any) =>
                    a[`${labelName}`].toLowerCase() > b[`${labelName}`].toLowerCase() ? 1 : -1,
                );

                if (arrayOptions.length !== res.data.count) {
                    setArrayOptions(sortingData);
                }
                if (res.data.results.length === 10) {
                    setSelectPage(page);
                }
            }
            if (arrayOptions.length === res.data.count) {
                setAllPost(true);
            } else {
                setAllPost(false);
            }
            setAPIOn(false);
            if (useFor && res.data.results.length === 0) {
                enqueueSnackbar(`${useFor} Not Found`, { preventDuplicate: true, variant: "error", });
            }
        }).catch((e) => {
            console.log("Error in DropDown API", e)
            const { response } = e;
            if (response.status === 403 && response.statusText === "Forbidden") {
                if (useFor) {
                    enqueueSnackbar(`${useFor} Permission Denied`, { preventDuplicate: true, variant: "error", });
                }
            }
            if (response.status === 401 && response.statusText === "Unauthorized") {
                if (useFor) {
                    enqueueSnackbar(`${useFor} Unauthorized`, { preventDuplicate: true, variant: "error", });
                }
            }
            setAPIOn(false);
        })
    }
    const loadMorecontent = () => {
        const nextPage = selectPage + 1;
        if (dependOn !== '') {
            if (!isAPiOn && !isAllPost) {
                getDataAPi(nextPage);
            }
        } else {
            if (!isAPiOn && !isAllPost) {
                getDataAPi(nextPage);
            }
        }

    }
    // const handleScroll = (event: any) => {
    //     const listboxNode = event.currentTarget;

    //     const position = listboxNode.scrollTop + listboxNode.clientHeight;
    //     if (listboxNode.scrollHeight - position <= 1) {
    //         loadMorecontent();
    //     }
    // }
    useEffect(() => {
        if (selectPage === 1) {
            if (!isAPiOn && !isAllPost && dependOn === '') {
                getDataAPi(selectPage);
            }
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!isAPiOn && dependOn !== '' && apiParamValue !== '') {
            setSelectPage(1);
            saveValue.setFieldValue(formFiled, "");
            setArrayOptions([]);
            getDataAPi(1);
        }
        // eslint-disable-next-line
    }, [dependOn, apiParamValue])

    useEffect(() => {
        if (arrayOptions.length > 0) {
            if (arrayOptions.length !== totalCount && !isAPiOn) {
                setTimeout(() => loadMorecontent(), 100);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount, arrayOptions, isAPiOn]);
    useEffect(() => {
        if (defaultValue !== "" && (Object.keys(isDefault).length === 0 || isDefault.id !== defaultValue)) {
            AxiosMain.get(`${apiLink}${defaultValue}/`).then((res) => {
                //console.log("default ", res);
                if (res.status === 200) {
                    setDefault(res.data);
                    setIsshow(true);
                    saveValue.setFieldValue(formFiled, res.data[`${valueName}`]);
                    if (replaceDefault !== "") {
                        const newObj = { ...res.data, icc: replaceDefault };
                        setDefaultText(newObj);
                    }
                }
            }).catch((e) => {
                const { response } = e;
                //console.log(response);
                console.log("Error While geting Defualt Value");
                if (response.status === 404 && response.statusText === "Not Found") {
                    if (useFor) {
                        if (useFor === "SIM") {
                            //enqueueSnackbar(`${useFor} ${t("NotFound")}`, { preventDuplicate: true, variant: "error", });
                            let timerInterval: any
                            Swal.fire({
                                title: `${useFor} Not Found`,
                                text: "User details mismatch. Contant Setera Communications Oy",
                                timer: 3000,
                                timerProgressBar: true,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                icon: "error",
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                                willClose: () => {
                                    clearInterval(timerInterval)
                                }
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    dispatch(setAppNextRoute("/mobiles/mobile-user-list"));
                                    dispatch(setAppcurrentPageForm("process"));
                                }
                            })
                        }
                    }
                }

                if (response.status === 403 && response.statusText === "Forbidden") {
                    if (useFor) {
                        enqueueSnackbar(`${useFor} Permission Denied`, { preventDuplicate: true, variant: "error", });
                    }
                }
                setIsshow(true);
            })
        } else {
            setIsshow(true);
        }
        // eslint-disable-next-line
    }, [defaultValue, replaceDefault]);
    
    return (
        <>
            {isShow && (
                <>
                    {arrayOptions.length > 0 && (
                        <>
                            <div className={className}>
                                <div className="form-lable-name">{optionLableName}</div>
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    disabled={isDisabled}
                                    value={Object.keys(defaultText).length > 0 ? defaultText : Object.keys(isDefault).length > 0 ? isDefault : arrayOptions[0]}
                                    className="cm-global-list-name cm-global-select-icon"
                                    onChange={(event, newValue) => {
                                        if (newValue !== null && isDefault[`${labelName}`] !== newValue[`${labelName}`]) {
                                            saveValue.setFieldValue(formFiled, newValue[`${valueName}`])
                                        }
                                        else {
                                            saveValue.setFieldValue(formFiled, "")
                                        }
                                    }}
                                    sx={{ width: 300 }}
                                    options={arrayOptions}
                                    autoHighlight
                                    disableClearable
                                    freeSolo
                                    getOptionLabel={(option: any) => option[`${labelName}`]}
                                    renderOption={(props, option: any) => {
                                        const deftVal = isDefault[`${labelName}`];
                                        if (option[`${labelName}`] !== '' && option[`${labelName}`] !== deftVal) {
                                            return (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={`${formFiled}_key_${option.id}_${option[`${labelName}`]}`}>
                                                    <span className="cm-org-list-name">{option[`${labelName}`]}</span>
                                                </Box>
                                            )
                                        } else if (option[`${labelName}`] === deftVal) {
                                            return null
                                        }
                                        else {
                                            return (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={`${formFiled}_key_${option.id}_${option.name}`}>
                                                    <span className="cm-org-list-name">{option.name}</span>
                                                </Box>
                                            )
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <Box sx={{
                                                paddingRight: "15px",
                                                position: "absolute",
                                                right: 2,
                                                top: 2,
                                                bottom: 2,
                                                display: "flex",
                                                alignItems: "center",
                                                background: "#fff",
                                                zIndex: 1
                                            }}>
                                                <ArrowDropDownIcon />
                                            </Box>
                                        <TextField
                                            className="cm-org-list-name"
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                        </>
                                    )}
                                    // ListboxProps={{
                                    //     onScroll: handleScroll
                                    // }}
                                />
                                {saveValue.errors[`${formFiled}`] && saveValue.touched[`${formFiled}`] && <p className="cm-form-error">{saveValue.errors[`${formFiled}`]}</p>}
                            </div>
                        </>
                    )}
                </>
            )}


        </>
    )
}
PaginationEditDropDown.defaultProps = {
    dependOn: "",
    apiParamValue: "",
    defaultSort: "name",
    replaceDefault: ""
}
export default PaginationEditDropDown