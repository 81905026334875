import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { priceFormatWithCurrency } from '../../../components/layout/Helper';
import LoaderScreen from '../../../components/layout/LoaderScreen';
import AppPermissionHOC from '../../../components/layout/AppPermissionHOC';
import { AppAPiUrl, appModelPermission, appModelType } from '../../../configs/appData';
import TranslationGetHOC from '../../../components/layout/TranslationGetHOC';
import MaterialReactDataTable from '../../../components/layout/MaterialReactDataTable';
type Props = {
  translateDataft: any;
  translationStatus: any;
};
const SalesOrderList = (props: Props) => {
  const navigate = useNavigate();
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }

  const saleOrderRow = [
    {
      accessorFn: (row: any) => row.name,
      header: t("SalesOrderID"),
    },
    {
      accessorFn: (row: any) => row.original_contract_duration,
      header: t("ContractDuration"),
      filterVariant: 'text',
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row: any) => row.order_line.length,
      header: t("OrderLinesCount"),
      filterVariant: 'text',
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
    },
    {
      size: 300,
      accessorFn: (row: any) => new Date(row.create_date),
      id: 'create_date',
      header: t('OrderDate'),
      filterVariant: 'date-range',
      Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
    },
    {
      accessorFn: (row: any) => priceFormatWithCurrency(row.currency_id, row.amount_total),
      header: t("Total"),
      enableSorting: false,
      enableFilters: true,
      enableColumnFilterModes: false,
      enableColumnFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
    },
  ]

  const saleOrderSorting = [
    {
      descSort: "-name",
      ascSort: "name",
      id: t("SalesOrderID"),
    },
    {
      descSort: "-create_date",
      ascSort: "create_date",
      id: "create_date",
    },
  ];
  const saleOrderSearching = [
    {
      match: "name",
      id: t("SalesOrderID"),
    },
    {
      match: "create_date",
      id: "create_date",
    },
  ];
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  const getpopDate = (row: any) => navigate(`/accounting/sales-order/edit/${row.id}`);
    return (
      <div className='cm-sale-order-main ' style={{
        marginTop: "20px",
        marginBottom: "20px"
      }}>
        {isTransLoaded
            ? (<div className='cm-mobile-user-Data-table'>
              <MaterialReactDataTable
                useFor={"Accounting Sale Orders List"}
                apiLink={AppAPiUrl.AccountingSalesorder}
                tRow={saleOrderRow}
                clickFunc={getpopDate}
                sortingData={saleOrderSorting}
                searchData={saleOrderSearching}
              />
            </div>)
          : <LoaderScreen />
        }
      </div>
    );
};
function AccountSalesOrderListComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(SalesOrderList, "accounting_sales_order", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AccountSalesOrderListComponentReady, appModelPermission.ACCOUNTINGSALESORDER, appModelType.READ);