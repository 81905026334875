const appFieldTypeName = {
    "NAME": "name",
    "ACTIVE": "active",
    "CERTIFICATION_STATUS": "certificate_status",
    "VALUE": "value"
};
const appModelType = {
    READ: "READ",
    UPDATE: "UPDATE",
    CREATE: "CREATE",
    DESTROY: "DESTROY"
}
const appModelPermission = {
    "LOGENTRY": "LOGENTRY",
    "ACCOUNTING": "ACCOUNTING",
    "ACCOUNTINGBILLING": "BILLING",
    "ACCOUNTINGSUBSCRIPTION": "ACCOUNT_SUBSCRIPTION",
    "ACCOUNTINGTRAFFIC": "TRAFFIC",
    "ACCOUNTINGLICENCE": "LICENSE",
    "ACCOUNTINGSALESORDER": "SALES-ORDERS",
    "PERMISSION": "PERMISSION",
    "GROUP": "GROUP",
    "CONTENTTYPE": "CONTENTTYPE",
    "COMMISSIONS": "COMMISSIONS",
    "SESSION": "SESSION",
    "BLACKLISTEDTOKEN": "BLACKLISTEDTOKEN",
    "OUTSTANDINGTOKEN": "OUTSTANDINGTOKEN",
    "RESETPASSWORDTOKEN": "RESETPASSWORDTOKEN",
    "TOKEN": "TOKEN",
    "TOKENPROXY": "TOKENPROXY",
    "TASKRESULT": "TASKRESULT",
    "CHORDCOUNTER": "CHORDCOUNTER",
    "GROUPRESULT": "GROUPRESULT",
    "CRONTABSCHEDULE": "CRONTABSCHEDULE",
    "INTERVALSCHEDULE": "INTERVALSCHEDULE",
    "PERIODICTASK": "PERIODICTASK",
    "PERIODICTASKS": "PERIODICTASKS",
    "SOLARSCHEDULE": "SOLARSCHEDULE",
    "CLOCKEDSCHEDULE": "CLOCKEDSCHEDULE",
    "QUEUE": "QUEUE",
    "USERROLES": "USERROLES",
    "SETERAUSER": "SETERAUSER",
    "EMAILTOKEN": "EMAILTOKEN",
    "SERVICEID": "SERVICEID",
    "PROVNOTIFICATION": "PROVNOTIFICATION",
    "ORGANIZATION": "ORGANIZATION",
    "BARRINGDATA": "BARRINGDATA",
    "BARRINGMMS": "BARRINGMMS",
    "BARRINGROAMING": "BARRINGROAMING",
    "BARRINGROAMINGDATA": "BARRINGROAMINGDATA",
    "BARRINGSMS": "BARRINGSMS",
    "BARRINGVOICE": "BARRINGVOICE",
    "CARRIER": "CARRIER",
    "MOBILEDATACODE": "MOBILEDATACODE",
    "MOBILEPRIORITY": "MOBILEPRIORITY",
    "MOBILEPRODUCT": "MOBILEPRODUCT",
    "SIM": "SIM",
    "SUBSCRIPTIONVOICEPACKAGE": "SUBSCRIPTIONVOICEPACKAGE",
    "SUBSCRIPTIONDATASPEED": "SUBSCRIPTIONDATASPEED",
    "SUBSCRIPTION": "SUBSCRIPTION",
    "SUBSCRIPTIONCERTIFICATE": "SUBSCRIPTIONCERTIFICATE",
    "CATALOGSERVICES": "CATALOGSERVICES",
    "TASK": "TASK",
    "LOG": "LOG",
    "LANGUAGE": "LANGUAGE",
    "LANGUAGECATEGORY": "LANGUAGECATEGORY",
    "TRANSLATION": "TRANSLATION",
    "SETERAMODELS": "SETERAMODELS",
    "SETERAMODELLEVELPERMISSIONS": "SETERAMODELLEVELPERMISSIONS",
    "SETERAMODELFIELDS": "SETERAMODELFIELDS",
    "SETERAFIELDLEVELPERMISSIONS": "SETERAFIELDLEVELPERMISSIONS"
}
const appPermisionName = {
    CanAddBarringData: "Can Add Barring Data",
    CanChangeBarringData: "Can Change Barring Data",
    CanDeleteBarringData: "Can Delete Barring Data",
    CanViewBarringData: "Can View Barring Data",
    CanAddBarringMms: "Can Add Barring Mms",
    CanChangeBarringMms: "Can Change Barring Mms",
    CanDeleteBarringMms: "Can Delete Barring Mms",
    CanViewBarringMms: "Can View Barring Mms",
    CanAddBarringRoaming: "Can Add Barring Roaming",
    CanChangeBarringRoaming: "Can Change Barring Roaming",
    CanDeleteBarringRoaming: "Can Delete Barring Roaming",
    CanViewBarringRoaming: "Can View Barring Roaming",
    CanAddBarringRoamingData: "Can Add Barring Roaming Data",
    CanChangeBarringRoamingData: "Can Change Barring Roaming Data",
    CanDeleteBarringRoamingData: "Can Delete Barring Roaming Data",
    CanViewBarringRoamingData: "Can View Barring Roaming Data",
    CanAddBarringSms: "Can Add Barring Sms",
    CanChangeBarringSms: "Can Change Barring Sms",
    CanDeleteBarringSms: "Can Delete Barring Sms",
    CanViewBarringSms: "Can View Barring Sms",
    CanAddBarringVoice: "Can Add Barring Voice",
    CanChangeBarringVoice: "Can Change Barring Voice",
    CanDeleteBarringVoice: "Can Delete Barring Voice",
    CanViewBarringVoice: "Can View Barring Voice",
    CanAddCarrier: "Can Add Carrier",
    CanChangeCarrier: "Can Change Carrier",
    CanDeleteCarrier: "Can Delete Carrier",
    CanViewCarrier: "Can View Carrier",
    CanAddCatalogServices: "Can Add Catalog Services",
    CanChangeCatalogServices: "Can Change Catalog Services",
    CanDeleteCatalogServices: "Can Delete Catalog Services",
    CanViewCatalogServices: "Can View Catalog Services",
    CanAddMobileDataCode: "Can Add Mobile Data Code",
    CanChangeMobileDataCode: "Can Change Mobile Data Code",
    CanDeleteMobileDataCode: "Can Delete Mobile Data Code",
    CanViewMobileDataCode: "Can View Mobile Data Code",
    CanAddMobilePriority: "Can Add Mobile Priority",
    CanChangeMobilePriority: "Can Change Mobile Priority",
    CanDeleteMobilePriority: "Can Delete Mobile Priority",
    CanViewMobilePriority: "Can View Mobile Priority",
    CanAddMobileProduct: "Can Add Mobile Product",
    CanChangeMobileProduct: "Can Change Mobile Product",
    CanDeleteMobileProduct: "Can Delete Mobile Product",
    CanViewMobileProduct: "Can View Mobile Product",
    CanAddOrganization: "Can Add Organization",
    CanChangeOrganization: "Can Change Organization",
    CanDeleteOrganization: "Can Delete Organization",
    CanViewOrganization: "Can View Organization",
    CanAddProvNotification: "Can Add Prov Notification",
    CanChangeProvNotification: "Can Change Prov Notification",
    CanDeleteProvNotification: "Can Delete Prov Notification",
    CanViewProvNotification: "Can View Prov Notification",
    CanAddServiceId: "Can Add Service Id",
    CanChangeServiceId: "Can Change Service Id",
    CanDeleteServiceId: "Can Delete Service Id",
    CanViewServiceId: "Can View Service Id",
    CanAddSeteraUser: "Can Add Setera User",
    CanChangeSeteraUser: "Can Change Setera User",
    CanDeleteSeteraUser: "Can Delete Setera User",
    CanViewSeteraUser: "Can View Setera User",
    CanAddSim: "Can Add Sim",
    CanChangeSim: "Can Change Sim",
    CanDeleteSim: "Can Delete Sim",
    CanViewSim: "Can View Sim",
    CanAddSubscription: "Can Add Subscription",
    CanChangeSubscription: "Can Change Subscription",
    CanDeleteSubscription: "Can Delete Subscription",
    CanViewSubscription: "Can View Subscription",
    CanAddSubscriptionDataSpeeds: "Can Add Subscription Data Speeds",
    CanChangeSubscriptionDataSpeeds: "Can Change Subscription Data Speeds",
    CanDeleteSubscriptionDataSpeeds: "Can Delete Subscription Data Speeds",
    CanViewSubscriptionDataSpeeds: "Can View Subscription Data Speeds",
    CanAddSubscriptionVoicePackage: "Can Add Subscription Voice Package",
    CanChangeSubscriptionVoicePackage: "Can Change Subscription Voice Package",
    CanDeleteSubscriptionVoicePackage: "Can Delete Subscription Voice Package",
    CanViewSubscriptionVoicePackage: "Can View Subscription Voice Package",
    CanViewAccounting: "Can View Accounting",
    CanViewAccountingLicence: "Can View Accounting Licence",
    CanViewAccountingSalesorder: "Can View Accounting Salesorder",
    CanViewAccountingSubscription: "Can View Accounting Subscription",
    CanViewAccountingTraffic: "Can View Accounting Traffic",
    CanViewTask: "Can View Task",
    CanViewTranslation: "Can View Translation",
    CanViewUserRoles: "Can View User Roles",
    CanViewMessage: "Can View Message"
}
const AppRoutesPath = {
    PrivacyPolicy: "/privacy-policy",
    WholeSale:"/wholesale",
    WholeSaleList:"/wholesale/commissions/:id",
    WholeSaleInvoiceReport:"/wholesale/invoice-reports",
    WholeSaleCommission:"/wholesale/commissions",
    Notifications: "/notifications",
    Users: "/users",
    Admin: "/admin",
    AdminMessage: "/admin/messages",
    AdminTask: "/admin/tasks",
    AdminTranslation: "/admin/translation",
    AdminUsersRoles: "/admin/users-roles",
    AdminUsersRolesEdit: "/admin/users-roles/edit/:role",
    AdminCommissionCounting: "/admin/commission-counting",
    AdminCommissionCountingSingle: "/admin/commission-counting/:id",
    UserEdit: "/users/edit/:id",
    Accounting: "/accounting",
    Commissions: "/commissions",
    CommissionsList: "/wholesale/commissions-list",
    CommissionsSingle: "/wholesale/commissions/:id",
    Mobile: "/mobiles",
    MobileUserList: "/mobiles/mobile-user-list",
    MobileCertificateList: "/mobiles/mobile-certificate-list",
    MobileUserListAdd: "/mobiles/mobile-user-list/add",
    MobileUserListEdit: "/mobiles/mobile-user-list/edit/:id",
    MobileProvNotifications: "/mobiles/mobile-prov-notifications",
    MobileProvNotificationsEdit: "/mobiles/mobile-prov-notifications/:id",
    MobileProvNotificationsSubscriptionEdit: "/mobiles/mobile-prov-notifications/subscription/:subscription",
    AccountingBilling: "/accounting/Billing",
    AccountingSubscription: "/accounting/subscription",
    AccountingSubscriptionEdit: "/accounting/subscription/edit/:id",
    AccountingTraffic: "/accounting/traffic",
    AccountingLicence: "/accounting/licence",
    AccountingSalesOrder: "/accounting/sales-order",
    AccountingSalesOrderEdit: "/accounting/sales-order/edit/:id",
    
}
const AppAPiUrl = {
    Accountpermission: "/account-permission/",
    AccountingDescription: "/accounting/description/",
    AccountingSalesorder: "/accounting/sales-order/",
    AccountingSubscription: "/accounting/subscription/",
    AccountingBilling: "/accounting/billing/",
    AccountingBillingAttachment: "/accounting/billing/attachment/",
    AccountingCalldetails: "/accounting/call-details/",
    Addrole: "/add-role/",
    Adduser: "/add-user/",
    Confirm: "/confirm/",
    DnaProvnotification: "/dna/prov-notification/",
    DnaServiceid: "/dna/service-id/",
    AppSearchGlobal: "/search/global/",
    Forgetpassword: "/forget-password/",
    Login: "/login/",
    MobileOtp: "/mobile-otp/",
    MobileBarringdata: "/mobile/barring-data/",
    MobileBarringmms: "/mobile/barring-mms/",
    MobileBarringroamingdata: "/mobile/barring-roaming-data/",
    MobileBarringroaming: "/mobile/barring-roaming/",
    MobileBarringroamingdataupdate: "/mobile/barring-roamingdata-update/",
    MobileBarringsms: "/mobile/barring-sms/",
    MobileBarringvoice: "/mobile/barring-voice/",
    MobileCarrier: "/mobile/carrier/",
    MobileCatalog: "/mobile/catalog/",
    MobileAddCertificate: "/mobile/add-certificate/",
    MobileRemoveCertificate: "/mobile/delete-certificate/",
    MobileEndsubscription: "/mobile/end-subscription/",
    Mobiledatacode: "/mobile/mobile-data-code/",
    Mobilepriotity: "/mobile/mobile-priotity/",
    Mobileproductupdate: "/mobile/mobile-product-update/",
    Mobileproduct: "/mobile/mobile-product/",
    MobileSimupdate: "/mobile/sim-update/",
    MobileSim: "/mobile/sim/",
    MobileSubscription: "/mobile/subscription/",
    MobileSubscriptionClosedate: "/mobile/subscription-closedate/",
    MobileTempOpenclose: "/mobile/temp-open-close/",
    OrganizationOrganization: "/organization/organization/",
    Otpverify: "/otp-verify/",
    PermissionsRole: "/permissions/role/",
    PermissionsUpdateField: "/permissions/update/field/",
    PermissionsUpdateModel: "/permissions/update/model/",
    TasksAddcirtificate: "/tasks/add-cirtificate",
    TasksChangemobileproduct: "/tasks/change-mobile-product",
    TasksCreatednaopr: "/tasks/create-dna-opr",
    TasksCreateopr: "/tasks/create-opr",
    TasksCreatetask: "/tasks/create-task",
    TasksDnamodifyrominglimit: "/tasks/dna-modify-roming-limit",
    TasksDnaterminatemobile: "/tasks/dna-terminate-mobile",
    TasksDotask: "/tasks/do-task",
    TasksInsertserviceid: "/tasks/insert-service-id",
    TasksIscompleted: "/tasks/iscompleted/",
    TasksRemoveservices: "/tasks/remove-services",
    TasksRomingservices: "/tasks/roming-services",
    TasksTaskdatabarring: "/tasks/task-data-barring",
    TasksTasks: "/tasks/tasks/",
    TranslateLanguages: "translate/languages/",
    TranslateAll: "translate/all",
    TranslationUpdate: "translate/update/instance/",
    TokenRefresh: "/token/refresh/",
    Usergroup: "/user-group/",
    Userpermissionlist: "/user-permission-list/",
    Userpermission: "/user-permission/",
    Users: "/users/",
    Commissions:"/commissions/",
    CommissionDetail:"/commissions/detail/",
    ProductCategories: "/commissions/fetch/all/",
    CommissionProducts: "/commissions/product/",
    CommissionCategories: "/commissions/category/",
    CommissionproductDelete: "/commissions/product/delete/",
    CommissioncategoryDelete: "/commissions/category/delete/",
    CommissionProductList:"/commissions/product/list/",
    CommissionAgent:"/commissions/allocation/update/",
    CommissionAgentList:"/commissions/agent/list/",
    CommissionAgentAdd: "/commissions/allocation/add/",
    CommissionOrgList:"/commissions/org/list/",
    WholeSaleInvoiceReport:"/commissions/invoices-reports",
    WholeSaleCommission:"/commissions/wholesale-org/",
    WholeSaleList:"/commissions/org-wholesale/",
}
const formMessage = {
    Global: {
        Yes: "Yes",
        DontWantSaveChanges: "Areyousureyoudontwanttosavethechanges?",
        Error: "Error",
        PermissionDenied: "PermissionDenied",
        SomethingWentWrong: "SomethingWentWrong",
        Cancel: "Cancel",
        Submited: "Submited",
        YourDataHBSaved: "YourDatahasbeensaved",
        Logout: "logout",
        CheckYourLoginsDetailsInternet: "CheckYourLoginsDetailsInternet",
        NotFound:"NotFound"
    },
    Topbar: {
        CheckYourLoginsDetailsInternet: "CheckYourLoginsDetailsInternet",
        Unabletogetorganization: "Unabletogetorganization"
    },
    Sidebar: {
        SureWantLogout: "yousurewanttologout",
        Logout: "logout"
    },
    YouSureWantToLogout: "yousurewanttologout",

}
export {
    appPermisionName,
    AppAPiUrl,
    formMessage,
    appModelPermission,
    appModelType,
    appFieldTypeName,
    AppRoutesPath
}