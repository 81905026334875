import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Stack from '@mui/material/Stack';
import colorConfigs from '../../../configs/colorConfigs';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import DataTableComponent from '../../../components/layout/DataTableComponet';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import AxiosMain from '../../../components/layout/AxiosMain';
import { RootState } from '../../../redux/store';
import LoaderScreen from '../../../components/layout/LoaderScreen';
import { setAppNextRoute, setAppcurrentPageForm } from '../../../redux/features/appStateSlice';
import Swal from 'sweetalert2';
import { priceFormatWithCurrency } from '../../../components/layout/Helper';
import AppPermissionHOC from '../../../components/layout/AppPermissionHOC';
import { AppAPiUrl, appModelPermission, appModelType, formMessage } from '../../../configs/appData';
import TranslationGetHOC from '../../../components/layout/TranslationGetHOC';

type Props = {
  translateDataft: any;
  translationStatus: any;
};

const SubscriptionEdit = (props: Props) => {
  const param = useParams();
  const { id } = param;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [subscritionData, setSubscritionData] = useState<any>({});
  const isCurrenFormEidt = useSelector((state: RootState) => state.appState.appCurrentPageForm);
  const isNextRoute = useSelector((state: RootState) => state.appState.appNextRoute);
  const isPervRoute = useSelector((state: RootState) => state.appState.appPrevRoute);
  const [subscriptionLineProduct, setSubscriptionLineProduct] = useState([]);
  const [subscriptionProductTotal, setSubscriptionProductTotal] = useState(0);
  const [iComponetTranslate, setICTranslation] = useState([]);
  const [isTransLoaded, setLoaded] = useState(false);
  const { appCountry } = useSelector((state: RootState) => state.appState);
  const t = (name: string) => {
    const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
    if (ifExist.length > 0) {
      return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
    }
    return `No key found (${name})`;
  }
  const msgDontSaveChanges = t(formMessage.Global.DontWantSaveChanges);
  const msgCancel = t(formMessage.Global.Cancel);
  const msgYes = t(formMessage.Global.Yes);
  //const AllSingleSubscription = useSelector((state: any) => state.subscription.singlesubscriptionState);
  const subscriptionEditForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer_inovoice: "",
      customer_enduser: "Puzzel Oy",
      delivery_address: "Puzzel Oy",
      pricelist: "Colt porting price, International Oy (EUR)",
      reference: "SUBFI0011",
      source_document: "Puzzel Oy",
      start_date: "01 June, 2018",
      subscription_template: "Monthly",
      aggregate_invoice_group: "Monthly",
      sale_person: "Marko Lahnala",
      sale_team: "Communications",
      company: "Setera Communications Oy",
      date_next_inovoice: "01 Jan, 2023",
      to_renew: false,
      conntract_period: "36"
    },
    onSubmit: (values) => {
      console.log("Values", values);
    }
  })
  const getSingleSubscription = (id: any) => {
    AxiosMain.get(`${AppAPiUrl.AccountingSubscription}${id}`).then((res) => {
      if (res.status === 200) {
        setSubscritionData(res.data);
      }
    }).catch((e) => {
      console.log("error", e);
      dispatch(setAppNextRoute("/accounting/subscription"));
      dispatch(setAppcurrentPageForm("process"));
      setIsLoading(false);
    })
  }
  const subscriptionPage = () => {
    dispatch(setAppNextRoute("/accounting/subscription"));
    dispatch(setAppcurrentPageForm("process"));
  }
  const areYouSure = () => {
    dispatch(setAppcurrentPageForm("process"));
    // Swal.fire({
    //   text: msgDontSaveChanges,
    //   icon: 'warning',
    //   cancelButtonText: msgCancel,
    //   showCancelButton: true,
    //   confirmButtonColor: '#103256',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: msgYes,
    // }).then((result: any) => {
    //   if (result.isConfirmed) {
    //     dispatch(setAppcurrentPageForm("process"));
    //   }
    // })
  }
  useEffect(() => {
    dispatch(setAppcurrentPageForm("yes"));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isCurrenFormEidt === "yes" && isNextRoute !== "" && isNextRoute !== isPervRoute) {
      areYouSure();
    }
    // eslint-disable-next-line
  }, [isNextRoute, isCurrenFormEidt, isPervRoute]);
  useEffect(() => {

    if (id !== '' && Object.keys(subscritionData).length === 0) {
      getSingleSubscription(id);
    }
    if (Object.keys(subscritionData).length > 0) {
      //console.log("Data ", subscritionData);
      const { subLines } = subscritionData;
      const {
        partner_id,
        partner_end_customer_id,
        partner_shipping_id,
        pricelist_id, code,
        origin,
        date_start,
        template_id,
        aggregate_invoice_group_id,
        user_id,
        team_id,
        company_id,
        recurring_next_date,
        original_contract_duration,
        to_renew,
        //subLines,
        recurring_total
      } = subscritionData?.subscriptionDetails;
      if (partner_id.length > 0) {
        subscriptionEditForm.setFieldValue("customer_inovoice", partner_id[1]);
      }
      if (partner_end_customer_id.length > 0) {
        subscriptionEditForm.setFieldValue("customer_enduser", partner_end_customer_id[1]);
      }
      if (partner_shipping_id.length > 0) {
        subscriptionEditForm.setFieldValue("delivery_address", partner_shipping_id[1]);
      }
      if (pricelist_id.length > 0) {
        subscriptionEditForm.setFieldValue("pricelist", pricelist_id[1]);
      }
      if (code !== '') {
        subscriptionEditForm.setFieldValue("reference", code);
      }
      if (origin !== '' && origin !== false) {
        subscriptionEditForm.setFieldValue("source_document", origin);
      }
      if (date_start !== '') {
        subscriptionEditForm.setFieldValue("start_date", date_start);
      }
      if (template_id.length > 0) {
        subscriptionEditForm.setFieldValue("subscription_template", template_id[1]);
      }
      if (aggregate_invoice_group_id.length > 0) {
        subscriptionEditForm.setFieldValue("aggregate_invoice_group", aggregate_invoice_group_id[1]);
      }
      if (user_id.length > 0) {
        subscriptionEditForm.setFieldValue("sale_person", user_id[1]);
      }
      if (company_id.length > 0) {
        subscriptionEditForm.setFieldValue("sale_team", company_id[1]);
      }
      if (team_id.length > 0) {
        subscriptionEditForm.setFieldValue("company", team_id[1]);
      }
      if (recurring_next_date !== '' && recurring_next_date !== false) {
        subscriptionEditForm.setFieldValue("date_next_inovoice", recurring_next_date);
      }
      if (original_contract_duration !== '' && original_contract_duration !== false) {
        subscriptionEditForm.setFieldValue("conntract_period", original_contract_duration);
      }
      subscriptionEditForm.setFieldValue("to_renew", to_renew);
      if (subLines.length > 0) {
        setSubscriptionLineProduct(subLines);
      }
      setSubscriptionProductTotal(recurring_total);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [id, subscritionData]);
  const SubscriptionRow = [
    // {
    //   name: t('Product'),
    //   cell: (row: any) => row.product_id[1]
    // },
    {
      name: t('Description'),
      cell: (row: any) => row.name
    },
    {
      name: t('Quantity'),
      cell: (row: any) => row.quantity
    },
    {
      name: t('UnitPrice'),
      cell: (row: any) => priceFormatWithCurrency(subscritionData?.subscriptionDetails?.currency_id, row.price_unit)
    },
    {
      name: t('Subtotal'),
      cell: (row: any) => priceFormatWithCurrency(subscritionData?.subscriptionDetails?.currency_id, row.price_subtotal)
    }
  ];
  useEffect(() => {
    const { translateDataft } = props;
    const newCode = appCountry.toLocaleLowerCase();
    if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
      setICTranslation(translateDataft[`${newCode}`]);
      setTimeout(() => {
        setLoaded(true);
      }, 100);
    }
    else {
      setICTranslation([]);
      setLoaded(true);
    }
  }, [props, appCountry]);
  return (
    <>
      {isLoading ? <LoaderScreen /> : (
        <>
          {isTransLoaded ?
              (<>
                <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
                  <Stack direction="row" spacing={2} alignItems="center" justifyContent={"start"}>
                    <Button variant="contained" startIcon={<ArrowBackIosIcon />}
                      className="cm-btn-style"
                      sx={{
                        color: colorConfigs.btnColor.hoverbgWhite,
                        background: colorConfigs.btnColor.bgColorRed,
                        "&: hover": {
                          color: colorConfigs.btnColor.bgColorRed,
                          background: colorConfigs.btnColor.hoverbgWhite,
                        }
                      }} onClick={() => subscriptionPage()}>{t('Back')}</Button>
                  </Stack>
                </Box>
                <div className='cm-subcription-col-box' style={{ display: 'none' }}>
                  <div className='cm-user-form-wrap'>
                    <form onSubmit={subscriptionEditForm.handleSubmit}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('CustomerInvoicingAddress')} sddas</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="customer_inovoice"
                                  value={subscriptionEditForm.values.customer_inovoice}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('CustomerEndUser')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="customer_enduser"
                                  value={subscriptionEditForm.values.customer_enduser}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('DeliveryAddress')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="delivery_address"
                                  value={subscriptionEditForm.values.delivery_address}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('Pricelist')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="pricelist"
                                  value={subscriptionEditForm.values.pricelist}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('Reference')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="reference"
                                  value={subscriptionEditForm.values.reference}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('SourceDocument')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="source_document"
                                  value={subscriptionEditForm.values.source_document}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('StartDate')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="start_date"
                                  value={subscriptionEditForm.values.start_date}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('SubscriptionTemplate')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="subscription_template"
                                  value={subscriptionEditForm.values.subscription_template}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('AggregateInvoiceGroup')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="aggregate_invoice_group"
                                  value={subscriptionEditForm.values.aggregate_invoice_group}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('SalesPerson')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="sale_person"
                                  value={subscriptionEditForm.values.sale_person}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('SalesTeam')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="sale_team"
                                  value={subscriptionEditForm.values.sale_team}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('Company')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="company"
                                  value={subscriptionEditForm.values.company}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('DateofNextInvoice')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="date_next_inovoice"
                                  value={subscriptionEditForm.values.date_next_inovoice}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'>{t('ContractPeriodMonths')}</div>
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  name="conntract_period"
                                  value={subscriptionEditForm.values.conntract_period}
                                  onChange={subscriptionEditForm.handleChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item lg={4} md={6} xs={12}>
                            <Box sx={{ width: "100%" }}>
                              <div className='form-lable-name'></div>
                              <FormControl sx={{ width: "100%" }}>
                                <FormControlLabel
                                  className="form-checkbox-text"
                                  control={
                                    <Checkbox
                                      name="to_renew"
                                      checked={subscriptionEditForm.values.to_renew}
                                      onChange={subscriptionEditForm.handleChange}
                                    />
                                  }
                                  label={t("Torenew")}
                                />
                              </FormControl>
                            </Box>
                          </Grid>

                        </Grid>
                      </Box>
                    </form>
                  </div>
                </div>
                <div className='cm-subscription-detaile-wrapper'>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={12} xs={12}>
                        <Box className='cm-subcription-col-box'>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('CustomerInvoicingAddress')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.customer_inovoice}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('CustomerEndUser')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.customer_enduser}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('DeliveryAddress')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.delivery_address}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('Pricelist')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.pricelist}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('Reference')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.reference}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('SourceDocument')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.source_document}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('StartDate')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.start_date}</Box>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={12} xs={12}>
                        <Box className='cm-subcription-col-box'>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('SubscriptionTemplate')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.subscription_template}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('AggregateInvoiceGroup')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.aggregate_invoice_group}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('SalesPerson')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.sale_person}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('SalesTeam')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.sale_team}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('Company')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.company}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('DateofNextInvoice')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.date_next_inovoice}</Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('Torenew')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">
                              <FormControl sx={{ width: "100%" }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="to_renew"
                                      checked={subscriptionEditForm.values.to_renew}
                                      onChange={subscriptionEditForm.handleChange}
                                    />}
                                  label="" />
                              </FormControl>
                            </Box>
                          </Stack>
                          <Stack direction="row" spacing={3} className="col-box-inner-row">
                            <Box sx={{ width: "50%" }} className="cm-col-box-th">{t('ContractPeriodMonths')}</Box>
                            <Box sx={{ width: "50%" }} className="cm-col-box-td">{subscriptionEditForm.values.conntract_period}</Box>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ width: "100%" }} className="subs-line-title">{t('SubscriptionLines')}</Box>
                  <Box sx={{ width: "100%" }} className="sub-line-wrapper">
                    <Box className='cm-single-sub-line-Data-table'>
                      {subscriptionLineProduct.length > 0 && (
                        <DataTableComponent isWithBG={false} tRow={SubscriptionRow} tData={subscriptionLineProduct} />
                      )}
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent={"end"} className="sub-table-price">
                      <Box sx={{ marginTop: '15px' }}>
                        <Box className='cm-order-sale-total-tbl' sx={{ borderTop: 'none' }}>
                          <Box className='order-sale-table-data tfoot'>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent={"between"}>
                              <Box className='order-sl-th'>{t('RecurringPrice')}:</Box>
                              <Box className='order-sl-td'>{priceFormatWithCurrency(subscritionData?.subscriptionDetails?.currency_id, subscriptionProductTotal)}</Box>
                            </Stack>
                          </Box>
                        </Box>
                      </Box>
                    </Stack>
                    {/* <Box sx={{ marginTop: '30px' }} className="order-sale-term-txt">
                      {t('RelatedtrafficreportsinvoicesendwithseparatemessagetoVidarThorsen')}
                    </Box> */}
                  </Box>
                </div>
            </>) : <LoaderScreen />
          }
        </>
      )}
    </>
  )
}
function AccountSubscriptionEditComponentReady({ isAllow }: any) {
  const dependency = { require: ['Sidebar'] };
  const MainComponent = TranslationGetHOC(SubscriptionEdit, "accounting_subscription", isAllow, {}, dependency);
  return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default AppPermissionHOC(AccountSubscriptionEditComponentReady, appModelPermission.ACCOUNTINGSUBSCRIPTION, appModelType.UPDATE);