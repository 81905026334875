import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Box from '@mui/material/Box';
import LoaderScreen from './LoaderScreen';

const DataTableComponent = (props: any) => {
  const { isWithBG, tRow, tData, className, isRounded } = props;
  const [isPaginate, setisPaginate] = useState(false);
  const [isLoaded, setisLoaded] = useState(true);
  // eslint-disable-next-line
  const [perPage, setPerPage] = useState(1);
  const rowPerPage = "Rows Per Page";
  useEffect(() => {
    if (tData.length > 0) {
      if (tData.length > 10) {
        setisPaginate(true);
        setTimeout(() => {
          setisLoaded(false)
        }, 100);
      }
      else if (tData.length > 0 || tData.length < 10) {
        setisPaginate(false);
        setTimeout(() => {
          setisLoaded(false)
        }, 100);
      }
      else {
        setisLoaded((prev) => prev = true);
      }
    } else if (tData.length === 0) {
      setisLoaded(true);
      setTimeout(() => {
        setisLoaded(false);
      }, 100);
    }
    else {
      setisPaginate(false);
      setTimeout(() => {
        setisLoaded(false);
      }, 100);
    }
  }, [tData]);
    if (isWithBG) {
        return (
          <Box className="cm-table-with-bg">
            <Box
              className={`no-b cm-table-main-wrapper ${
                isRounded ? "cm-table-rounded" : " "
              }`}
            >
              {tRow.length > 0 && tData.length >= 0 && (
                <DataTable
                  className={
                    className
                      ? `cm-table-component ${className}`
                      : "cm-table-component"
                  }
                  pagination={isPaginate}
                  paginationComponentOptions={{ rowsPerPageText: rowPerPage }}
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                  columns={tRow}
                  data={tData}
                  paginationDefaultPage={perPage}
                  progressPending={isLoaded}
                  progressComponent={<LoaderScreen />}
                />
              )}
            </Box>
          </Box>
        );
    } else {
        return (
            <Box className={`no-b cm-table-main-wrapper ${isRounded ? "cm-table-rounded" : " "}`}>
            {tRow.length > 0 && tData.length > 0 && (
              <DataTable
                className={className ? `cm-table-component ${className}` : "cm-table-component"}
                pagination={isPaginate}
                paginationComponentOptions={{ rowsPerPageText: rowPerPage }}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                columns={tRow}
                data={tData}
                paginationDefaultPage={perPage}
                progressPending={isLoaded}
                progressComponent={<LoaderScreen />}
              />
            )}
            </Box>
        );
    }

}

DataTableComponent.defaultProps = {
    tData: [],
    tRow: [],
}
export default DataTableComponent;