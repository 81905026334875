import React, { useState, useEffect } from "react";
import AxiosMain from "./AxiosMain";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Pagination } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    MaterialReactTable,
    type MRT_ColumnFiltersState,
} from 'material-react-table';
import { enqueueSnackbar } from "notistack";
import LoaderScreen from "./LoaderScreen";
function PaginationMaterialTable(props: any) {
    const { cmSizeShow, useFor, dependRun, isallData, clickFunc, dependValue, apiLink, tRow, dependOn, apiParamName, apiParamValue } = props;
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableDataArray, setTableDataArray] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([],);
    const orgnisationData = useSelector((state: RootState) => state.appState.appOrg);
    const getDataAPI = React.useCallback((data: any) => {
        if (dependRun) {
        setLoading((prev) => prev = true);
        setTableDataArray((prev) => prev = [])
        let apiURL = "";

            if (dependOn !== '') {
                apiURL = `${apiLink}?page=${data}&${apiParamName}=${apiParamValue}`
            }
            else if (Object.keys(dependValue).length > 0) {
                let str = Object.keys(dependValue).map(key => key + '=' + dependValue[key]).join('&');
                apiURL = `${apiLink}?page=${data}&${str}`
            }
            else {
                apiURL = `${apiLink}?page=${data}`
            }
        AxiosMain.get(`${apiURL}`).then((res) => {
            setTimeout(() => setLoading(false), 100);
            if (res.status === 200 && res.data.count > 0) {
                const totalPage = res.data.count;
                setTotalPages(Math.ceil(totalPage / 10));
                if(cmSizeShow){
                    setTableDataArray(res.data.results.slice(0, cmSizeShow));
                }else{
                    setTableDataArray(res.data.results);
                }
                
                setCurrentPage(data);
            } else {
                setTableDataArray([]);
            }
        }).catch((e) => {
            console.log("While geting Error", e);
            const { response } = e;
            if (response) {
                if (response.status === 403 && response.statusText === "Forbidden") {
                    enqueueSnackbar(`${useFor} Permission Denied`, { preventDuplicate: true, variant: "error", });
                }
                if (response.status === 401 && response.statusText === "Unauthorized") {
                    enqueueSnackbar(`${useFor} Unauthorized`, { preventDuplicate: true, variant: "error", });
                }
            }
            setLoading(false);
        })
        } else {
            setTableDataArray([]);
            setLoading((prev) => prev = false);
        }
        // eslint-disable-next-line
    }, [orgnisationData, apiLink, apiParamValue, dependValue, dependRun]);
    useEffect(() => {
        getDataAPI(currentPage);
        // eslint-disable-next-line
    }, [getDataAPI]);

    const paginationChange = (event: any, value: number) => {
        if (totalPages >= value) {
            getDataAPI(value);
        }
    }
    return (
        <>
            <div className={`cm-material-table-component  cm-paginate-data-table ${isLoading && "active-loader"}`}>
                {isLoading
                    ? (<LoaderScreen />)
                    : (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MaterialReactTable
                                columns={tRow}
                                data={tableDataArray}
                                initialState={{ showColumnFilters: true }}
                                enableTopToolbar={false}
                                enablePagination={isallData}
                                onColumnFiltersChange={setColumnFilters}
                                state={{
                                    columnFilters,
                                }}
                                muiTableHeadProps={{
                                    sx: {
                                        "& > tr": {
                                            background: "#00ADD0"
                                        },
                                        "& .Mui-TableHeadCell-Content": {
                                            justifyContent: "space-between",
                                            marginBottom: "10px"
                                        },
                                        ".MuiTableCell-head .Mui-TableHeadCell-Content-Wrapper": {
                                            color: "#fff",
                                            fontSize:"13px"
                                        },
                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper button": {
                                            color: "rgb(255 255 255 / 26%)"
                                        },
                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper input": {
                                            color: "#fff",
                                            padding: "8px"
                                        },
                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper .MuiInputBase-adornedEnd::before": {
                                            borderColor: "rgb(255 255 255 / 26%)"
                                        },
                                        ".MuiTableCell-head .MuiCollapse-entered .MuiCollapse-wrapper  fieldset.MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgb(255 255 255 / 26%)"
                                        },
                                        "& .Mui-TableHeadCell-Content-Actions button": {
                                            opacity: 1
                                        },
                                        "& .Mui-TableHeadCell-Content-Actions button svg": {
                                            color: "#fff",
                                        }
                                    }
                                }}
                                columnFilterDisplayMode='popover'
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        clickFunc(row.original);
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                                />
                            </LocalizationProvider>
                        </>)}

                <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
                    {tableDataArray.length > 0 && totalPages > 1 && !isallData && (
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={paginationChange}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

PaginationMaterialTable.defaultProps = {
    useFor: "Data",
    isallData: false,
    dependRun: true,
    dependOn: "",
    apiParamValue: "",
    defaultSort: "name",
    searchfieldName: "search",
    dependValue: {},
    clickFunc: () => { },
}
export default PaginationMaterialTable