import {
  AppBar,
  Toolbar,
  Stack,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import ReactFlagsSelect from "react-flags-select";
import React, { useEffect, useState, } from "react";
import AxiosMain from "../../components/layout/AxiosMain";
import Swal from "sweetalert2";
import { resetOrderSale } from "../../redux/features/orderSaleStateSlice";
import { resetUserState } from "../../redux/features/usersStateSlice";
import { resetTrafficState } from "../../redux/features/trafficStateSlice";
import { resetTaskState } from "../../redux/features/tasksStateSlice";
import { resetSupportState } from "../../redux/features/supportStateSlice";
import { resetSubsriptionState } from "../../redux/features/subscriptionStateSlice";
import { resetSipTrucksState } from "../../redux/features/sipTunksStateSlice";
import { removeorganizationState } from "../../redux/features/organizationStateSlice";
import { resetMobileUserState } from "../../redux/features/mobileUserStateSlice";
import { removeMessageState } from "../../redux/features/messagesStateSlice";
import { resetLicenceState } from "../../redux/features/licenceStateSlice";
import { resetDataConnectionState } from "../../redux/features/dataConnectionStateSlice";
import { removeAuthState, setUserState } from "../../redux/features/authStateSlice";
import { resetAppState, setAppCountry, setAppNextRoute, setAppPermission, setAppcurrentPageForm, setappCountryList } from "../../redux/features/appStateSlice";
import OrganisationDropDown from "../layout/OrganisationDropDown";

import { AppAPiUrl, formMessage } from "../../configs/appData";
import TopBarSearchComponent from "../layout/TopBarSearchComponent";
const Topbar = (props: any) => {
  const { drawerWidth, handleDrawerToggle } = props;
  const dispatch = useDispatch();
  const [countrySelected, setCountrySelected] = useState("GB");
  const countriesLable = Object(
    {
      GB: { primary: "English" },
      ES: { primary: "Spanish" },
      IT: { primary: "Italian" },
      FI: { primary: "Finnish" },
      SE: { primary: "Swedish" },
    }
  );

  const [sideBarText, setSideBarText] = useState("");
  //const [userName, setUserName] = useState("");
  const [iComponetTranslate, setICTranslation] = useState([]);
  const { appState } = useSelector((state: RootState) => state.appState);
  const appTitle = useSelector((state: RootState) => state.appState.appTitle);
  const userDetails = useSelector((state: RootState) => state.authState.userState);
  const { appCountry, appAllTranslate } = useSelector((state: RootState) => state.appState);
  const appCountryList = useSelector((state: RootState) => state.appState.appCountryList);
  // const [appCountryState, setAppCountryState] = useState(["GB", "ES", "IT", "FI", "SE"]);
  // const [appCountryLabels, setAppCountryLabels] = useState();
  const checkUser = useSelector((state: RootState) => state.authState.authState);
  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);
  //const openProfile = Boolean(anchorElProfile);
  const navigate = useNavigate();
  const t = (name: string) => {
    if (iComponetTranslate.length > 0) {
      const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
      if (ifExist.length > 0) {
        return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
      }
      return `No key found (${name})`;
    }
  }
  const msgSureWantLogout = t(formMessage.Sidebar.SureWantLogout);
  const msgLogout = t(formMessage.Sidebar.Logout);
  const msgCancel = t(formMessage.Global.Cancel);

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {setAnchorElProfile(event.currentTarget);};

  const handleCloseProfile = () => {setAnchorElProfile(null);};

  const userLogout = () => {
    Swal.fire({
      text: msgSureWantLogout,
      icon: 'warning',
      cancelButtonText: msgCancel,
      showCancelButton: true,
      confirmButtonColor: '#103256',
      cancelButtonColor: '#d33',
      confirmButtonText: msgLogout,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(resetAppState());
        dispatch(resetDataConnectionState());
        dispatch(resetLicenceState());
        dispatch(removeMessageState());
        dispatch(resetMobileUserState());
        dispatch(removeorganizationState());
        dispatch(resetSipTrucksState());
        dispatch(resetSubsriptionState());
        dispatch(resetSupportState());
        dispatch(resetTaskState());
        dispatch(resetTrafficState());
        dispatch(resetUserState());
        dispatch(resetOrderSale());
        dispatch(removeAuthState());
        localStorage.clear();
        navigate("/");
      }
    })
  };
  useEffect(() => {
    const newCode: any = appCountry;
    if (appCountry !== countrySelected) {
      setCountrySelected(newCode);
    }
    // eslint-disable-next-line
  }, [appCountry]);


  useEffect(() => {
    let displayText;
    if (appTitle === "Home") {
      displayText = appTitle;
    } else {
      displayText = appTitle.replace(/ /g, "");
    }
    const tempText = t(`sidebar_${displayText}`);
    setSideBarText(tempText);
    // eslint-disable-next-line
  }, [appState, appCountry, sideBarText, iComponetTranslate]);

  const getUserPermissions = (userData: any) => {
    const { role } = userData;
    AxiosMain.get(`${AppAPiUrl.PermissionsRole}${role}/`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          dispatch(setAppPermission(res.data));
        }
      }
    }).catch((e) => {
      console.log("Error While getting User Detils ", e);
    })
  }
  const getUserData = () => {
    AxiosMain.get(`${AppAPiUrl.Users}${checkUser.user.user_id}/`).then((res) => {
      //console.log("Response ", res);
      if (res.status === 200) {
        dispatch(setUserState(res.data));
      }
    }).catch((e) => {
      console.log("Error While getting User Detils ", e.response);
      const { response } = e;
      if (response) {
        if (response.status === 403 && response.statusText === "Forbidden") {
          Swal.fire({
            title: "Permission Denied!",
            text: `Unable to get your Details`,
            icon: "error"
          })
        }
        else if (response.status === 401 && response.statusText === "Unauthorized") {
          Swal.fire({
            title: "Error",
            text: `Unauthorized`,
            icon: "error"
          })
        }
      }

    })
  }
  const getAppCountryList = () => {
    AxiosMain.get(`${AppAPiUrl.TranslateLanguages}`).then((res) => {
      if (res.status === 200 && res.data.count > 0) {
        dispatch(setappCountryList(res.data.results));
      }
    }).catch((e) => console.log("erroe while getting translation county List", e));
  }
  useEffect(() => {
    if (Object.keys(userDetails).length === 0 && Object.keys(checkUser).length > 0) {
      getUserData();
    }
    if (Object.keys(userDetails).length > 0) {
      getUserPermissions(userDetails);
    }

  // eslint-disable-next-line
  }, [userDetails, checkUser]);

  useEffect(() => {
    if (Object.keys(checkUser).length > 0) {
      if (appCountryList.length === 1) {
        getAppCountryList();
        }
      }
    // eslint-disable-next-line
  }, [appCountryList, checkUser])
  useEffect(() => {
    const newCode = appCountry.toLocaleLowerCase();
    const findifExist = appAllTranslate.filter((item: any) => item.name === "Sidebar" && item[`${newCode}`]);
    if (findifExist.length > 0) {
      setICTranslation(findifExist[0][`${newCode}`]);
    } else {
      setICTranslation([]);
    }
  }, [appAllTranslate, appCountry]);
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        boxShadow: "unset",
        backgroundColor: colorConfigs.sidebar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar className="cm-topbar-wrap">
        <Stack
          direction="row"
          alignItems="center"
          spacing={4}
        >
          <Box  sx={{ width: "auto", display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Box sx={{ fontWeight: "600" }}>{appTitle ? sideBarText : appState ? sideBarText : "No"} </Box> */}
          </Box>
         <Box sx={{margin:"0 !important"}}>  <TopBarSearchComponent /> </Box>
          {/* <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
            >
              <OrganisationDropDown /> 
               <Box
                sx={{
                  display: { xs: "flex", sm: "flex" },
                  margin: { xs: "0 10px !important" },
                }}
              >
                <ReactFlagsSelect
                  className="cm-logged-in-fleg-select"
                  selected={countrySelected}
                  showSelectedLabel={false}
                  countries={appCountryState}
                  customLabels={appCountryLabels}
                  onSelect={(code) => dispatch(setAppCountry(code))}
                />
              </Box>

              <Tooltip title="Open settings">
                <IconButton
                  onClick={handleClickProfile}
                  aria-controls={openProfile ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfile ? "true" : undefined}
                  sx={{ p: 0, margin: { xs: "0 10px !important" } }}
                >
                  <Avatar sx={{ bgcolor: "#103256" }}>{userName.length > 0 ? userName : ""}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorElProfile}
                id="account-menu"
                open={openProfile}
                onClose={handleCloseProfile}
                onClick={handleCloseProfile}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: "200px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem sx={{ marginBottom: "10px" }} onClick={() => {
                  if (Object.keys(userDetails).length > 0) {
                    dispatch(setAppNextRoute(`/users?id=${userDetails.id}`));
                    dispatch(setAppcurrentPageForm("process"));
                  } else {
                    dispatch(setAppNextRoute(`/users`));
                    dispatch(setAppcurrentPageForm("process"));
                  }
                }}>
                  <Avatar /> {t("Profile")}
                </MenuItem>
                <MenuItem sx={{ marginBottom: "10px" }} onClick={() => userLogout()}>
                  <Logout />
                  <div style={{ paddingLeft: "10px" }}>{t("logout")}</div>
                </MenuItem>
              </Menu> 
            </Stack>
          </Box> */}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
export default Topbar;
