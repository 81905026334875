import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from "@mui/icons-material/Remove";
import colorConfigs from "../../configs/colorConfigs";
import AxiosMain from "../../components/layout/AxiosMain";
import { AppAPiUrl, appFieldTypeName, appModelPermission, appModelType } from "../../configs/appData";
import Swal from "sweetalert2";
import MaterialReactDataTable from "../../components/layout/MaterialReactDataTable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import TranslationGetHOC from "../../components/layout/TranslationGetHOC";
import AppPermissionHOC from "../../components/layout/AppPermissionHOC";
import LoaderScreen from "../../components/layout/LoaderScreen";
import { isFieldPermission } from "../../components/layout/Helper";
type Props = {
    translateDataft: any;
    translationStatus: any;
};
function Mobilecertificate(props: Props) {
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const checkUser = useSelector((state: RootState) => state.authState.authState);
    const appPermission = useSelector((state: RootState) => state.appState.appPermission)
    const { appCountry } = useSelector((state: RootState) => state.appState);

    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const formSwalTranslation = {
        formSwalTitle: t('SomethingWentWrong'),
        formUpdate: t('Update'),
    }
    const mobileSearching = [
        {
            match: "name",
            id: t("Username"),
        },
        {
            match: "number",
            id: t("MobileNumber"),
        },
    ];
    const mobileSorting = [
        {
            descSort: "-name",
            ascSort: "name",
            id: t("Username"),
        },
        {
            descSort: "-number",
            ascSort: "number",
            id: t('MobileNumber'),
        },
        {
            descSort: "-active",
            ascSort: "active",
            id: t("Status"),
        },
    ]
    const mobileCertificateRow = [
        {
            accessorFn: (row: any) => row.name,
            header: t("Username"),
        },
        {
            accessorFn: (row: any) => row.number,
            header: t("MobileNumber"),
        },
        {

            accessorFn: (row: any) => (
                <Box className="cm-table-td">
                    {
                        row.certificate_status ? (
                            <Box sx={{
                                color: colorConfigs.btnColor.hoverbgWhite, background: colorConfigs.btnColor.bgColorGreen,
                            }} className="cm-btn-sm-style">
                                Added
                            </Box>
                        ) : (<Box sx={{
                            color: colorConfigs.btnColor.hoverbgWhite,
                            background: colorConfigs.btnColor.bgColorRed,
                        }} className="cm-btn-sm-style">
                            Not Added
                        </Box>
                        )
                    }

                </Box>
            ),
            header: t("Status"),
            filterVariant: 'text',
            enableSorting: true,
            enableFilters: false,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false
        },
        {
            accessorFn: (row: any) => {
                const isAllowUpdate: any = isFieldPermission(checkUser, appPermission, appModelPermission.SUBSCRIPTION, appFieldTypeName.CERTIFICATION_STATUS, appModelType.UPDATE);
                if (isAllowUpdate) {
                    if (row.certificate_status) {
                        return (
                            <Box className="cm-table-td">
                                <Button color="error" variant="contained" onClick={() => removeCertificate(row.id, false)} className="cm-btn-sm-style">
                                    <RemoveIcon /> {`${t('Remove')} ${t('Mobilecertificate')}`}
                                </Button>
                            </Box>)
                    } else {
                        return (
                            <Box className="cm-table-td">
                                <Button variant="contained" onClick={() => addCertificate(row.id, true)} className="cm-btn-sm-style">
                                    <AddIcon /> {`${t('Add')} ${t('Mobilecertificate')}`}
                                </Button>
                            </Box>)
                    }
                } else {
                    return (
                        <Box className="cm-table-td">
                            <Button onClick={() => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Not Allowed",
                                    icon: "error"
                                });
                            }} color={row.certificate_status ? "success" : "error"} variant="contained" className="cm-btn-sm-style">
                                <RemoveIcon /> {`${row.certificate_status ? t('Remove') : t('Add')} ${t('Mobilecertificate')}`}
                            </Button>
                        </Box>)
                }

            },
            header: t("Action"),
            enableSorting: false,
            enableFilters: false,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false
        },
    ]
    const removeCertificate = (id: any, val: any) => {
        AxiosMain.patch(`${AppAPiUrl.MobileRemoveCertificate}${id}/`, { certificate_status: val }).then((res: any) => {
            if (res.status === 200) {
                Swal.fire({
                    title: formSwalTranslation.formUpdate,
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            }
        }).catch((e) => {
            console.log("Error while adding MobileCertificate");
            Swal.fire({
                title: formSwalTranslation.formSwalTitle,
                icon: 'warning',
            })
        })
    }
    const addCertificate = (id: any, val: any) => {
        AxiosMain.patch(`${AppAPiUrl.MobileAddCertificate}${id}/`, { certificate_status: val }).then((res: any) => {
            if (res.status === 200) {
                Swal.fire({
                    title: formSwalTranslation.formUpdate,
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowEscapeKey: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            }
        }).catch((e) => {
            console.log("Error while adding MobileCertificate");
            Swal.fire({
                title: formSwalTranslation.formSwalTitle,
                icon: 'warning',
            })
        })
    }
    const initialSortingData = [
        {
            desc: false,
            id: t("Username"),
        },
    ]
    const initialFilterData = [
        {
            match: "carrier_id",
            value: 2,
        },
        {
            match: "active",
            value: true
        }
    ]
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    return (
        <div className='cm-mobile-certificate-main' style={{ marginTop: "20px" }}>
            {isTransLoaded ? (
                <MaterialReactDataTable
                    useFor={"Mobile Certification List"}
                    apiLink={AppAPiUrl.MobileSubscription}
                    tRow={mobileCertificateRow}
                    searchData={mobileSearching}
                    sortingData={mobileSorting}
                    initialSortData={initialSortingData}
                    initialFilter={initialFilterData}
                    initialStatus={true} />
            ) : <LoaderScreen />}
        </div>
    )
}
function MSCertificateComponentReady({ isAllow }: any) {
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(Mobilecertificate, "mobile_certificate", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}

export default AppPermissionHOC(MSCertificateComponentReady, appModelPermission.SUBSCRIPTIONCERTIFICATE, appModelType.READ)
