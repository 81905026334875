import { Box } from "@mui/material";
import LoaderScreen from "../../../components/layout/LoaderScreen";
import TranslationGetHOC from "../../../components/layout/TranslationGetHOC";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import MaterialReactDataTable from "../../../components/layout/MaterialReactDataTable";
import { AppAPiUrl } from "../../../configs/appData";
import * as changeCase from "change-case";


function InvoiceReportPageComponent(props:any){
    const [iComponetTranslate, setICTranslation] = useState([]);
    const [isTransLoaded, setLoaded] = useState(false);
    const [tableSelectOptions, setTableSelectOptions] = useState<any>([]);
    const [tableDataShow, setTableDataShow] = useState([]);
    const { appCountry } = useSelector((state: RootState) => state.appState);
    const t = (name: string) => {
        const ifExist: any = iComponetTranslate.filter((item: any) => item.label === name);
        if (ifExist.length > 0) {
            return ifExist[0].value ? ifExist[0].value : `No translate found (${ifExist[0].label})`;
        }
        return `No key found (${name})`;
    }
    const billingRow = [
        {
            //size: 300,
            accessorFn: (row: any) => row.customer?.name ? row.customer?.name : "-",
            id: 'customer',
            header: t('Customer'),
            filterVariant: 'customer',

        },
        {
            accessorFn: (row: any) => new Date(row.date_invoice),
            header: t('InvoiceDate'),
            filterVariant: 'date-range',
            //size: 230,
            Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
        },
        {
            accessorFn: (row: any) => "#"+row.number,
            header: t('Number'),
            filterVariant: 'text',
            //size: 230
        },

        {
            //size: 300,
            accessorFn: (row: any) => new Date(row.date_due),
            id: 'due_date',
            header: t('DueDate'),
            filterVariant: 'date-range',
            Cell: ({ cell }: any) => cell.getValue()?.toLocaleDateString(),
        },
        {
            accessorKey: 'tax_excluded',
            header: t('TaxExcluded'),
            //size: 230,
            accessorFn: (row: any) => "$"+row.amount_untaxed,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'tax',
            header: t('Tax'),
            //size: 230,
            accessorFn: (row: any) => "$"+row.amount_tax,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'total',
            header: t('Total'),
            //size: 230,
            accessorFn: (row: any) => "$ "+row.amount_total,
            enableSorting: false,
            enableFilters: true,
            enableColumnFilterModes: false,
            enableColumnFilters: false,
            enableColumnActions: false,
            enableColumnFilter: false,
        },
        {
            header: t('Status'),
            //size: 230,
            accessorFn: (row: any) => <Box className="cm-table-td " sx={{ cursor: "pointer" }} onClick={() => {
                console.log(row);
            }}>{row.state !== '' ? changeCase.capitalCase(row.state) : "No state"}</Box>,
            filterVariant: "select",
            filterSelectOptions: tableSelectOptions
        },

    ];
    const getUniqueArray = (arr: any, index: any) => {
        const unique = arr
            .map((e: any) => e[index])
            // store the keys of the unique objects
            .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e]).map((e: any) => arr[e]);
        return unique;
    }
    const getpopDate = (row: any, tableData: any) => console.log(row);
    const cmSetTableData = (rows: any) => setTableDataShow(rows);
    useEffect(() => {
        const { translateDataft } = props;
        const newCode = appCountry.toLocaleLowerCase();
        if (translateDataft && translateDataft.hasOwnProperty(`${newCode}`)) {
            setICTranslation(translateDataft[`${newCode}`]);
            setTimeout(() => {
                setLoaded(true);
            }, 100);
        }
        else {
            setICTranslation([]);
            setLoaded(true);
        }
    }, [props, appCountry]);
    useEffect(() => {
        if (tableDataShow.length > 0) {
            const newOptions = tableDataShow.map((item: any) => {
                let output: any = {};
                if (item.state === "open" || item.state === "Open") {
                    output["text"] = "Open";
                    output["value"] = "true";
                }
                else {
                    output["text"] = "Close";
                    output["value"] = "false";
                }
                return output;
            })
            const uniqueOptions = getUniqueArray(newOptions, "text");
            setTableSelectOptions(uniqueOptions)
        }
    }, [tableDataShow]);
    return(
        <Box className='cm-global-tabs-wrapper' sx={{ overflow: "hidden", borderRadius: "8px", marginTop: "20px" }}>
                <div className='cm-form-inner-fields cm-pagination-billing-dt cm-table-remvove-style'>
                     {isTransLoaded 
                        ? <MaterialReactDataTable 
                            apiLink={AppAPiUrl.WholeSaleInvoiceReport}
                            tRow={billingRow}
                            clickFunc={getpopDate}
                            setTableDataShow={cmSetTableData}
                            /> 
                        : <LoaderScreen/>}
                </div>
        </Box>
    )
}

function WholeSaleInvoiceReportPage({ isAllow2}: any) {
    const isAllow = true;
    const dependency = { require: ['Sidebar'] };
    const MainComponent = TranslationGetHOC(InvoiceReportPageComponent, "accounting_billing", isAllow, {}, dependency);
    return isAllow ? <MainComponent /> : (<LoaderScreen msg="Loading ..." />)
}
export default WholeSaleInvoiceReportPage;